import React, { useEffect, useState } from "react";
import "../../asset/css/community/Navigation.css";
import Icon_Home_Orange from "../../asset/icon/Icon_Home_Orange.svg";
import Icon_Home_Gray from "../../asset/icon/Icon_Home_Gray.svg";
import Icon_Find_Orange from "../../asset/icon/Icon_Find_Orange.svg";
import Icon_Find_Gray from "../../asset/icon/Icon_Find_Gray.svg";
import Icon_Club_Orange from "../../asset/icon/Icon_Club_Orange.svg";
import Icon_Club_Gray from "../../asset/icon/Icon_Club_Gray.svg";
import Icon_My_Orange from "../../asset/icon/Icon_My_Orange.svg";
import Icon_My_Gray from "../../asset/icon/Icon_My_Gray.svg";

function Navigation() {
  const [page, setPage] = useState();
  const param_path = window.location.pathname.split("/");
  const session_key = window.localStorage.getItem("session_key");
  useEffect(() => {
    switch (param_path[2]) {
      case "home":
        setPage(0);
        break;
      case "club":
        setPage(1);
        break;
      case "myclub":
        setPage(2);
        break;
      case "mypage":
        setPage(3);
        break;
      default:
    }
  }, []);

  return (
    <div className="div-navigation-section">
      <div
        className={
          page === 0
            ? "div-navigation-menu-selected Body6M"
            : "div-navigation-menu Body6M"
        }
        onClick={() => {
          if (session_key) window.location.href = "/community/home/0";
          else alert("로그인 후 이용 가능합니다.");
        }}
      >
        <img src={page === 0 ? Icon_Home_Orange : Icon_Home_Gray} alt="" />홈
      </div>
      <div
        className={
          page === 1
            ? "div-navigation-menu-selected Body6M"
            : "div-navigation-menu Body6M"
        }
        onClick={() => {
          if (session_key) window.location.href = "/community/club";
          else alert("로그인 후 이용 가능합니다.");
        }}
      >
        <img src={page === 1 ? Icon_Find_Orange : Icon_Find_Gray} alt="" />
        탐색
      </div>
      <div
        className={
          page === 2
            ? "div-navigation-menu-selected Body6M"
            : "div-navigation-menu Body6M"
        }
        onClick={() => {
          if (session_key) window.location.href = "/community/myclub/0/home/0";
          else alert("로그인 후 이용 가능합니다.");
        }}
      >
        <img src={page === 2 ? Icon_Club_Orange : Icon_Club_Gray} alt="" />
        동아리
      </div>
      <div
        className={
          page === 3
            ? "div-navigation-menu-selected Body6M"
            : "div-navigation-menu Body6M"
        }
        onClick={() => {
          if (session_key) window.location.href = "/community/mypage";
          else alert("로그인 후 이용 가능합니다.");
        }}
      >
        <img src={page === 3 ? Icon_My_Orange : Icon_My_Gray} alt="" />
        MYPAGE
      </div>
    </div>
  );
}

export default Navigation;
