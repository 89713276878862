import { useState, useCallback, useEffect, useRef } from "react";
import "./styles.css";
import Icon_Filter from "../asset/icon/Icon_Filter.svg";
import CheckBox from "../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../asset/icon/CheckBox_Checked.svg";
import Connection from "./Connection";
import TableContent6 from "./TableContent6";
import { TbSortDescending } from "react-icons/tb";
import { useMediaQuery } from "react-responsive";
import CommunityConnection from "./community/CommunityConnection";

const createHeaders = (headers) => {
  return headers.map((item, idx) => ({
    data: { ...item, id: idx },
    text: item.k,
    isView: true,
    filter: item.type === 5,
    a_id: item.id,
    ref: useRef(),
  }));
};
const Table6 = ({
  club_id,
  recruit_id,
  recruitDocs,
  headers,
  minCellWidth = 50,
  applicantList,
  isLeftSectionOpen,
  recruitInfo,
  managerList,
}) => {
  const isWide = useMediaQuery({
    query: isLeftSectionOpen ? "(min-width: 1065px)" : "(min-width: 785px)",
  });

  const [showWhole, setShowWhole] = useState(false);
  const [applicantListV, setApplicantListV] = useState([]);
  const [applicantListW, setApplicantListW] = useState([]);
  const [modalViewForm, setModalViewForm] = useState(false);
  const [allScore, setAllScore] = useState();

  const getAverage = (arr) => {
    if (arr?.length === 0) return 0;
    else
      return (
        arr?.reduce(function add(sum, current) {
          return sum + current;
        }, 0) / arr?.length
      );
  };

  const getAllScore = async () => {
    const res = await CommunityConnection("/getAllScore", {
      recruit_id: recruit_id,
    });
    if (res.is_success) setAllScore(res.score);
  };

  const setModalViewFormCallback = useCallback((modal) => {
    setModalViewForm(modal);
  }, []);

  const getAllScoreCallback = useCallback(() => {
    getAllScore();
  }, []);

  useEffect(() => {
    getAllScore();
    setApplicantListV(
      applicantList.map((a, idx) => ({
        ...a,
        idx: idx,
        stateV: a.state,
        score: getAverage(allScore?.find((score) => score.id === a.id)?.score),
      }))
    );
  }, [applicantList]);

  useEffect(() => {
    setApplicantListV(
      applicantList.map((a, idx) => ({
        ...a,
        idx: idx,
        stateV: a.state,
        score: getAverage(allScore?.find((score) => score.id === a.id)?.score),
      }))
    );
  }, [allScore]);

  const [isSorted, setIsSorted] = useState(-1);
  async function sortApplicantList(idx) {
    if (idx === 1) {
      isSorted === -1
        ? setApplicantListV(
            [...applicantListV].sort((a, b) => b.score - a.score)
          )
        : setApplicantListV([...applicantListV].sort((a, b) => a.idx - b.idx));
    } else {
      isSorted === -1
        ? setApplicantListV(
            [...applicantListV].sort(
              (a, b) => a.answer[idx - 6].sort_num - b.answer[idx - 6].sort_num
            )
          )
        : setApplicantListV([...applicantListV].sort((a, b) => a.idx - b.idx));
    }
    setIsSorted(isSorted === -1 ? idx : -1);
  }

  const [tableHeight, setTableHeight] = useState("auto");
  const [activeIndex, setActiveIndex] = useState(null);
  const tableElement = useRef(null);
  const [columns, setColumns] = useState(createHeaders(headers));

  const [scrollX, setScrollX] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(1);

  console.log("scrollDirection: " + scrollDirection);

  const [test, setTest] = useState(columns.length);

  const session_key = localStorage.getItem("session_key");

  useEffect(() => {
    const length = showWhole ? applicantListW.length : applicantListV.length;
    setTableHeight(31.5 * length + 28.5);
  }, [applicantListW, applicantListV, showWhole]);

  const sc = document.querySelector("#scTable");

  async function getRecruitStep2() {
    const res = await Connection("/getRecruitStep1", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
      recruit_id: recruit_id,
    });
    if (res.is_success) {
      setApplicantListW(
        res.applicant.map((a, idx) => ({ ...a, idx: idx, stateV: a.state }))
      );
    }
  }
  useEffect(() => {
    getRecruitStep2();
  }, []);

  const filterClickEvent = (index) => {
    setColumns(
      columns.map(function (col) {
        if (index == col.data.id) {
          col.isView = !col.isView;
        }
        return col;
      })
    );

    var ttt = 0;
    columns.map(function (col) {
      if (col.isView) ttt++;
    });

    setTest(ttt);
  };
  const mouseDown = (index) => {
    setActiveIndex(index);
  };
  const outSection = useRef();
  const mouseMove = useCallback(
    (e) => {
      const gridColumns = columns.map((col, i) => {
        if (i === activeIndex && col.ref) {
          const width = e.clientX + scrollX - col.ref.current.offsetLeft;

          if (width >= minCellWidth) {
            return `${width}px`;
          }
        }
        if (col.ref.current) {
          return `${col.ref.current.offsetWidth}px`;
        }
      });

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(
        " "
      )}`;
    },
    [activeIndex, columns, minCellWidth]
  );

  ///////////////////////////////////////////

  const removeListeners = useCallback(() => {
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);

  const showFilter = (
    <div
      className="div-table-filter-out-section"
      ref={outSection}
      onClick={(e) => {
        if (outSection.current === e.target) setIsFilterOpen(false);
      }}
    >
      <div className="div-table-filter-section">
        <div className="div-table-filter-section-01">
          {
            <div className="div-table-filter-section-quetion">
              <button>
                <img
                  className="img-table-checkbox"
                  src={test == columns.length ? CheckBox_Checked : CheckBox}
                  alt=""
                  onClick={function () {
                    setColumns(
                      columns.map(function (col) {
                        if (col.data.id > 2) {
                          if (test == columns.length) {
                            col.isView = false;
                            setTest(2);
                          } else {
                            col.isView = true;
                            setTest(columns.length);
                          }
                        }
                        return col;
                      })
                    );
                  }}
                />
              </button>
              전체 선택
            </div>
          }
        </div>
        <div className="div-table-filter-section-02">
          {columns.map((q, idx) => (
            <div key={idx}>
              {idx > 2 && (
                <div className="div-table-filter-section-quetion">
                  <button>
                    <img
                      className="img-table-checkbox"
                      src={q.isView ? CheckBox_Checked : CheckBox}
                      alt=""
                      onClick={() => {
                        filterClickEvent(idx);
                      }}
                    />
                  </button>
                  {q.text}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const [showScore, setShowScore] = useState(false);
  const showFeatures = (
    <div
      className="div-table-filter-out-section"
      ref={outSection}
      onClick={(e) => {
        if (outSection.current === e.target) setIsFeaturesOpen(false);
      }}
    >
      <div className="div-table-features-section">
        {recruitInfo.step !== "서류" && (
          <p
            className="p-table-feature Body4"
            onClick={() => setShowWhole(!showWhole)}
            style={{ color: showWhole && "rgb(255, 122, 0)" }}
          >
            전체 지원자 보기
          </p>
        )}
        <p
          className="p-table-feature Body4"
          onClick={() => downloadRecruitStep1()}
        >
          표로 내보내기
        </p>
        {/* <p className="p-table-feature Body4">답변 통계 보기</p> */}
        {/* <p className="p-table-feature Body4">테이블 크게 보기</p> */}
      </div>
    </div>
  );

  const [columnWidth, setColumnWidth] = useState([]);

  useEffect(() => {
    setColumnWidth(
      columns.map((col, idx) =>
        col.isView ? document.getElementById(idx).offsetWidth : columnWidth[idx]
      )
    );
  }, [activeIndex]);

  const [colString, setColString] = useState(
    "56px 60px 90px 110px 90px 50px 120px 120px 120px 120px 120px 120px 120px "
  );
  const getColString = async () => {
    var colString = "";
    await columnWidth.map((w, idx) => {
      if (columns[idx].isView) {
        colString = colString + w + "px ";
      }
    });
    setColString(colString);
  };

  useEffect(() => {
    getColString();
  }, [filterClickEvent]);

  async function downloadRecruitStep1() {
    const ret = await Connection("/downloadRecruitStep1", {
      club_id: club_id,
      recruit_id: recruit_id,
    });

    window.open(ret.file_url);
  }

  return (
    <div className="table-wrapper Body4" id="tt">
      {isWide && (
        <div className="div-table-filter-button-section">
          <button
            className="outlined-black-button button-table-filter Body4"
            onClick={() => setIsFeaturesOpen(true)}
          >
            추가 기능
          </button>
          <button
            className="outlined-black-button button-table-filter Body4"
            onClick={() => {
              setIsFilterOpen(true);
              getColString();
            }}
          >
            필터
            <img className="img-table-filter" src={Icon_Filter} alt="" />
          </button>
        </div>
      )}
      {isFeaturesOpen && showFeatures}
      {isFilterOpen && showFilter}
      <table
        className={
          scrollDirection === 1
            ? "recruit-table not-scrollX"
            : "recruit-table not-scrollY"
        }
        id="scTable"
        ref={tableElement}
        onScroll={() => {
          sc.addEventListener("scroll", () => {
            setScrollX(sc.scrollLeft);
          });
        }}
        style={
          test === 2
            ? {
                gridTemplateColumns: "56px 60px 90px",
                // maxHeight: "calc(100vh - 200px)",
              }
            : {
                gridTemplateColumns: colString,
                // maxHeight: "calc(100vh - 200px)",
              }
        }
        onWheel={(e) => {
          if (!modalViewForm) {
            if (scrollDirection === 0) {
              sc.scrollLeft += e.deltaY * 0.7;
            }
          }
        }}
        onMouseOver={(e) => {
          isLeftSectionOpen
            ? setScrollDirection(e.clientX - 696 - 36 - 60 - 15 < 131.3 ? 1 : 0)
            : setScrollDirection(
                e.clientX - 696 - 36 - 60 + 280 - 15 < 131.3 ? 1 : 0
              );
        }}
      >
        <thead>
          <tr>
            {columns.map(({ ref, text, filter, a_id }, i) => (
              <>
                {columns[i].isView && (
                  <th
                    ref={ref}
                    key={text}
                    className={
                      (i === 0 && "thead-test th-sticky-01") ||
                      (i === 1 && "thead-test th-sticky-02") ||
                      (i === 2 && "thead-test th-sticky-03-score") ||
                      "thead-test"
                    }
                    id={i}
                  >
                    <span className="Body4">
                      <div style={{ display: "flex", gap: "6px" }}>
                        {(filter || i === 1) && !showWhole && (
                          <TbSortDescending
                            className="div-click"
                            size="15"
                            onClick={() => {
                              sortApplicantList(i);
                            }}
                            color={isSorted === i && "#FF7A00"}
                          />
                        )}
                        {text}
                      </div>
                    </span>
                    {i !== 0 &&
                      i !== 1 &&
                      i !== 2 &&
                      i !== columns.length - 1 && (
                        <div
                          style={{ height: tableHeight }}
                          onMouseDown={() => mouseDown(i)}
                          className={`resize-handle ${
                            activeIndex === i ? "active" : "idle"
                          }`}
                        >
                          <div className="line-viewer" />
                        </div>
                      )}
                  </th>
                )}
              </>
            ))}
          </tr>
        </thead>
        <tbody className="tbody-test">
          <TableContent6
            recruit_id={recruit_id}
            recruitDocs={recruitDocs}
            applicantList={showWhole ? applicantListW : applicantListV}
            columnList={columns}
            columnWidth={columnWidth}
            modalViewForm={modalViewForm}
            setModalViewForm={setModalViewFormCallback}
            // setModalViewForm={setModalViewForm}
            recruitInfo={recruitInfo}
            managerList={managerList}
            getAllScore={getAllScoreCallback}
            // getAllScore={getAllScore}
          />
        </tbody>
      </table>
    </div>
  );
};

export default Table6;
