import React from "react";
import Icon_Plus from "../asset/icon/Icon_Plus.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import CheckBox from "../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../asset/icon/CheckBox_Checked.svg";
import { CoachMark } from "./guideModal";
import { useSelector } from "react-redux";

function SelectQustion({
  club_type,
  default_question,
  recommended_question,
  checkedDefaultQuestion,
  checkedRecommendedQuestion,
  setCheckedDefaultQuestion,
  setCheckedRecommendedQuestion,
}) {
  const showDefault = default_question.map((d, idx) => (
    <div
      className="div-formmaking-chip-question Body4 button-chip"
      style={{
        backgroundColor: !checkedDefaultQuestion[idx] && "#ECECEC",
        cursor: "pointer",
      }}
      onClick={() => {
        setCheckedDefaultQuestion(
          checkedDefaultQuestion.map((q, i) => (i === idx ? !q : q))
        );
      }}
    >
      {d.k}
      <img
        className="img-formmaking-chip-question-close"
        src={checkedDefaultQuestion[idx] ? Icon_Close : Icon_Plus}
        alt=""
      />
    </div>
  ));

  const showRecommended = recommended_question.map((r, idx) => (
    <div className="div-formmaking-right-section-03-03-question Body5">
      <img
        className="icon"
        src={checkedRecommendedQuestion[idx] ? CheckBox_Checked : CheckBox}
        alt=""
        onClick={() => {
          setCheckedRecommendedQuestion(
            checkedRecommendedQuestion.map((q, i) => (i === idx ? !q : q))
          );
        }}
      />
      <div className="div-formmaking-chip-keyword Tag1">{r.k}</div>
      <div className="div-formmaking-chip-questiontext">{r.q}</div>
    </div>
  ));

  const makeform_1 = useSelector((state) => state.guide.makeform_1);
  const makeform_1i = useSelector((state) => state.guide.makeform_1i);

  return (
    <>
      <div className="div-formmaking-right-section-01 Sub1">
        간편하게 질문을 추가해볼까요?
      </div>
      <div className="div-formmaking-right-section-02">
        <div className="div-formmaking-right-section-02-01">
          <div className="Sub3">01. 필수 질문</div>
          <div className="div-formmaking-right-section-02-sub Body5">
            해당 정보는 동들에서 수집하여 제공합니다.
          </div>
        </div>
        {/* 여기 */}
        <div className="div-formmaking-right-section-02-02">
          <div className="div-formmaking-chip-question Body4">이름</div>
          <div className="div-formmaking-chip-question Body4">연락처</div>
          <div className="div-formmaking-chip-question Body4">생년월일</div>
          <div className="div-formmaking-chip-question Body4">성별</div>
        </div>
      </div>
      <div
        className="div-formmaking-right-section-02"
        style={{ marginBottom: "27px" }}
      >
        <div
          className="div-formmaking-right-section-02-01"
        >
          <div className="Sub3">02. 추천 질문</div>
          <div className="div-formmaking-right-section-02-sub Body5">
            동들에서 제공하는 추천 질문 폼입니다.
          </div>
        </div>
        <CoachMark
          isOpen={makeform_1}
          guideIndex={makeform_1i}
          ownIndex={1}
          eclipseStyle={{ top: "12px" }}
        >
          <div className="div-formmaking-right-section-02-02">
            {showDefault}
          </div>
        </CoachMark>
      </div>
      <div className="div-formmaking-right-section-03">
        <div
          className="div-formmaking-right-section-03-01 Sub1"
        >
          {`다른 "${club_type}" 동아리에서는`}
          <br />
          이런 질문을 해요! 🙋🏻‍️
        </div>
        <CoachMark
          isOpen={makeform_1}
          guideIndex={makeform_1i}
          ownIndex={2}
          eclipseStyle={{ top: "12px" }}
        >
          <div className="div-formmaking-right-section-03-03">
            {showRecommended}
          </div>
        </CoachMark>
      </div>
    </>
  );
}

export default SelectQustion;
