import { React, useState, useRef, useEffect } from "react";
import Connection from "./Connection";
import Loginform from "./Loginform";
import Icon_Plus from "../asset/icon/Icon_Plus.svg";
import Icon_Delete from "../asset/icon/Icon_Delete_Gray.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";

function ClubManagerPage({ club_id }) {
  const [loginModal, setLoginModal] = useState(false);

  const [managerInfo, setManagerInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newManagerInfo, setNewManagerInfo] = useState({
    name: "",
    gender: null,
    position: "",
    phone: "",
  });

  const [displayGuide, setDisplayGuide] = useState(false);
  const [displayGuide2, setDisplayGuide2] = useState(false);

  const onChange = (e) => {
    switch (e.target.name) {
      case "name":
        setNewManagerInfo((info) => ({
          ...info,
          name: e.target.value,
        }));
        break;
      case "position":
        setNewManagerInfo((info) => ({
          ...info,
          position: e.target.value,
        }));
        break;
      case "phone":
        e.target.value = pnumHypen(e.target.value);
        setNewManagerInfo((info) => ({
          ...info,
          phone: e.target.value,
        }));
        break;
      default:
    }
  };

  const pnumHypen = function (str) {
    str = str.replace(/[^0-9]/g, "");
    let tmp = "";
    if (str.length < 4) {
      return str;
    } else if (str.length < 8) {
      tmp += str.substr(0, 3);
      tmp += "-";
      tmp += str.substr(3);
      return tmp;
    } else {
      tmp += str.substr(0, 3);
      tmp += "-";
      tmp += str.substr(3, 4);
      tmp += "-";
      tmp += str.substr(7);
      return tmp;
    }
  };

  const outSection = useRef();

  async function connect1() {
    setManagerInfo(
      await Connection("/getClubAdmin", {
        club_id: club_id,
      })
    );
  }

  useEffect(() => {
    connect1();
  }, []);

  useEffect(() => {
    if (managerInfo.is_success) {
      if (
        managerInfo.admin.length === 1 &&
        !window.localStorage?.guide_clubManager
      ) {
        setDisplayGuide(true);
      } else {
        setDisplayGuide(false);
      }
    }
  }, [managerInfo]);

  const addManager = async () => {
    const res = await Connection("/insertClubAdmin", {
      club_id: club_id,
      position: newManagerInfo.position,
      phone: newManagerInfo.phone.replace(/-/g, ""),
    });
    if (res.is_success) {
      setNewManagerInfo({
        name: "",
        gender: null,
        position: "",
        phone: "",
      });
      alert("관리자 추가가 완료되었습니다.");
      connect1();
      setIsModalOpen(false);
    }
  };

  /*
    Todo: 관리자 수정
    const updateManager = async (admin_id, position) => {
      await Connection("/updateClubAdmin", {
        
        club_id: club_id,
        admin_id: admin_id,
        position: position,
      })
    );
  */

  const removeManager = async (phone) => {
    const res = await Connection("/deleteClubAdmin", {
      club_id: club_id,
      admin_id: phone,
    });
    if (res.is_success) {
      alert("관리자 삭제가 완료되었습니다.");
      connect1();
    }
  };

  return (
    <>
      <div className="div-ClubManagerInfo-section">
        <div className="div-ClubManagerInfo-section-01 H3">
          🙋 운영진 현황
          <div style={{ position: "relative" }}>
            <button
              className="outlined-black-button button-table-filter Body4"
              onClick={() => {
                setIsModalOpen(true);
                setDisplayGuide(false);
                window.localStorage.setItem("guide_clubManager", true);
                if (managerInfo?.admin?.length === 1) {
                  setDisplayGuide2(true);
                } else {
                  setDisplayGuide2(false);
                }
              }}
              style={{
                position: "relative",
                zIndex: displayGuide ? "1231" : "0",
                backgroundColor: "white",
              }}
            >
              등록
              <img className="img-table-filter" src={Icon_Plus} alt="" />
            </button>
            {displayGuide && (
              <>
                <div
                  className="div-guide-dim"
                  onClick={() => setDisplayGuide(false)}
                />
                <div
                  className="div-guide Sub2"
                  style={{
                    zIndex: "1231",
                    transform: "translateX(-100%)",
                    left: "-5px",
                    top: "0",
                  }}
                >
                  운영진을 등록해주세요!
                </div>
              </>
            )}
          </div>
        </div>

        {isModalOpen && (
          <div
            className="div-modal-background"
            ref={outSection}
            onClick={(e) => {
              if (outSection.current === e.target) setIsModalOpen(false);
              setDisplayGuide2(false);
            }}
          >
            <div
              className="div-ClubManagerInfo-modal-section"
              onClick={() => setDisplayGuide2(false)}
            >
              <div className="div-ClubManagerInfo-modal-section-01 Sub1">
                운영진 등록
                <img
                  className="icon img-ClubManagerInfo-modal-close"
                  src={Icon_Close}
                  alt=""
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                />
              </div>
              <div className="div-ClubManagerInfo-modal-section-02"></div>
              <div className="div-form-question-section-04">
                <div
                  className="div-form-question-default-q Sub3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  이름
                </div>
                <input
                  className="input-form-question-section Body4"
                  placeholder="홍길동"
                  value={newManagerInfo.name}
                  name="name"
                  onChange={onChange}
                  maxLength="10"
                />
              </div>
              <div className="div-form-question-section-04">
                <div
                  className="div-form-question-default-q Sub3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  직급
                </div>
                <input
                  className="input-form-question-section Body4"
                  placeholder="ex. 회장, 부회장, 동아리원..."
                  value={newManagerInfo.position}
                  name="position"
                  onChange={onChange}
                  maxLength="20"
                />
              </div>
              <div className="div-form-question-section-04">
                <div
                  className="div-form-question-default-q Sub3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  전화번호
                </div>
                <input
                  className="input-form-question-section Body4"
                  placeholder="010-0000-0000"
                  value={newManagerInfo.phone}
                  name="phone"
                  onChange={onChange}
                  maxLength="13"
                />
              </div>
              <div className="div-guide-wrapper">
                <button
                  className="button-ClubManagerInfo-modal-add"
                  onClick={() => {
                    if (localStorage.getItem("session_key") === "admin_demo") {
                      window.alert("회원가입 후 이용 가능합니다🙂");
                      setLoginModal(true);
                    } else {
                      addManager();
                    }
                  }}
                >
                  등록하기
                </button>
                {displayGuide2 && (
                  <div className="div-guide Sub2">
                    추가할 운영진의 이름, 직급, 전화번호를 입력해주세요!
                    <br />
                    운영진이 회원가입하면 자동으로 권한이 부여됩니다.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="div-ClubManagerInfo-section-02">
          <table className="table-ClubManagerInfo">
            <thead className="Sub2">
              <tr>
                <td style={{ border: "none" }}>이름</td>
                <td>직급</td>
                <td>전화번호</td>
                <td>최근 접속일</td>
                <td>삭제</td>
              </tr>
            </thead>
            <tbody className="Body4">
              {managerInfo.is_success &&
                managerInfo.admin.length > 0 &&
                managerInfo.admin.map((m, idx) => (
                  <tr key={idx}>
                    <td style={{ border: "none" }}>{m.name}</td>
                    <td>{m.position}</td>
                    <td>{m.phone}</td>
                    <td>{m.recent_date}</td>
                    <td>
                      <img
                        className="icon"
                        src={Icon_Delete}
                        alt=""
                        onClick={() => {
                          if (window.confirm("정말 삭제하시겠습니까?")) {
                            removeManager(m.phone);
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {loginModal && <Loginform setModalOpen={setLoginModal} />}
    </>
  );
}

export default ClubManagerPage;
