import React, { useEffect } from "react";
import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Plus from "../../asset/icon/Icon_Plus.svg";
import Icon_Close from "../../asset/icon/Icon_Close.svg";
import Icon_Delete from "../../asset/icon/Icon_Delete.svg";
import "../../asset/css/community/Article.css";
import "../../asset/css/community/BoardSetting.css";
import { useState } from "react";
import CommunityConnection from "./CommunityConnection";

function BoardSetting({ setIsOpen, board, clubId, getBoard, setBoardNum }) {
  const session_key = window.localStorage.getItem("session_key");
  const [isAddBoardPageOpen, setIsAddBoardPageOpen] = useState(false);
  const [newBoard, setNewBoard] = useState({
    name: "",
    is_private: 1,
    is_anonymous: 1,
  });
  async function addBoard() {
    if (window.confirm("정말 추가하시겠습니까?")) {
      const res = await CommunityConnection("/addBoard", {
        club_id: clubId,
        name: newBoard.name,
        is_private: newBoard.is_private,
        is_anonymous: newBoard.is_anonymous,
      });
      if (res.is_success) {
        alert("게시판 추가가 완료되었습니다.");
        setIsAddBoardPageOpen(false);
        getBoard();
        setNewBoard({ name: "", is_private: 1, is_anonymous: 1 });
      }
    }
  }

  async function deleteBoard(id) {
    if (
      window.confirm(
        "게시판 삭제시 해당 게시판 내 게시글도 모두 삭제됩니다.\n정말 삭제하시겠습니까?"
      )
    ) {
      const res = await CommunityConnection("/deleteBoard", {
        club_id: clubId,
        board_id: id,
      });
      if (res.is_success) {
        alert("게시판 삭제가 완료되었습니다.");
        getBoard();
        setBoardNum(9999);
      }
    }
  }

  const addBoardPage = (
    <div className="div-community-modal-background">
      <div className="div-add-board-section Sub4M">
        <div className="div-add-board-header Sub3M">
          게시판 추가
          <img
            className="img-add-board-close"
            src={Icon_Close}
            alt=""
            onClick={() => setIsAddBoardPageOpen(false)}
          />
        </div>
        게시판 이름
        <input
          className="input-add-board Body4M"
          placeholder="게시판1"
          value={newBoard.name}
          onChange={(e) => setNewBoard({ ...newBoard, name: e.target.value })}
        />
        공개범위
        <div className="div-add-page-check-section">
          <button
            className={
              newBoard.is_private
                ? "main-black-button button-add-board-check Sub3M"
                : "outlined-black-button button-add-board-check Sub3M"
            }
            onClick={() => setNewBoard({ ...newBoard, is_private: 1 })}
          >
            동아리원
          </button>
          <button
            className={
              !newBoard.is_private
                ? "main-black-button button-add-board-check Sub3M"
                : "outlined-black-button button-add-board-check Sub3M"
            }
            onClick={() => setNewBoard({ ...newBoard, is_private: 0 })}
          >
            전체공개
          </button>
        </div>
        {/* 익명 가능 여부
        <div className="div-add-page-check-section">
          <button
            className={
              newBoard.is_anonymous
                ? "main-black-button button-add-board-check"
                : "outlined-black-button button-add-board-check"
            }
            onClick={() => setNewBoard({ ...newBoard, is_anonymous: 1 })}
          >
            가능
          </button>
          <button
            className={
              !newBoard.is_anonymous
                ? "main-black-button button-add-board-check"
                : "outlined-black-button button-add-board-check"
            }
            onClick={() => setNewBoard({ ...newBoard, is_anonymous: 0 })}
          >
            불가능
          </button>
        </div> */}
        <button
          className="main-black-button button-add-board-complete Sub3M"
          disabled={newBoard.name === ""}
          onClick={() => addBoard()}
        >
          추가하기
        </button>
      </div>
    </div>
  );
  return (
    <>
      <div className="div-article-section no-scrollbar">
        {isAddBoardPageOpen && addBoardPage}
        <div className="div-article-header-section">
          <div style={{ display: "flex", gap: "8px" }}>
            <img src={Icon_ArrowLeft} alt="" onClick={() => setIsOpen(false)} />
            게시판 설정
          </div>
          <img
            src={Icon_Plus}
            alt=""
            onClick={() => setIsAddBoardPageOpen(true)}
          />
        </div>
        <div className="div-boardsetting-section">
          {board?.map((board) => (
            <div className="div-boardsetting-board">
              {board.name}
              <img
                src={Icon_Delete}
                alt=""
                onClick={() => deleteBoard(board.id)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default BoardSetting;
