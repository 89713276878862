import React from "react";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

function ShowTimeTable({
  setModalOpen,
  session_key,
  club_id,
  recruit_id,
  timetable,
  applicantList,
  stateList,
}) {
  return (
    <div className="div-modal-background">
      <div className="div-show-timetable-section">
        <div className="H3">📆 면접자 타임테이블</div>
        <img
          className="icon img-message-section-close"
          src={Icon_Close}
          alt=""
          style={{ width: "21px" }}
          onClick={() => setModalOpen(false)}
        />
        <div className="div-show-timetable-scroll-section">
          {timetable.map((t) => (
            <div className="div-show-timetable-section-01">
              <div className="div-show-timetable-section-01-date Sub3">
                {dayjs(t.date).format("YYYY. MM. DD ddd")}
              </div>
              <div className="div-show-timetable-section-01-table">
                {t.split.map((s) =>
                  s.time_m.map(
                    (m) =>
                      m.checked && (
                        <div className="div-show-timetable-section-01-time-name">
                          <div className="div-show-timetable-section-01-time Body4">
                            {m.time}
                          </div>
                          <div className="div-show-timetable-section-01-name-section">
                            {applicantList.map(
                              (a) =>
                                dayjs(a.interview_date).format("YYYYMMDD") ===
                                  dayjs(t.date).format("YYYYMMDD") &&
                                a.interview_time === m.time && (
                                  <div className="div-show-timetable-section-01-name Body4">
                                    {a.profile.name}
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      )
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShowTimeTable;
