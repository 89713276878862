import React, { useState } from "react";
import GuideModal, { CoachMark } from "./guideModal";

export default function TestGuide() {
  const [guideIndex, setGuideIndex] = useState(0);
  const guideContents = ["가이드 내용1", "가이드 내용2", "가이드 내용3"];
  const [displayGuide, setDisplayguide] = useState(true);

  const [guideIndex1, setGuideIndex1] = useState(0);
  const guideContents1 = ["가이드 내용1", "가이드 내용2", "가이드 내용3"];
  const [displayGuide1, setDisplayguide1] = useState(true);

  const [guideIndex2, setGuideIndex2] = useState(0);
  const guideContents2 = ["가이드 내용1", "가이드 내용2", "가이드 내용3"];
  const [displayGuide2, setDisplayguide2] = useState(true);

  const [guideIndex3, setGuideIndex3] = useState(0);
  const guideContents3 = ["가이드 내용1", "가이드 내용2", "가이드 내용3"];
  const [displayGuide3, setDisplayguide3] = useState(true);

  const [guideIndex4, setGuideIndex4] = useState(0);
  const guideContents4 = ["가이드 내용1", "가이드 내용2", "가이드 내용3"];
  const [displayGuide4, setDisplayguide4] = useState(true);

  const [guideIndex5, setGuideIndex5] = useState(0);
  const guideContents5 = ["가이드 내용1", "가이드 내용2", "가이드 내용3"];
  const [displayGuide5, setDisplayguide5] = useState(true);

  const [guideIndex6, setGuideIndex6] = useState(0);
  const guideContents6 = ["가이드 내용1", "가이드 내용2", "가이드 내용3"];
  const [displayGuide6, setDisplayguide6] = useState(true);

  return (
    <div style={{
      display: "flex",
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      backgroundColor: 'lightgray',
      position: 'relative',
    }}>
      <CoachMark 
        ownIndex={1}
        guideIndex={guideIndex}
        guideName={'test1'}
        style={{position: 'relative', top: '13px'}}
        isOpen={displayGuide}
      />
      <CoachMark 
        ownIndex={2}
        guideIndex={guideIndex}
        guideName={'test1'}
        isOpen={displayGuide}
      />
      <CoachMark 
        ownIndex={3}
        guideIndex={guideIndex}
        guideName={'test1'}
        isOpen={displayGuide}
      />
      {displayGuide && (
        <GuideModal
          contents={guideContents}
          size={3}
          currentIndex={guideIndex}
          onChangeIndex={setGuideIndex}
          guideName={"test1"}
          position="left"
          showIndex={false}
          setModal={setDisplayguide}
        />
      )}
      {displayGuide1 && (
        <GuideModal
          contents={guideContents1}
          size={3}
          currentIndex={guideIndex1}
          onChangeIndex={setGuideIndex1}
          guideName={"test11"}
          position="right"
          setModal={setDisplayguide1}
        />
      )}
      {displayGuide2 && (
        <GuideModal
          contents={guideContents2}
          size={3}
          currentIndex={guideIndex2}
          onChangeIndex={setGuideIndex2}
          guideName={"test12"}
          position="right"
          showIndex={false}
          setModal={setDisplayguide2}
        />
      )}
      {displayGuide3 && (
        <GuideModal
          contents={guideContents3}
          size={3}
          currentIndex={guideIndex3}
          onChangeIndex={setGuideIndex3}
          guideName={"test13"}
          position="right"
          showIndex={false}
          setModal={setDisplayguide3}
        />
      )}
      {displayGuide4 && (
        <GuideModal
          contents={guideContents4}
          size={3}
          currentIndex={guideIndex4}
          onChangeIndex={setGuideIndex4}
          guideName={"test14"}
          position="right"
          showIndex={false}
          setModal={setDisplayguide4}
        />
      )}
      {displayGuide5 && (
        <GuideModal
          contents={guideContents5}
          size={3}
          currentIndex={guideIndex5}
          onChangeIndex={setGuideIndex5}
          guideName={"test5"}
          position="right"
          showIndex={false}
          setModal={setDisplayguide5}
        />
      )}
      {displayGuide6 && (
        <GuideModal
          contents={guideContents6}
          size={3}
          currentIndex={guideIndex6}
          onChangeIndex={setGuideIndex6}
          guideName={"test6"}
          position="right"
          showIndex={false}
          setModal={setDisplayguide6}
        />
      )}
    </div>
  );
}
