import { React, useState, useEffect } from "react";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import RadioButton from "../asset/icon/RadioButton.svg";
import RadioButton_Checked from "../asset/icon/RadioButton_Checked.svg";
import Connection from "./Connection";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";

function Message({
  club_id,
  recruit_id,
  setModalOpen,
  recipient,
  recruitStep1,
  setRecruitState,
  step2,
}) {
  const session_key = localStorage.getItem("session_key");
  const [template, setTemplate] = useState("default");
  const [content, setContent] = useState("");

  async function getMsgTemplate() {
    const res = await Connection("/getMsgTemplate", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
    });

    if (res.is_success) {
      setContent(res.template.interview_time);
    }
  }

  useEffect(() => {
    getMsgTemplate();
  }, []);

  return (
    <div className="div-modal-background">
      <div className="div-message-modal">
        <div className="H3">✉️ 면접 시간 안내 문자</div>
        <img
          className="icon img-message-section-close"
          src={Icon_Close}
          alt=""
          style={{ width: "21px" }}
          onClick={() => setModalOpen(false)}
        />
        <div className="div-message-section-01">
          <div className="div-message-section-01-left Sub3">수신자</div>
          <div className="div-message-section-01-right">
            {recipient.map((r) => (
              <div className="div-message-section-01-right-chip Body4">
                {r.profile["name"]}
              </div>
            ))}
          </div>
        </div>
        <div className="div-message-section-01-right-count Body5">
          총 {recipient.length}명
        </div>
        <div className="div-message-section-02-right-chip-section">
          {content && content.default.state === 2 && (
            <div
              className="div-click div-managemessage-message-select-section-01 Body4"
              onClick={() =>
                content && content.default.state === 2 && setTemplate("default")
              }
              style={{ marginRight: "9px" }}
            >
              <img
                className="icon"
                src={template === "default" ? RadioButton_Checked : RadioButton}
                alt=""
                style={{ marginRight: "6px" }}
              />
              기본 템플릿
            </div>
          )}
          {content && content.template1.state === 2 && (
            <div
              className="div-click div-managemessage-message-select-section-01 Body4"
              onClick={() => setTemplate("template1")}
              style={{ marginRight: "9px" }}
            >
              <img
                className="icon"
                src={
                  template === "template1" ? RadioButton_Checked : RadioButton
                }
                alt=""
                style={{ marginRight: "6px" }}
              />
              템플릿 1
            </div>
          )}
          {content && content.template2.state === 2 && (
            <div
              className="div-click div-managemessage-message-select-section-01 Body4"
              onClick={() => setTemplate("template2")}
              style={{ marginRight: "9px" }}
            >
              <img
                className="icon"
                src={
                  template === "template2" ? RadioButton_Checked : RadioButton
                }
                alt=""
                style={{ marginRight: "6px" }}
              />
              템플릿 2
            </div>
          )}
        </div>
        <div className="div-message-section-02">
          <div className="div-message-section-01-left Sub3">문자 내용</div>
          <div className="textarea-message-section-02-right Body4">
            <HighlightWithinTextarea
              value={
                content && template && content[template].con
                  ? content && template && content[template].con
                  : ""
              }
              highlight={
                /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
              }
            />
          </div>
        </div>
        <button
          className="outlined-black-button button-message-bottom-left Sub3"
          onClick={async () => {
            if (window.confirm("문자 전송을 건너뛰시겠습니까?")) {
              const ci = await Connection("/setStep2Timetable", {
                club_id: club_id,
                recruit_id: recruit_id,
                timetable: JSON.stringify(step2),
              });

              if (ci.is_success) {
                const ret = await Connection("/sendTimetableMsg", {
                  club_id: club_id,
                  recruit_id: recruit_id,
                  template_id: "none",
                });
                if (ret.is_success) {
                  setModalOpen(false);
                  setRecruitState(4);
                }
              }
            }
          }}
        >
          건너뛰기
        </button>
        <button
          className="main-black-button button-message-bottom Sub3"
          onClick={async () => {
            if (window.confirm("문자를 전송하시겠습니까?")) {
              const ci = await Connection("/setStep2Timetable", {
                club_id: club_id,
                recruit_id: recruit_id,
                timetable: JSON.stringify(step2),
              });

              if (ci.is_success) {
                const ret = await Connection("/sendTimetableMsg", {
                  club_id: club_id,
                  recruit_id: recruit_id,
                  template_id: template,
                });
                if (ret.is_success) {
                  window.alert("문자 전송이 완료되었습니다.");
                  setModalOpen(false);
                  setRecruitState(4);
                }
              }
            }
          }}
        >
          문자 전송하기
        </button>
      </div>
    </div>
  );
}

export default Message;
