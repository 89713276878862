import Icon_Table from "../asset/icon/Icon_Table_Gray.svg";
import Icon_Download from "../asset/icon/Icon_Download_Gray.svg";
import Icon_Picture from "../asset/icon/Icon_Picture_Gray.svg";
import ChipOption from "./ChipOption";
import React from "react";

const Answer = (props) => {
  switch (parseInt(props.question.type)) {
    case 1: // 이름
    case 2: // 연락처
    case 3: // 생년월일
    case 4: // 학교/학년
    case 5: // 활동 지역
    case 7: // 단답형
    case 8: // 단답형
    case 10: // 날짜
    case 18: // 시간
      return <span className="Body4">{props.answer}</span>;
    case 6: // 면접 날짜/시간
      return <span className="Body5">{props.answer}</span>;
    case 11: // 표
      return (
        <button>
          <img className="icon" src={Icon_Table} alt="" />
        </button>
      );

    case 9: // 객관식 질문
      var now = 0;
      var cnt = 0;
      var end = false;
      return (
        <>
          {typeof props.answer === "object" && props.answer.length > 1 ? (
            <div className="div-chip-section">
              {props.answer.map((a, idx) => {
                now += 42;
                if (idx === props.answer.length - 1) now -= 18;
                if (now + 18 + 15 < props.width) {
                  cnt++;
                  return (
                    <ChipOption
                      option={props.question.option.findIndex((item, idx) => {
                        return item.a === a;
                      })}
                      name={a}
                      key={idx}
                      qType={12}
                    />
                  );
                } else {
                  if (!end) {
                    end = true;
                    return (
                      <div
                        className="Sub3"
                        style={{
                          color: "#FF7A00",
                          width: "15px",
                        }}
                      >
                        +{props.answer.length - cnt}
                      </div>
                    );
                  }
                }
              })}
            </div>
          ) : (
            <span>
              <ChipOption
                option={props.question.option.findIndex((item, idx) => {
                  return item.a === props.answer[0];
                })}
                name={props.answer[0]}
              />
            </span>
          )}
        </>
      );
    case 12: {
      // 성별
      return (
        <span className="Body4">
          {props.answer === "male" ? (
            <ChipOption option={5} name={"남자"} qType={13} />
          ) : (
            <ChipOption option={0} name={"여자"} qType={13} />
          )}
        </span>
      );
    }

    case 13: // 드롭 다운
      return (
        <span>
          <ChipOption option={9} name={props.answer} qType={13} />
        </span>
      );

    case 14: // 파일
      return (
        <a href={props.answer.replace("storage/", "api/v1/download?url=")}>
          <img className="icon" src={Icon_Download} alt="" />
        </a>
      );

    case 15: // 선형 배율
      return <ChipOption option={props.answer} name={props.answer} />;

    case 16: // 객관식 그리드
      return <></>;

    case 17: // 체크박스 그리드
      return <></>;

    case 19: // 사진
      return (
        <button onClick={() => window.open(`${props.answer}`, "_blank")}>
          <img
            src={Icon_Picture}
            alt=""
            className="img-table-picture 
          "
          />
        </button>
      );
    default:
      return <span className="Body4">{props.answer}</span>;
  }
};

export default React.memo(Answer);
