import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Thumbsup_Gray from "../../asset/icon/Icon_Thumbsup_Gray.svg";
import CommunityConnection from "./CommunityConnection";

import Icon_Comment from "../../asset/icon/Icon_Comment_6A6A6A.svg";
import { detailDate } from "./detailDate";
import Icon_View from "../../asset/icon/Icon_View_6A6A6A.svg";
import "../../asset/css/community/ClubMainBoard.css";
import "../../asset/css/community/community.css";
import MyArticle from "./MyArticle";

export default function MyActivity({ ...props }) {
  const session_key = window.localStorage.getItem("session_key");
  const [selectedTab, setSelectedTab] = useState(props.firstTab);
  const [myArticle, setMyArticle] = useState();

  const [allBoardInfo, setAllBoardInfo] = useState();
  async function getAllBoardInfo() {
    const res = await CommunityConnection("/getAllBoardInfo");
    if (res.is_success) {
      setAllBoardInfo(res.boardInfo);
    }
  }
  const [allClubInfo, setAllClubInfo] = useState();
  async function getAllClubInfo() {
    const res = await CommunityConnection("/getAllClubInfo");
    if (res.is_success) {
      setAllClubInfo(res.clubInfo);
    }
  }

  async function getMyArticle() {
    const res = await CommunityConnection("/getMyArticle", {});
    if (res.is_success) {
      setMyArticle(res.article);
    }
  }
  const [myLikedArticle, setMyLikedArticle] = useState();
  async function getMyLikedArticle() {
    const res = await CommunityConnection("/getMyLikedArticle", {});
    if (res.is_success) {
      setMyLikedArticle(
        res.article.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )
      );
    }
  }
  useEffect(() => {
    getAllBoardInfo();
    getAllClubInfo();
    getMyArticle();
    getMyLikedArticle();
  }, []);

  useEffect(() => {
    selectedTab ? setArticle(myLikedArticle) : setArticle(myArticle);
  }, [myArticle, myLikedArticle]);

  const [article, setArticle] = useState();

  useEffect(() => {
    selectedTab === 0 ? setArticle(myArticle) : setArticle(myLikedArticle);
  }, [selectedTab]);

  const [isArticleOpen, setIsArticleOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState();
  function openArticle(article) {
    setSelectedArticle(article);
    setIsArticleOpen(true);
  }

  useEffect(() => {
    getMyArticle();
    getMyLikedArticle();
  }, [isArticleOpen]);

  return (
    <>
      <ContentsWrapper>
        <Header>
          <section className="Sub1M">
            <img
              src={Icon_ArrowLeft}
              alt=""
              onClick={() => props.setPage("mypage-main")}
            />
            내 활동
          </section>
          <Tabs>
            <Tab
              isSelected={selectedTab === 0}
              onClick={() => setSelectedTab(0)}
            >
              작성한 게시물
            </Tab>
            <Tab
              isSelected={selectedTab === 1}
              onClick={() => setSelectedTab(1)}
            >
              공감한 게시물
            </Tab>
          </Tabs>
        </Header>
        <Body>
          {<TotalSum className="ag1M">총 {article?.length}개</TotalSum>}
          {selectedTab === 0 && article?.length === 0 && (
            <DivNoWrite>
              <p className="Sub4M">
                작성한 게시물이 없어요!
                <br />
                👇 게시물을 작성해 볼까요?👇
              </p>
              <button className="Sub3M">게시물 작성하기</button>
            </DivNoWrite>
          )}
          {selectedTab === 1 && article?.length === 0 && (
            <DivNoLike>
              <img src={Icon_Thumbsup_Gray} alt="" />
              <p className="Sub4M">공감한 게시물이 없어요!</p>
            </DivNoLike>
          )}
          {article?.map((article) => (
            <div
              className="div-club-main-board-article-section"
              onClick={() => openArticle(article)}
            >
              <div className="div-club-main-board-article-text">
                <div className="div-club-main-board-article-category">
                  {
                    allClubInfo?.find((club) => club.id === article.club_id)
                      ?.name
                  }{" "}
                  /{" "}
                  {
                    allBoardInfo?.find((board) => board.id === article.board_id)
                      ?.name
                  }
                </div>
                <div className="div-club-main-board-article-title">
                  {article.title}
                </div>
                <div className="div-club-main-board-article-detail-section">
                  <div className="div-club-main-board-article-detail">
                    {article.nickname}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    {detailDate(new Date(article.created_at))}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    <img src={Icon_View} alt="" />
                    {article.view_cnt}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    <img src={Icon_Thumbsup_Gray} alt="" />
                    {article.like_cnt}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    <img src={Icon_Comment} alt="" />
                    {article.reply_cnt}
                  </div>
                </div>
              </div>
              {article.images && (
                <img
                  className="img-club-main-board-article"
                  src={article.images}
                  alt=""
                />
              )}
            </div>
          ))}
          <div style={{ height: "100px" }} />
          {isArticleOpen && (
            <MyArticle
              setIsOpen={setIsArticleOpen}
              articleInfo={selectedArticle}
            />
          )}
        </Body>
      </ContentsWrapper>
    </>
  );
}
const ContentsWrapper = styled.div`
  min-width: 280px;
`;
const Header = styled.div`
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  > section:first-child {
    padding: 0px 12px;
    height: 48px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: #1e1e1e;
    > img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
`;
const Tabs = styled.div.attrs({
  className: "Sub4M",
})`
  height: 48px;
  display: flex;
`;
const Tab = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isSelected ? "border-bottom: 2px solid black;" : "color: #CDCDCD"}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DivNoWrite = styled.div.attrs({
  className: "Sub4M",
})`
  text-align: center;
  margin-top: 108px;
  > p {
    color: #6a6a6a;
    margin-bottom: 22px;
  }
  > button {
    color: white;
    padding: 12px 16px;
    width: 232px;
    border-radius: 29px;
    background: #ff7a00;
  }
`;
const DivNoLike = styled.div`
  text-align: center;
  margin-top: 108px;
  > img {
    width: 40px;
    height: 40px;
  }
  > p {
    margin-top: 20px;
    color: #cdcdcd;
  }
`;
const TotalSum = styled.p`
  padding: 16px 12px 6px 12px;
`;
