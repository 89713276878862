import React, { useState, useEffect, useRef } from "react";
import "../asset/css/calendar.css";
import Icon_Calendar from "../asset/icon/Icon_Calendar.svg";
import Icon_ArrowLeft from "../asset/icon/Icon_ArrowLeft.svg";
import Icon_ArrowRight from "../asset/icon/Icon_ArrowRight.svg";

const today = new Date();

export default function Calendar({ text, setValue }) {
  const calendarSection = useRef(null);
  const [modalDisplay, setModalDisplay] = useState(false);

  const [curDate, setCurDate] = useState(new Date());
  const [firstDate, setFirstDate] = useState(
    new Date(curDate.getFullYear(), curDate.getMonth(), 1)
  );
  const [lastDate, setLastDate] = useState(
    new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0)
  );

  const [selectedDate, setSelectedDate] = useState();

  const prevSelected = useRef(null);
  const calendarOpener = useRef();

  const modalControl = () => {
    if (modalDisplay) {
      setModalDisplay(false);
      calendarOpener.current.style.border = "1px solid #CDCDCD";
    } else {
      setModalDisplay(true);
      calendarOpener.current.style.border = "1px solid #000000";
    }
  };

  const clickArrow = (e) => {
    prevSelected.current = null;
    switch (e.target.name) {
      case "prev":
        setCurDate(
          new Date(
            curDate.getFullYear(),
            curDate.getMonth() - 1,
            curDate.getDate()
          )
        );
        break;
      case "next":
        setCurDate(
          new Date(
            curDate.getFullYear(),
            curDate.getMonth() + 1,
            curDate.getDate()
          )
        );
        break;
      default:
    }
  };

  const onClick = (e) => {
    if (prevSelected.current !== e.target) {
      if (prevSelected.current) {
        if (prevSelected.current.className.includes("calendar-today")) {
          prevSelected.current.className =
            "div-calendar-cell Tag1 calendar-today";
        } else if (prevSelected.current.className.includes("calendar-sunday")) {
          prevSelected.current.className =
            "div-calendar-cell Tag1 calendar-sunday";
        } else {
          prevSelected.current.className = "div-calendar-cell Tag1";
        }
      }
      prevSelected.current = e.target;
      setSelectedDate({
        year: curDate.getFullYear(),
        month: curDate.getMonth() + 1,
        date: Number(e.target.id),
        day: Number(e.target.dataset.day),
      });
      setValue(
        `${curDate.getFullYear()}.${String(curDate.getMonth() + 1).padStart(
          2,
          "0"
        )}.${String(e.target.id).padStart(2, "0")}`
      );
    }
  };

  const convertDay = (value) => {
    const converter = {
      0: "Sun",
      1: "Mon",
      2: "Tue",
      3: "Wed",
      4: "Thu",
      5: "Fri",
      6: "Sat",
    };
    return converter[value];
  };

  const showCalendar = () => {
    const isThisMonth =
      curDate.getFullYear() === today.getFullYear() &&
      curDate.getMonth() === today.getMonth();
    const isSelectedMonth =
      curDate.getFullYear() === selectedDate?.year &&
      curDate.getMonth() + 1 === selectedDate?.month;
    const dateArr = [];
    for (let i = 1; i <= lastDate.getDate(); i++) {
      dateArr.push({
        date: i,
        day: (firstDate.getDay() + i - 1) % 7,
        isToday: isThisMonth && today.getDate() === i,
        isSelected: isSelectedMonth && selectedDate.date === i,
      });
    }
    const weeks = [];
    let days = [];

    for (let i = 0; i < dateArr[0].day; i++) {
      days.push(<div className="div-calendar-cell Tag1 cursor-default" />);
    }
    dateArr.map((d) => {
      if (d.day === 0) {
        if (days.length) {
          weeks.push(<div className="div-calendar-week">{days}</div>);
          days = [];
        }
        days.push(
          <div
            className={`
              div-calendar-cell Tag1 calendar-sunday
              ${d.isToday ? " calendar-today" : ""}
              ${d.isSelected ? " calendar-selected" : ""}
            `}
            onClick={onClick}
            id={d.date}
            data-day={d.day}
          >
            {d.date}
          </div>
        );
      } else {
        days.push(
          <div
            className={`
              div-calendar-cell Tag1
              ${d.isToday ? " calendar-today" : ""}
              ${d.isSelected ? " calendar-selected" : ""}
            `}
            onClick={onClick}
            id={d.date}
            data-day={d.day}
          >
            {d.date}
          </div>
        );
      }
    });
    if (days.length) {
      for (let i = lastDate.getDay(); i < 6; i++) {
        days.push(<div className="div-calendar-cell Tag1 cursor-default" />);
      }

      weeks.push(<div className="div-calendar-week">{days}</div>);
    }
    return weeks;
  };

  useEffect(() => {
    const clickOutside = (e) => {
      if (modalDisplay && !calendarSection.current.contains(e.target)) {
        setModalDisplay(false);
        document.removeEventListener("mouseup", clickOutside);
        calendarOpener.current.style.border = "1px solid #CDCDCD";
      }
    };
    window.addEventListener("mouseup", clickOutside);
    return () => {
      document.removeEventListener("mouseup", clickOutside);
    };
  }, [modalDisplay]);

  useEffect(() => {
    setFirstDate(new Date(curDate.getFullYear(), curDate.getMonth(), 1));
    setLastDate(new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0));
  }, [curDate]);

  return (
    <div className="div-calendar-wrapper" ref={calendarSection}>
      <div
        className="div-calendar-opener"
        ref={calendarOpener}
        onClick={modalControl}
      >
        <span className="Body4">
          {selectedDate
            ? `${selectedDate.year}.${selectedDate.month}.${selectedDate.date}`
            : text}
        </span>
        <img src={Icon_Calendar} alt="" />
      </div>
      {modalDisplay && (
        <div className="div-calendar-modal">
          <div className="div-calendar-header Sub3">
            <img src={Icon_ArrowLeft} alt="" name="prev" onClick={clickArrow} />
            <span>{`${curDate.getFullYear()}.${curDate.getMonth() + 1}`}</span>
            <img
              src={Icon_ArrowRight}
              alt=""
              name="next"
              onClick={clickArrow}
            />
          </div>
          <div className="div-calendar-month">
            <div className="div-calendar-week">
              <span className="div-calendar-cell Tag1 cursor-default calendar-sunday">
                일
              </span>
              <span className="div-calendar-cell Tag1 cursor-default">월</span>
              <span className="div-calendar-cell Tag1 cursor-default">화</span>
              <span className="div-calendar-cell Tag1 cursor-default">수</span>
              <span className="div-calendar-cell Tag1 cursor-default">목</span>
              <span className="div-calendar-cell Tag1 cursor-default">금</span>
              <span className="div-calendar-cell Tag1 cursor-default">토</span>
            </div>
            {showCalendar()}
          </div>
        </div>
      )}
    </div>
  );
}
