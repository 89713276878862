import React, { useState, useRef } from "react";
import "../asset/css/createClub.css";
import close from "../asset/icon/Icon_Close.svg";
import RadioButton from "../asset/icon/RadioButton.svg";
import RadioButton_Checked from "../asset/icon/RadioButton_Checked.svg";
import CheckBox from "../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../asset/icon/CheckBox_Checked.svg";
import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import Icon_Search from "../asset/icon/Icon_Search.svg";
import Button_Upload from "../asset/upload/Button_Upload.svg";

export default function UpdateClub({ setModalOpen, info, clubInfo, clubId }) {
  const [clubName, setClubName] = useState(clubInfo.name);
  const [contents, setContents] = useState(clubInfo.introduce);

  const [purpose, setPurpose] = useState(
    clubInfo.purpose === "취미" ? "hobby" : "job"
  );
  const [interest, setInterest] = useState(clubInfo.category[0]);
  const [region, setRegion] = useState(clubInfo.location[0]);
  const [fee, setFee] = useState(
    String(clubInfo.cost).charAt(0) === "유" ? "true" : "false"
  );
  const [additionalFee, setAdditionalFee] = useState(
    String(clubInfo.cost).includes("가입비 별도")
  );

  const [select1, setSelect1] = useState(false);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const [imageFile1, setImageFile1] = useState({ thumbnail: clubInfo.image });
  const [imageFile2, setImageFile2] = useState(null);
  const [imageFile3, setImageFile3] = useState(null);
  const [imageFile4, setImageFile4] = useState(null);
  const [imageFile5, setImageFile5] = useState(null);
  const [imageChange, setImageChange] = useState(0);
  const [activity1Change, setActivity1Change] = useState(
    clubInfo.activiy_image[0] || null
  );
  const [activity2Change, setActivity2Change] = useState(
    clubInfo.activiy_image[1] || null
  );
  const [activity3Change, setActivity3Change] = useState(
    clubInfo.activiy_image[2] || null
  );
  const [activity4Change, setActivity4Change] = useState(
    clubInfo.activiy_image[3] || null
  );
  const [fMainHover, setFMainHover] = useState(false);
  const [fSub1Hover, setFSub1Hover] = useState(false);
  const [fSub2Hover, setFSub2Hover] = useState(false);
  const [fSub3Hover, setFSub3Hover] = useState(false);
  const [fSub4Hover, setFSub4Hover] = useState(false);

  const handleClickFileInput = (e) => {
    switch (e.target.id) {
      case "main-image":
        fileInputRef1.current?.click();
        break;
      case "sub1-image":
        fileInputRef2.current?.click();
        break;
      case "sub2-image":
        fileInputRef3.current?.click();
        break;
      case "sub3-image":
        fileInputRef4.current?.click();
        break;
      case "sub4-image":
        fileInputRef5.current?.click();
        break;
      case "default":
      default:
    }
  };

  const uploadProfile = (e) => {
    const fileList = e.target.files;
    if (fileList && fileList[0]) {
      if (fileList[0].size <= 10 * 1024 * 1024) {
        const url = URL.createObjectURL(fileList[0]);
        const imageFile = {
          file: fileList[0],
          name: fileList[0].name,
          type: fileList[0].type.slice(0, 5), // image
          size: fileList[0].size,
          thumbnail: url,
        };
        switch (e.target.id) {
          case "main-inputImg":
            setImageFile1(imageFile);
            break;
          case "sub1-inputImg":
            setImageFile2(imageFile);
            break;
          case "sub2-inputImg":
            setImageFile3(imageFile);
            break;
          case "sub3-inputImg":
            setImageFile4(imageFile);
            break;
          case "sub4-inputImg":
            setImageFile5(imageFile);
            break;
          case "default":
          default:
        }
      } else {
        alert("10MB 이하의 이미지만 첨부할 수 있습니다.");
      }
    }
  };

  const deleteFile = (e) => {
    switch (e.currentTarget.id) {
      case "delete-main":
        setImageFile1(null);
        fileInputRef1.current.value = null;
        setImageChange(1);
        break;
      case "delete-sub1":
        setImageFile2(null);
        fileInputRef2.current.value = null;
        setActivity1Change(null);
        break;
      case "delete-sub2":
        setImageFile3(null);
        fileInputRef3.current.value = null;
        setActivity2Change(null);
        break;
      case "delete-sub3":
        setImageFile4(null);
        fileInputRef4.current.value = null;
        setActivity3Change(null);
        break;
      case "delete-sub4":
        setImageFile5(null);
        fileInputRef5.current.value = null;
        setActivity4Change(null);
        break;
      case "default":
      default:
    }
  };

  const onChange = (e) => {
    switch (e.target.id) {
      case "clubName":
        setClubName(e.target.value);
        break;
      case "contents":
        setContents(e.target.value);
        break;
      default:
    }
  };

  const onClick = (e) => {
    switch (e.target.id) {
      case "purpose-hobby":
        setPurpose("hobby");
        break;
      case "purpose-job":
        setPurpose("job");
        break;
      case "fee-true":
        setFee("true");
        break;
      case "fee-false":
        setFee("false");
        break;
      case "additional-fee":
        setAdditionalFee(e.target.checked);
        break;
      case "default":
      default:
    }
  };

  const interests = ["관심사 선택", ...Object.values(info.category)];
  const regions = info.location;
  const regionsArr = Object.entries(info.location);

  const dropdown = (data, select, setSelect, value, setValue) => (
    <div className="div-createClub-dropdown-wrapper">
      <div
        className="div-createClub-dropdown Body4"
        style={{
          borderColor: select && "black",
          zIndex: select && 2,
        }}
      >
        <div
          className="div-createClub-dropdown-box"
          style={{
            borderBottom: select && "1px solid #cdcdcd",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelect((select) => !select);
          }}
        >
          {data[value]}
          {select ? (
            <img
              className="img-createClub-dropdown-arrow"
              src={Icon_ArrowUp}
              alt=""
            />
          ) : (
            <img
              className="img-createClub-dropdown-arrow"
              src={Icon_ArrowDown}
              alt=""
            />
          )}
        </div>
        {select && (
          <div className="div-createClub-dropdown-scroll">
            {data.map((d, idx) => (
              <button
                className="div-createClub-dropdown-selection Body4"
                style={{
                  color: value === idx && "#FF7A00",
                }}
                onClick={() => {
                  setValue(idx);
                  setSelect(false);
                }}
              >
                {d}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const handleSubmit = async (e) => {
    const formdata = new FormData();
    formdata.append("session_key", window.localStorage.session_key);
    formdata.append("club_id", clubId);
    formdata.append("name", clubName);
    formdata.append("introduce", contents);
    formdata.append("purpose", purpose === "hobby" ? "취미" : "취업");
    formdata.append(
      "cost",
      `${fee ? "유" : "무"}${additionalFee ? ", 가입비 별도" : ""}`
    );
    formdata.append("category", interest);
    formdata.append("location", region);
    if (imageFile1) {
      formdata.append("image", imageFile1 && imageFile1?.file);
    }
    if (imageFile2) {
      formdata.append("activity1", imageFile2 && imageFile2?.file);
    }
    if (imageFile3) {
      formdata.append("activity2", imageFile3 && imageFile3?.file);
    }
    if (imageFile4) {
      formdata.append("activity3", imageFile4 && imageFile4?.file);
    }
    if (imageFile5) {
      formdata.append("activity4", imageFile5 && imageFile5?.file);
    }
    formdata.append("image_change", imageChange);
    formdata.append("activity1_change", activity1Change);
    formdata.append("activity2_change", activity2Change);
    formdata.append("activity3_change", activity3Change);
    formdata.append("activity4_change", activity4Change);

    const api_host =
      // window.location.host === "dongdle.com"
      true ? "https://api.dongdle.com" : "http://api_test.dongdle.com:405";

    await fetch(api_host + "/api/v1/updateClub", {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.is_success) {
          alert("동아리 정보 변경이 완료되었습니다.");
          window.location.reload();
        } else {
          alert(json.error_message);
        }
      });
  };

  const [dropdownRegion, setDropdownRegion] = useState(false);
  const [search, setSearch] = useState(regions[region]);

  const selectRegion = (
    <div className="div-createClub-dropdown-wrapper">
      <div className="div-createClub-dropdown Body4" style={{ width: "180px" }}>
        <div className="div-createClub-dropdown-box Body4">
          <img
            src={Icon_Search}
            alt=""
            style={{ width: "18px", height: "18px" }}
          />
          <input
            className="div-createClub-dropdown-box Body4"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setDropdownRegion(true);
              if (e.target.value === "") setDropdownRegion(false);
            }}
            placeholder="활동 지역을 검색하세요!"
          />
        </div>
        {dropdownRegion && (
          <div
            className="div-createClub-dropdown-scroll"
            style={{ height: "fit-content", maxHeight: "132px" }}
          >
            {regionsArr.length > 0 &&
              regionsArr.map(
                (d) =>
                  d[1].includes(search) && (
                    <button
                      className="div-createClub-dropdown-selection Body4"
                      onClick={() => {
                        setDropdownRegion(false);
                        setRegion(Number(d[0]));
                        setSearch(d[1]);
                      }}
                      style={{ width: "180px" }}
                    >
                      {d[1]}
                    </button>
                  )
              )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="div-modal-background">
        <div id="div-createClub-wrapper">
          <div id="createClub-header">
            <p className="Sub1">🚩 동아리 정보 수정</p>
            <div id="createClub-header-right">
              <img
                src={close}
                alt=""
                onClick={() => {
                  setModalOpen(false);
                }}
              />
            </div>
          </div>
          <form id="form-createClub-questions" onSubmit={handleSubmit}>
            <div className="div-createClub-question div-disabled">
              <label htmlFor="clubName" className="Sub3">
                동아리 명
              </label>
              <input
                id="clubName"
                className="input-createClub-inputText Body4 -input-box"
                placeholder="내용을 입력해주세요"
                onChange={onChange}
                maxLength="30"
                value={clubName}
              />
            </div>
            <div className="div-createClub-question">
              <label htmlFor="contents" className="Sub3">
                소개글
              </label>
              <input
                id="contents"
                className="input-createClub-inputText Body4 -input-box"
                placeholder="내용을 입력해주세요"
                onChange={onChange}
                maxLength="30"
                value={contents}
              />
            </div>
            <div
              className="div-createClub-question"
              style={{ alignItems: "center" }}
            >
              <label htmlFor="images" className="Sub3">
                동아리 로고
              </label>
              <input
                id="main-inputImg"
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                ref={fileInputRef1}
                onChange={uploadProfile}
              />
              <div
                className="div-createClub-image-wrapper"
                onMouseOver={() => {
                  setFMainHover(true);
                }}
                onMouseLeave={() => {
                  setFMainHover(false);
                }}
              >
                {imageFile1 && fMainHover && (
                  <div
                    id="delete-main"
                    className="div-createClub-deleteImg"
                    onClick={deleteFile}
                  >
                    <img src={close} alt="" />
                  </div>
                )}
                <img
                  id="main-image"
                  src={imageFile1?.thumbnail || Button_Upload}
                  alt=""
                  onClick={handleClickFileInput}
                />
              </div>
            </div>
            <div className="div-createClub-question div-disabled">
              <label htmlFor="purpose" className="Sub3">
                목적
              </label>
              <div className="div-createClub-selections">
                <input
                  className="createClub-selection"
                  type="radio"
                  name="purpose"
                  id="purpose-hobby"
                  onClick={onClick}
                  defaultChecked={purpose === "hobby"}
                />
                <label htmlFor="purpose-hobby">
                  <img
                    src={
                      purpose === "hobby" ? RadioButton_Checked : RadioButton
                    }
                    alt=""
                  />
                  <p className="Body4">취미</p>
                </label>
                <input
                  className="createClub-selection"
                  type="radio"
                  name="purpose"
                  id="purpose-job"
                  onClick={onClick}
                  defaultChecked={purpose === "job"}
                />
                <label htmlFor="purpose-job">
                  <img
                    src={purpose === "job" ? RadioButton_Checked : RadioButton}
                    alt=""
                  />
                  <p className="Body4">취업</p>
                </label>
              </div>
            </div>
            <div className="div-createClub-question div-disabled">
              <label className="Sub3">관심사</label>
              {dropdown(interests, select1, setSelect1, interest, setInterest)}
            </div>
            <div className="div-createClub-question">
              <label className="Sub3">활동 지역</label>
              {selectRegion}
            </div>
            <div id="createClub-vertical-devider" />
            <div className="div-createClub-question">
              <label htmlFor="fee" className="Sub3">
                회비
              </label>
              <div className="div-createClub-selections">
                <input
                  className="createClub-selection"
                  type="radio"
                  name="fee"
                  id="fee-true"
                  onClick={onClick}
                />
                <label htmlFor="fee-true">
                  <img
                    src={fee === "true" ? RadioButton_Checked : RadioButton}
                    alt=""
                  />
                  <p className="Body4">유</p>
                </label>
                <input
                  className="createClub-selection"
                  type="radio"
                  name="fee"
                  id="fee-false"
                  onClick={onClick}
                />
                <label htmlFor="fee-false">
                  <img
                    src={fee === "false" ? RadioButton_Checked : RadioButton}
                    alt=""
                  />
                  <p className="Body4">무</p>
                </label>
                <input
                  className="createClub-selection"
                  type="checkbox"
                  name="fee"
                  id="additional-fee"
                  onClick={onClick}
                />
                <label htmlFor="additional-fee">
                  <img
                    src={additionalFee ? CheckBox_Checked : CheckBox}
                    alt=""
                  />
                  <p className="Body4">가입비 별도</p>
                </label>
              </div>
            </div>
            <div className="div-createClub-question">
              <label htmlFor="fee" className="Sub3">
                활동 사진
              </label>
              <div className="div-createClub-images">
                <input
                  id="sub1-inputImg"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  ref={fileInputRef2}
                  onChange={uploadProfile}
                />
                <div
                  className="div-createClub-image-wrapper"
                  onMouseOver={() => {
                    setFSub1Hover(true);
                  }}
                  onMouseLeave={() => {
                    setFSub1Hover(false);
                  }}
                >
                  {(imageFile2 || activity1Change) && fSub1Hover && (
                    <div
                      id="delete-sub1"
                      className="div-createClub-deleteImg"
                      onClick={deleteFile}
                    >
                      <img src={close} alt="" />
                    </div>
                  )}
                  <img
                    id="sub1-image"
                    src={
                      imageFile2?.thumbnail || activity1Change || Button_Upload
                    }
                    alt=""
                    onClick={handleClickFileInput}
                  />
                </div>
                <input
                  id="sub2-inputImg"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  ref={fileInputRef3}
                  onChange={uploadProfile}
                />
                <div
                  className="div-createClub-image-wrapper"
                  onMouseOver={() => {
                    setFSub2Hover(true);
                  }}
                  onMouseLeave={() => {
                    setFSub2Hover(false);
                  }}
                >
                  {(imageFile3 || activity2Change) && fSub2Hover && (
                    <div
                      id="delete-sub2"
                      className="div-createClub-deleteImg"
                      onClick={deleteFile}
                    >
                      <img src={close} alt="" />
                    </div>
                  )}
                  <img
                    id="sub2-image"
                    src={
                      imageFile3?.thumbnail || activity2Change || Button_Upload
                    }
                    alt=""
                    onClick={handleClickFileInput}
                  />
                </div>
                <input
                  id="sub3-inputImg"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  ref={fileInputRef4}
                  onChange={uploadProfile}
                />
                <div
                  className="div-createClub-image-wrapper"
                  onMouseOver={() => {
                    setFSub3Hover(true);
                  }}
                  onMouseLeave={() => {
                    setFSub3Hover(false);
                  }}
                >
                  {(imageFile4 || activity3Change) && fSub3Hover && (
                    <div
                      id="delete-sub3"
                      className="div-createClub-deleteImg"
                      onClick={deleteFile}
                    >
                      <img src={close} alt="" />
                    </div>
                  )}
                  <img
                    id="sub3-image"
                    src={
                      imageFile4?.thumbnail || activity3Change || Button_Upload
                    }
                    alt=""
                    onClick={handleClickFileInput}
                  />
                </div>
                <input
                  id="sub4-inputImg"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  ref={fileInputRef5}
                  onChange={uploadProfile}
                />
                <div
                  className="div-createClub-image-wrapper"
                  onMouseOver={() => {
                    setFSub4Hover(true);
                  }}
                  onMouseLeave={() => {
                    setFSub4Hover(false);
                  }}
                >
                  {(imageFile5 || activity4Change) && fSub4Hover && (
                    <div
                      id="delete-sub4"
                      className="div-createClub-deleteImg"
                      onClick={deleteFile}
                    >
                      <img src={close} alt="" />
                    </div>
                  )}
                  <img
                    id="sub4-image"
                    src={
                      imageFile5?.thumbnail || activity4Change || Button_Upload
                    }
                    alt=""
                    onClick={handleClickFileInput}
                  />
                </div>
              </div>
            </div>
          </form>
          <div id="createClub-footer">
            <button
              id="createClub-submit"
              className="main-black-button"
              onClick={handleSubmit}
              disabled={
                !clubName ||
                !contents ||
                !purpose ||
                !interest ||
                !region ||
                !imageFile1
              }
            >
              <p className="Sub3">변경 완료</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
