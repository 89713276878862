import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components/macro";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Plus from "../../asset/icon/Icon_Plus.svg";
import Icon_Delete_Gray from "../../asset/icon/Icon_Delete_Gray.svg";
import Icon_Close from "../../asset/icon/Icon_Close.svg";

import Connection from "../Connection";

function ConfirmModal({ ...props }) {
  return (
    <>
      <ModalBackground>
        <ModalWrapper>
          <ConfirmPharse>
            운영진 "<span>{props.deletedMember.name}</span>" 을/를
            <br />
            삭제하시겠습니까?
          </ConfirmPharse>
          <DivButtons>
            <div onClick={() => props.setModal("")}>취소</div>
            <div
              onClick={() => {
                Connection("/deleteClubAdmin", {
                  admin_id: props.deletedMember.phone,
                  club_id: props.clubId,
                  session_key: window.localStorage.getItem("session_key"),
                }).then((res) => {
                  if (res.is_success) {
                    window.alert("삭제가 완료되었습니다.");
                    window.location.reload();
                  }
                });
              }}
            >
              삭제
            </div>
          </DivButtons>
        </ModalWrapper>
      </ModalBackground>
    </>
  );
}

const ConfirmPharse = styled.p.attrs({
  className: "Body4M",
})`
  padding: 20px 16px 8px 16px;
  line-height: 1.5;
  span {
    display: inline-block;
    vertical-align: bottom;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const DivButtons = styled.div.attrs({
  className: "Sub3M",
})`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  div {
    flex-grow: 1;
    border-radius: 8px;
    padding: 12px 16px;
    text-align: center;
  }
  div:first-child {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
  div:last-child {
    background-color: black;
    color: white;
  }
`;

function Modal({ ...props }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [detectName, setDetectName] = useState(false);
  const [detectPosition, setDetectPosition] = useState(false);
  const [detectPhone, setDetectPhone] = useState(false);

  const handlePhone = (e) => {
    const phoneHypen = function (str) {
      str = str.replace(/[^0-9]/g, "");
      let tmp = "";
      if (str.length < 4) {
        return str;
      } else if (str.length < 8) {
        tmp += str.substr(0, 3);
        tmp += "-";
        tmp += str.substr(3);
        return tmp;
      } else {
        tmp += str.substr(0, 3);
        tmp += "-";
        tmp += str.substr(3, 4);
        tmp += "-";
        tmp += str.substr(7);
        return tmp;
      }
    };
    e.target.value = phoneHypen(e.target.value);
    setPhone(e.target.value);
  };

  return (
    <>
      <ModalBackground>
        <ModalWrapper>
          <Header>
            <div style={{ width: "24px" }} />
            <span className="Sub2M">회원 등록</span>
            <img
              src={Icon_Close}
              onClick={(e) => {
                e.stopPropagation();
                props.setModal(false);
              }}
            />
          </Header>
          <ModalBody>
            <Segment>
              <label className="Sub4M" htmlFor="clubmanager-name">
                이름
              </label>
              <input
                id="clubmanager-name"
                className="-input-box Body4M"
                placeholder="홍길동"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Segment>
            <Segment>
              <label className="Sub4M" htmlFor="clubmanager-position">
                직급
              </label>
              <input
                id="clubmanager-position"
                className="-input-box Body4M"
                placeholder="ex. 회장, 부회장, 동아리원.."
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </Segment>
            <Segment>
              <label className="Sub4M" htmlFor="clubmanager-phone">
                전화번호
              </label>
              <input
                id="clubmanager-phone"
                className="-input-box Body4M"
                placeholder="010-0000-0000"
                value={phone}
                onInput={handlePhone}
                maxLength={13}
              />
            </Segment>
          </ModalBody>
          <ModalFooter>
            <div
              onClick={() => {
                if (props.clubId !== "986cccae-6181-45a5-a4cb-47c1e1065c7d") {
                  Connection("/insertClubAdmin", {
                    club_id: props.clubId,
                    phone: phone.replace(/[^0-9]/g, ""),
                    position: position,
                    session_key: window.localStorage.getItem("session_key"),
                  }).then((res) => {
                    if (res.is_success) {
                      window.alert("등록이 완료되었습니다.");
                      window.location.reload();
                    }
                  });
                } else {
                  alert("회원가입 후 이용 가능합니다🙂");
                }
              }}
            >
              등록하기
            </div>
          </ModalFooter>
        </ModalWrapper>
      </ModalBackground>
    </>
  );
}
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;
const ModalWrapper = styled.div`
  width: 285px;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
`;
const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 12px;
`;
const Segment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const ModalFooter = styled.div.attrs({
  className: "Sub3M",
})`
  padding: 12px 16px;
  margin-top: 12px;
  div {
    padding: 12px 16px;
    text-align: center;
    border-radius: 8px;
    background-color: black;
    color: white;
    cursor: pointer;
  }
`;

export default function ClubManager({ ...props }) {
  const [deletedMember, setDeletedMember] = useState("");
  const [registerModal, setRegisterModal] = useState(false);
  const [adminList, setAdminList] = useState([]);

  const getUrlParams = () => {
    const params = window.location.href.split("/");
    return {
      clubId: params[params.length - 2], // 동아리 id
      manageState: params[params.length - 1], // 관리 상태: manage, recruitlist, ...
    };
  };

  useEffect(() => {
    const session_key = window.localStorage.getItem("session_key");
    const fetchData = async () => {
      try {
        const res = await Connection("/getClubAdmin", {
          club_id: props.clubId,
        });
        setAdminList(Object.values(res.admin));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <ContentsWrapper>
        <Header>
          <img
            src={Icon_ArrowLeft}
            onClick={() => {
              props.setPage("manage");
              window.history.pushState(
                {},
                "",
                `/${getUrlParams().clubId}/manage`
              );
            }}
          />
          <span className="Sub2M">🙋‍♀ 운영진 관리️</span>
          <img src={Icon_Plus} onClick={() => setRegisterModal(true)} />
        </Header>
        <Body>
          <TableHeader>
            <div>이름</div>
            <div>전화번호</div>
            <div>삭제</div>
          </TableHeader>
          {adminList.map((admin, idx) => (
            <TableRow>
              <div>{admin.name}</div>
              <div>{admin.phone}</div>
              <div>
                <img
                  src={Icon_Delete_Gray}
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeletedMember(admin);
                  }}
                />
              </div>
            </TableRow>
          ))}
        </Body>
      </ContentsWrapper>
      {registerModal && (
        <Modal setModal={setRegisterModal} clubId={props.clubId} />
      )}
      {deletedMember.length !== 0 && (
        <ConfirmModal
          setModal={setDeletedMember}
          clubId={props.clubId}
          deletedMember={deletedMember}
        />
      )}
    </>
  );
}
const ContentsWrapper = styled.div`
  width: 100vw;
  min-width: 280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  flex-shrink: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  cursor: default;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  span {
    text-align: center;
    height: auto;
  }
`;
const Body = styled.div`
  flex-grow: 1;
  padding: 10px 8px;
  overflow-y: scroll;
`;
const TableHeader = styled.div.attrs({
  className: "Body5M",
})`
  width: 100%;
  height: 42px;
  display: flex;
  gap: 1px;
  div {
    background-color: #ececec;
    text-align: center;
  }
  div:first-child {
    width: 100px;
    flex-grow: 100;
    padding: 12px 15px;
  }
  div:nth-child(2) {
    width: 130px;
    flex-grow: 130;
    padding: 12px 3px;
  }
  div:last-child {
    flex: none;
    width: 64px;
    padding: 12px 0px;
  }
`;
const TableRow = styled.div.attrs({
  className: "Body5M",
})`
  width: 100%;
  height: 40px;
  display: flex;
  div {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  div:first-child {
    width: 100px;
    flex-grow: 100;
    padding: 12px 15px;
    border-left: 1px solid #ececec;
  }
  div:nth-child(2) {
    width: 130px;
    flex-grow: 130;
    padding: 12px 3px;
  }
  div:last-child {
    flex: none;
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ececec;
    img {
      width: 24px;
      height: 24px;
    }
  }
  &:last-child {
    border-bottom: 1px solid #ececec;
  }
`;
