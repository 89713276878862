import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";

import Connection from "../Connection";
import Dropdown from "./DropDown";
import ToastPopup from "../ToastPopup";

export default function SendMessage({ ...props }) {
  const [ready, setReady] = useState(false); // getMsgTemplate 완료 시 true
  const [applicantReject, setApplicantReject] = useState(); // 서류 불합격 템플릿 리스트
  const [interviewPass, setInterviewPass] = useState(); // 면접 시간 템플릿 리스트
  const [interviewReject, setInterviewReject] = useState(); // 최종 합불 템플릿 리스트
  const [interviewTime, setInterviewTime] = useState();
  useEffect(() => {
    const session_key = window.localStorage.getItem("session_key");
    const fetchData = async () => {
      try {
        const res = await Connection("/getMsgTemplate", {
          club_id: props.clubId,
        });
        setApplicantReject(res.template.applicant_reject);
        setInterviewPass(res.template.interview_pass);
        setInterviewReject(res.template.interview_reject);
        setInterviewTime(res.template.interview_time);
      } catch (error) {
        console.error(error);
      }
      setReady(true);
    };
    fetchData();
  }, []);

  const [selectedTab, setSelectedTab] = useState(0); // 현재 탭: 서류 불합격, 면접 시간...
  const [dropdownValue, setDropdownValue] = useState(0); // 현재 템플릿
  const [contents, setContents] = useState("로딩중입니다..");
  const [contents2, setContents2] = useState("로딩중입니다..");
  useEffect(() => {
    if (ready) {
      let templateInfo = {
        con: "",
        state: 0,
      };
      let templateInfo2 = {
        con: "",
        state: 0,
      };
      switch (selectedTab) {
        case 0:
          switch (dropdownValue) {
            case 0:
              templateInfo = { ...applicantReject.default };
              break;
            case 1:
              templateInfo = { ...applicantReject.template1 };
              break;
            case 2:
              templateInfo = { ...applicantReject.template2 };
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (dropdownValue) {
            case 0:
              templateInfo = { ...interviewTime.default };
              break;
            case 1:
              templateInfo = { ...interviewTime.template1 };
              break;
            case 2:
              templateInfo = { ...interviewTime.template2 };
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (dropdownValue) {
            case 0:
              templateInfo = { ...interviewPass.default };
              templateInfo2 = { ...interviewReject.default };
              break;
            case 1:
              templateInfo = { ...interviewPass.template1 };
              templateInfo2 = { ...interviewReject.template1 };
              break;
            case 2:
              templateInfo = { ...interviewPass.template2 };
              templateInfo2 = { ...interviewReject.template2 };
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      setContents(templateInfo.con);
      setContents2(templateInfo2.con);
    }
  }, [ready, selectedTab, dropdownValue]);
  const getChipStateList = useMemo(() => {
    // 현재 탭의 템플릿 심사 상태 리스트
    const res = new Array(3);
    const getState = [applicantReject, interviewTime, interviewPass];
    res[0] = getState[selectedTab]?.default.state || 0;
    res[1] = getState[selectedTab]?.template1.state || 0;
    res[2] = getState[selectedTab]?.template2.state || 0;
    return res;
  }, [selectedTab, ready]);

  // 토스트 팝업 부분
  const [animation1, setAnimation1] = useState("");
  const [animation2, setAnimation2] = useState("");
  const [openToastPopup1, setOpenToastPopup1] = useState(false);
  const [openToastPopup2, setOpenToastPopup2] = useState(false);
  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setAnimation1("openAnimation-toast");
      setOpenToastPopup1(true);
      setTimeout(() => {
        setAnimation1("closeAnimation-toast");
        setTimeout(() => {
          setOpenToastPopup1(false);
        }, 450);
      }, 2000);
    } catch (e) {
      setAnimation2("openAnimation-toast");
      setOpenToastPopup2(true);
      setTimeout(() => {
        setAnimation2("closeAnimation-toast");
        setTimeout(() => {
          setOpenToastPopup2(false);
        }, 450);
      }, 2000);
    }
  };

  const Index2Type = ["applicant_reject", "interview_time", "interview_pass"];
  async function updateMsgTemplate(type, t_id, con, con2) {
    var id;
    switch (t_id) {
      case 0:
        id = "default";
        break;
      case 1:
        id = "template1";
        break;
      case 2:
        id = "template2";
        break;
      default:
    }
    const res = await Connection("/updateMsgTemplate", {
      session_key: localStorage.getItem("session_key"),
      club_id: props.clubId,
      type: type,
      template_id: id,
      con: con,
    });

    if (res.is_success) {
      if (type === "interview_pass") {
        const res2 = await Connection("/updateMsgTemplate", {
          session_key: localStorage.getItem("session_key"),
          club_id: props.clubId,
          type: "interview_reject",
          template_id: id,
          con: con2,
        });
        if (res2.is_success) {
          window.alert("수정이 완료되었습니다.");
          window.location.reload();
        }
      } else {
        window.alert("수정이 완료되었습니다.");
        window.location.reload();
      }
    }
  }

  return (
    <>
      <ContentsWrapper>
        <div style={{ boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)" }}>
          <Header>
            <img
              src={Icon_ArrowLeft}
              onClick={() => {
                props.setPage("manage");
                window.history.pushState({}, "", `/${props.clubId}/manage`);
              }}
            />
            <span className="Sub2M">📩 합불 메세지 관리</span>
            <div style={{ width: "24px" }} />
          </Header>
          <Tabs>
            <Tab
              isSelected={selectedTab === 0}
              onClick={() => setSelectedTab(0)}
            >
              서류 불합격
            </Tab>
            <Tab
              isSelected={selectedTab === 1}
              onClick={() => setSelectedTab(1)}
            >
              면접 시간
            </Tab>
            <Tab
              isSelected={selectedTab === 2}
              onClick={() => setSelectedTab(2)}
            >
              최종 합불
            </Tab>
          </Tabs>
        </div>
        <Body>
          <DropdownWrapper>
            <Dropdown
              list={["기본 템플릿", "템플릿1", "템플릿2"]}
              chipStateList={getChipStateList}
              currentIndex={dropdownValue}
              setCurrentIndex={setDropdownValue}
            />
          </DropdownWrapper>
          <DivDescription>
            <p>
              ✔ 카카오 심사 후 승인된 템플릿만 발송 가능합니다.
              <t className="Body5"> ※ 1000자 이내</t>
              <br />✔ 카카오톡 ‘동들(Dongdle)’ 채널을 통해 발송됩니다.
              <br />
              <t className="Body5"> ※ 특수기호가 사용되는 경우 LMS로 발송</t>
              <br />
              ✔ 심사는 1~3일이 소요됩니다. 빠른 승인을 원하시는 경우
              문의해주세요.
              <br />✔ 면접이 없는 '서류' 전형을 진행하실 경우 불합격 안내 문자는
              '서류 불합격 안내 문자'에, 합격 안내 문자는 '최종 합불 안내
              문자(합격자)'에 등록해주세요.
            </p>
            <DivChipsContainer>
              <Chips onClick={() => handleCopyClipBoard("#{공고_이름}")}>
                #공고 이름
              </Chips>
              <Chips onClick={() => handleCopyClipBoard("#{동아리_이름}")}>
                #동아리 이름
              </Chips>
              <Chips onClick={() => handleCopyClipBoard("#{지원자_이름}")}>
                #지원자 이름
              </Chips>
            </DivChipsContainer>
            <span className="Body6M">
              태그 선택 후 원하는 곳에 붙여넣기 하세요.
            </span>
          </DivDescription>
          {selectedTab === 2 ? (
            <>
              <DivWrapper>
                <span>합격자</span>
                <DivMessageWrapper>
                  <HighlightWithinTextarea
                    value={contents}
                    onChange={setContents}
                    highlight={
                      /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
                    }
                  />
                </DivMessageWrapper>
              </DivWrapper>
              <DivWrapper>
                <span>탈락자</span>
                <DivMessageWrapper>
                  <HighlightWithinTextarea
                    value={contents2}
                    onChange={setContents2}
                    highlight={
                      /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
                    }
                  />
                </DivMessageWrapper>
              </DivWrapper>
            </>
          ) : (
            <DivMessageWrapper>
              <HighlightWithinTextarea
                value={contents}
                onChange={setContents}
                highlight={
                  /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
                }
              />
            </DivMessageWrapper>
          )}
          <ButtonSubmit
            onClick={() => {
              if (localStorage.getItem("session_key") === "admin_demo") {
                window.alert("회원가입 후 이용 가능합니다🙂");
              } else {
                if (contents === "" || (selectedTab === 2 && contents2 === ""))
                  window.alert("메세지 내용이 없습니다.");
                else {
                  if (window.confirm("정말 수정하시겠습니까?")) {
                    updateMsgTemplate(
                      Index2Type[selectedTab],
                      dropdownValue,
                      contents,
                      contents2
                    );
                  }
                }
              }
            }}
          >
            제출하기
          </ButtonSubmit>
        </Body>
        {/* {openToastPopup1 && (
          <div
            style={{
              position: "fixed",
              bottom: "45px",
              left: "50vw",
              transform: "translateX(-50%)",
              width: "220px",
            }}
          >
            <ToastPopup
              status={"success"}
              children={
                "클립보드에 복사되었습니다!\n원하는 곳에 붙여넣기 하세요!"
              }
              className={`${animation1}`}
            />
          </div>
        )} */}
        {openToastPopup2 && (
          <div
            style={{
              position: "fixed",
              bottom: "65px",
              left: "50vw",
              transform: "translateX(-50%)",
              width: "170px",
            }}
          >
            <ToastPopup
              status={"error"}
              children={"복사에 실패하였습니다"}
              className={`${animation2}`}
            />
          </div>
        )}
      </ContentsWrapper>
    </>
  );
}

const ContentsWrapper = styled.div`
  width: 100vw;
  min-width: 280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  flex-shrink: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  background-color: white;
  cursor: default;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  span {
    text-align: center;
    height: auto;
  }
`;
const Body = styled.div`
  flex-grow: 1;
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  overflow-y: scroll;
`;
const Tabs = styled.div.attrs({
  className: "Sub4M",
})`
  height: 48px;
  display: flex;
`;
const Tab = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isSelected ? "border-bottom: 2px solid #FF7A00;" : "color: #CDCDCD"}
`;
const DivDescription = styled.div.attrs({
  className: "Body6M",
})`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  background: #f8f8f8;
  word-break: keep-all;
  p {
    margin-bottom: 20px;
  }
  span {
    color: #808080;
  }
`;
const DivChipsContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;
const Chips = styled.div.attrs({
  className: "Body6M",
})`
  padding: 3px 8px;
  border-radius: 10px;
  color: white;
  background-color: #ff7a00;
`;
const DivWrapper = styled.div.attrs({
  className: "Sub3M",
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;
const DivMessageWrapper = styled.div.attrs({
  className: "Body5M -input-box",
})`
  height: 220px;
  width: 100%;
  overflow-y: scroll;
`;
const DropdownWrapper = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 44px;
`;
const ButtonSubmit = styled.div.attrs({
  className: "Sub3M",
})`
  margin-top: 8px;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background-color: black;
  color: white;
`;
