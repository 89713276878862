// 클럽 없는 사용자일 경우 구현 안되어 있음!!
import { React, useEffect, useState, useMemo } from "react";
import "../../asset/css/ATSM/ManageMain.css";
import styled from "styled-components/macro";
import dayjs from "dayjs";

import sample_img from "../../asset/main/background.png";
import sample_logo from "../../asset/header/logoM.png";
import Icon_ArrowRight from "../../asset/icon/Icon_ArrowRight.svg";
import Icon_ArrowLeft_White from "../../asset/icon/Icon_ArrowLeft_White.svg";
import Icon_Profile from "../../asset/icon/Icon_Profile.svg";
import Icon_Profile_White from "../../asset/icon/Icon_Profile_White.svg";
import badge from "../../asset/clubList/badge.png";
import Icon_Plus_Club from "../../asset/icon/Icon_Plus_Club.png";

import Connection from "../Connection";
import RecruitList from "./RecruitList";
import MainFlow from "./MainFlow";
import ManageMessage from "./ManageMessage";
import ClubManager from "./ClubManager";
import Mypage from "./Mypage";

const BottomSheet = ({ myClubList, setModal, currentClubId, changeClub }) => {
  return (
    <>
      <BottomSheetWrapper onClick={() => setModal(false)}>
        <BottomSheetContainer>
          <ScrollContents>
            {myClubList.map((myClub, idx) => (
              <DivClub
                isSelected={myClub?.id === currentClubId}
                onClick={(e) => {
                  window.history.pushState(
                    {},
                    "",
                    `/${(myClub?.id || currentClubId) + "/manage"}`
                  );
                  changeClub(myClub?.id || currentClubId);
                }}
              >
                <ImgClubIcon src={myClub?.image} />
                <span>{myClub?.name || `내 동아리${idx + 1}`}</span>
              </DivClub>
            ))}
            <DivClub
              onClick={(e) => {
                // window.history.pushState(
                //   {},
                //   "",
                //   "/createclub"
                // );
                if (localStorage.getItem("session_key") === "admin_demo") {
                  window.alert("회원가입 후 이용 가능합니다🙂");
                } else {
                  window.location.href = "/createclub";
                }
              }}
            >
              <ImgClubIcon src={Icon_Plus_Club} />
              <span>동아리 개설하기</span>
            </DivClub>
          </ScrollContents>
        </BottomSheetContainer>
      </BottomSheetWrapper>
    </>
  );
};
const BottomSheetWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
`;
const BottomSheetContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 40vh;
  overflow-y: scroll;
  border-radius: 8px 8px 0px 0px;
  background-color: white;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const ScrollContents = styled.div`
  padding-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const DivClub = styled.div.attrs({
  className: "Body4M",
})`
  width: 100%;
  height: 64px;
  padding: 12px 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  ${(props) =>
    props?.isSelected
      ? "background-color: #F8F8F8; color: #FF7A00; img{ border: 1.5px solid #9f9f9f; }"
      : "background-color: white; color: black;"}
`;
const ImgClubIcon = styled.img.attrs({
  alt: "",
})`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const session_key = window.localStorage.getItem("session_key");
function ManageMain() {
  const today = new Date();

  const getUrlParams = () => {
    const params = window.location.href.split("/");
    return {
      clubId: params[params.length - 2], // 동아리 id
      manageState: params[params.length - 1], // 관리 상태: manage, recruitlist, ...
    };
  };

  const [clubId, setClubId] = useState(getUrlParams().clubId || null);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isWriteSectionOpen, setIsWriteSectionOpen] = useState(false);
  const [isArticleOpen, setIsArticleOpen] = useState(false);

  // 바텀 시트 노출 여부
  const [bottomSheet, setBottomSheet] = useState(false);

  // 받아오는 정보들
  const [profile, setProfile] = useState({});
  const [myClubList, setMyClubList] = useState([]);
  const [myClubInfo, setMyClubInfo] = useState({});

  // 현재 페이지 상태
  const [manageState, setManageState] = useState("manage");

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (window.scrollY > 190) setIsScrolled(true);
      else setIsScrolled(false);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // handling onpopstate: 페이지 주소변경 감지
  useEffect(() => {
    const handlePopState = (e) => {
      setClubId(getUrlParams().clubId);
      switch (getUrlParams().manageState) {
        case "manage":
          setManageState("manage");
          break;
        case "recruitlist":
          setManageState("recruitlist");
          break;
        case "mypage":
          setManageState("mypage");
          break;
        default:
          setManageState(getUrlParams().manageState);
          break;
      }
    };
    window.addEventListener("popstate", handlePopState);

    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  // 첫 로딩 시 내 동아리 리스트 & 내 정보 불러오기, manageState 업데이트
  useEffect(() => {
    setManageState(getUrlParams().manageState);
    Connection("/getUserInfo", {}).then((res) => setProfile(res));
    Connection("/getClubByUser", {}).then((res) => {
      setMyClubList(res.club);
      setClubId(getUrlParams().clubId);
    });
  }, []);

  // url에서 club_id를 받아온 후
  useEffect(() => {
    if (clubId === "no_club") {
      setMyClubInfo({ message: "동아리가 없습니다.", recruit: [] });
    } else if (clubId) {
      setMyClubInfo({}); // myClubInfo 비우기(skeleton 효과 발생)
      Connection("/getClubInfo", {
        club_id: clubId,
      }).then((res) => setMyClubInfo(res));
    }
  }, [clubId]);
  // myClubInfo 비어있지 않을 경우 true
  const isReadyMyClubInfo = useMemo(
    () => Object.entries(myClubInfo).length !== 0,
    [myClubInfo]
  );

  return (
    <>
      {manageState === "manage" && (
        <>
          <div
            style={{
              overflow: (isWriteSectionOpen || isArticleOpen) && "hidden",
            }}
          >
            {isScrolled ? (
              <div className="div-manage-main-top-section-scrolled">
                {myClubList[0]?.name || "내 동아리"}
                <img src={Icon_Profile} alt="" />
              </div>
            ) : (
              <div className="div-manage-main-top-section">
                <img
                  src={Icon_ArrowLeft_White}
                  alt=""
                  onClick={() => {
                    window.location.href = "/";
                  }}
                />
                <img
                  src={Icon_Profile_White}
                  alt=""
                  onClick={() => {
                    setManageState("mypage");
                    window.history.pushState({}, "", "/mypage");
                  }}
                />
              </div>
            )}
            {!isScrolled && (
              <img
                className={`img-manage-main-top ${
                  isReadyMyClubInfo ? null : "skeleton"
                }`}
                src={
                  isReadyMyClubInfo
                    ? myClubInfo?.activiy_image?.length
                      ? myClubInfo?.activiy_image[0]
                      : sample_img
                    : null
                }
                alt=""
              />
            )}
            <div className="div-manage-main-scroll-section">
              <div className="div-manage-main-scroll-background" />
              {isScrolled ? (
                <div className="div-manage-main-infobox-section-scrolled">
                  {myClubList[0]?.name || "내 동아리"}
                </div>
              ) : (
                <div className="div-manage-main-infobox-section">
                  <div className="div-manage-main-infobox-info-wrapper">
                    {/* {club.certified && ( */}
                    {true && (
                      <img
                        className="img-manage-info-badge"
                        src={badge}
                        alt=""
                      />
                    )}
                    {isReadyMyClubInfo ? (
                      <img
                        className="img-manage-main-infobox-logo"
                        src={myClubInfo?.image || sample_logo}
                        alt=""
                      />
                    ) : (
                      <div className="img-manage-main-infobox-logo skeleton" />
                    )}
                    {isReadyMyClubInfo ? (
                      <div className="div-manage-main-infobox-text-section">
                        <div className="div-manage-main-infobox-name Sub2M">
                          {myClubInfo?.name || "내 동아리"}
                        </div>
                        <div className="div-manage-main-infobox-introduce Body5M">
                          {myClubInfo?.introduce}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="div-manage-main-infobox-text-section skeleton"
                        style={{
                          borderRadius: "8px",
                          width: "calc(100% - 76px)",
                        }}
                      />
                    )}
                  </div>
                  <button
                    className="button-manage-main-infobox-change Sub3M"
                    onClick={() => setBottomSheet(true)}
                  >
                    {myClubList?.length > 1
                      ? "다른 동아리 선택하기"
                      : "동아리 개설하기"}
                  </button>
                </div>
              )}
              <div
                className="div-manage-main-contents"
                onClick={() => {
                  if (isReadyMyClubInfo) {
                    setManageState("recruitlist");
                    window.history.pushState({}, "", `/${clubId}/recruitlist`);
                  }
                }}
              >
                <div className="div-manage-main-link-page Sub2M">
                  📣 모집 공고 리스트
                  <img
                    className="img-manage-main-link-icon"
                    src={Icon_ArrowRight}
                    alt=""
                  />
                </div>
                <div className="div-manage-main-links">
                  {isReadyMyClubInfo ? (
                    myClubInfo?.recruit?.length !== 0 ? (
                      myClubInfo.recruit.map((rec, idx) => {
                        if (idx < 3) {
                          return (
                            <>
                              <div className="div-manage-main-link-recruit Body4M">
                                <p>{rec.title}</p>
                                {rec.end_date === "2999-12-31" ? (
                                  <div className="chip-manage-main background-orange Tag2M">
                                    상시
                                  </div>
                                ) : rec.state === 1 &&
                                  parseInt(
                                    dayjs(rec.end_date).format("YYYYMMDD")
                                  ) -
                                    parseInt(dayjs(today).format("YYYYMMDD")) >=
                                    0 ? (
                                  <div
                                    className="chip-manage-main background-orange Tag2M"
                                    style={
                                      parseInt(
                                        dayjs(rec.start_date).format("YYYYMMDD")
                                      ) >
                                      parseInt(dayjs(today).format("YYYYMMDD"))
                                        ? { backgroundColor: "black" }
                                        : { backgroundColor: "#FF7A00" }
                                    }
                                  >
                                    D-
                                    {dayjs(rec.end_date).format("YYYYMMDD") ===
                                    dayjs(today).format("YYYYMMDD")
                                      ? "DAY"
                                      : dayjs(rec.end_date).diff(
                                          dayjs(today),
                                          "day"
                                        ) + 1}
                                  </div>
                                ) : (
                                  <div className="chip-manage-main background-black Tag2M">
                                    마감
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        } else return;
                      })
                    ) : (
                      <div className="div-manage-main-link-recruit Body4M">
                        <p>모집 공고가 없습니다.</p>
                      </div>
                    )
                  ) : (
                    <>
                      <div
                        className="div-manage-main-link-recruit skeleton"
                        style={{ height: "20px", borderRadius: "8px" }}
                      />
                      <div
                        className="div-manage-main-link-recruit skeleton"
                        style={{ height: "20px", borderRadius: "8px" }}
                      />
                      <div
                        className="div-manage-main-link-recruit skeleton"
                        style={{ height: "20px", borderRadius: "8px" }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div
                className="div-manage-main-contents"
                onClick={() => {
                  if (clubId === "no_club") {
                    alert("동아리 등록 후 이용해주세요!!😀");
                  } else if (isReadyMyClubInfo) {
                    setManageState("manageMessage");
                    window.history.pushState(
                      {},
                      "",
                      `/${clubId}/manageMessage`
                    );
                  }
                }}
              >
                <div className="div-manage-main-link-page Sub2M">
                  📩 합불 메세지 관리
                  <img
                    className="img-manage-main-link-icon"
                    src={Icon_ArrowRight}
                    alt=""
                  />
                </div>
              </div>
              <div
                className="div-manage-main-contents"
                onClick={() => {
                  if (clubId === "no_club") {
                    alert("동아리 등록 후 이용해주세요!!😀");
                  } else if (isReadyMyClubInfo) {
                    setManageState("clubManager");
                    window.history.pushState({}, "", `/${clubId}/clubManager`);
                  }
                }}
              >
                <div className="div-manage-main-link-page Sub2M">
                  🙋‍♀ 운영진 관리️
                  <img
                    className="img-manage-main-link-icon"
                    src={Icon_ArrowRight}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          {bottomSheet && (
            <BottomSheet
              myClubList={myClubList}
              setModal={setBottomSheet}
              currentClubId={clubId}
              changeClub={setClubId}
            />
          )}
        </>
      )}
      {manageState === "recruitlist" && isReadyMyClubInfo && (
        <RecruitList
          recruitList={myClubInfo.recruit}
          setPage={setManageState}
        />
      )}
      {manageState === "manageMessage" && (
        <ManageMessage clubId={clubId} setPage={setManageState} />
      )}
      {manageState === "clubManager" && (
        <ClubManager clubId={clubId} setPage={setManageState} />
      )}
      {manageState === "mypage" && (
        <Mypage setPage={setManageState} profile={profile} />
      )}
      {manageState === "mypage" ||
        manageState === "recruitlist" ||
        manageState === "manage" ||
        manageState === "manageMessage" ||
        manageState === "clubManager" || <MainFlow setPage={setManageState} />}
    </>
  );
}

export default ManageMain;
