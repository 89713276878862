/*================================
* 서류 합격자 현황 테이블
==================================*/

import React, { useState, useEffect } from "react";
import Icon_Application from "../asset/icon/Icon_Application_Gray.svg";
import Answer from "./Answer";
import Connection from "./Connection";
import ViewForm from "./ViewForm";
import ChipStatus4 from "./ChipStatus4";

const TableContent4 = ({
  club_id,
  recruit_id,
  recruitDocs,
  applicantList,
  setApplicantList,
  columnList,
  columnWidth,
  setCountFail,
  setCountPass,
  stateList,
  setStateList,
  modalViewForm,
  setModalViewForm,
  showWhole,
  managerList,
  getAllScore,
}) => {
  const session_key = window.localStorage.getItem("session_key");

  const [applicantNum, setApplicantNum] = useState();

  useEffect(() => {
    setCountPass(applicantList.filter((a) => a.stateV === 3).length);
    setCountFail(applicantList.filter((a) => a.stateV === 4).length);
  }, [applicantList]);

  const updateState = (applicant_id, state) => {
    Connection("/updateRecruitStep2", {
      club_id: club_id,
      recruit_id: recruit_id,
      applicant_id: applicant_id,
      state: String(state),
    });
  };

  const showRows = applicantList.map((applicant, idx) => (
    <tr key={idx}>
      {/* 상태 */}
      <td className="td-sticky-01">
        <span
          onClick={async () => {
            if (!showWhole) {
              if (applicant.stateV === 1) {
                setApplicantList(
                  applicantList.map((a, i) =>
                    i === idx ? { ...a, stateV: 3 } : a
                  )
                );

                updateState(applicant.id, 3);
              }
              if (applicant.stateV === 3) {
                setApplicantList(
                  applicantList.map((a, i) =>
                    i === idx ? { ...a, stateV: 4 } : a
                  )
                );
                updateState(applicant.id, 4);
              }
              if (applicant.stateV === 4) {
                setApplicantList(
                  applicantList.map((a, i) =>
                    i === idx ? { ...a, stateV: 1 } : a
                  )
                );
                updateState(applicant.id, 1);
              }
            }
          }}
        >
          {applicant.stateV === 2 ? (
            <button
              className="button-chip button-chip-status-1 Body5"
              style={{ backgroundColor: "#ececec" }}
            >
              불합격
            </button>
          ) : (
            <ChipStatus4 state={applicant.stateV} />
          )}
        </span>
      </td>
      {/* 점수 */}
      <td className="td-sticky-02-score">
        {applicant.stateV !== 2 && (
          <Answer
            question={columnList[1].data}
            answer={
              applicant.score % 1 > 0
                ? applicant.score.toFixed(1)
                : applicant.score
            }
          />
        )}
      </td>
      {/* 면접 시간 */}
      <td className="td-sticky-03-score">
        {applicant.stateV !== 2 && (
          <Answer
            question={columnList[2].data}
            answer={
              applicant.interview_date === ""
                ? "(미배정)"
                : `${applicant.interview_date.split("-")[1]}/${
                    applicant.interview_date.split("-")[2]
                  } (${applicant.interview_time})`
            }
          />
        )}
      </td>
      {/* 지원자명 */}
      <td className="td-sticky-03">
        <div className="td-sticky-02-index Tag1">{applicant.idx + 1}</div>
        {
          <Answer
            question={columnList[3].data}
            answer={applicant.profile.name}
          />
        }
        <img
          className="div-click img-table-application"
          src={Icon_Application}
          alt=""
          onClick={() => {
            setApplicantNum(idx);
            setModalViewForm(true);
          }}
        />
      </td>

      {/* --------------------------- 질문 ---------------------------*/}
      <>
        {columnList[4].isView && (
          <td>
            <Answer
              question={columnList[4].data}
              answer={applicant.profile.phone}
            />
          </td>
        )}
      </>
      <>
        {columnList[5].isView && (
          <td>
            <Answer
              question={columnList[5].data}
              answer={applicant.profile.birthday}
            />
          </td>
        )}
      </>
      <>
        {columnList[6].isView && (
          <td>
            <Answer
              question={columnList[6].data}
              answer={applicant.profile.gender}
            />
          </td>
        )}
      </>
      {columnList.map(function (column, idxx) {
        if (idxx > 5 && applicant.answer[idxx - 7] && column.isView) {
          return (
            <td key={idxx}>
              <Answer
                question={column.data}
                answer={applicant.answer[idxx - 7].value}
                width={columnWidth[idxx]}
              />
            </td>
          );
        }
      })}
    </tr>
  ));

  return (
    <>
      {showRows}
      {modalViewForm && (
        <ViewForm
          questions={recruitDocs?.question}
          answers={applicantList[applicantNum]}
          applicantNum={applicantNum}
          setApplicantNum={setApplicantNum}
          setModalOpen={setModalViewForm}
          applicantListLength={applicantList.length}
          managerList={managerList}
          recruit_id={recruit_id}
          applicantList={applicantList}
          setApplicantList={setApplicantList}
          getAllScore={getAllScore}
        />
      )}
    </>
  );
};

export default React.memo(TableContent4);
