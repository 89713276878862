import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "../asset/css/ViewForm.css";
import Icon_ArrowRight from "../asset/icon/Icon_ArrowRight.svg";
import Icon_ArrowLeft from "../asset/icon/Icon_ArrowLeft.svg";
import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import Icon_Download from "../asset/icon/Icon_Download.svg";
import Default_Image from "../asset/ViewForm/Default_Image.svg";
import ChipOption from "./ChipOption";
import Connection from "./Connection";
import CommunityConnection from "./community/CommunityConnection";

// api 통해 가져온 질문 객체, 답변 객체, and 지원자 번호
function ViewForm({
  questions,
  answers,
  applicantNum,
  setApplicantNum,
  setModalOpen,
  applicantListLength,
  managerList,
  recruit_id,
  applicantList,
  setApplicantList,
  isClosed,
}) {
  const session_key = window.localStorage.getItem("session_key");
  const phone = window.localStorage.getItem("phone");

  const [showScore, setShowScore] = useState(false);
  const focusRef = useRef(null);

  useLayoutEffect(() => {
    if (focusRef.current !== null) focusRef.current.focus();
  });

  const [score, setScore] = useState();
  const [myScore, setMyScore] = useState(0);
  const [myNewScore, setMyNewScore] = useState(0);
  const getScore = async () => {
    const res = await CommunityConnection("/getScore", {
      recruit_id: recruit_id,
      applicant_id: answers?.id,
    });
    if (res.is_success) {
      setScore(res.score);
    }
  };
  const getScoreArr = async () => {
    const res = await CommunityConnection("/getScore", {
      recruit_id: recruit_id,
      applicant_id: answers?.id,
    });
    if (res.is_success) {
      return res.score;
    }
  };

  const getMyScore = async () => {
    const res = await CommunityConnection("/getMyScore", {
      recruit_id: recruit_id,
      applicant_id: answers?.id,
    });
    if (res.is_success) {
      setMyScore(res.score?.score || 0);
      setMyNewScore(res.score?.score || 0);
    }
  };

  useEffect(() => {
    getScore();
    getMyScore();
  }, [answers]);

  const getAverage = (arr) => {
    if (arr?.length === 0) return 0;
    else
      return (
        arr?.reduce(function add(sum, current) {
          return sum + current.score;
        }, 0) / arr?.length
      );
  };

  const inputScore = async () => {
    const res = await CommunityConnection("/inputScore", {
      recruit_id: recruit_id,
      applicant_id: answers?.id,
      score: myNewScore,
    });
    if (res.is_success) {
      setMyScore(myNewScore);
      const res = await getScoreArr();
      if (res) {
        setApplicantList(
          applicantList.map((a) =>
            a.id === answers?.id ? { ...a, score: getAverage(res) } : a
          )
        );
      }
    }
  };

  const showScoreSection = (
    <div className="div-viewform-score-section">
      {managerList?.map((manager, idx) => (
        <div className="div-viewform-score">
          <div className="div-viewform-score-manager-name Sub2">
            {manager.name}
          </div>
          {recruit_id === "986cd456-4e3d-43a5-8ba8-f1f1fa297a49" ||
          manager.phone === phone ? (
            <input
              className="input-viewform-score Sub2"
              value={myNewScore}
              onChange={(e) =>
                setMyNewScore(
                  parseInt(e.target.value === "" ? 0 : e.target.value)
                )
              }
              ref={focusRef}
              maxLength={4}
              disabled={isClosed}
            />
          ) : (
            <input
              className="input-viewform-score Sub2"
              value={
                score?.find((score) => manager.phone === score.phone)?.score
              }
              disabled
            />
          )}
          {(recruit_id === "986cd456-4e3d-43a5-8ba8-f1f1fa297a49" ||
            (manager.phone === phone && !isClosed)) && (
            <button
              className="main-black-button button-viewform-score Body4"
              onClick={() => inputScore()}
              disabled={myNewScore === myScore}
            >
              입력하기
            </button>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="div-modal-background">
      <div id="viewform-modal">
        {applicantNum !== 0 && (
          <img
            src={Icon_ArrowLeft}
            className="viewform-arrow-left"
            alt=""
            onClick={() => setApplicantNum(applicantNum - 1)}
          />
        )}
        {applicantNum !== applicantListLength - 1 && (
          <img
            src={Icon_ArrowRight}
            className="viewform-arrow-right"
            alt=""
            onClick={() => setApplicantNum(applicantNum + 1)}
          />
        )}

        <div id="viewform-header">
          <p className="H3">🗂️ 지원서</p>
          <img
            id="viewform-close"
            src={Icon_Close}
            alt=""
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </div>
        <div id="viewform-scroll">
          <div style={{ position: "relative" }}>
            <hr
              style={{ border: 0, height: "1px" }}
              width="600px"
              color="#FF7A00"
            />
            <p className="Sub1" style={{ marginTop: "9px" }}>
              필수 정보
            </p>
          </div>
          <div className="viewform-basicInfo-row">
            <div className="viewform-basicInfo-column">
              <div className="viewform-stat">
                <p className="Sub3">이름</p>
                <div className="viewform-basicInfo-answer">
                  {answers?.profile?.name}
                </div>
              </div>
              <div className="viewform-stat">
                <p className="Sub3">전화번호</p>
                <div className="viewform-basicInfo-answer">
                  {answers?.profile?.phone}
                </div>
              </div>
            </div>
            <div className="viewform-basicInfo-column">
              <div className="viewform-stat">
                <p className="Sub3">성별</p>
                <span className="Sub3">
                  {answers?.profile?.gender === "male" ? (
                    <ChipOption option={5} name={"남자"} qType={13} />
                  ) : (
                    <ChipOption option={0} name={"여자"} qType={13} />
                  )}
                </span>
              </div>
              <div className="viewform-stat">
                <p className="Sub3">생년월일</p>
                <div className="viewform-basicInfo-answer">
                  {answers?.profile?.birthday}
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <hr
              style={{ border: 0, height: "1px" }}
              width="600px"
              color="#6A6A6A"
            />
            <p className="Sub1" style={{ marginTop: "9px" }}>
              추가 정보
            </p>
          </div>
          <div className="viewform-moreInfo-column">
            {questions
              .filter((q) => q.id != null)
              .map((q, idx) => {
                switch (q.type) {
                  case 14:
                    return (
                      <div className="viewform-moreInfo-question">
                        <p className="Sub3">
                          ({q.k} )
                          <pre className="viewform-moreInfo-question-title Sub3">
                            {q.q}
                          </pre>
                        </p>
                        <a
                          href={answers.answer[idx].value}
                          download
                          style={{ textDecorationLine: "none" }}
                        >
                          <button className="viewform-moreInfo-answer-file">
                            <img src={Icon_Download} alt="" />
                            <span className="Sub3">파일 다운로드</span>
                          </button>
                        </a>
                      </div>
                    );
                  case 19:
                    return (
                      <div className="viewform-moreInfo-question">
                        <p className="Sub3">
                          ({q.k} )
                          <pre className="viewform-moreInfo-question-title Sub3">
                            {q.q}
                          </pre>
                        </p>
                        <img
                          src={answers.answer[idx].value}
                          alt={Default_Image}
                          className="viewform-moreInfo-answer-image"
                          onClick={() =>
                            window.open(
                              `${answers.answer[idx].value}`,
                              "_blank"
                            )
                          }
                        />
                      </div>
                    );
                  case 22:
                    return (
                      <div className="viewform-moreInfo-question">
                        <p className="Sub3">{`(${q.k} )`}</p>
                        <pre className="viewform-moreInfo-answer-default Body4">
                          {answers.answer[idx].value}
                        </pre>
                      </div>
                    );
                  default:
                    return (
                      <div className="viewform-moreInfo-question">
                        <p className="Sub3">
                          ({q.k} )
                          <pre className="viewform-moreInfo-question-title Sub3">
                            {q.q}
                          </pre>
                        </p>
                        <pre className="viewform-moreInfo-answer-default Body4">
                          {Array.isArray(answers.answer[idx].value)
                            ? answers.answer[idx].value.map((line) => (
                                <>
                                  {line}
                                  <br />
                                </>
                              ))
                            : answers.answer[idx].value}
                        </pre>
                      </div>
                    );
                }
              })}
          </div>
        </div>
        <div className="div-viewform-footer" style={{ position: "relative" }}>
          {showScore ? (
            <img
              className="img-viewform-footer-close div-click"
              src={Icon_Close}
              alt=""
              onClick={() => setShowScore(false)}
            />
          ) : (
            <>
              <div
                className="viewform-memoBtn div-click"
                onClick={() => {
                  setShowScore(true);
                }}
              >
                <img src={Icon_ArrowUp} alt="" />
                <span className="Body3">채점하기</span>
              </div>
            </>
          )}
          {showScore && showScoreSection}
        </div>
      </div>
    </div>
  );
}

ViewForm.defaultProps = {
  isClosed: false,
};

export default ViewForm;
