import React, { useState } from "react";
import styled from "styled-components/macro";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";

import CommunityConnection from "./CommunityConnection";

MyProfile.defaultProps = {
  interests: "",
  getInterests: function() { return },
}
export default function MyProfile({ ...props }) {
  const initInterests = props.interests.split(",");
  const [first, setFirst] = useState(initInterests.includes("문화/예술/공연"));
  const [second, setSecond] = useState(initInterests.includes("봉사/사회활동"));
  const [third, setThird] = useState(initInterests.includes("학술/교양"));
  const [fourth, setFourth] = useState(initInterests.includes("창업/취업"));
  const [fifth, setFifth] = useState(initInterests.includes("어학"));
  const [sixth, setSixth] = useState(initInterests.includes("체육"));
  const [seventh, setSeventh] = useState(initInterests.includes("친목"));
  const [eighth, setEighth] = useState(initInterests.includes("기타"));
  const [ninth, setNinth] = useState(initInterests.includes("진짜기타"));
  const setTrue = () => {
    let count = 0;
    first && count++;
    second && count++;
    third && count++;
    fourth && count++;
    fifth && count++;
    sixth && count++;
    seventh && count++;
    eighth && count++;
    ninth && count++;
    return count < 3;
  };
  const handleInterestsUpdate = async() => {
    const array = [];
    first && array.push("문화/예술/공연");
    second && array.push("봉사/사회활동");
    third && array.push("학술/교양");
    fourth && array.push("창업/취업");
    fifth && array.push("어학");
    sixth && array.push("체육");
    seventh && array.push("친목");
    eighth && array.push("기타");
    ninth && array.push("진짜기타");
    const updateInterests = async() => {
      CommunityConnection('/updateInterests', {
        session_key: window.localStorage.getItem('session_key'),
        interests: array,
      });
    }
    if (array.length || window.confirm("등록된 관심사가 없어요. 그래도 진행하시겠어요?")) {
      updateInterests().then(props.getInterests());
      props.setPage(false);
    }
  }
  return (
    <>
      <MainContainer>
        <Header className="Sub1M">
          <img src={Icon_ArrowLeft} alt=""  onClick={() => props.setPage(false)}/>
          관심 분야
        </Header>
        <Body>
          <Description className="Sub2M">
            관심있는 분야를
            <br />
            최대 3가지 선택해주세요!
          </Description>
          <DivChips>
            <Chip
              onClick={() => setFirst((prev) => (prev ? false : setTrue()))}
              isSelected={first}
            >
              문화/예술/공연
            </Chip>
            <Chip
              onClick={() =>
                setSecond((prev) => (prev ? false : setTrue()))
              }
              isSelected={second}
            >
              봉사/사회활동
            </Chip>
            <Chip
              onClick={() =>
                setThird((prev) => (prev ? false : setTrue()))
              }
              isSelected={third}
            >
              학술/교양
            </Chip>
            <Chip
              onClick={() =>
                setFourth((prev) => (prev ? false : setTrue()))
              }
              isSelected={fourth}
            >
              창업/취업
            </Chip>
            <Chip
              onClick={() =>
                setFifth((prev) => (prev ? false : setTrue()))
              }
              isSelected={fifth}
            >
              어학
            </Chip>
            <Chip
              onClick={() =>
                setSixth((prev) => (prev ? false : setTrue()))
              }
              isSelected={sixth}
            >
              체육
            </Chip>
            <Chip
              onClick={() =>
                setSeventh((prev) => (prev ? false : setTrue()))
              }
              isSelected={seventh}
            >
              친목
            </Chip>
            <Chip
              onClick={() =>
                setEighth((prev) => (prev ? false : setTrue()))
              }
              isSelected={eighth}
            >
              기타
            </Chip>
            <Chip
              onClick={() =>
                setNinth((prev) => (prev ? false : setTrue()))
              }
              isSelected={ninth}
            >
              진짜기타🎸
            </Chip>
          </DivChips>
          <ButtonModify 
            className="Sub3M"
            onClick={() => {
              handleInterestsUpdate();

            }}
          >수정했어요</ButtonModify>
        </Body>
      </MainContainer>
    </>
  );
}
const MainContainer = styled.div`
  user-select: none;
  width: 100vw;
  min-width: 280px;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
`;
const Header = styled.div`
  padding: 0px 12px;
  height: 56px;
  display: flex;
  gap: 8px;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
const Body = styled.div`
  position: relative;
  padding: 20px 12px 0px 12px;
  width: 100%;
  height: calc(100% - 56px);
`;
const Description = styled.p`
  margin-top: 20px;
`;
const DivChips = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
const Chip = styled.div.attrs({
  className: "Sub3M",
})`
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 22px;
  ${(props) =>
    props.isSelected
      ? "background-color: #ff7a00; color: white;"
      : "outline: 1px solid #ff7a00; color: #ff7a00"}
`;
const ButtonModify = styled.button`
  position: fixed;
  bottom: 20px;
  width: calc(100% - 24px);
  border-radius: 8px;
  background-color: black;
  color: white;
  padding: 12px 16px;
  text-align: center;
`;
