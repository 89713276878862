import React from "react";
import comingsoon from "../asset/comingsoon/comingsoon.svg";

function ComingSoonM() {
  return (
    <div className="div-comingsoonM-section">
      <img className="img-comingsoonM" src={comingsoon} alt="" />
      <div className="div-comingsoon-section-title Sub1M">
        서비스를 준비하고 있어요 🛠️
      </div>
    </div>
  );
}

export default ComingSoonM;
