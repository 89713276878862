import React from "react";
import "../asset/css/ChipStatus4M.css";

function ChipStatusM({ state }) {
  if (state === 0)
    return <button className="button-chip-status-0M Body5M">미처리</button>;
  else {
    switch (state) {
      case 1:
        return <button className="button-chip-status-1M Body5M">합격</button>;
      case 2:
        return <button className="button-chip-status-2M Body5M">불합격</button>;
      default:
    }
  }
}

export default ChipStatusM;
