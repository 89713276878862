import { React, useState, useEffect } from "react";

import Icon_Purpose from "../asset/icon/Icon_Purpose.svg";
import Icon_Group from "../asset/icon/Icon_Group.svg";
import Icon_Location from "../asset/icon/Icon_Location.svg";
import Icon_Card from "../asset/icon/Icon_Card.svg";
import Icon_Fold from "../asset/icon/Icon_Fold.svg";
import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import Icon_Plus from "../asset/icon/Icon_Plus.svg";
import Icon_Setting from "../asset/icon/Icon_Setting.svg";

import Connection from "./Connection";
import ClubManagerPage from "./ClubManagerPage";
import ComingSoon from "./ComingSoon";
import CreateClub from "./CreateClub";
import RecruitPage1 from "./RecruitPage1";
import RecruitPage2 from "./RecruitPage2";
import RecruitPage4 from "./RecruitPage4";
import RecruitPage6 from "./RecruitPage6";
import UpdateClub from "./UpdateClub";
import ViewImages from "./ViewImages";
import ManageMessage from "./ManageMessage";
import Loginform from "./Loginform";
import RecruitPageAlways from "./RecruitPageAlways";
import dayjs from "dayjs";
import GuideModal, { CoachMark, GuidePunctureDim } from "./guideModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setRecruit1,
  setRecruit1i,
  setRecruit2,
  setRecruit2i,
  setTimetable1,
  setTimetable1i,
  setTemplate1,
  setTemplate1i,
} from "../modules/guide";

function Manage() {
  const today = new Date();
  const [info, setInfo] = useState({});
  const [clubByUser, setClubByUser] = useState({
    is_success: false,
    club: [{ id: null }],
  });
  const [clubInfo, setClubInfo] = useState({}); // .recruit: 모집 공고 배열
  const [recruitInfo, setRecruitInfo] = useState({});
  const [recruitDocs, setRecruitDocs] = useState({});
  const [recruitStep1, setRecruitStep1] = useState({}); // .applicant: 지원자 배열

  const [isClubInfoOpen, setIsClubInfoOpen] = useState(false);
  const [isMozipListOpen, setIsMozipListOpen] = useState(false);

  const param = window.location.href.split("/");
  const [checkedMenu, setCheckedMenu] = useState(0); // 모집공고, 합불메세지, 운영진 ...
  const [checkedClub, setCheckedClub] = useState(param[param.length - 2]);
  const [checkedMozip, setCheckedMozip] = useState(param[param.length - 1]);

  const session_key = window.localStorage.getItem("session_key");

  const [url, setUrl] = useState("");

  const [createClubModal, setCreateClubModal] = useState(false);

  const [updateClubModal, setUpdateClubModal] = useState(false);
  const [viewImageModal, setViewImageModal] = useState(false);

  const [recruitState, setRecruitState] = useState(); // 모집 단계

  const [loginModal, setLoginModal] = useState(false);

  const [managerList, setManagerList] = useState({});
  async function getClubAdmin() {
    const res = await Connection("/getClubAdmin", {
      club_id: checkedClub,
    });
    if (res.is_success) {
      setManagerList(res.admin);
    }
  }

  useEffect(() => {
    getClubAdmin();
  }, [checkedClub]);

  useEffect(() => {
    if (param[param.length - 1] === "manageMessage") {
      setCheckedMenu(1);
    } else if (param[param.length - 1] === "clubManager") {
      setCheckedMenu(2);
    } else {
      setIsMozipListOpen(true);
    }
  }, []);

  useEffect(() => {
    if (checkedMenu === 0) {
      setUrl(checkedClub + "/" + checkedMozip);
      const state = { club_id: 0, recruit_id: 0 };
      window.history.pushState(
        state,
        "",
        `/${checkedClub + "/" + checkedMozip}`
      );
    }
    if (checkedMenu === 1) {
      setUrl(checkedClub + "/manageMessage");
      const state = { club_id: 0, recruit_id: 0 };
      window.history.pushState(state, "", `/${checkedClub + "/manageMessage"}`);
    }
    if (checkedMenu === 2) {
      setUrl(checkedClub + "/clubManager");
      const state = { club_id: 0, recruit_id: 0 };
      window.history.pushState(state, "", `/${checkedClub + "/clubManager"}`);
    }
  }, [checkedClub, checkedMozip, checkedMenu]);

  useEffect(() => {
    connect5();
  }, [recruitState]);

  useEffect(() => {
    connect1();
  }, []);

  useEffect(() => {
    setClubInfo({});
    setRecruitInfo({});
    setRecruitDocs({});
    setRecruitStep1({});
  }, [clubByUser]);

  useEffect(() => {
    setClubInfo({});
    setRecruitInfo({});
    setRecruitDocs({});
    setRecruitStep1({});
    if (clubByUser.is_success) {
      connect2();
    }
    setIsClubInfoOpen(false);
  }, [checkedClub]);

  useEffect(() => {
    if (clubByUser.is_success) {
      connect3();
      connect4();
      connect5();
    }
  }, [checkedMozip, checkedMenu]);

  useEffect(() => {
    const guide = JSON.parse(window.localStorage.getItem("guide"));
    if (
      // guide.recruit_1
      !guide?.recruit_1 && // "그만 보기"를 누르지 않았는가?
      clubInfo.recruit?.length > 0 && // 모집 공고를 한개 이상 등록 했는가?
      checkedMenu === 0 && // 현재 모집 공고 관리 화면인가?
      recruitState === 1 // 1차 합불처리 단계인가?
    ) {
      onChangeRec2(false);
      onChangeTime1(false);
      onChangeRec1(true);
      onChangeRec1i(0);
    } else if (
      // guide.recruit_2
      !guide?.recruit_2 &&
      !guideRecruit_1 && // 이전 가이드 모달을 닫았는가?
      clubInfo.recruit?.length > 0 &&
      checkedMenu === 0 &&
      recruitState === 1 &&
      recruitStep1.applicant?.length > 0 // 지원자가 최소 한명 이상인가?
    ) {
      onChangeRec1(false);
      onChangeTime1(false);
      onChangeRec2(true);
      onChangeRec2i(0);
    } else if (
      !guide?.timetable_1 && // "그만 보기"를 누르지 않았는가?
      !guideRecruit_1 && // 이전 가이드 모달을 닫았는가?
      clubInfo.recruit?.length > 0 && // 모집 공고를 한개 이상 등록 했는가?
      checkedMenu === 0 && // 현재 모집 공고 관리 화면인가?
      recruitState === 2 // 2차 합불처리 단계인가?
    ) {
      onChangeRec1(false);
      onChangeRec2(false);
      onChangeTime1(true);
      onChangeTime1i(0);
    } else {
      onChangeRec1(false);
      onChangeRec2(false);
      onChangeTime1(false);
    }
  }, [clubInfo, checkedMenu, recruitState, recruitInfo, recruitStep1]);

  const onChangeGuide = () => {
    const guide = JSON.parse(window.localStorage.getItem("guide"));
    if (
      !guide?.recruit_2 &&
      clubInfo.recruit?.length > 0 &&
      checkedMenu === 0 &&
      recruitState === 1 &&
      recruitStep1.applicant?.length > 0
    ) {
      onChangeRec2(true);
      onChangeRec2i(0);
    }
  };

  async function setFirstMozip() {
    const ci = await Connection("/getClubInfo", {
      club_id: checkedClub,
    });

    if (ci.recruit.length > 0) {
      setCheckedMozip(ci.recruit[0].id);
    }
  }

  async function connect1() {
    setInfo(await Connection("/getInfo", {}));

    const cbu = await Connection("/getClubByUser", {});

    setClubByUser(cbu);

    const ci = await Connection("/getClubInfo", {
      club_id: checkedClub,
    });

    setClubInfo(ci);

    const recruitList = ci.recruit;
    let isRecruit = false;

    for (var i = 0; i < recruitList.length; i++) {
      if (recruitList[i].id == checkedMozip) {
        isRecruit = true;
      }
    }

    if (checkedMozip !== "no_recruit" && checkedMozip !== "clubManager") {
      if (isRecruit) {
        const ret = await Connection("/getRecruitInfo", {
          club_id: checkedClub,
          recruit_id: checkedMozip,
        });
        setRecruitInfo(ret);

        setRecruitState(ret.state);
      }

      if (isRecruit) {
        setRecruitDocs(
          await Connection("/getRecruitDocs", {
            club_id: checkedClub,
            recruit_id: checkedMozip,
          })
        );
      }

      if (isRecruit) {
        setRecruitStep1(
          await Connection("/getRecruitStep1", {
            club_id: checkedClub,
            recruit_id: checkedMozip,
          })
        );
      }
    }
  }

  async function connect2() {
    const ci = await Connection("/getClubInfo", {
      club_id: checkedClub,
    });
    setClubInfo(ci);
    setCheckedMozip(ci.recruit[0].id);

    const recruitList = ci.recruit;
    let isRecruit = false;

    for (var i = 0; i < recruitList.length; i++) {
      if (recruitList[i].id == checkedMozip) {
        isRecruit = true;
      }
    }

    if (checkedMozip !== "no_recruit" && checkedMozip !== "clubManager") {
      if (isRecruit) {
        const ret = await Connection("/getRecruitInfo", {
          club_id: checkedClub,
          recruit_id: checkedMozip,
        });
        setRecruitInfo(ret);

        setRecruitState(ret.state);
      }

      if (isRecruit) {
        setRecruitDocs(
          await Connection("/getRecruitDocs", {
            club_id: checkedClub,
            recruit_id: ci.recruit[0].id,
          })
        );
      }

      if (isRecruit) {
        setRecruitStep1(
          await Connection("/getRecruitStep1", {
            club_id: checkedClub,
            recruit_id: ci.recruit[0].id,
          })
        );
      }
    }
  }

  async function connect3() {
    let isRecruit = false;
    const recruitList = clubInfo.recruit;

    for (var i = 0; i < recruitList.length; i++) {
      if (recruitList[i].id == checkedMozip) {
        isRecruit = true;
      }
    }

    if (isRecruit) {
      const ret = await Connection("/getRecruitInfo", {
        club_id: checkedClub,
        recruit_id: checkedMozip,
      });
      setRecruitInfo(ret);

      setRecruitState(ret.state);
    }
  }

  async function connect4() {
    let isRecruit = false;
    const recruitList = clubInfo.recruit;

    for (var i = 0; i < recruitList.length; i++) {
      if (recruitList[i].id == checkedMozip) {
        isRecruit = true;
      }
    }

    if (isRecruit) {
      setRecruitDocs(
        await Connection("/getRecruitDocs", {
          club_id: checkedClub,
          recruit_id: checkedMozip,
        })
      );
    }
  }

  async function connect5() {
    let isRecruit = false;
    const recruitList = clubInfo.recruit;

    for (var i = 0; i < recruitList?.length; i++) {
      if (recruitList[i].id == checkedMozip) {
        isRecruit = true;
      }
    }

    if (isRecruit) {
      setRecruitStep1(
        await Connection(
          recruitState === 4 ? "/getRecruitStep2" : "/getRecruitStep1",
          {
            club_id: checkedClub,
            recruit_id: checkedMozip,
          }
        )
      );
    }
  }

  const [animation1, setAnimation1] = useState("");
  const onToggle = () => {
    if (isLeftSectionOpen) {
      setAnimation1("fold");
      setIsLeftSectionOpen(false);
    } else {
      setAnimation1("spread");
      setIsLeftSectionOpen(true);
    }
  };

  const [isLeftSectionOpen, setIsLeftSectionOpen] = useState(true);

  useEffect(() => {
    if (!isClubInfoOpen && isLeftSectionOpen) {
      let ins = document.createElement("ins");
      let scr = document.createElement("script");
      ins.className = "kakao_ad_area";
      ins.style = "display:none; width:100%;";
      scr.async = "true";
      scr.type = "text/javascript";
      scr.src = "//t1.daumcdn.net/kas/static/ba.min.js";
      ins.setAttribute("data-ad-width", "250");
      ins.setAttribute("data-ad-height", "250");
      ins.setAttribute("data-ad-unit", "DAN-W7SwgboLynoH9WS8");
      document.querySelector(".adfit").appendChild(ins);
      document.querySelector(".adfit").appendChild(scr);
    }
  }, [isClubInfoOpen, isLeftSectionOpen]);

  const leftSection = (
    <div className="div-manage-section-02">
      <div className="div-manage-section-02-01">
        <div className="div-manage-section-02-01-01">
          {clubInfo.image ? (
            <img
              className="img-manage-logo-square"
              src={clubInfo.image}
              alt=""
            />
          ) : (
            <div className="skeleton-manage-logo-square" />
          )}
          {clubInfo.is_success ? (
            <div className="div-manage-section-02-01-01-01">
              <div className="H3" style={{ wordBreak: "keep-all" }}>
                {clubInfo.name}
              </div>
              <pre className="pre-manage-section-02-01-01-01 Body4">
                {clubInfo.introduce}
              </pre>
            </div>
          ) : (
            <div className="skeleton-manage-section-02-01-01-01" />
          )}
        </div>
        {updateClubModal && (
          <UpdateClub
            setModalOpen={setUpdateClubModal}
            info={info}
            clubInfo={clubInfo}
            clubId={checkedClub}
          />
        )}
      </div>
      {clubInfo.is_success && isClubInfoOpen ? (
        <>
          <div className="div-manage-section-02-02">
            <div className="div-manage-section-02-02-01">
              <div className="div-manage-section-02-02-01-01 Sub3">
                <div className="div-manage-section-02-02-01-01-01">
                  <div className="div-manage-section-02-02-01-01-01-line">
                    <img className="icon" src={Icon_Purpose} alt="" />
                    <div className="div-manage-section-02-02-01-01-01-left">
                      목적
                    </div>
                    <div className="div-manage-section-02-02-01-01-01-right">
                      {clubInfo.purpose}
                    </div>
                  </div>
                  <div className="div-manage-section-02-02-01-01-01-line">
                    <img className="icon" src={Icon_Group} alt="" />
                    <div className="div-manage-section-02-02-01-01-01-left">
                      관심사
                    </div>
                    <div className="div-manage-section-02-02-01-01-01-right">
                      {info.category[clubInfo.category]}
                    </div>
                  </div>
                  <div className="div-manage-section-02-02-01-01-01-line">
                    <img className="icon" src={Icon_Location} alt="" />
                    <div className="div-manage-section-02-02-01-01-01-left">
                      활동 지역
                    </div>
                    <div className="div-manage-section-02-02-01-01-01-right">
                      {clubInfo.location.map((loc, idx) => (
                        <div key={idx}>
                          {idx !== 0 && "/"}
                          {info.location[loc]}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="div-manage-section-02-02-01-01-01-line">
                    <img className="icon" src={Icon_Card} alt="" />
                    <div className="div-manage-section-02-02-01-01-01-left">
                      회비
                    </div>
                    <div className="div-manage-section-02-02-01-01-01-right">
                      {clubInfo.cost}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-manage-section-02-02-02">
              <div className="Sub2">활동 사진</div>
              <div
                className="div-manage-section-02-02-02-02"
                onClick={() => {
                  setViewImageModal(true);
                }}
              >
                {clubInfo.activiy_image.map((img, idx) => (
                  <img className="img-manage-pic" src={img} alt="" key={idx} />
                ))}
              </div>
            </div>
            <div className="div-manage-section-02-02-03">
              <div
                className="chip-button"
                onClick={() => {
                  if (localStorage.getItem("session_key") === "admin_demo") {
                    window.alert("회원가입 후 이용 가능합니다🙂");
                    setLoginModal(true);
                  } else {
                    setUpdateClubModal(true);
                  }
                }}
              >
                <span className="Body4">정보 수정</span>
                <img src={Icon_Setting} alt="" />
              </div>
            </div>
          </div>
          <button
            className="button-manage-close"
            onClick={() => setIsClubInfoOpen(false)}
          >
            <div className="div-manage-close">
              <img className="icon" src={Icon_ArrowUp} alt="" />
              접어두기
            </div>
          </button>
        </>
      ) : (
        <button
          className="button-manage-close"
          onClick={() => clubInfo.is_success && setIsClubInfoOpen(true)}
        >
          <div className="div-manage-close">
            <img className="icon" src={Icon_ArrowDown} alt="" />
            자세히 보기
          </div>
        </button>
      )}
      <div
        className={
          isClubInfoOpen
            ? "div-manage-section-02-03 div-manage-section-02-03-open"
            : "div-manage-section-02-03"
        }
      >
        <button
          className={
            checkedMenu === 0
              ? "button-manage-section-02-03-mozip Sub2"
              : "button-manage-section-02-03-mozip Body3"
          }
          onClick={() => {
            setIsMozipListOpen(!isMozipListOpen);
          }}
        >
          📣 모집 공고 리스트
          {clubInfo.is_success &&
            clubInfo.recruit.length > 0 &&
            (isMozipListOpen ? (
              <img className="icon" src={Icon_ArrowUp} alt="" />
            ) : (
              <img className="icon" src={Icon_ArrowDown} alt="" />
            ))}
        </button>
        {isMozipListOpen &&
          clubInfo.is_success &&
          clubInfo.recruit.length > 0 && (
            <div className="div-manage-section-02-03-mozip-list">
              {clubInfo.is_success && (
                <>
                  {clubInfo.recruit.map((rec, idx) => (
                    <button
                      className={
                        rec.id === checkedMozip && checkedMenu === 0
                          ? "button-manage-mozip-list-section-checked"
                          : "button-manage-mozip-list-section"
                      }
                      onClick={() => {
                        if (recruitStep1.is_success || checkedMenu !== 0) {
                          if (rec.id !== checkedMozip || checkedMenu !== 0) {
                            setCheckedMozip(rec.id);
                            if (isMozipListOpen) {
                              setRecruitDocs({});
                              setRecruitInfo({});
                              setRecruitStep1({ is_success: false });
                              setCheckedMenu(0);
                            }
                          }
                        }
                      }}
                      key={idx}
                    >
                      <div className="div-manage-mozip-list Body4">
                        {rec.title}
                      </div>
                      {rec.end_date === "2999-12-31" ? (
                        <div
                          className="div-chip-mozip-list Tag1"
                          style={{ backgroundColor: "#FF7A00" }}
                        >
                          상시
                        </div>
                      ) : rec.state === 1 &&
                        parseInt(dayjs(rec.end_date).format("YYYYMMDD")) -
                          parseInt(dayjs(today).format("YYYYMMDD")) >=
                          0 ? (
                        <div
                          className="div-chip-mozip-list Tag1"
                          style={
                            parseInt(dayjs(rec.start_date).format("YYYYMMDD")) >
                            parseInt(dayjs(today).format("YYYYMMDD"))
                              ? { backgroundColor: "black" }
                              : { backgroundColor: "#FF7A00" }
                          }
                        >
                          D-
                          {dayjs(rec.end_date).format("YYYYMMDD") ===
                          dayjs(today).format("YYYYMMDD")
                            ? "DAY"
                            : dayjs(rec.end_date).diff(dayjs(today), "day") + 1}
                        </div>
                      ) : (
                        <div className="div-chip-mozip-list Tag1">마감</div>
                      )}
                    </button>
                  ))}
                </>
              )}
            </div>
          )}
        <button
          className={
            checkedMenu === 1
              ? "button-manage-section-02-03-mozip Sub2"
              : "button-manage-section-02-03-mozip Body3"
          }
          onClick={() => {
            setCheckedMenu(1);
            setIsMozipListOpen(false);
          }}
        >
          📩 합불 메세지 관리
        </button>
        <button
          className={
            checkedMenu === 2
              ? "button-manage-section-02-03-mozip Sub2"
              : "button-manage-section-02-03-mozip Body3"
          }
          onClick={() => {
            setCheckedMenu(2);
            setIsMozipListOpen(false);
          }}
        >
          🙋️ 운영진 관리
        </button>
        <button
          className={
            checkedMenu === 3
              ? "button-manage-section-02-03-mozip Sub2"
              : "button-manage-section-02-03-mozip Body3"
          }
          onClick={() => {
            setCheckedMenu(3);
            setIsMozipListOpen(false);
          }}
        >
          🙋🏻‍♀️ 동아리 회원 관리
        </button>
        <button
          className={
            checkedMenu === 4
              ? "button-manage-section-02-03-mozip Sub2"
              : "button-manage-section-02-03-mozip Body3"
          }
          onClick={() => {
            setCheckedMenu(4);
            setIsMozipListOpen(false);
          }}
        >
          📆 동아리 일정 관리
        </button>
      </div>
      {!isClubInfoOpen && (
        <div
          style={{
            padding: "16px 0 12px 0",
            width: "100%",
            borderTop: "1px solid #ececec",
            textAlign: "center",
          }}
        >
          <div className="adfit"></div>
        </div>
      )}

      <button
        className="button-manage-section-02-04 Sub2"
        onClick={() => {
          window.open("http://pf.kakao.com/_xnQWxfxj");
        }}
        style={{ zIndex: 10 }}
      >
        📞️ 문의하기
      </button>
    </div>
  );

  // redux를 활용한 가이드 인덱스 관리
  const {
    guideRecruit_1,
    guideRecruit_1i,
    guideRecruit_2,
    guideRecruit_2i,
    guideRecruit_3,
    guideTimetable_1,
    guideTimetable_1i,
    guideTemplate_1,
    guideTemplate_1i,
  } = useSelector((state) => ({
    guideRecruit_1: state.guide.recruit_1,
    guideRecruit_1i: state.guide.recruit_1i,
    guideRecruit_2: state.guide.recruit_2,
    guideRecruit_2i: state.guide.recruit_2i,
    guideRecruit_3: state.guide.recruit_3,
    guideTimetable_1: state.guide.timetable_1,
    guideTimetable_1i: state.guide.timetable_1i,
    guideTemplate_1: state.guide.template_1,
    guideTemplate_1i: state.guide.template_1i,
  }));

  const dispatch = useDispatch();
  const onChangeRec1 = (value) => dispatch(setRecruit1(value));
  const onChangeRec1i = (index) => dispatch(setRecruit1i(index));
  const onChangeRec2 = (value) => dispatch(setRecruit2(value));
  const onChangeRec2i = (index) => dispatch(setRecruit2i(index));
  const onChangeTime1 = (value) => dispatch(setTimetable1(value));
  const onChangeTime1i = (index) => dispatch(setTimetable1i(index));
  const onChangeTemplate1 = (value) => dispatch(setTemplate1(value));
  const onChangeTemplate1i = (index) => dispatch(setTemplate1i(index));

  useEffect(() => {
    const guide = JSON.parse(window.localStorage.getItem("guide"));
    if (guide?.recruit_1) {
      onChangeRec1(false);
      if (guide?.recruit_2) {
        onChangeRec2(false);
      } else {
        onChangeRec2(true);
      }
    } else {
      onChangeRec1(true);
    }
  }, []);

  return (
    <>
      {/* 가이드 구간 =========================== */}
      <GuideModal
        contents={[
          "모집 공고 리스트를 선택할 수 있어요!",
          "URL 공유하기 버튼을 누르면 모집 공고가 복사돼요.\n자유롭게 공유해 보세요!",
          "새로고침 버튼을 통해 실시간으로 지원자 현황을 확인할 수 있어요!",
        ]}
        size={3}
        guideIndex={guideRecruit_1i}
        onChangeIndex={onChangeRec1i}
        guideName={"recruit_1"}
        modal={guideRecruit_1}
        setModal={onChangeRec1}
        setNextModal={onChangeGuide}
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
        position="right"
      />
      <GuidePunctureDim
        style={{
          width: "280px",
          height: "54px",
          left: "78px",
          top: "236px",
        }}
        guideIndex={guideRecruit_1i}
        ownIndex={1}
        CoachMark={true}
        guideName={"recruit_1"}
        isOpen={guideRecruit_1}
      />
      <GuideModal
        contents={[
          "상태 칩을 클릭하여 지원자의 합불 처리를 할 수 있어요!",
          "지원서 아이콘을 눌러 지원자의 답변을 확인할 수 있어요.",
          "좌우 드래그를 통해 칸의 영역 너비를 조절할 수 있어요.",
          "필터를 통해 원하는 정보를 빠르게 모아 볼 수 있어요!",
          "마우스 위치에 따라 테이블의 스크롤 방향이 달라져요!\n스크롤을 통해 많은 정보를 확인할 수 있어요.",
        ]}
        size={5}
        guideIndex={guideRecruit_2i}
        onChangeIndex={onChangeRec2i}
        guideName={"recruit_2"}
        modal={guideRecruit_2}
        setModal={onChangeRec2}
        style={{
          position: "fixed",
          left: "20px",
          bottom: "20px",
        }}
        position="left"
      />
      <GuidePunctureDim
        style={{
          width: "calc(100vw - 786px)",
          height: `calc(28.5px + ${31.5 * recruitStep1?.applicant?.length}px)`,
          maxHeight: "calc(100vh - 132px - 157.5px)",
          left: "732px",
          top: "153px",
        }}
        isOpen={guideRecruit_2 && guideRecruit_2i !== 3}
        guideIndex={guideRecruit_2i}
        ownIndex={guideRecruit_2i + 1}
        CoachMark={guideRecruit_2i === 4}
        guideName={"recruit_2"}
      >
        {guideRecruit_2i === 4 && (
          <>
            <div
              style={{
                position: "absolute",
                width: "146px",
                height: "100%",
                backgroundColor: "rgba(255, 122, 0, 0.8)",
                borderRadius: "8px 0 0 8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                overflow: "clip",
                textAlign: "center",
              }}
            >
              <p className="Sub2">
                세로 스크롤
                <br />
                영역
              </p>
            </div>
            <div
              style={{
                position: "absolute",
                left: "146px",
                width: "calc(100% - 146px)",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ff7a00",
                overflow: "clip",
                textAlign: "center",
              }}
            >
              <p className="Sub2">
                가로 스크롤
                <br />
                영역
              </p>
            </div>
          </>
        )}
      </GuidePunctureDim>
      <GuideModal
        contents={[
          "서류 합격자에게 발송될 면접 일정을 추가할 수 있어요.",
          "면접 소요시간 및 동시 면접 인원을 설정할 수 있어요.",
          "면접 날짜 및 총 소요시간을 설정한 후 + 버튼을 누르면 일정이 설정돼요.",
        ]}
        size={3}
        guideIndex={guideTimetable_1i}
        onChangeIndex={onChangeTime1i}
        guideName={"timetable_1"}
        modal={guideTimetable_1}
        setModal={onChangeTime1}
        style={{
          position: "fixed",
          left: "20px",
          bottom: "20px",
        }}
        position="left"
      />
      <GuidePunctureDim
        style={{
          width: "calc(100vw - 786px)",
          height: "90px",
          left: "732px",
          top: "153px",
        }}
        guideName="timetable_1"
        guideIndex={guideTimetable_1i}
        ownIndex={guideTimetable_1i + 1}
        CoachMark={false}
        isOpen={guideTimetable_1}
      >
        <CoachMark
          style={{
            position: "absolute",
            left: "27px",
            bottom: "15px",
            width: "229.25px",
            height: "33px",
            borderRadius: "8px",
            outline: "3px solid #FF7A00",
          }}
          isOpen={guideTimetable_1}
          guideIndex={guideTimetable_1i}
          ownIndex={2}
        />
        <CoachMark
          style={{
            position: "absolute",
            left: "297px",
            bottom: "15px",
            width: "366px",
            height: "33px",
            borderRadius: "8px",
            outline: "3px solid #FF7A00",
          }}
          isOpen={guideTimetable_1}
          guideIndex={guideTimetable_1i}
          ownIndex={3}
        />
      </GuidePunctureDim>
      <GuideModal
        contents={[
          "발송 가능한 문자 템플릿을 미리 확인할 수 있어요!\n카카오의 심사과정을 거친 템플릿만 사용 가능해요!",
          "기본 템플릿 사용 시 지원자가 받을 메세지 예시를 확인해 보세요!",
        ]}
        size={2}
        guideIndex={guideTemplate_1i}
        onChangeIndex={onChangeTemplate1i}
        guideName="template_1"
        modal={guideTemplate_1}
        setModal={onChangeTemplate1}
        position="right"
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
      />
      <GuidePunctureDim
        style={{
          width: "412.5px",
          height: "24px",
          left: "435px",
          top: "265px",
        }}
        guideIndex={guideTemplate_1i}
        ownIndex={1}
        CoachMark={true}
        guideName={"template_1"}
        isOpen={guideTemplate_1}
      />
      {/* =========================== 가이드 구간 */}

      <div
        className="div-manage-section"
        style={
          guideRecruit_1 ||
          guideRecruit_2 ||
          guideRecruit_3 ||
          guideTimetable_1 ||
          guideTemplate_1
            ? { pointerEvents: "none" }
            : null
        }
      >
        <div
          className="div-manage-section-01"
          style={{ zIndex: createClubModal ? "1001" : "1" }}
        >
          <div className="div-manage-section-01-01">
            {clubByUser.is_success ? (
              clubByUser.club.map((cl, idx) => (
                <button key={idx}>
                  <img
                    className={
                      checkedClub === clubByUser.club[idx].id
                        ? "img-manage-logo-circle img-manage-logo-circle-checked"
                        : "img-manage-logo-circle"
                    }
                    src={cl.image}
                    alt=""
                    onClick={() => {
                      if (clubByUser.club[idx].id !== checkedClub) {
                        setCheckedClub(clubByUser.club[idx].id);
                      }
                    }}
                  />
                </button>
              ))
            ) : (
              <div className="skeleton-manage-logo-circle" />
            )}
            <img
              className="div-click img-manage-logo-plus"
              src={Icon_Plus}
              alt=""
              onClick={() => {
                if (localStorage.getItem("session_key") === "admin_demo") {
                  window.alert("회원가입 후 이용 가능합니다🙂");
                  setLoginModal(true);
                } else {
                  setCreateClubModal(true);
                }
              }}
            />
            {createClubModal && (
              <CreateClub setModalOpen={setCreateClubModal} info={info} />
            )}
          </div>
          {isLeftSectionOpen ? (
            <img
              className={`icon ${animation1}`}
              src={Icon_Fold}
              alt=""
              style={{ marginBottom: "12px" }}
              onClick={onToggle}
            />
          ) : (
            <img
              className={`icon ${animation1}`}
              src={Icon_Fold}
              alt=""
              style={{ marginBottom: "12px" }}
              onClick={onToggle}
            />
          )}
        </div>
        {isLeftSectionOpen && leftSection}
        <div className="div-right-section">
          {checkedMenu === 0 &&
            (recruitInfo.always ? (
              <RecruitPageAlways
                club_id={checkedClub}
                recruit_id={checkedMozip}
                recruitDocs={recruitDocs}
                recruitInfo={recruitInfo}
                recruitStep1={recruitStep1}
                setRecruitStep1={setRecruitStep1}
                setRecruitState={setRecruitState}
                clubInfo={clubInfo}
                info={info}
                isLeftSectionOpen={isLeftSectionOpen}
                managerList={managerList}
              />
            ) : (
              <>
                {recruitState === 1 &&
                  clubInfo.is_success &&
                  clubInfo.recruit.length > 0 && (
                    <RecruitPage1
                      club_id={checkedClub}
                      recruit_id={checkedMozip}
                      recruitDocs={recruitDocs}
                      recruitInfo={recruitInfo}
                      recruitStep1={recruitStep1}
                      setRecruitStep1={setRecruitStep1}
                      setRecruitState={setRecruitState}
                      clubInfo={clubInfo}
                      info={info}
                      isLeftSectionOpen={isLeftSectionOpen}
                      managerList={managerList}
                    />
                  )}
                {recruitState === 2 && (
                  <RecruitPage2
                    club_id={checkedClub}
                    recruit_id={checkedMozip}
                    recruitDocs={recruitDocs}
                    recruitInfo={recruitInfo}
                    recruitStep1={recruitStep1}
                    setRecruitState={setRecruitState}
                    clubInfo={clubInfo}
                    info={info}
                    isLeftSectionOpen={isLeftSectionOpen}
                  />
                )}
                {recruitState === 3 && <div className="H3">333</div>}
                {recruitState === 4 &&
                  clubInfo.is_success &&
                  clubInfo.recruit.length > 0 && (
                    <RecruitPage4
                      club_id={checkedClub}
                      recruit_id={checkedMozip}
                      recruitDocs={recruitDocs}
                      recruitInfo={recruitInfo}
                      recruitStep1={recruitStep1}
                      setRecruitState={setRecruitState}
                      clubInfo={clubInfo}
                      info={info}
                      isLeftSectionOpen={isLeftSectionOpen}
                      managerList={managerList}
                    />
                  )}
                {recruitState === 6 && (
                  <RecruitPage6
                    club_id={checkedClub}
                    recruit_id={checkedMozip}
                    recruitDocs={recruitDocs}
                    recruitInfo={recruitInfo}
                    recruitStep1={recruitStep1}
                    setRecruitState={setRecruitState}
                    clubInfo={clubInfo}
                    info={info}
                    isLeftSectionOpen={isLeftSectionOpen}
                    managerList={managerList}
                  />
                )}
                {viewImageModal && (
                  <ViewImages
                    setModalOpen={setViewImageModal}
                    clubInfo={clubInfo}
                  />
                )}
              </>
            ))}
          {checkedMenu === 1 && clubInfo.is_success && (
            <ManageMessage
              club_id={checkedClub}
              isLeftSectionOpen={isLeftSectionOpen}
            />
          )}
          {checkedMenu === 2 && clubInfo.is_success && (
            <ClubManagerPage session_key={session_key} club_id={checkedClub} />
          )}
          {(checkedMenu === 3 || checkedMenu === 4) && <ComingSoon />}
        </div>
      </div>
      {loginModal && <Loginform setModalOpen={setLoginModal} />}
    </>
  );
}
export default Manage;
