import React, { useEffect, useState } from "react";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import RadioButton from "../asset/icon/RadioButton.svg";
import RadioButton_Checked from "../asset/icon/RadioButton_Checked.svg";
import Connection from "./Connection";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import GuideModal, { CoachMark } from "./guideModal";
import { setMessage1, setMessage1i } from "../modules/guide";
import { useSelector, useDispatch } from "react-redux";

function MessageOut({
  club_id,
  recruit_id,
  setModalOpen,
  countFail,
  setRecruitState,
}) {
  const session_key = localStorage.getItem("session_key");

  const [recruitStep1, setRecruitStep1] = useState();

  async function getRecruitStep1() {
    const ret = await Connection("/getRecruitStep1", {
      club_id: club_id,
      recruit_id: recruit_id,
    });

    if (ret.is_success) {
      setRecruitStep1(ret);
    }
  }

  useEffect(() => {
    getRecruitStep1();
  }, []);

  const [template, setTemplate] = useState("default");
  const [content, setContent] = useState("");

  async function getMsgTemplate() {
    const res = await Connection("/getMsgTemplate", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
    });

    if (res.is_success) {
      setContent(res.template.applicant_reject);
    }
  }

  useEffect(() => {
    getMsgTemplate();
  }, []);

  const dispatch = useDispatch();
  const onChangeValue = (value) => dispatch(setMessage1(value));
  const onChangeIndex = (index) => dispatch(setMessage1i(index));

  const guideMessage_1 = useSelector((state) => state.guide.message_1);
  const guideMessage_1i = useSelector((state) => state.guide.message_1i);

  useEffect(() => {
    const guide = JSON.parse(window.localStorage.getItem("guide"));
    if (guide?.message_1) {
      onChangeValue(false);
    } else {
      onChangeValue(true);
    }
  }, []);

  return (
    <>
      <GuideModal
        contents={[
          "선택된 수신자에게 아래 문자 내용을 일괄 전송돼요.",
          "원하는 템플릿을 선택할 수 있어요!",
          "전송할 문자 내용을 확인할 수 있어요!",
        ]}
        size={3}
        guideIndex={guideMessage_1i}
        onChangeIndex={onChangeIndex}
        guideName={"message_1"}
        modal={guideMessage_1}
        setModal={onChangeValue}
        showIndex={true}
        position={"right"}
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
      />
      <div className="div-modal-background">
        <div
          className="div-message-modal"
          style={guideMessage_1 ? { pointerEvents: "none" } : null}
        >
          <div className="H3">✉️ 탈락자 안내 문자</div>
          <img
            className="icon img-message-section-close"
            src={Icon_Close}
            alt=""
            style={{ width: "21px" }}
            onClick={() => setModalOpen(false)}
          />
          <div
            className="div-message-section-01"
            style={{ position: "relative" }}
          >
            <div className="div-message-section-01-left Sub3">수신자</div>
            <CoachMark
              isOpen={guideMessage_1}
              guideIndex={guideMessage_1i}
              ownIndex={1}
            >
              <div className="div-message-section-01-right">
                {recruitStep1 &&
                  recruitStep1.applicant.map((a) => (
                    <>
                      {a.state === 2 && (
                        <div className="div-message-section-01-right-chip Body4">
                          {a.profile["name"]}
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </CoachMark>
          </div>
          <div className="div-message-section-01-right-count Body5">
            총 {countFail}명
          </div>
          <div className="div-message-section-02-right-chip-section">
            {content && content.default.state === 2 && (
              <CoachMark
                isOpen={guideMessage_1}
                guideIndex={guideMessage_1i}
                ownIndex={2}
              >
                <div
                  className="div-click div-managemessage-message-select-section-01 Body4"
                  onClick={() =>
                    content &&
                    content.default.state === 2 &&
                    setTemplate("default")
                  }
                  style={{ marginRight: "9px" }}
                >
                  <img
                    className="icon"
                    src={
                      template === "default" ? RadioButton_Checked : RadioButton
                    }
                    alt=""
                    style={{ marginRight: "6px" }}
                  />
                  기본 템플릿
                </div>
              </CoachMark>
            )}
            {content && content.template1.state === 2 && (
              <div
                className="div-click div-managemessage-message-select-section-01 Body4"
                onClick={() => setTemplate("template1")}
                style={{ marginRight: "9px" }}
              >
                <img
                  className="icon"
                  src={
                    template === "template1" ? RadioButton_Checked : RadioButton
                  }
                  alt=""
                  style={{ marginRight: "6px" }}
                />
                템플릿 1
              </div>
            )}
            {content && content.template2.state === 2 && (
              <div
                className="div-click div-managemessage-message-select-section-01 Body4"
                onClick={() => setTemplate("template2")}
                style={{ marginRight: "9px" }}
              >
                <img
                  className="icon"
                  src={
                    template === "template2" ? RadioButton_Checked : RadioButton
                  }
                  alt=""
                  style={{ marginRight: "6px" }}
                />
                템플릿 2
              </div>
            )}
          </div>
          <div
            className="div-message-section-02"
            style={{ position: "relative" }}
          >
            <div className="div-message-section-01-left Sub3">문자 내용</div>
            <CoachMark
              isOpen={guideMessage_1}
              guideIndex={guideMessage_1i}
              ownIndex={3}
            >
              <div className="textarea-message-section-02-right Body4">
                <HighlightWithinTextarea
                  value={
                    content && template && content[template].con
                      ? content && template && content[template].con
                      : ""
                  }
                  highlight={
                    /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
                  }
                />
              </div>
            </CoachMark>
          </div>
          <button
            className="outlined-black-button button-message-bottom-left Sub3"
            onClick={async () => {
              if (window.confirm("문자 전송을 건너뛰시겠습니까?")) {
                const ret = await Connection("/completeStep1", {
                  club_id: club_id,
                  recruit_id: recruit_id,
                  template_id: "none",
                });
                if (ret.is_success) {
                  setModalOpen(false);
                  setRecruitState(2);
                }
              }
            }}
          >
            건너뛰기
          </button>
          <button
            className="main-black-button button-message-bottom Sub3"
            onClick={async () => {
              if (window.confirm("문자를 전송하시겠습니까?")) {
                const ret = await Connection("/completeStep1", {
                  club_id: club_id,
                  recruit_id: recruit_id,
                  template_id: template,
                });

                if (ret.is_success) {
                  window.alert("문자 전송이 완료되었습니다.");
                  setModalOpen(false);
                  setRecruitState(2);
                }
              }
            }}
          >
            문자 전송하기
          </button>
        </div>
      </div>
    </>
  );
}

export default MessageOut;
