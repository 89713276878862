import React from "react";
import FooterM from "./FooterM";

function TermsM() {
  return (
    <div className="div-terms-section" style={{ height: "100vh" }}>
      <div
        className="div-terms-text"
        style={{ padding: "60px 20px 40px 20px" }}
      >
        <h2>회원이용약관</h2>
        <h3>제 1 조 (목적)</h3>본 약관은 {"<"} MACLE {">"}이 제공하는 “dongdle”
        (이하 '동들) 및 동들 관련 제반 서비스의 이용과 관련하여 “회사”와
        회원간의 권리, 의무 및 책임사항 기타 필요한 사항을 규정함을 목적으로
        한다.
        <h3>제 2 조 (용어의 정의)</h3>
        이 약관에서 사용하는 용어의 정의는 아래와 같다.
        <br />
        1.“동들”이라 함은 “연합동아리”와 관련하여 “회사”가 제공하는 웹서비스
        혹은 인터넷과 연결된 모든 서비스를 말한다. <br />
        2."동아리 운영진"이라 함은 “동들”을 이용하기 위하여 동 약관에 동의하여
        "회사"와 이용 계약을 체결한 "연합동아리를 운영하는 회원"을 말한다.{" "}
        <br />
        3.“동아리 지원자”라 함은 “동들”을 통해서 연합동아리 가입을 위하여 별도의
        약관에 동의하여 “회사”와 이용 계약을 체결한 “연합동아리를 지원한 회원”을
        말한다 <br />
        4."동아리 운영진"과 "동아리 지원자"를 통틀어 "회원"이라 말한다.
        <h3>제 3 조 (약관의 명시와 개정)</h3>
        1. "회사"는 이 약관의 내용과 상호, 소재지, 대표자의 성명, 사업자등록번호
        등을 "회원"이 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로
        "회원"에게 공지해야 한다. <br />
        2. "회사"는 약관의 규제 등에 관한 법률, 전기통신기본법, 전기통신사업법,
        정보 통신망 이용 촉진 등에 관한 법률 등 관련법을 위배하지 않는 범위에서
        이 약관을 개정할 수 있다. <br />
        3. "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행
        약관과 함께 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지
        공지한다. 단 "회원"의 권리, 의무에 중대한 영향을 주는 변경의 경우에는
        적용일자 30일 전부터 공지하도록 한다. <br />
        4. "회원"은 변경된 약관에 대해 거부할 권리가 있다. "회원"은 변경된
        약관이 공지된 지 15일 이내에 거부의사를 표명할 수 있다. "회원"이
        거부하는 경우 본 “서비스” 제공자인 "회사"는 15일의 기간을 정하여
        "회원"에게 사전 통지 후 당해 "회원"과의 계약을 해지할 수 있다. 만약,
        "회원"이 거부의사를 표시하지 않거나, 전항에 따라 시행일 이후에 “동들”을
        이용하는 경우에는 동의한 것으로 간주한다.
        <h3>제 4 조 (약관의 해석)</h3>
        1. 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제 등에 관한 법률,
        전기통신기본법, 전기통신사업법, 정보 통신망 이용 촉진 등에 관한 법률
        등의 관계법령에 따른다. <br />
        2. "회원"이 "회사"와 개별 계약을 체결하여 “동들”을 이용하는 경우에는
        개별 계약이 우선한다.
        <h3>제 5 조 (이용계약의 성립)</h3>
        1. "회사"의 “동들” 이용계약(이하 "이용계약")은 “동들”을 이용하고자 하는
        자가 본 약관과 개인정보취급방침을 읽고 "동의" 또는 "확인" 버튼을 누른
        경우 본 약관에 동의한 것으로 간주한다.
        <br />
        2. 제1항 신청에 있어 "회사"는 "회원"에게 전문기관을 통한 실명확인 및
        본인인증을 요청할 수 있다. "회원"은 본인 인증에 필요한 (카카오)이름,
        생년월일, 연락처 등을 제공하여야 한다. <br />
        3. "회원"이 이용신청(회원가입 신청) 작성 후에 "회사"가 웹 상의 안내 및
        전자메일로 "회원"에게 통지함으로써 “이용계약”이 성립된다.
        <h3>제 6 조 (이용신청의 승낙과 제한)</h3>
        1. "회사"는 전조의 규정에 의한 이용신청 고객에 대하여 업무수행 상 또는
        기술상 지장이 없는 경우에는 원칙적으로 접수 순서에 따라 “동들” 이용을
        승낙한다. <br />
        2. "회사"는 아래 사항에 해당하는 경우에 대해서는 이용 신청을 승낙하지
        아니하며, 추후 아래 사항에 해당함이 확인된 경우 “회사”는 이용계약을
        해지한다. 가. 실명이 아니거나 타인의 명의를 이용하여 신청한 경우 나.
        이용계약 신청서의 내용을 허위로 기재한 경우 다. 만 15세 미만의 아동이
        신청한 경우 <br />
        3. "회사"는 아래 사항에 해당하는 경우에는 그 신청에 대하여 승낙 제한
        사유가 해소될 때까지 승낙을 유보할 수 있다. 가. "회사"가 설비의 여유가
        없는 경우 나. "회사"의 기술상 지장이 있는 경우 다. 기타"회사"의
        귀책사유로 이용 승낙이 곤란한 경우 <h3>제 7 조 (“서비스”의 내용)</h3>
        1. "회사"는 제2조 1항의 서비스를 제공할 수 있으며 그 내용은 다음 각호와
        같다. 가. 링크 기반 연합 동아리 모집 서비스 나. 연합 동아리 통합 관리
        서비스 다. 기타 "회사"가 추가 개발하거나 제휴 계약 등을 통해 "회원"에게
        제공하는 일체의 서비스 <br />
        2. "동들"은 "동아리 운영진"과 "동아리 지원자" 간의 중개 서비스로서 선발
        과정 전반에 대한 책임은 일체 없음을 알린다. <br />
        3. "회사"는 필요한 경우 서비스의 내용을 추가 또는 변경 할 수 있다. 단,
        이 경우 "회사"는 추가 또는 변경내용을 "회원"에게 공지해야 한다.
        <h3>제 8 조 (“동들” 이용시간)</h3>
        1. "회사"는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를
        제공한다. 다만, "회사"는 서비스의 종류나 성질에 따라 제공하는 서비스 중
        일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 "회사"는 그
        이용시간을 사전에 "회원"에게 공지 또는 통지하여야 한다. <br />
        2. "회사"는 자료의 가공과 갱신을 위한 시스템 작업시간, 장애 해결을 위한
        보수작업 시간, 회선 장애 등이 발생한 경우 일시적으로 서비스를 중단할 수
        있으며 계획된 작업의 경우 공지란에 서비스 중단 시간과 작업 내용을 알려야
        한다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
        사후에 통지할 수 있다.
        <h3>제 9 조 (“상품”의 이용 요금)</h3>
        1. "동아리 운영진" 가입과 서비스의 이용 요금은 별도로 부과하지 않는다.
        <br />
        2. "동아리 지원자" 가입은 별도로 부과하지 않으며, 서비스를 이용하는
        요금은 "서비스" 홈페이지에 명시한다.
        <h3>제 10조 (“동들”의 서비스 제공 중지)</h3> "회사"는 다음 각호에
        해당하는 경우 “동들”의 서비스 제공을 중지할 수 있다. <br />
        <br />
        가. 설비의 보수 등 "회사"의 필요에 의해 사전에 "회원"들에게 통지한 경우
        <br />
        나. 기간통신사업자가 전기통신 서비스 제공을 중지하는 경우 <br />
        다. 기타 불가항력적인 사유에 의해 “상품의” 서비스 제공이 객관적으로
        불가능한 경우 <h3>제 11조 (정보의 제공 및 광고의 게재)</h3> 1. "회사"는
        "회원"에게 “동들” 이용에 필요가 있다고 인정되거나 서비스 개선 및 회원
        대상의 “동들” 소개 등의 목적으로 하는 각종 정보에 대해서 전자우편이나
        서신 우편을 이용한 방법으로 제공할 수 있다. <br />
        2. "회사"는 제공하는 “동들”와 관련되는 정보 또는 광고를 “동들” 상의
        화면, 홈페이지 등에 게재할 수 있으며, 상기 내용이 게재된 전자우편을
        수신한 "회원"은 "회사"에게 수신거절을 요청 할 수 있다. <br />
        3. "회사"는 “동들” 상에 게재되어 있거나 본 “동들”을 통한 광고주의
        판촉활동에 "회원"이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든
        손실과 손해에 대해 책임을 지지 않는다. <br />
        4. 본 “동들”의 "회원"은 “동들" 이용 시 노출되는 광고게재에 대해 동의하는
        것으로 간주한다. <h3>제 12조 (구직자 정보 활용 및 권한)</h3> 1. 본
        "동들"를 이용해 "동아리 지원자"이 작성한 이력서, 자기소개서를 포함한
        개인 정보를 "지원자 정보"라 한다. <br />
        2. 본 "동들"의 서비스를 이용해 작성한 "지원자 정보"는 "동아리 운영진"만
        열람할 수 있음을 원칙으로 한다.
        <br />
        3. 단, "동아리 운영진"의 부주의로 인한 "지원자 정보"의 유출은 "회사"에게
        그 책임을 지지 않는다. <h3>제 13조 ("회사"의 의무)</h3> 1. "회사"는 본
        약관에서 정한 바에 따라 계속적, 안정적으로 “동들”의 서비스를 제공할 수
        있도록 최선의 노력을 다해야 한다. <br />
        2. "회사"는 “동들”의 서비스와 관련한 "회원"의 불만사항이 접수되는 경우
        이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와
        처리일정을 “동들”의 화면 또는 기타 방법을 통해 동 "회원"에게 통지하여야
        한다. <br />
        3. 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여
        “상품의” 서비스가 중단될 경우 이에 대한 손해에 대해서는 "회사"가 책임을
        지지 않는다. 다만 자료의 복구나 정상적인 서비스가 지원 되도록 최선을
        다할 의무를 진다. <br />
        4. "회사"는 유료 결제와 관련한 결제 사항 정보를 1년 이상 보존한다. 다만
        회원 자격이 없는 사용자는 예외로 한다. <h3>제 14조 (회원의 의무)</h3>
        1. "회원"은 관계법령과 본 약관의 규정 및 기타 "회사"가 통지하는 사항을
        준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 해서는 안 된다.
        <br />
        2. "회원"은 “동들”을 이용하여 얻은 정보를 "회사"의 사전동의 없이 복사,
        복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할
        수 없다. <br />
        3. "회원"은 본 “동들”을 기업정보 확인 및 건전한 취업 이외의 목적으로
        사용해서는 안되며 이용 중 다음 각 호의 행위를 해서는 안 된다. <br />
        <br />
        가. 다른 “회원”의 아이디를 부정 사용하는 행위 <br />
        나. 범죄 행위를 목적으로 하거나 기타 범죄행위와 관련된 행위 <br />
        다. 타인의 명예를 훼손하거나 모욕하는 행위 <br />
        라. 타인의 지적재산권 등의 권리를 침해하는 행위 <br />
        마. 해킹 행위 또는 바이러스의 유포 행위 <br />
        바. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로
        전송하는 행위 <br />
        사. “동들”의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는
        행위
        <br />
        아. “동들”의 정보 및 “동들”의 서비스를 이용한 영리행위 자. 그밖에 선량한
        풍속, 기타 사회질서를 해하거나 관계법령에 위반하는 행위 <br />
        <br />
        4. "회원"이 신청한 “동들”의 유료 서비스는 등록 또는 신청과 동시에
        "회사"와 채권, 채무 관계가 발생하며, "회원"은 이에 대한 요금을 지정한
        기일 내에 납부하여야 한다. <br />
        5. "회원"이 결제 수단으로 신용카드를 사용할 경우 비밀번호 등 정보 유실
        방지는 "회원" 스스로 관리해야 한다. 단, “동들”의 결함에 따른 정보 유실의
        발생에 대한 책임은 "회원"의 의무에 해당하지 아니한다.
        <h3>제 15조 (회원탈퇴 및 회원자격 상실)</h3>
        1. “회원”은 “회사”에 언제든지 “동들”의 서비스 탈퇴를 요청할 수 있으며,
        탈퇴 요청은 "동들"의 웹 페이지 "문의하기"를 통하여 직접 탈퇴 할 수 있다.
        <br />
        2. “회사”는 “회원”이 이 약관 및 개별 서비스 이용약관을 위반한 경우 경고,
        일시적 이용정지, 영구적 이용정지 등의 단계로 “어플리케이션” 이용을
        제한하거나 이용계약을 해지할 수 있다. <br />
        3. “회원”은 제 3항에 따른 “동들” 이용정지 기타 “동들”이용과 관련된
        이용제한에 대한 “회사”가 정한 절차에 따라 이의신청을 할 수 있으며,
        “회사”는 “회원”의 이의신청이 정당하다고 판단되는 경우 즉시 “동들” 이용을
        재개한다. <h3>제 16조 (손해배상)</h3> 1. "회사"가 "회원"에게 손해를
        입히거나 기타 "회사"가 제공하는 모든 “동들”와 관련된 서비스 이용에
        있어서 "회사"의 책임 있는 사유로 인해 “회원”에게 손해가 발생한 경우
        "회사"는 그 손해를 배상하여야 한다. <br />
        2. "회원"이 이 약관의 규정에 위반한 행위로 "회사" 및 제3자에게 손해를
        입히거나 "회원"의 책임 있는 사유에 의해 "회사" 및 제3자에게 손해를 입힌
        경우에는 "회원"은 그 손해를 배상하여야 한다. <br />
        3. 타 회원(개인회원, 기업회원 모두 포함)의 귀책사유로 "회원"의 손해가
        발생한 경우 "회사"는 이에 대한 배상 책임이 없다.
        <h3>제 17조 ("회원"의 개인정보보호)</h3> 1. ”회사"는 "회원"의
        개인정보보호를 위하여 노력해야 한다. "회원"의 개인정보보호에 관해서는
        정보통신망이용촉진 및 정보보호 등에 관한 법률에 따르고, “동들”에
        "개인정보 취급 방침"을 고지한다. <h3>제 18조 (이용요금 오류의 조정)</h3>{" "}
        1. ”회사"는 이용 요금과 관련하여 오류가 있는 경우에 "회원"의 요청, 또는
        "회사"의 사전 통지에 의하여 다음에 해당하는 조치를 취한다. <br />
        <br />
        가. 과다 납입한 요금에 대하여는 그 금액을 반환한다. 다만, "회원"이
        동의할 경우 다음 달에 청구할 요금에서 해당 금액만큼을 감하여 청구한다.{" "}
        <br />
        나. 요금을 반환 받아야 할 "회원"이 요금 체납이 있는 경우에는 반환해야 할
        요금에서 이를 우선 공제하고 반환한다. <br />
        다. "회사"는 과소청구액에 대해서는 익월에 합산청구한다.
        <h3>제 19조 (신용정보의 제공 활용 동의)</h3> 1. "회사"가 회원가입과
        관련하여 취득한 "회원"의 개인신용정보를 타인에게 제공하거나 활용하고자
        할 때에는 신용정보의 이용 및 보호에 관한 법률 제23조의 규정에 따라
        사전에 그 사유 및 해당기관 또는 업체명 등을 밝히고 해당 "회원"의 동의를
        얻어야 한다.
        <br />
        2. 본 “동들”와 관련하여 "회사"가 "회원"으로부터 신용정보의 이용 및
        보호에 관한 법률에 따라 타인에게 제공 활용에 동의를 얻은 경우 "회원"은
        "회사"가 신용정보 사업자 또는 신용정보 집중 기관에 정보를 제공하여
        "회원"의 신용을 판단하기 위한 자료로 활용하거나, 공공기관에서 정책
        자료로 활용되도록 정보를 제공하는 데 동의한 것으로 간주한다.{" "}
        <h3>제 20조 (분쟁의 해결)</h3>
        1. "회사"와 "회원"은 “동들”와 관련하여 발생한 분쟁을 원만하게 해결하기
        위하여 필요한 모든 노력을 하여야 한다. <br />
        2. 전항의 노력에도 불구하고, 동 분쟁에 관한 소송 발생 시 소송은 "회사"의
        주소지 관할법원으로 한다. <br />
        <h3>부칙</h3> 1. 이 개인정보처리방침은 2022년 1월 1부터 적용됩니다.
      </div>
      <FooterM />
    </div>
  );
}

export default TermsM;
