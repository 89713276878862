import { useState, useEffect, useRef } from "react";
import "./styles.css";
import Icon_Download from "../asset/icon/Icon_Download.svg";
import Connection from "./Connection";
import TableContentM from "./TableContentM";

const createHeaders = (headers) => {
  return headers.map((item, idx) => ({
    data: { ...item, id: idx },
    text: item.k,
    isView: true,
    filter: item.type === 5,
    a_id: item.id,
    ref: useRef(),
  }));
};
const TableM = ({
  club_id,
  recruit_id,
  recruitDocs,
  headers,
  applicantList,
  setCountFail,
  setCountPass,
}) => {
  const [applicantListV, setApplicantListV] = useState([]);
  const [modalViewForm, setModalViewForm] = useState(false);
  useEffect(() => {
    setApplicantListV(
      applicantList.map((a, idx) => ({ ...a, idx: idx, stateV: a.state }))
    );
  }, [applicantList]);

  const [columns, setColumns] = useState(createHeaders(headers));
  const session_key = localStorage.getItem("session_key");

  async function downloadRecruitStep1() {
    const ret = await Connection("/downloadRecruitStep1", {
      club_id: club_id,
      recruit_id: recruit_id,
    });

    window.open(ret.file_url);
  }

  return (
    <div
      className="table-wrapper Body4M"
      style={{
        marginTop: "12px",
        marginBottom: "60px",
      }}
    >
      <div className="div-table-filter-button-sectionM">
        <button
          className="button-table-filterM Tag1M"
          onClick={() => downloadRecruitStep1()}
        >
          표로 내보내기
          <img className="img-table-filter" src={Icon_Download} alt="" />
        </button>
      </div>
      <table
        className="recruit-table"
        style={{
          gridTemplateColumns: "0.5fr 1fr 1.5fr",
          maxHeight: "100%",
          overflow: "auto",
          paddingBottom: "80px",
        }}
      >
        <thead>
          <tr>
            <th className="thead-test th-sticky-01" style={{ height: "30px" }}>
              <span className="Body4M"></span>
            </th>
            <th className="thead-test th-sticky-01" style={{ height: "30px" }}>
              <span className="Body4M">상태</span>
            </th>
            <th className="thead-test th-sticky-02" style={{ height: "30px" }}>
              <span className="Body4M">이름</span>
            </th>
          </tr>
        </thead>
        <tbody className="tbody-test">
          <TableContentM
            club_id={club_id}
            recruit_id={recruit_id}
            recruitDocs={recruitDocs}
            applicantList={applicantListV}
            setApplicantList={setApplicantListV}
            columnList={columns}
            setCountFail={setCountFail}
            setCountPass={setCountPass}
            modalViewForm={modalViewForm}
            setModalViewForm={setModalViewForm}
          />
        </tbody>
      </table>
    </div>
  );
};

export default TableM;
