import React from "react";
import comingsoon from "../asset/comingsoon/comingsoon.svg";

function ComingSoon() {
  return (
    <div className="div-comingsoon-section">
      <img className="img-comingsoon" src={comingsoon} alt="" />
      <div className="div-comingsoon-section-title H1">
        서비스를 준비하고 있어요 🛠️
      </div>
      <div className="div-comingsoon-section-sub Body3">
        동아리 관리를 운영진들이 편하게 할 수 있도록 서비스 개발 중입니다.
      </div>
    </div>
  );
}

export default ComingSoon;
