import React, { useState, useEffect } from "react";
import Icon_Reset from "../asset/icon/Icon_Reset.svg";
import Connection from "./Connection";
import ToastPopup from "./ToastPopup";
import Table4M from "./Table4M";

function RecruitPage4M({ club_id, recruit_id }) {
  const session_key = localStorage.getItem("session_key");
  const [countAll, setCountAll] = useState(0);
  const [countFail, setCountFail] = useState(0);
  const [countPass, setCountPass] = useState(0);
  const [recruitDocs, setRecruitDocs] = useState({});
  const [applicantList, setApplicantList] = useState([]);
  const [timetable, setTimetable] = useState([]);
  const [recruitStep2, setRecruitStep2] = useState({});

  async function getRecruitDocs() {
    setRecruitDocs(
      await Connection("/getRecruitDocs", {
        club_id: club_id,
        recruit_id: recruit_id,
      })
    );
  }
  async function getRecruitStep2() {
    const res = await Connection("/getRecruitStep2", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
      recruit_id: recruit_id,
    });

    setRecruitStep2(res);

    if (res.is_success) {
      setApplicantList(
        res.applicant_r.filter((a) => a.state !== 0 && a.state !== 2)
      );
      setTimetable(res.timetable);
    }
  }

  useEffect(() => {
    getRecruitDocs();
    getRecruitStep2();
  }, []);

  useEffect(() => {
    setCountAll(applicantList.length);
  }, [applicantList]);

  const stateHeader = [
    { k: "상태", type: 0 },
    { k: "📆 면접 날짜 (시간)", type: 0 },
  ];
  const newHeader = [].concat(stateHeader, recruitDocs.question);

  const [animation1, setAnimation1] = useState("");
  const [isToastPopupOpen, setIsToastPopupOpen] = useState(false);
  const onToggle = () => {
    setAnimation1("openAnimation-toast");
    setIsToastPopupOpen(true);
    setTimeout(() => {
      setAnimation1("closeAnimation-toast");
      setTimeout(() => {
        setIsToastPopupOpen(false);
      }, 450);
    }, 2000);
  };

  return (
    <>
      <div className="div-recruitpage6m-section H3M">
        <div className="div-recruitpage6m-section-title H3M">
          🙌 서류 합격자 현황
          <div className="div-recruitpage6m-top-gradient" />
        </div>
        <div className="div-recruitpage6m-section-content">
          <div className="div-recruitpage6m-reset-section">
            <div
              className="div-recruitpage6m-reset Tag1M"
              onClick={async () => {
                if (!isToastPopupOpen) {
                  await getRecruitStep2();
                  onToggle();
                }
              }}
            >
              <img src={Icon_Reset} alt="" style={{ width: "18px" }} />
              새로고침
            </div>
            <div className="div-recruitpage6m-reset-toastpopup-section">
              {isToastPopupOpen && (
                <ToastPopup
                  status={"success"}
                  children={"새로고침이 완료되었습니다."}
                  className={`${animation1} Tag1`}
                />
              )}
            </div>
          </div>
          {recruitDocs.is_success && recruitStep2.is_success && (
            <Table4M
              club_id={club_id}
              recruit_id={recruit_id}
              recruitDocs={recruitDocs}
              headers={newHeader}
              applicantList={applicantList}
              setCountFail={setCountFail}
              setCountPass={setCountPass}
              timetable={timetable}
            />
          )}
        </div>
        <div className="div-recruitpage6m-bottom-gradient" />
        <div className="div-recruitpage6m-bottom">
          <div className="div-recruitpage6m-bottom-section">
            <div
              className="div-manage-section-03-02-01-02-count Body4M"
              style={{ width: "64px" }}
            >
              총 지원자
              <div className="H3">{countAll}명</div>
            </div>
            <div
              className="div-manage-section-03-02-01-02-count Body4M"
              style={{ width: "64px" }}
            >
              불합격
              <div className="H3">{countFail}명</div>
            </div>
            <div
              className="div-manage-section-03-02-01-02-count Body4M"
              style={{ width: "64px" }}
            >
              합격
              <div className="H3" style={{ color: "#FF7A00" }}>
                {countPass}명
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruitPage4M;
