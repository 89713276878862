import React, { useState, useEffect } from "react";
import Icon_Reset from "../asset/icon/Icon_Reset.svg";
import Table from "./Table";
import Connection from "./Connection";
import MessageOut from "./MessageOut";
import RecruitInfo from "./RecruitInfo";
import ToastPopup from "./ToastPopup";
import MessageDocs from "./MessageDocs";
import Loginform from "./Loginform";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { GuideDefaultDim } from "./guideModal";

function RecruitPage1({
  club_id,
  recruit_id,
  recruitDocs,
  recruitInfo,
  recruitStep1,
  setRecruitStep1,
  setRecruitState,
  clubInfo,
  info,
  isLeftSectionOpen,
  managerList,
}) {
  const isWide = useMediaQuery({
    query: isLeftSectionOpen ? "(min-width: 1225px)" : "(min-width: 945px)",
  });

  const [loginModal, setLoginModal] = useState(false);

  const [recruitDetailOpen, setRecruitDetailOpen] = useState(false);
  const [countAll, setCountAll] = useState(0);
  const [countFail, setCountFail] = useState(0);
  const [countPass, setCountPass] = useState(0);

  useEffect(() => {
    recruitStep1.is_success && setCountAll(recruitStep1.applicant.length);
  }, [recruitStep1]);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const stateHeader = [
    { k: "상태", type: 0 },
    { k: "점수", type: 0 },
  ];
  const newHeader = [].concat(stateHeader, recruitDocs.question);

  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isFinalMessageModalOpen, setIsFinalMessageModalOpen] = useState(false);

  const [animation1, setAnimation1] = useState("");
  const [isToastPopupOpen, setIsToastPopupOpen] = useState(false);
  const onToggle = () => {
    setAnimation1("openAnimation-toast");
    setIsToastPopupOpen(true);
    setTimeout(() => {
      setAnimation1("closeAnimation-toast");
      setTimeout(() => {
        setIsToastPopupOpen(false);
      }, 450);
    }, 2000);
  };

  // redux를 활용한 가이드 인덱스 관리
  const { guideRecruit_1, guideRecruit_1i } = useSelector((state) => ({
    guideRecruit_1: state.guide.recruit_1,
    guideRecruit_1i: state.guide.recruit_1i,
  }));

  return (
    <>
      <RecruitInfo
        club_id={club_id}
        recruit_id={recruit_id}
        recruitInfo={recruitInfo}
        recruitDetailOpen={recruitDetailOpen}
        setRecruitDetailOpen={setRecruitDetailOpen}
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        clubInfo={clubInfo}
        info={info}
      />
      <div
        className={
          isLeftSectionOpen
            ? "div-manage-section-03-02"
            : "div-manage-section-03-02-wide"
        }
      >
        <div
          className={
            isLeftSectionOpen
              ? "div-manage-section-03-02-01"
              : "div-manage-section-03-02-01-wide"
          }
        >
          <div>
            <div className="div-manage-section-03-01-01-01 H3">
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <div
                  className="H3"
                  style={{
                    width: "112px",
                    flexShrink: "0",
                  }}
                >
                  🙌 지원자 현황
                </div>
                {isWide && (
                  <GuideDefaultDim
                    guideIndex={guideRecruit_1i}
                    ownIndex={3}
                    CoachMark={true}
                    guideName={"recruit_1"}
                    isOpen={guideRecruit_1}
                  >
                    <div
                      className="div-click div-manage-section-03-01-01-01-reset Tag1"
                      onClick={async () => {
                        if (!isToastPopupOpen) {
                          setRecruitStep1(
                            await Connection("/getRecruitStep1", {
                              session_key: localStorage.getItem("session_key"),
                              club_id: club_id,
                              recruit_id: recruit_id,
                            })
                          );
                          onToggle();
                        }
                      }}
                    >
                      <img src={Icon_Reset} alt="" style={{ width: "18px" }} />
                      새로고침
                    </div>
                  </GuideDefaultDim>
                )}

                {isToastPopupOpen && (
                  <ToastPopup
                    status={"success"}
                    children={"새로고침이 완료되었습니다."}
                    className={`${animation1} Tag1`}
                  />
                )}
              </div>
              {!isWide && (
                <ToastPopup
                  status={"warning"}
                  children={"화면을 늘려주세요!"}
                  className={`Tag1`}
                />
              )}
            </div>
            {recruitDocs.is_success && recruitStep1.is_success && (
              <Table
                club_id={club_id}
                recruit_id={recruit_id}
                recruitDocs={recruitDocs}
                headers={newHeader}
                applicantList={recruitStep1.applicant}
                setCountFail={setCountFail}
                setCountPass={setCountPass}
                isLeftSectionOpen={isLeftSectionOpen}
                managerList={managerList}
              />
            )}
          </div>
          <div className="div-manage-section-03-02-01-02">
            <div className="div-manage-section-03-02-01-02-count-section">
              <div className="div-manage-section-03-02-01-02-count Body4">
                총 지원자
                <div className="H3">{countAll}명</div>
              </div>
              <div className="div-manage-section-03-02-01-02-count Body4">
                불합격
                <div className="H3">{countFail}명</div>
              </div>
              <div className="div-manage-section-03-02-01-02-count Body4">
                합격
                <div className="H3" style={{ color: "#FF7A00" }}>
                  {countPass}명
                </div>
              </div>
            </div>
            {recruitInfo.step === "서류" ? (
              <button
                className="main-orange-button button-manage-section-03-02-01-02-complete H3"
                onClick={async () => {
                  if (localStorage.getItem("session_key") === "admin_demo") {
                    window.alert("회원가입 후 이용 가능합니다🙂");
                    setLoginModal(true);
                  } else {
                    if (countAll === countFail + countPass) {
                      if (window.confirm("합불 처리를 완료하시겠습니까?")) {
                        setIsFinalMessageModalOpen(true);
                      }
                    } else {
                      window.alert("아직 평가하지 않은 지원자가 있습니다.");
                    }
                  }
                }}
              >
                최종 합불 처리
              </button>
            ) : (
              <button
                className="main-orange-button button-manage-section-03-02-01-02-complete H3"
                onClick={async () => {
                  if (localStorage.getItem("session_key") === "admin_demo") {
                    window.alert("회원가입 후 이용 가능합니다🙂");
                    setLoginModal(true);
                  } else {
                    if (countAll === countFail + countPass) {
                      if (
                        window.confirm("서류 합불 처리를 완료하시겠습니까?")
                      ) {
                        setIsMessageModalOpen(true);
                      }
                    } else {
                      window.alert("아직 평가하지 않은 지원자가 있습니다.");
                    }
                  }
                }}
              >
                서류 합불 처리
              </button>
            )}
          </div>
        </div>
      </div>
      {isMessageModalOpen && (
        <MessageOut
          club_id={club_id}
          recruit_id={recruit_id}
          setModalOpen={setIsMessageModalOpen}
          countFail={countFail}
          setRecruitState={setRecruitState}
        />
      )}
      {isFinalMessageModalOpen && (
        <MessageDocs
          club_id={club_id}
          recruit_id={recruit_id}
          setModalOpen={setIsFinalMessageModalOpen}
          countPass={countPass}
          countFail={countFail}
          countAll={countAll}
          setRecruitState={setRecruitState}
        />
      )}
      {loginModal && <Loginform setModalOpen={setLoginModal} />}
    </>
  );
}

export default RecruitPage1;
