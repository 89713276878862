async function ApplyApplicant(
  club_id,
  recruit_id,
  answer,
  profile,
  fileList,
  setCompleted,
  setIsFinalDisabled
) {
  const formdata = new FormData();
  formdata.append("club_id", club_id);
  formdata.append("recruit_id", recruit_id);
  formdata.append("answer", JSON.stringify(answer));
  formdata.append("profile", JSON.stringify(profile));

  fileList.length > 0 &&
    fileList.map((f) => {
      formdata.append(`file_data[${f.qNum}][]`, f.file);
    });

  const api_host =
    // window.location.host === "dongdle.com"
    true ? "https://api.dongdle.com" : "http://api_test.dongdle.com:405";

  const aa = await fetch(api_host + "/api/v1/applyApplicant", {
    method: "POST",
    body: formdata,
  })
    .then((res) => res.json())
    .then((json) => {
      if (json.is_success) {
        setCompleted(true);
      } else {
        window.alert(json.error_message);
        setIsFinalDisabled(false);
      }
    });
}

export default ApplyApplicant;
