import { React, useEffect } from "react";
import "../../asset/css/community/ClubMain.css";
import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_ArrowLeft_White from "../../asset/icon/Icon_ArrowLeft_White.svg";
import Icon_Search from "../../asset/icon/Icon_Search.svg";
import Icon_Search_White from "../../asset/icon/Icon_Search_White.svg";
import Icon_Etc from "../../asset/icon/Icon_Etc.svg";
import Icon_Etc_White from "../../asset/icon/Icon_Etc_White.svg";
import badge from "../../asset/clubList/badge.png";
import { useState } from "react";
import ClubMainHome from "./ClubMainHome";
import ClubMainInfo from "./ClubMainInfo";
import ClubMainBoard from "./ClubMainBoard";
import ClubMainSchedule from "./ClubMainSchedule";
import CommunityConnection from "./CommunityConnection";
import Connection from "../Connection";
import ComingSoonM from "../ComingSoonM";
import IsMember from "./IsMember";

function ClubMain() {
  const session_key = window.localStorage.getItem("session_key");
  const param_path = window.location.pathname.split("/");
  const [boardId, setBoardId] = useState(
    param_path[4] === "board" ? param_path[5] : "9999"
  );
  const club_id = param_path[3];

  const [info, setInfo] = useState();
  async function getInfo() {
    setInfo(await Connection("/getInfo", {}));
  }
  const [isMember, setIsMember] = useState();
  async function getIsMember(clubId) {
    setIsMember(await IsMember(session_key, clubId));
  }
  const [test, setTest] = useState();
  async function getClubMember() {
    const res = await CommunityConnection(
      "/getClubMember",
      {
        id: club_id,
      },
      false
    );
    if (res.is_success) setTest(res.member);
  }
  useEffect(() => {
    getIsMember(club_id);
    getClubMember();
  }, []);

  const [clubInfo, setClubInfo] = useState();
  const [recruits, setRecruits] = useState();
  const [images, setImages] = useState();
  const [category, setCategory] = useState();
  const [location, setLocation] = useState();

  async function getCommunityClubInfo() {
    const res = await CommunityConnection("/getCommunityClubInfo", {
      id: club_id,
    });
    if (res.is_success) setClubInfo(res.club_info);
  }
  async function getCommunityClubRecruits() {
    const res = await CommunityConnection("/getCommunityClubRecruits", {
      id: club_id,
    });
    if (res.is_success) setRecruits(res.recruits);
  }
  async function getCommunityClubImages() {
    const res = await CommunityConnection("/getCommunityClubImages", {
      id: club_id,
    });
    if (res.is_success) setImages(res.images);
  }
  async function getCommunityClubCategory() {
    const res = await CommunityConnection("/getCommunityClubCategory", {
      id: club_id,
    });
    if (res.is_success) setCategory(res.category);
  }
  async function getCommunityClubLocation() {
    const res = await CommunityConnection("/getCommunityClubLocation", {
      id: club_id,
    });
    if (res.is_success) setLocation(res.location);
  }
  const [menu, setMenu] = useState();
  useEffect(() => {
    getInfo();
    getCommunityClubInfo();
    getCommunityClubRecruits();
    getCommunityClubImages();
    getCommunityClubCategory();
    getCommunityClubLocation();

    switch (param_path[4]) {
      case "home":
        setMenu(0);
        break;
      case "info":
        setMenu(1);
        break;
      case "board":
        setMenu(2);
        break;
      case "schedule":
        setMenu(3);
        break;
      default:
    }
  }, []);

  useEffect(() => {
    if (menu === 0) {
      const state = { menu: 0 };
      window.history.pushState(
        state,
        "",
        "/community/club/" + club_id + "/home/0"
      );
    }
    if (menu === 1) {
      const state = { menu: 1 };
      window.history.pushState(
        state,
        "",
        "/community/club/" + club_id + "/info/0"
      );
    }
    if (menu === 2) {
      const state = { menu: 2 };
      if (param_path.length !== 7) {
        window.history.pushState(
          state,
          "",
          "/community/club/" + club_id + "/board/9999"
        );
      }
    }
    if (menu === 3) {
      const state = { menu: 3 };
      window.history.pushState(
        state,
        "",
        "/community/club/" + club_id + "/schedule/0"
      );
    }
  }, [menu]);

  const [isScrolled, setIsScrolled] = useState(false);

  const [isWriteSectionOpen, setIsWriteSectionOpen] = useState(false);

  const [isArticleOpen, setIsArticleOpen] = useState(param_path.length === 7);

  function handleScroll() {
    if (window.scrollY > 190) setIsScrolled(true);
    else setIsScrolled(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    function checkScroll() {
      window.addEventListener("scroll", handleScroll);
    }
    checkScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handlePopState1 = (e) => {
      setIsArticleOpen(false);
    };
    window.addEventListener("popstate", handlePopState1);

    return () => window.removeEventListener("popstate", handlePopState1);
  }, []);

  return (
    <div
      style={{ overflow: (isWriteSectionOpen || isArticleOpen) && "hidden" }}
    >
      {isScrolled ? (
        <div className="div-club-main-top-section-scrolled">
          <div style={{ display: "flex", gap: "8px" }}>
            <img
              src={Icon_ArrowLeft}
              alt=""
              onClick={() => (window.location.href = "/community/club")}
            />
            {clubInfo?.name}
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            {/* <img src={Icon_Search} alt="" />
            <img src={Icon_Etc} alt="" /> */}
          </div>
        </div>
      ) : (
        <div className="div-club-main-top-section">
          <img
            src={Icon_ArrowLeft_White}
            alt=""
            onClick={() => (window.location.href = "/community/club")}
          />
          <div style={{ display: "flex", gap: "16px" }}>
            {/* <img src={Icon_Search_White} alt="" />
            <img src={Icon_Etc_White} alt="" /> */}
          </div>
        </div>
      )}
      {!isScrolled && images && images[0]?.image_url && (
        <img
          className="img-club-main-top"
          src={images && images[0]?.image_url}
          alt=""
        />
      )}
      <div className="div-club-main-scroll-section">
        <div className="div-club-main-scroll-background" />
        {isScrolled ? (
          <div className="div-club-main-infobox-section-scrolled">
            {clubInfo?.name}
          </div>
        ) : (
          <div className="div-club-main-infobox-section">
            {clubInfo?.is_certified === 1 && (
              <img className="img-club-info-badge" src={badge} alt="" />
            )}
            <img
              className="img-club-main-infobox-logo"
              src={clubInfo?.image}
              alt=""
            />
            <div className="div-club-main-infobox-text-section">
              <div className="div-club-main-infobox-name Sub2M">
                {clubInfo?.name}
              </div>
              <div className="div-club-main-infobox-introduce Body5M">
                {clubInfo?.introduce}
              </div>
            </div>
          </div>
        )}
        <div
          className={
            isScrolled
              ? "div-club-main-menu-section-scrolled"
              : "div-club-main-menu-section"
          }
        >
          <div
            className={
              menu === 0
                ? "div-club-main-menu-checked Sub3M"
                : "div-club-main-menu Sub3M"
            }
            onClick={() => {
              setMenu(0);
              if (window.scrollY >= 190) window.scrollTo(0, 191);
            }}
          >
            홈
          </div>
          <div
            className={
              menu === 1
                ? "div-club-main-menu-checked Sub3M"
                : "div-club-main-menu Sub3M"
            }
            onClick={() => {
              setMenu(1);
              if (window.scrollY >= 190) window.scrollTo(0, 191);
            }}
          >
            정보
          </div>
          <div
            className={
              menu === 2
                ? "div-club-main-menu-checked Sub3M"
                : "div-club-main-menu Sub3M"
            }
            onClick={() => {
              setMenu(2);
              if (window.scrollY >= 190) window.scrollTo(0, 191);
            }}
          >
            게시판
          </div>
          <div
            className={
              menu === 3
                ? "div-club-main-menu-checked Sub3M"
                : "div-club-main-menu Sub3M"
            }
            onClick={() => {
              setMenu(3);
              if (window.scrollY >= 190) window.scrollTo(0, 191);
            }}
          >
            일정
          </div>
        </div>
        <div className="div-club-main-contents-section">
          {menu === 0 && (
            <ClubMainHome
              recruit={recruits && recruits}
              activiy_image={images && images}
            />
          )}
          {menu === 1 && (
            <ClubMainInfo
              purpose={clubInfo?.purpose}
              category={info?.category[category?.category]}
              location={info?.location[location?.location_id]}
              sns_link={clubInfo?.sns_link}
              info={clubInfo?.info}
            />
          )}
          {menu === 2 && (
            <ClubMainBoard
              isMember={isMember}
              isWriteSectionOpen={isWriteSectionOpen}
              setIsWriteSectionOpen={setIsWriteSectionOpen}
              isArticleOpen={isArticleOpen}
              setIsArticleOpen={setIsArticleOpen}
              boardId={boardId}
              setBoardId={setBoardId}
            />
          )}
          {/* {menu === 3 && <ClubMainSchedule />} */}
          {menu === 3 && <ComingSoonM />}
        </div>
      </div>
    </div>
  );
}

export default ClubMain;
