import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Connection from "../Connection";

export default function EditRecruit({ ...props }) {
  const [title, setTitle] = useState("Loading..");
  const [contents, setContents] = useState("Loading..");

  useEffect(() => {
    const session_key = window.localStorage.getItem("session_key");
    const fetchData = async () => {
      try {
        const res = await Connection("/getRecruitInfo", {
          club_id: props.clubId,
          recruit_id: props.recruitId,
        });
        setTitle(res.title);
        setContents(res.description);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <ContentsWrapper>
        <Header>
          <img src={Icon_ArrowLeft} onClick={() => props.setPage(false)} />
          <span className="Sub2M">공고 수정</span>
          <div style={{ width: "24px" }} />
        </Header>
        <Body>
          <Segment style={{ opacity: "0.3" }}>
            <label className="Sub3M" htmlFor="title">
              공고 제목
            </label>
            <input
              id="title"
              type="text"
              className="Body4M -input-box"
              value={title}
              style={{ pointerEvents: "none" }}
            />
          </Segment>
          <Segment>
            <label className="Sub3M" htmlFor="contents">
              공고 내용
            </label>
            <textarea
              id="contents"
              type="text"
              className="Body4M -input-box"
              value={contents}
              onChange={(e) => setContents(e.target.value)}
            />
          </Segment>
          <p>공고 등록 후에는 모집 글만 수정 가능합니다!</p>
        </Body>
        <Footer className="Sub3M">저장</Footer>
      </ContentsWrapper>
    </>
  );
}

const ContentsWrapper = styled.div`
  width: 100vw;
  min-width: 280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  background-color: white;
  cursor: default;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  span {
    text-align: center;
    height: auto;
  }
`;
const Body = styled.div`
  flex-grow: 1;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  p {
    width: 216px;
    color: #b4b4b4;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    word-break: keep-all;
  }
`;
const Segment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  input {
    padding: 12px 16px;
    border-radius: 8px;
  }
  textarea {
    resize: none;
    height: 220px;
  }
`;
const Footer = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  cursor: pointer;
`;
