import React from "react";
import logo from "../asset/header/logo.svg";

function HeaderM() {
  return (
    <div className="div-form-mobile-section">
      <div className="div-headerM-section">
        <img className="img-headerM" src={logo} alt="" />
      </div>
    </div>
  );
}

export default HeaderM;
