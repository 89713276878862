import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components/macro";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Plus from "../../asset/icon/Icon_Plus.svg";
import Icon_Delete_Gray from "../../asset/icon/Icon_Delete_Gray.svg";
import Icon_Close from "../../asset/icon/Icon_Close.svg";

import Connection from "../Connection";

export default function FirstTable({ ...props }) {
  const session_key = localStorage.getItem("session_key");
  const [recruitDocs, setRecruitDocs] = useState({});
  const [recruitStep1, setRecruitStep1] = useState({});

  useEffect(() => {
    async function getRecruitDocs() {
      setRecruitDocs(
        await Connection("/getRecruitDocs", {
          club_id: props.clubId,
          recruit_id: props.recruitId,
        })
      );
    }
    async function getRecruitStep1() {
      setRecruitStep1(
        await Connection("/getRecruitStep1", {
          club_id: props.clubId,
          recruit_id: props.recruitId,
        })
      );
    }
    getRecruitDocs();
    getRecruitStep1();
  }, []);

  return (
    <>
      <ContentsWrapper>
        <Header>
          <img src={Icon_ArrowLeft} />
          <span className="Sub2M">🙋‍♀ 운영진 관리️</span>
          <div style={{ width: "24px", height: "24px" }} />
        </Header>
        <Body>
          <TableHeader>
            <div>이름</div>
            <div>전화번호</div>
            <div>삭제</div>
          </TableHeader>
          {recruitStep1?.applicant?.map((applicant, idx) => (
            <TableRow>
              <div>{applicant.profile.name}</div>
              <div>{applicant.state}</div>
              <div>
                <img src={Icon_Delete_Gray} alt="" />
              </div>
            </TableRow>
          ))}
        </Body>
      </ContentsWrapper>
    </>
  );
}
const ContentsWrapper = styled.div`
  width: 100vw;
  min-width: 320px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  flex-shrink: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  cursor: default;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  span {
    text-align: center;
    height: auto;
  }
`;
const Body = styled.div`
  flex-grow: 1;
  padding: 10px 8px;
  overflow-y: scroll;
`;
const TableHeader = styled.div.attrs({
  className: "Body5M",
})`
  width: 100%;
  height: 42px;
  display: flex;
  gap: 1px;
  div {
    background-color: #ececec;
    text-align: center;
  }
  div:first-child {
    width: 100px;
    flex-grow: 100;
    padding: 12px 15px;
  }
  div:nth-child(2) {
    width: 130px;
    flex-grow: 130;
    padding: 12px 3px;
  }
  div:last-child {
    flex: none;
    width: 64px;
    padding: 12px 0px;
  }
`;
const TableRow = styled.div.attrs({
  className: "Body5M",
})`
  width: 100%;
  height: 40px;
  display: flex;
  div {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  div:first-child {
    width: 100px;
    flex-grow: 100;
    padding: 12px 15px;
    border-left: 1px solid #ececec;
  }
  div:nth-child(2) {
    width: 130px;
    flex-grow: 130;
    padding: 12px 3px;
  }
  div:last-child {
    flex: none;
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ececec;
    img {
      width: 24px;
      height: 24px;
    }
  }
  &:last-child {
    border-bottom: 1px solid #ececec;
  }
`;
