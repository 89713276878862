import React, { useState, useEffect } from "react";
import backgroundM from "../asset/mainM/backgroundM.png";
import section_05 from "../asset/main/section_05.png";
import section_04 from "../asset/mainM/section_04.gif";
import section_06 from "../asset/mainM/section_06.gif";
import section_07 from "../asset/mainM/section_07.gif";

import logo_white from "../asset/main/logo_white.svg";
import Icon_Fold from "../asset/main/Icon_Fold.svg";
import rocket from "../asset/main/rocket.png";
import LoginformM from "./LoginformM";
import Connection from "./Connection";

import mainCard from "../mainCard.json";
import mainClubInfoCard from "../mainClubInfoCard.json";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import FooterM from "./FooterM";
import { useMediaQuery } from "react-responsive";
import CreateClub from "./CreateClub";

function MainM() {
  const isPC = useMediaQuery({
    query: "(min-width: 480px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const [createOpen, setCreateOpen] = useState(false);

  const kakaoAuth_ok = window.localStorage.kakaoAuth_ok || 0;
  const [loginOpen, setLoginOpen] = useState(false);
  const [info, setInfo] = useState({});
  const [cbu, setCbu] = useState({});

  const getUserInfo = async () => {
    const res = await Connection("/getUserInfo", {
      session_key: window.localStorage.session_key,
    });
    const res2 = await Connection("/getClubByUser", {
      session_key: window.localStorage.session_key,
    });
    setCbu(res2);
    const res3 = await Connection("/getInfo", {});
    setInfo(res3);
    if (res.is_success) {
      window.localStorage.setItem("name", res.name);
      window.localStorage.setItem("phone", res.phone);
    } else {
      window.localStorage.removeItem("session_key");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("session_key") === "admin_demo") {
      localStorage.clear();
      window.location.reload();
    } else {
      if (kakaoAuth_ok) {
        setLoginOpen(true);
      }
      if (window.localStorage.session_key) {
        getUserInfo();
      }
    }
  }, []);

  return (
    <>
      {createOpen && (
        <div
          className="div-loginform-modal-wrapper"
          style={{
            backgroundColor: createOpen
              ? "rgba(0, 0, 0, 0.3)"
              : "rgba(0, 0, 0, 0)",
          }}
        >
          <CreateClub setModalOpen={setCreateOpen} info={info} />
        </div>
      )}
      {loginOpen ? (
        <LoginformM setModalOpen={setLoginOpen} registerNumber={kakaoAuth_ok} />
      ) : (
        <></>
      )}
      <div className="div-mainm-section" id="main">
        <div className="div-mainm-section-01">
          <img className="img-mainm-background" src={backgroundM} alt="" />
          <img
            className="img-mainm-logo"
            src={logo_white}
            alt=""
            style={{ width: "153px" }}
          />
          <div className="div-mainm-section-01-middle H3M">
            동아리의
            <br />
            모든 것, 동들
          </div>
          {localStorage.getItem("session_key") ? (
            <>
              {/* <button
                className="outlined-orange-buttonM button-mainm-community Sub2M"
                onClick={() => {
                  if (window.localStorage.session_key) {
                    window.location.href = "/community/home/0";
                  } else {
                    alert("로그인 후 이용해주세요!");
                    setLoginOpen(true);
                  }
                }}
              >
                커뮤니티 바로가기
              </button> */}
              <button
                className="main-orange-buttonM button-mainm-start Sub2M"
                onClick={async () => {
                  if (isPC) {
                    if (window.localStorage.session_key) {
                      if (!cbu.club.length) {
                        alert("동아리 등록 후 이용해주세요!!😀");
                        setCreateOpen(true);
                      } else {
                        const ci = await Connection("/getClubInfo", {
                          session_key: window.localStorage.session_key,
                          club_id: cbu.club[0].id,
                        });
                        const club_id = cbu.club[0].id;
                        const recruit_id =
                          ci.recruit.length > 0
                            ? ci.recruit[0].id
                            : "no_recruit";

                        window.location.href = "/" + club_id + "/" + recruit_id;
                      }
                    } else {
                      alert("로그인 후 이용해주세요!");
                      setLoginOpen(true);
                    }
                  } else {
                    if (window.localStorage.session_key) {
                      if (!cbu.club.length) {
                        window.location.href = "/no_club/manage";
                      } else {
                        const club_id = cbu.club[0].id;
                        window.location.href = "/" + club_id + "/manage";
                      }
                    }
                  }
                }}
              >
                동아리 관리하기
              </button>
            </>
          ) : (
            <>
              {/* <button
                className="outlined-orange-buttonM button-mainm-community Sub2M"
                onClick={() => {
                  if (window.localStorage.session_key) {
                    window.location.href = "/community/home/0";
                  } else {
                    alert("로그인 후 이용해주세요!");
                    setLoginOpen(true);
                  }
                }}
              >
                커뮤니티 바로가기
              </button> */}
              <button
                className="main-orange-buttonM button-mainm-start Sub2M"
                onClick={async () => {
                  if (isPC) {
                    localStorage.setItem("session_key", "admin_demo");
                    localStorage.setItem("name", "동들 관리자");
                    localStorage.setItem("phone", "01012345678");
                    const cbu = await Connection("/getClubByUser", {
                      session_key: "admin_demo",
                    });

                    const ci = await Connection("/getClubInfo", {
                      session_key: "admin_demo",
                      club_id: cbu.club[0].id,
                    });
                    const club_id = cbu.club[0].id;
                    const recruit_id =
                      ci.recruit.length > 0 ? ci.recruit[0].id : "no_recruit";

                    window.location.href = "/" + club_id + "/" + recruit_id;
                  } else {
                    localStorage.setItem("session_key", "admin_demo");
                    localStorage.setItem("name", "동들 관리자");
                    localStorage.setItem("phone", "01012345678");
                    window.location.href =
                      "/986cccae-6181-45a5-a4cb-47c1e1065c7d/manage";
                  }
                }}
              >
                동들 체험하기
              </button>
              <button
                className="button-mainm-start-demo-form Sub3M"
                onClick={() =>
                  window.open(
                    "https://dongdle.com/form/986cccae-6181-45a5-a4cb-47c1e1065c7d/986cd456-4e3d-43a5-8ba8-f1f1fa297a49"
                  )
                }
              >
                동들폼 구경하기
              </button>
            </>
          )}

          <div
            className="div-click div-mainm-section-01-bottom bounceM Sub1"
            onClick={() =>
              document.getElementById("main").scrollTo({
                top: document.getElementById("main").clientHeight,
                behavior: "smooth",
              })
            }
          >
            동들에 대해 더 알아보기
            <img src={Icon_Fold} alt="" style={{ width: "21px" }} />
          </div>
        </div>
        <div className="div-mainm-section-02">
          <div className="div-mainm-section-02-top H3M">
            <div className="div-main-section-02-top-01 H3M">
              <div
                className="div-mainm-section-02-top-category Sub1M"
                id="vertical"
              >
                <Swiper
                  slidesPerView={1}
                  autoplay={{ delay: 2000, disableOnInteraction: false }}
                  loop={true}
                  direction="vertical"
                  style={{
                    height: "24px",
                  }}
                >
                  <SwiperSlide>연합 동아리</SwiperSlide>
                  <SwiperSlide>학교 동아리</SwiperSlide>
                  <SwiperSlide>대외 활동</SwiperSlide>
                  <SwiperSlide>소규모 스터디</SwiperSlide>
                </Swiper>
              </div>
              운영,
            </div>
            무엇이 고민인가요?
          </div>
          <div className="div-mainm-section-02-bottom">
            <Swiper
              slidesPerView={2}
              autoplay={{ delay: 0, disableOnInteraction: false }}
              loop={true}
              speed={2000}
            >
              {mainCard.map(({ icon, content, idx }) => (
                <SwiperSlide key={idx}>
                  <div className="div-mainm-section-02-chip-section">
                    <div className="H3M">{icon}</div>
                    <div className="div-mainm-section-02-chip-content Tag1M">
                      {content}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="div-mainm-section-03 Body5M">
          <img
            src={rocket}
            alt=""
            style={{ width: "120px" }}
            onClick={() => {
              if (window.localStorage.session_key) {
                window.location.href = "/community/home/0";
              } else {
                alert("로그인 후 이용해주세요!");
                setLoginOpen(true);
              }
            }}
          />
          동들과 함께하면 쉽고 편한 동아리 관리
          <span
            style={{ fontWeight: "700", marginTop: "12px" }}
            className="Sub1M"
          >
            <span
              style={{
                backgroundColor: "#FF7A00",
                color: "white",
                padding: "0 3px",
                marginRight: "3px",
              }}
              className="Sub1M"
            >
              모집, 관리, 홍보
            </span>
            까지 한번에!
          </span>
        </div>
        <div className="div-mainm-section-03 Sub1M">
          전국의 다양한 동아리들이
          <br />
          동들과 함께 걷고있어요🤝️
          <br />
          <br />
          <div className="div-mainm-section-02-bottom">
            {isPC ? (
              <Swiper
                slidesPerView={isTablet ? 3 : 4}
                autoplay={{ delay: 0, disableOnInteraction: false }}
                loop={true}
                speed={2000}
              >
                {mainClubInfoCard.map(({ name, info, insta, image }) => (
                  <SwiperSlide key={name}>
                    <div
                      className="div-main-section-03-02-chip-section"
                      style={{
                        width: `calc(${100 / (isTablet ? 3 : 4)}vw - 12px)`,
                        height: `calc(${100 / (isTablet ? 3 : 4)}vw - 12px)`,
                      }}
                    >
                      <img
                        className="img-main-section-03-02-chip"
                        src={image}
                        alt=""
                      />
                      <div className="Sub1" style={{ marginBottom: "4.5px" }}>
                        {name}
                      </div>
                      <div className="div-main-section-03-02-chip-content Body4">
                        {info}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Swiper
                slidesPerView={2}
                autoplay={{ delay: 0, disableOnInteraction: false }}
                loop={true}
                speed={2000}
              >
                {mainClubInfoCard.map(({ name, info, insta, image }) => (
                  <SwiperSlide key={name}>
                    <div className="div-mainm-section-03-02-chip-section">
                      <img
                        className="img-mainm-section-03-02-chip"
                        src={image}
                        alt=""
                      />
                      <div className="H3" style={{ marginBottom: "4px" }}>
                        {name}
                      </div>
                      <div className="div-mainm-section-02-chip-content Body5">
                        {info}
                      </div>
                      <div
                        className="div-mainm-section-02-chip-content Tag1"
                        style={{ color: insta ? "#ff7a00" : "white" }}
                      >
                        @{insta}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
        <div className="div-mainm-section-03-03 Sub1M">
          동들은 이런 서비스에요 🚀
        </div>
        <div className="div-mainm-section-04">
          <div className="div-mainm-section-title-sub">
            <div className="div-mainm-section-04-title Sub1M">
              우리 동아리 맞춤 질문들로
              <br />
              편하게 지원폼을 만들 수 있어요
            </div>
            <div className="Body5M">
              동들에서 필요할 것 같은 질문들을 선별하여
              <br />
              추천해드립니다. 직접 작성할 필요없이
              <br />
              편하게 질문을 담기만 하세요!
            </div>
          </div>
          <img className="img-mainm-section-05" src={section_04} alt="" />
        </div>
        <div className="div-mainm-section-05">
          <div className="div-mainm-section-title-sub">
            <div className="div-mainm-section-04-title Sub1M">
              우리 동아리에 지원한
              <br />
              지원자들의 정보를 한눈에
            </div>
            <div className="Body5M">
              지원자들의 정보를 한번에 보실 수 있도록
              <br />
              정리해드려요. 원하는 정보만 선별해서
              <br />
              확인할 수 있어 도움이 될 거에요!
            </div>
          </div>
          <img className="img-mainm-section-05" src={section_05} alt="" />
        </div>
        <div className="div-mainm-section-06">
          <div className="div-mainm-section-title-sub">
            <div className="div-mainm-section-04-title Sub1M">
              합격자 선별부터 결과 문자까지
              <br />
              간편하게 해볼까요?
            </div>
            <div className="Body5M">
              지원자들의 합/불 여부를 버튼으로
              <br />
              간단하게 설정하고 수집한 지원자들의 연락처로
              <br />
              결과 문자까지 한번에 보낼 수 있어요!
            </div>
          </div>
          <img className="img-mainm-section-05" src={section_06} alt="" />
        </div>
        <div className="div-mainm-section-07">
          <div className="div-mainm-section-title-sub">
            <div className="div-mainm-section-04-title Sub1M">
              지원자와의 약속, 면접 일정
              <br />
              편리하게 조율할 수 있어요
            </div>
            <div className="Body5M">
              합격 문자 전송 시, 지원자가 면접 가능한 시간에
              <br />
              직접 면접을 신청할 수 있어
              <br />
              노쇼를 방지할 수 있어요!
            </div>
          </div>
          <img className="img-mainm-section-05" src={section_07} alt="" />
        </div>
        <div className="div-mainm-section-08 Sub3M">
          동들과 함께 새로운 동아리 관리를
          <br />
          경험해보세요!
          {localStorage.getItem("session_key") ? (
            <button
              className="main-orange-button button-mainm-section-08 Sub2M"
              onClick={async () => {
                if (isPC) {
                  if (window.localStorage.session_key) {
                    if (!cbu.club.length) {
                      alert("동아리 등록 후 이용해주세요!!😀");
                      setCreateOpen(true);
                    } else {
                      const ci = await Connection("/getClubInfo", {
                        session_key: window.localStorage.session_key,
                        club_id: cbu.club[0].id,
                      });
                      const club_id = cbu.club[0].id;
                      const recruit_id =
                        ci.recruit.length > 0 ? ci.recruit[0].id : "no_recruit";

                      window.location.href = "/" + club_id + "/" + recruit_id;
                    }
                  } else {
                    alert("로그인 후 이용해주세요!");
                    setLoginOpen(true);
                  }
                } else {
                  if (window.localStorage.session_key) {
                    const cbu = await Connection("/getClubByUser", {
                      session_key: window.localStorage.session_key,
                    });
                    if (!cbu.club.length) {
                      window.location.href = "/no_club/manage";
                    } else {
                      const club_id = cbu.club[0].id;
                      window.location.href = "/" + club_id + "/manage";
                    }
                  } else {
                    alert("로그인 후 이용해주세요!");
                    setLoginOpen(true);
                  }
                }
              }}
            >
              바로 시작하기
            </button>
          ) : (
            <button
              className="main-orange-button button-mainm-section-08 Sub2M"
              onClick={async () => {
                localStorage.setItem("session_key", "admin_demo");
                localStorage.setItem("name", "동들 관리자");
                localStorage.setItem("phone", "01012345678");
                window.location.href =
                  "/986cccae-6181-45a5-a4cb-47c1e1065c7d/manage";
              }}
            >
              동들 체험하기
            </button>
          )}
        </div>
        <FooterM />
      </div>
    </>
  );
}

export default MainM;
