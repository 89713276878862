import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import "../../asset/css/ManageM.css";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Reset from "../../asset/icon/Icon_Reset.svg";

import Connection from "../Connection";
import ViewImages from "../ViewImages";
import RecruitPage1M from "../RecruitPage1M";
import RecruitPage4M from "../RecruitPage4M";
import RecruitPage6M from "../RecruitPage6M";
import FirstTable from "./FirstTable";

export default function MainFlow({ ...props }) {
  const session_key = localStorage.getItem("session_key");
  const param = window.location.href.split("/");
  const checkedClub = param[param.length - 2];
  const checkedMozip = param[param.length - 1];
  const [viewImageModal, setViewImageModal] = useState(false);
  const [recruitState, setRecruitState] = useState();
  const [messagePage, setMessagePage] = useState(0); // 1: 최초 탈락자, 2: 면접 공지, 3: 최종 공지
  const [recruitInfo, setRecruitInfo] = useState({});

  useEffect(() => {
    async function getRecruitInfo() {
      const res = await Connection("/getRecruitInfo", {
        club_id: checkedClub,
        recruit_id: checkedMozip,
      });
      setRecruitInfo(res);
      setRecruitState(res.state);
    }
    getRecruitInfo();
  }, []);

  return (
    <>
      <Header>
        <section>
          <img
            src={Icon_ArrowLeft}
            onClick={() => {
              props.setPage("recruitlist");
              window.history.pushState({}, "", `/${checkedClub}/recruitlist`);
            }}
          />

          <div style={{ width: "24px", height: "24px" }} />
        </section>
        <section className="Sub2M">
          🙌 지원자 현황
          <ResetButton className="Tag2M">
            <img src={Icon_Reset} alt="" />
            새로고침
          </ResetButton>
        </section>
      </Header>
      {messagePage === 0 ? (
        <div className="div-manageM-section">
          <div className="div-ManageM-section">
            <>
              {recruitState === 1 && (
                <RecruitPage1M
                  club_id={checkedClub}
                  recruit_id={checkedMozip}
                />
                // <FirstTable clubId={checkedClub} recruitId={checkedMozip} />
              )}
              {recruitState === 2 && (
                <div
                  className="div-form-question-section H3M"
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div className="H3M" style={{ marginBottom: "90px" }}>
                    면접 타임테이블 설정이 필요합니다. <br />
                    PC를 이용해주세요🙂
                  </div>
                </div>
              )}
              {recruitState === 4 && (
                <RecruitPage4M
                  club_id={checkedClub}
                  recruit_id={checkedMozip}
                />
              )}
              {recruitState === 6 && (
                <RecruitPage6M
                  club_id={checkedClub}
                  recruit_id={checkedMozip}
                  recruitInfo={recruitInfo}
                />
              )}
              {viewImageModal && (
                <ViewImages setModalOpen={setViewImageModal} />
              )}
            </>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

const Header = styled.div`
  > section:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    height: 48px;
    padding: 12px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    > span {
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
    > *:first-child() {
      flex-shrink: 0;
    }
    > *:first-child() {
      flex-shrink: 0;
    }
  }
  > section:last-child {
    padding: 12px;
    display: flex;
    gap: 12px;
    align-items: center;
  }
`;
const ResetButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #b4b4b4;
  > img {
    width: 24px;
    height: 24px;
  }
`;
