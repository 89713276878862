import React, { useState, useEffect } from "react";
import "swiper/css";
import "../asset/css/timePicker.css";
import { Swiper, SwiperSlide } from "swiper/react";

export default function TimePicker({ questionNum, answer, time, setAnswer }) {
  const [AmPm, setAmPm] = useState("오전");
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);

  const hours = Array(12)
    .fill()
    .map((v, i) => (v = i + 1));
  const minutes = Array(60)
    .fill()
    .map((v, i) => (v = i));

  useEffect(() => {
    setAnswer(
      answer.map((a, idx) =>
        idx === questionNum
          ? {
              ...a,
              value: `${AmPm} ${hour}시 ${String(minute).padStart(2, "0")}분`,
            }
          : a
      )
    );
  }, [AmPm, hour, minute]);

  return (
    <div className="timepicker-wrapper Sub2M cursor-default">
      <div className="timepicker-middlebox" />
      <Swiper
        className="timepicker-column1"
        direction={"vertical"}
        slidesPerView={5}
        slideToClickedSlide={true}
        centeredSlides={true}
        onSlideChange={(swiper) => {
          setAmPm(swiper.realIndex ? "오후" : "오전");
        }}
        initialSlide={time.split(" ")[0] === "오후" ? "1" : "0"}
      >
        <SwiperSlide className="cursor-pointer">
          {({ isActive }) => (
            <div
              className={
                "timepicker-value Sub2M " + (isActive ? "text-black" : "")
              }
            >
              오전
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className="cursor-pointer">
          {({ isActive }) => (
            <div
              className={
                "timepicker-value Sub2M " + (isActive ? "text-black" : "")
              }
            >
              오후
            </div>
          )}
        </SwiperSlide>
      </Swiper>
      <div className="timepicker-columns">
        <Swiper
          className="timepicker-column2"
          direction={"vertical"}
          slidesPerView={5}
          // loop={true}
          loopAdditionalSlides={36}
          slideToClickedSlide={true}
          centeredSlides={true}
          onSlideChange={(swiper) => {
            setHour(swiper.realIndex + 1);
          }}
          initialSlide={String(
            hours.indexOf(parseInt(time.split(" ")[1].slice(0, -1)))
          )}
        >
          {hours.map((no) => (
            <SwiperSlide key={no} className="cursor-pointer">
              {({ isActive }) => (
                <div
                  style={{ lineHeight: "22px" }}
                  className={
                    "timepicker-value Sub2M " + (isActive ? "text-black" : "")
                  }
                >
                  {no}
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <span className="Sub2M timepicker-word">시</span>
      </div>
      <div className="timepicker-columns">
        <Swiper
          className="timepicker-column2"
          direction={"vertical"}
          slidesPerView={5}
          // loop={true}
          loopAdditionalSlides={60}
          slideToClickedSlide={true}
          centeredSlides={true}
          onSlideChange={(swiper) => {
            setMinute(swiper.realIndex);
          }}
          initialSlide={String(
            minutes.indexOf(parseInt(time.split(" ")[2].slice(0, -1)))
          )}
        >
          {minutes.map((no) => (
            <SwiperSlide key={no} className="cursor-pointer">
              {({ isActive }) => (
                <div
                  style={{ lineHeight: "22px" }}
                  className={
                    "timepicker-value Sub2M " + (isActive ? "text-black" : "")
                  }
                >
                  {String(no).padStart(2, "0")}
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <span className="Sub2M timepicker-word">분</span>
      </div>
    </div>
  );
}
