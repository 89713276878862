import React, { useEffect, useState } from "react";
import CommunityConnection from "./community/CommunityConnection";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import logo from "../asset/header/logo.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "지원자 수",
    },
  },
};
function Macle() {
  const [dongdleData, setDongdleData] = useState();
  const [labels, setLabels] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [recruits, setRecruits] = useState([]);

  async function getDongdleData() {
    const res = await CommunityConnection("/getDongdleData");
    if (res.is_success) {
      setDongdleData(res.data);
    }
  }
  async function getMonthlyData() {
    const res = await CommunityConnection("/getMonthlyData");
    if (res.is_success) {
      let arr = [];
      res.data.forEach((d) => {
        if (arr.length > 0) arr.push(arr[arr.length - 1] + d.applicant_cnt);
        else arr.push(d.applicant_cnt);
      });
      setApplicants(arr);
      arr = [];
      res.data.forEach((d) => {
        if (arr.length > 0) arr.push(arr[arr.length - 1] + d.recruit_cnt);
        else arr.push(d.recruit_cnt);
      });
      setRecruits(arr);
      arr = [];
      res.data.forEach((d) => {
        arr.push(d.recruit_year + "-" + d.recruit_month);
      });
      setLabels(arr);
    }
  }
  useEffect(() => {
    getDongdleData();
    getMonthlyData();
  }, []);

  const applicant_data = {
    labels,
    datasets: [
      {
        label: "누적 지원자 수",
        data: applicants?.map((a) => a),
        borderColor: "#ff7a00",
        backgroundColor: "rgba(255, 122, 0, 0.8)",
      },
    ],
  };

  const recruit_data = {
    labels,
    datasets: [
      {
        label: "누적 모집 횟수",
        data: recruits?.map((r) => r),
        borderColor: "#1e1e1e",
        backgroundColor: "#4c4c4c",
      },
    ],
  };

  return (
    <>
      <div className="div-header-section">
        <div className="div-header-section-01">
          <img
            className="div-click img-header-logo"
            src={logo}
            alt=""
            onClick={() => {
              if (localStorage.getItem("session_key") === "admin_demo") {
                localStorage.clear();
              }
              window.location.href = "/";
            }}
          />
        </div>
      </div>
      <div className="div-macle-background">
        <div className="div-macle-table-section">
          <div className="div-macle-table">
            <table className="table-macle">
              <thead>
                <td>동아리명</td>
                <td>지원자 수</td>
                <td>모집 횟수</td>
              </thead>
              {dongdleData?.map((club) => (
                <tr>
                  <td>{club.name}</td>
                  <td>{club.applicant_cnt}</td>
                  <td>{club.recruit_cnt}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        {labels.length > 0 && (
          <div
            className="div-macle-table-section Sub1"
            style={{ width: "65%" }}
          >
            누적 지원자 수
            <div className="div-macle-graph" style={{ paddingBottom: "18px" }}>
              <Line options={options} data={applicant_data} />
            </div>
            누적 모집 횟수
            <div className="div-macle-graph">
              <Line options={options} data={recruit_data} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Macle;
