import React, { useEffect, useState, useRef } from "react";

import "../asset/css/timePicker.css";
import "../asset/css/FormDrink.css";
import "../asset/css/FormTest.css";
import "../asset/css/FormCheck.css";

import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import Icon_Search from "../asset/icon/Icon_Search.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import CheckBox from "../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../asset/icon/CheckBox_Checked.svg";
import Icon_EmptyGlass from "../asset/form/Icon_EmptyGlass.svg";
import Icon_HalfBottle from "../asset/form/Icon_HalfBottle.svg";
import Icon_OneGlass from "../asset/form/Icon_OneGlass.svg";
import Icon_OneBottle from "../asset/form/Icon_OneBottle.svg";

import CalendarQ from "./CalendarQ";
import TimePicker from "./TimePicker";
import Connection from "./Connection";
import { Slider } from "@mui/material";
import { useDebounce } from "../customhooks/useDebounce";

function Question({
  questionNum,
  question,
  answer,
  setAnswer,
  fileList,
  setFileList,
}) {
  const [imageFile, setImageFile] = useState(null);
  const [showThumb, setShowThumb] = useState(false);
  const fileInputRef = useRef(null);
  const [value, setValue] = useState("10:00");
  const onChange = (timeValue) => {
    setValue(timeValue);
  };

  // type05 지역 선택
  const [dropdown5, setDropdown5] = useState(false);
  const [dong, setDong] = useState([]);
  const [search, setSearch] = useState(answer[questionNum].value);
  const debouncedSearch = useDebounce(search, 300);
  const [searchedDong, setSearchedDong] = useState([]);
  async function getDong() {
    const d = await Connection("/getDong", {});
    d.is_success && setDong(d.data);
  }

  useEffect(() => {
    getDong();
  }, []);

  useEffect(() => {
    const newDong = dong?.filter((d) => d.includes(debouncedSearch));
    setSearchedDong(newDong);
    console.log("search!!!");
  }, [debouncedSearch]);

  const showType05 = (
    <div className="div-question-13-section">
      <div className="div-question-13-dropdown Body4M">
        <div className="div-question-05-dropdown Body4M">
          <img src={Icon_Search} alt="" style={{ width: "20px" }} />
          <input
            className="div-question-13-dropdown-box Body4M"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setDropdown5(true);
              if (e.target.value === "") setDropdown5(false);
            }}
            placeholder="동 이름으로 검색하세요!"
          />
        </div>
      </div>
      {search !== "" && (
        <div className="div-question-05-sub Tag1M">{`'(${search})' 검색 결과`}</div>
      )}
      {dropdown5 && (
        <div className="div-question-13-dropdown-scroll">
          {searchedDong.length > 0 &&
            searchedDong.map((d, d_idx) => (
              <button
                className="button-question-05 Body4M"
                onClick={() => {
                  setDropdown5(false);
                  setAnswer(
                    answer.map((a, idx) =>
                      idx === questionNum ? { ...a, value: d } : a
                    )
                  );
                  setSearch(d);
                }}
                key={d_idx}
              >
                {d}
              </button>
            ))}
        </div>
      )}
    </div>
  );

  // type07 단답형
  const showType07 = (
    <input
      className="input-question-07-section Body4M"
      placeholder="답변을 입력해주세요."
      value={answer[questionNum].value}
      onChange={(e) => {
        setAnswer(
          answer.map((a, idx) =>
            idx === questionNum ? { ...a, value: e.target.value } : a
          )
        );
      }}
    />
  );

  // type08 장문형
  const showType08 = (
    <textarea
      className="textarea-question-01-section Body4M"
      placeholder="내용을 입력해주세요."
      value={answer[questionNum].value}
      onChange={(e) => {
        setAnswer(
          answer.map((a, idx) =>
            idx === questionNum ? { ...a, value: e.target.value } : a
          )
        );
      }}
    />
  );

  // type09 객관식
  // const [answer, setAnswer] = useState(question.option);+
  const [selectedAnswer, setSelectedAnswer] = useState(
    answer[questionNum].option_idx
  );

  const [selectedMultiAnswer, setSelectedMultiAnswer] = useState(
    answer[questionNum].multi_idx
  );
  useEffect(() => {
    selectedAnswer !== -1 &&
      setAnswer(
        answer.map((a, a_idx) =>
          a_idx === questionNum
            ? {
                ...a,
                value: [question.option[selectedAnswer].a],
                option_idx: selectedAnswer,
              }
            : a
        )
      );
  }, [selectedAnswer]);
  useEffect(() => {
    selectedMultiAnswer.length > 0 &&
      setAnswer(
        answer.map((a, a_idx) =>
          a_idx === questionNum
            ? {
                ...a,
                value: selectedMultiAnswer.map((s) => question.option[s].a),
                multi_idx: selectedMultiAnswer,
              }
            : a
        )
      );
  }, [selectedMultiAnswer]);

  const showType09 = (
    <>
      <div className="div-question-09-section-top Sub3M">
        {question.multi && "(중복선택이 가능해요)"}
      </div>
      <div
        className={
          question.option && question.option.length > 5
            ? question.option.length > 10
              ? "div-question-09-section-03"
              : "div-question-09-section-02"
            : "div-question-09-section-01"
        }
        style={{ marginTop: question.multi && "30px" }}
      >
        {question.option &&
          question.option.map((o, idx) => (
            <button
              className={
                question.option.length > 5
                  ? question.option.length > 10
                    ? "button-question-09-03 Sub3M"
                    : "button-question-09-02 Sub3M"
                  : "button-question-09-01 Sub3M"
              }
              onClick={() => {
                question.multi
                  ? selectedMultiAnswer.indexOf(idx) === -1
                    ? setSelectedMultiAnswer([...selectedMultiAnswer, idx])
                    : setSelectedMultiAnswer(
                        selectedMultiAnswer.filter((m) => m !== idx)
                      )
                  : setSelectedAnswer(idx);
              }}
              style={{
                backgroundColor: question.multi
                  ? selectedMultiAnswer.indexOf(idx) !== -1 && "black"
                  : selectedAnswer === idx && "black",
                color: question.multi
                  ? selectedMultiAnswer.indexOf(idx) !== -1 && "white"
                  : selectedAnswer === idx && "white",
              }}
              key={idx}
            >
              {o.a}
            </button>
          ))}
      </div>
    </>
  );

  // type10 달력형
  const showType10 = (
    <div className="div-question-10-section">
      <CalendarQ
        questionNum={questionNum}
        question={question}
        answer={answer}
        setAnswer={setAnswer}
      />
    </div>
  );

  // type13 드롭다운
  const [dropdown13, setDropdown13] = useState(false);
  const [checked13, setChecked13] = useState(0);
  const showType13 = (
    <div className="div-question-13-section">
      <div
        className="div-question-13-dropdown Body4M"
        style={{ borderColor: dropdown13 && "black" }}
      >
        <div
          className="div-question-13-dropdown-box Body4M"
          style={{
            borderBottom: dropdown13 && "1px solid #cdcdcd",
          }}
          onClick={() => setDropdown13(!dropdown13)}
        >
          {question.type === 13 && question.option[checked13].a}
          {dropdown13 ? (
            <img className="iconM" src={Icon_ArrowUp} alt="" />
          ) : (
            <img className="iconM" src={Icon_ArrowDown} alt="" />
          )}
        </div>
        {dropdown13 && (
          <div className="div-question-13-dropdown-scroll">
            {question.option.map((o, idx) => (
              <button
                className="button-question-13 Body4M"
                style={{
                  color: checked13 === idx && "#FF7A00",
                }}
                onClick={() => {
                  setChecked13(idx);
                  setDropdown13(false);
                  setAnswer(
                    answer.map((a, a_idx) =>
                      a_idx === questionNum
                        ? {
                            ...a,
                            value: [o.a],
                          }
                        : a
                    )
                  );
                }}
              >
                {o.a}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const handleClickFileInput = () => {
    fileInputRef.current?.click();
  };
  const uploadProfile = (e) => {
    const file = e.target.files;
    if (file && file[0]) {
      if (file[0].size <= 10 * 1024 * 1024) {
        const url = URL.createObjectURL(file[0]);
        setImageFile(file[0]);
        setFileList([
          ...fileList,
          {
            qNum: question.id,
            file: file[0],
          },
        ]);
      } else {
        alert("10MB 이하의 파일만 첨부할 수 있습니다.");
      }
    }
    setAnswer(
      answer.map((a, a_idx) =>
        a_idx === questionNum
          ? {
              ...a,
              value: file[0].name,
            }
          : a
      )
    );
  };
  const deleteProfile = () => {
    setFileList((prevList) => prevList.filter((f) => f.qNum != question.id));
  };

  //type14 파일 업로드형
  const showType14 = (
    <form className="form-question-19">
      <input
        type="button"
        className="button-question-19 Sub3M"
        onClick={handleClickFileInput}
        value="파일 추가하기"
      />
      <div className="form-question-19-fileName">
        {fileList.filter((f) => f.qNum === question.id).length > 0 ? (
          <>
            <p className="-Tag1">
              {fileList.filter((f) => f.qNum === question.id)[0].file.name}
            </p>
            <img src={Icon_Close} alt="" onClick={deleteProfile} />
          </>
        ) : (
          <></>
        )}
      </div>
      <input type="file" ref={fileInputRef} onChange={uploadProfile} />
    </form>
  );

  // type18 시간형
  const showType18 = (
    <div className="form-question-18">
      <TimePicker
        questionNum={questionNum}
        answer={answer}
        time={
          answer[questionNum].value === ""
            ? "오전 0시 00분"
            : answer[questionNum].value
        }
        setAnswer={setAnswer}
      />
    </div>
  );

  // type19 사진 업로드형
  const showType19 = (
    <form className="form-question-19">
      <input
        type="button"
        className="button-question-19 Sub3M"
        onClick={handleClickFileInput}
        value="사진 추가하기"
      />
      <input
        type="file"
        accept="image/jpg, image/jpeg, image/png"
        ref={fileInputRef}
        onChange={uploadProfile}
      />
      <div className="form-question-19-fileName">
        {fileList.filter((f) => f.qNum === question.id).length > 0 ? (
          <>
            <p className="-Tag1">
              {fileList.filter((f) => f.qNum === question.id)[0].file.name}
            </p>
            <img src={Icon_Close} alt="" onClick={deleteProfile} />
          </>
        ) : (
          <></>
        )}
      </div>
      {fileList.filter((f) => f.qNum === question.id).length > 0 && (
        <img
          id="thumb"
          src={URL.createObjectURL(
            fileList.filter((f) => f.qNum === question.id)[0].file
          )}
          alt=""
        />
      )}
    </form>
  );

  useEffect(() => {
    setImageFile(null);
    setShowThumb(false);
  }, [question]);

  // 주량
  const drinkInfo = [
    { idx: 0, value: "0", type: "잔", a: "0잔" },
    { idx: 1, value: "1", type: "잔", a: "1잔" },
    { idx: 2, value: "2", type: "잔", a: "2잔" },
    { idx: 3, value: "반", type: "병", a: "반병" },
    { idx: 4, value: "1", type: "병", a: "1병" },
    { idx: 5, value: "1", type: "병 반", a: "1병 반" },
    { idx: 6, value: "2", type: "병", a: "2병" },
    { idx: 7, value: "2", type: "병 반", a: "2병 반" },
    { idx: 8, value: "3", type: "병", a: "3병" },
    { idx: 9, value: "3", type: "병 반", a: "3병 반" },
    { idx: 10, value: "4", type: "병 이상", a: "4병 이상" },
  ];

  const [drink, setDrink] = useState(
    question.type === 20 && answer[questionNum].value !== ""
      ? drinkInfo.filter((d) => d.a === answer[questionNum].value)[0].idx
      : 3
  ); // 슬라이더 값

  const [drinkState, setDrinkState] = useState("잔"); // 표시되는 문장
  const handleDrink = (e, v) => {
    setDrink(v);
  };

  const showIcons = (drink) => {
    let iconArray = [];
    const icons =
      drinkInfo[drink].type === "잔" ? (
        drinkInfo[drink].value === "0" ? (
          <img src={Icon_EmptyGlass} alt="" className="img-form-drink-glass" />
        ) : (
          <img src={Icon_OneGlass} alt="" className="img-form-drink-glass" />
        )
      ) : (
        <img src={Icon_OneBottle} alt="" className="img-form-drink-bottle" />
      );
    for (let i = 0; i < Number(drinkInfo[drink].value); i++) {
      iconArray.push(i);
    }
    if (Number(drinkInfo[drink].value) === 0) iconArray.push(0);
    return (
      <>
        {iconArray.map(() => icons)}
        {drinkInfo[drink].type === "병 반" ||
        drinkInfo[drink].value === "반" ? (
          <img src={Icon_HalfBottle} alt="" className="img-form-drink-bottle" />
        ) : (
          <></>
        )}
      </>
    );
  };
  useEffect(() => {
    question.type === 20 &&
      setAnswer(
        answer.map((a, idx) =>
          idx === questionNum
            ? { ...a, value: drinkInfo[drink].value + drinkInfo[drink].type }
            : a
        )
      );
    setDrinkState(drinkInfo[drink].value + drinkInfo[drink].type);
  }, [drink]);
  const showType20 = (
    <div className="div-form-drink-container">
      <div className="div-form-drink-icons">{showIcons(drink)}</div>
      <p className="Sub3M">{drinkState}</p>
      <div style={{ padding: "0px 10px", overflow: "hidden" }}>
        <Slider
          value={drink}
          min={0}
          max={10}
          onChange={handleDrink}
          sx={{ color: "#FF7A00" }}
        />
      </div>
    </div>
  );

  // MBTI
  const [ei, setEi] = useState(
    question.type === 21 && answer[questionNum].value === ""
      ? null
      : answer[questionNum].value[0]
  );
  const [sn, setSn] = useState(
    question.type === 21 && answer[questionNum].value === ""
      ? null
      : answer[questionNum].value[1]
  );
  const [tf, setTf] = useState(
    question.type === 21 && answer[questionNum].value === ""
      ? null
      : answer[questionNum].value[2]
  );
  const [jp, setJp] = useState(
    question.type === 21 && answer[questionNum].value === ""
      ? null
      : answer[questionNum].value[3]
  );
  useEffect(() => {
    question.type === 21 &&
      ei &&
      sn &&
      tf &&
      jp &&
      setAnswer(
        answer.map((a, idx) =>
          idx === questionNum ? { ...a, value: ei + sn + tf + jp } : a
        )
      );
  }, [ei, sn, tf, jp]);
  const showType21 = (
    <div className="div-question-mbti-section">
      <div className="div-question-mbti-row">
        <div
          className={`div-question-mbti-cell ${
            ei === "E" ? "mbti-cell-selectedE" : "mbti-cell-default"
          }`}
          onClick={() => setEi("E")}
        >
          <span className="Sub3M">외향(E)</span>
          <span className="Tag1M">단체,개방</span>
        </div>
        <div
          className={`div-question-mbti-cell ${
            ei === "I" ? "mbti-cell-selectedI" : "mbti-cell-default"
          }`}
          onClick={() => setEi("I")}
        >
          <span className="Sub3M">내향(I)</span>
          <span className="Tag1M">개인,내면</span>
        </div>
      </div>
      <div className="div-question-mbti-row">
        <div
          className={`div-question-mbti-cell ${
            sn === "S" ? "mbti-cell-selectedS" : "mbti-cell-default"
          }`}
          onClick={() => setSn("S")}
        >
          <span className="Sub3M">감각(S)</span>
          <span className="Tag1M">현실,경험,정확</span>
        </div>
        <div
          className={`div-question-mbti-cell ${
            sn === "N" ? "mbti-cell-selectedN" : "mbti-cell-default"
          }`}
          onClick={() => setSn("N")}
        >
          <span className="Sub3M">직관(N)</span>
          <span className="Tag1M">미래,가능성,신속</span>
        </div>
      </div>
      <div className="div-question-mbti-row">
        <div
          className={`div-question-mbti-cell ${
            tf === "T" ? "mbti-cell-selectedT" : "mbti-cell-default"
          }`}
          onClick={() => setTf("T")}
        >
          <span className="Sub3M">사고(T)</span>
          <span className="Tag1M">원칙,논리</span>
        </div>
        <div
          className={`div-question-mbti-cell ${
            tf === "F" ? "mbti-cell-selectedF" : "mbti-cell-default"
          }`}
          onClick={() => setTf("F")}
        >
          <span className="Sub3M">감정(F)</span>
          <span className="Tag1M">관계,협조</span>
        </div>
      </div>
      <div className="div-question-mbti-row">
        <div
          className={`div-question-mbti-cell ${
            jp === "J" ? "mbti-cell-selectedJ" : "mbti-cell-default"
          }`}
          onClick={() => setJp("J")}
        >
          <span className="Sub3M">판단(J)</span>
          <span className="Tag1M">계획,목적성</span>
        </div>
        <div
          className={`div-question-mbti-cell ${
            jp === "P" ? "mbti-cell-selectedP" : "mbti-cell-default"
          }`}
          onClick={() => setJp("P")}
        >
          <span className="Sub3M">인식(P)</span>
          <span className="Tag1M">자율,융통성</span>
        </div>
      </div>
    </div>
  );

  // 약관동의
  const [check, setCheck] = useState(
    answer[questionNum].value === "" ? false : true
  );
  const showType22 = (
    <div className="div-question-policy-container">
      <pre className="pre-question-policy-request Body4M">{question.q}</pre>
      <div
        className="div-click div-edit-section-checkbox"
        onClick={(e) => {
          setCheck(!check);
          check
            ? setAnswer(
                answer.map((a, idx) =>
                  idx === questionNum ? { ...a, value: "" } : a
                )
              )
            : setAnswer(
                answer.map((a, idx) =>
                  idx === questionNum ? { ...a, value: "확인" } : a
                )
              );
        }}
      >
        <img
          src={check ? CheckBox_Checked : CheckBox}
          alt=""
          style={{ width: "18px" }}
        />
        <p className="Body4M">위 내용을 확인하였습니다.</p>
      </div>
    </div>
  );

  return (
    <>
      {question.type === 5 && showType05}
      {question.type === 7 && showType07}
      {question.type === 8 && showType08}
      {question.type === 9 && showType09}
      {question.type === 10 && showType10}
      {question.type === 13 && showType13}
      {question.type === 14 && showType14}
      {question.type === 18 && showType18}
      {question.type === 19 && showType19}
      {question.type === 20 && showType20}
      {question.type === 21 && showType21}
      {question.type === 22 && showType22}
    </>
  );
}

export default Question;
