import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";

import Icon_ArrowDown from "../../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../../asset/icon/Icon_ArrowUp.svg";

import ChipMessage from "../ChipMessage";

function Dropdown({ list, currentIndex, setCurrentIndex, chipStateList }) {
  const [isOpen, setIsOpen] = useState(false);

  const dropDownRef = useRef(null);
  useEffect(() => {
    const handleOnClickOutside = (e) => {
      if (!dropDownRef.current.contains(e.target)) {
        setIsOpen(false); // 창 닫기
      }
    };
    document.addEventListener("click", handleOnClickOutside);
    return () => {
      document.removeEventListener("click", handleOnClickOutside);
    };
  }, []);

  return (
    <>
      <DropdownBox
        isOpen={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
        ref={dropDownRef}
      >
        <DropdownHeader isOpen={isOpen}>
          <ContentsWrapper>
            <span>{list[currentIndex]}</span>
            <ChipMessage font="Body5M" state={chipStateList[currentIndex]} />
          </ContentsWrapper>
          <img src={isOpen ? Icon_ArrowUp : Icon_ArrowDown} alt="" />
        </DropdownHeader>
        {isOpen &&
          list.map((value, idx) => (
            <DropdownList
              isSelected={idx === currentIndex}
              onClick={() => setCurrentIndex(idx)}
            >
              <ContentsWrapper>
                <span>{value}</span>
                <ChipMessage font="Body5M" state={chipStateList[idx]} />
              </ContentsWrapper>
            </DropdownList>
          ))}
      </DropdownBox>
    </>
  );
}

const DropdownBox = styled.div.attrs({
  className: "Body5M",
})`
  position: absolute;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  ${(props) => props.isOpen && "border-color: black;"}
  >div {
    width: 100%;
    padding: 10px 16px;
  }
  > div:first-child {
    ${(props) =>
      props.isOpen ? "border-radius: 8px 8px 0 0;" : "border-radius: 8px;"}
  }
  > div:last-child {
    ${(props) => props.isOpen && "border-radius: 0 0 8px 8px;"}
  }
  cursor: pointer;
  user-select: none;
`;
const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  background-color: white;
  ${(props) => props.isOpen && "border-bottom: 1px solid #CDCDCD;"}
`;
const DropdownList = styled.div`
  ${(props) =>
    props.isSelected
      ? "background-color: #ececec;"
      : "background-color: white;"}
`;
const ContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: calc(100% - 44px);
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default Dropdown;
