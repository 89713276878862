import React from "react";
import styled from "styled-components/macro";

import Logo from "../../asset/header/logo.svg";
import Icon_Close from "../../asset/icon/Icon_Close.svg";
import LoginIllustration from "../../asset/login/LoginIllustration.svg";
import Button_KakaoLogin from "../../asset/login/Button_KakaoLogin.svg";

LoginPage.defaultProps = {
  hideLogo: false,
};

export default function LoginPage({ ...props }) {
  return (
    <>
      <Container>
        <Header>
          {props.hideLogo ? (
            <div />
          ) : (
            <img
              src={Logo}
              alt=""
              onClick={() => (window.location.href = "/")}
            />
          )}
          <img src={Icon_Close} alt="" onClick={() => props.setPage(false)} />
        </Header>
        <InroduceStatement className="Sub3M">
          안녕하세요,
          <br />
          동아리의 모든 것, 동들입니다
        </InroduceStatement>
        <ImgLogin src={LoginIllustration} alt="" />
        <ImgLoginButton
          src={Button_KakaoLogin}
          alt=""
          onClick={() => {
            window.Kakao.Auth.authorize({
              redirectUri:
                window.location.protocol +
                "//" +
                window.location.host +
                "/kakaologin",
            });
          }}
        />
      </Container>
    </>
  );
}
const Container = styled.div`
  min-width: 280px;
  /* max-width: 480px; */
  /* width: 100%;
  height: 100%; */
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
  text-align: center;
`;
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  > img:first-child {
    // width: 90px;
    height: 20px;
    cursor: pointer;
  }
  > img:last-child {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
const InroduceStatement = styled.p`
  // margin-top: 72px;
  width: 100%;
  padding: 0px 12px;
  text-align: center;
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
`;
const ImgLogin = styled.img`
  margin-top: 40px;
  width: 100%;
  height: auto;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
`;
const ImgLoginButton = styled.img`
  // margin-top: 68px;
  cursor: pointer;
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
`;
