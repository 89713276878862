import React from "react";

function ChipStatusDocs({ state }) {
  if (state === 0)
    return (
      <button className="button-chip button-chip-status-0 Body5">지원</button>
    );
  else {
    switch (state) {
      case 1:
        return (
          <button className="button-chip button-chip-status-1 Body5">
            합격
          </button>
        );
      case 2:
        return (
          <button className="button-chip button-chip-status-2 Body5">
            불합격
          </button>
        );
      default:
    }
  }
}

export default React.memo(ChipStatusDocs);
