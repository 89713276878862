import React, { useEffect } from "react";
import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Share from "../../asset/icon/Icon_Share.svg";
import Icon_View from "../../asset/icon/Icon_View_6A6A6A.svg";
import Icon_Comment from "../../asset/icon/Icon_Comment_6A6A6A.svg";
import Icon_Edit_White from "../../asset/icon/Icon_Edit_White.svg";
import Icon_Delete_6A6A6A from "../../asset/icon/Icon_Delete_6A6A6A.svg";
import Icon_ReplyReply from "../../asset/icon/Icon_ReplyReply.svg";
import Icon_Thumbsup_Orange from "../../asset/icon/Icon_Thumbsup_Orange.svg";
import Icon_Thumbsdown_Orange from "../../asset/icon/Icon_Thumbsdown_Orange.svg";
import Icon_Thumbsup_White from "../../asset/icon/Icon_Thumbsup_White.svg";
import Icon_Thumbsdown_White from "../../asset/icon/Icon_Thumbsdown_White.svg";
import "../../asset/css/community/Article.css";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import dayjs from "dayjs";
import CommunityConnection from "./CommunityConnection";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";

function Article({ setIsOpen, articleInfo }) {
  const [article, setArticle] = useState(articleInfo);
  const [thumbs, setThumbs] = useState(null);
  const session_key = window.localStorage.getItem("session_key");
  const param_path = window.location.pathname.split("/");
  const [likeCnt, setLikeCnt] = useState(0);
  const [dislikeCnt, setDislikeCnt] = useState(0);
  const [articleId, setArticleId] = useState(
    parseInt(param_path[param_path.length - 1])
  );
  async function getArticle() {
    const res = await CommunityConnection("/getArticle", {
      article_id: articleId,
      club_id: param_path[3],
    });
    if (res.is_success) {
      setArticle(res.article);
    }
  }
  const [images, setImages] = useState();
  async function getImages() {
    const res = await CommunityConnection("/getImages", {
      article_id: articleId,
      club_id: param_path[3],
    });
    if (res.is_success) {
      setImages(res.images);
    }
  }
  useEffect(() => {
    if (!articleInfo) {
      getArticle();
    }
    getImages();
  }, []);

  function updateThumbs(type) {
    if (type === 1) {
      if (thumbs === 1) {
        setLikeCnt(likeCnt - 1);
        setThumbs(null);
        updateLike(null);
      } else if (thumbs === 2) {
        setLikeCnt(likeCnt + 1);
        setDislikeCnt(dislikeCnt - 1);
        setThumbs(1);
        updateLike(1);
      } else {
        setLikeCnt(likeCnt + 1);
        setThumbs(1);
        updateLike(1);
      }
    } else {
      if (thumbs === 2) {
        setDislikeCnt(dislikeCnt - 1);
        setThumbs(null);
        updateLike(null);
      } else if (thumbs === 1) {
        setLikeCnt(likeCnt - 1);
        setDislikeCnt(dislikeCnt + 1);
        setThumbs(2);
        updateLike(2);
      } else {
        setDislikeCnt(dislikeCnt + 1);
        setThumbs(2);
        updateLike(2);
      }
    }
  }

  async function getLikeCnt() {
    const res = await CommunityConnection("/getLikeCnt", {
      article_id: articleId,
    });
    if (res.is_success) {
      let like_cnt = 0;
      let dislike_cnt = 0;
      res.like_cnt.length > 0 &&
        res.like_cnt.map(async ({ type }, idx) => {
          type === 1 ? like_cnt++ : dislike_cnt++;
        });

      setLikeCnt(like_cnt);
      setDislikeCnt(dislike_cnt);
    }
  }

  const [likeCheck, setLikeCheck] = useState(0);
  async function getLike() {
    const res = await CommunityConnection("/getLike", {
      article_id: articleId,
    });
    if (res.is_success) setLikeCheck(res.like);
  }

  async function updateLike(type) {
    const res = await CommunityConnection("/updateLike", {
      article_id: articleId,
      type: type,
    });
  }

  const [reply, setReply] = useState();
  async function getReply() {
    const res = await CommunityConnection("/getReply", {
      article_id: articleId,
      club_id: param_path[3],
    });
    if (res.is_success) {
      setReply(res.reply);
    }
  }

  const [isMyArticle, setIsMyArticle] = useState();
  async function checkMyArticle() {
    const res = await CommunityConnection("/checkMyArticle", {
      id: articleId,
    });
    if (res.is_success) {
      setIsMyArticle(res.my_article);
    }
  }
  async function deleteArticle() {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      const res = await CommunityConnection("/deleteArticle", {
        id: articleId,
      });
      if (res.is_success) {
        setIsMyArticle(res.my_article);
        setIsOpen(false);
      }
    }
  }

  const [allBoardInfo, setAllBoardInfo] = useState();
  async function getAllBoardInfo() {
    const res = await CommunityConnection("/getAllBoardInfo");
    if (res.is_success) {
      setAllBoardInfo(res.boardInfo);
    }
  }
  const [allClubInfo, setAllClubInfo] = useState();
  async function getAllClubInfo() {
    const res = await CommunityConnection("/getAllClubInfo");
    if (res.is_success) {
      setAllClubInfo(res.clubInfo);
    }
  }

  useEffect(() => {
    getAllBoardInfo();
    getAllClubInfo();
    getLike();
    getLikeCnt();
    getReply();
    checkMyArticle();
  }, []);

  useEffect(() => {
    setThumbs(likeCheck?.type);
  }, [likeCheck]);

  const [isAnonymous, setIsAnonymous] = useState(1);
  const [content, setContent] = useState("");
  async function writeReply() {
    if (content !== "") {
      const res = await CommunityConnection("/writeReply", {
        club_id: param_path[3],
        article_id: articleId,
        is_anonymous: isAnonymous,
        content: content,
      });
      if (res.is_success) {
        setContent("");
        getReply();
      }
    }
  }

  async function deleteReply(id) {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      const res = await CommunityConnection("/deleteReply", {
        id: id,
      });
      if (res.is_success) {
        getReply();
      }
    }
  }

  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("클립보드에 되었습니다.");
    } catch (e) {
      alert("복사에 실패하였습니다");
    }
  };

  return (
    <div className="div-article-section no-scrollbar">
      <div className="div-article-header-section">
        <div style={{ display: "flex", gap: "8px" }}>
          <img
            src={Icon_ArrowLeft}
            alt=""
            onClick={() => window.history.back()}
          />
          {allClubInfo?.find((club) => club.id === article?.club_id)?.name} /{" "}
          {allBoardInfo?.find((board) => board.id === article?.board_id)?.name}
        </div>
        <img
          src={Icon_Share}
          alt=""
          onClick={() => handleCopyClipBoard(window.location.href)}
        />
      </div>
      <div className="div-article-title-section Sub2M">
        {article?.title}
        <div className="div-article-detail-section">
          <div className="div-article-detail">{article?.nickname}</div>
          <div
            style={{
              width: "1px",
              height: "12px",
              background: "#ECECEC",
            }}
          />
          <div className="div-article-detail">
            {dayjs(article?.created_at).format("YYYY/MM/DD HH:mm")}
          </div>
          {/* <div
            style={{
              width: "1px",
              height: "12px",
              background: "#ECECEC",
            }}
          />
          <div className="div-article-detail">
            <img src={Icon_View} alt="" />
            {article.view_cnt}
          </div>
          <div
            style={{
              width: "1px",
              height: "12px",
              background: "#ECECEC",
            }}
          />
          <div className="div-article-detail">
            <img src={Icon_Comment} alt="" />
            {article.reply_cnt}
          </div> */}
        </div>
        {isMyArticle === 1 && (
          <button
            className="button-article_delete-article Body6M"
            onClick={() => deleteArticle()}
          >
            삭제
          </button>
        )}
      </div>
      <pre className="pre-article-content Body5M">
        {article?.content?.replaceAll("<br>", "\r\n")}
      </pre>
      {images?.map((image_url) => (
        <img className="img-article" src={image_url} alt="" />
      ))}
      <div className="div-article-thumbs-section">
        <button
          className={
            thumbs === 1
              ? "outlined-orange-button-selected button-article-thumbs Sub3M"
              : "outlined-orange-button button-article-thumbs Sub3M"
          }
          onClick={() => {
            updateThumbs(1);
          }}
        >
          <img
            src={thumbs === 1 ? Icon_Thumbsup_White : Icon_Thumbsup_Orange}
            alt=""
          />
          {likeCnt}
        </button>
        <button
          className={
            thumbs === 2
              ? "outlined-orange-button-selected button-article-thumbs Sub3M"
              : "outlined-orange-button button-article-thumbs Sub3M"
          }
          onClick={() => {
            updateThumbs(2);
          }}
        >
          <img
            src={thumbs === 2 ? Icon_Thumbsdown_White : Icon_Thumbsdown_Orange}
            alt=""
          />
          {dislikeCnt}
        </button>
      </div>
      <div
        style={{ width: "100%", height: "8px", backgroundColor: "#F8F8F8" }}
      />
      <div className="div-article-reply-cnt">댓글 ({reply?.length})</div>
      {reply?.length > 0 &&
        reply.map((reply) => (
          <div className="div-article-reply-section">
            <div className="div-article-reply-name">{reply.nickname}</div>
            <pre className="pre-article-reply-content">
              {reply.content.replaceAll("<br>", "\r\n")}
            </pre>
            <div className="div-article-detail-section">
              <div className="div-article-detail">
                {dayjs(reply.created_at).format("YYYY/MM/DD HH:mm")}
              </div>
              {/* <div className="div-article-detail">
            <img src={Icon_Comment} alt="" style={{ width: "16px" }} /> 답글달기
          </div> */}
              {reply.my_reply === 1 && (
                <div
                  className="div-article-detail"
                  onClick={() => deleteReply(reply.id)}
                >
                  <img src={Icon_Delete_6A6A6A} alt="" /> 삭제하기
                </div>
              )}
            </div>
          </div>
        ))}
      {/* <div className="div-article-reply-reply-section">
        <div className="div-article-reply-name">
          <img src={Icon_ReplyReply} alt="" />
          username
        </div>
        <div className="div-article-reply-content">
          댓글을 입력해주세요. 댓글을 입력해주세요. 댓글을 입력해주세요. 댓글을
          입력해주세요.
        </div>
        <div className="div-article-detail-section">
          <div className="div-article-detail">
            {detailDate(new Date(article.created_at))}
          </div>
        </div>
      </div> */}
      <div style={{ height: "100px" }} />
      <div className="div-article-write-reply">
        <button
          className={
            isAnonymous
              ? "button-article-reply-anonymous-checked Sub3M"
              : "button-article-reply-anonymous Sub3M"
          }
          onClick={() => setIsAnonymous(!isAnonymous)}
        >
          {isAnonymous ? (
            <BiCheckboxChecked size={20} />
          ) : (
            <BiCheckbox size={20} />
          )}
          익명
        </button>
        <TextareaAutosize
          maxRows={5}
          defaultValue=""
          placeholder="댓글 작성"
          className="Body4M"
          onChange={(e) => setContent(e.target.value)}
          value={content}
        />
        <button
          className="main-orange-buttonM Sub3M"
          onClick={() => writeReply()}
          disabled={content === ""}
        >
          등록
        </button>
      </div>
    </div>
  );
}

export default Article;
