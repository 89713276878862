import React from "react";
import "../asset/css/ChipStatus4M.css";

function ChipStatus4M({ state }) {
  if (state === 1)
    return <button className="button-chip-status-0M Body5M">지원</button>;
  else {
    switch (state) {
      case 3:
        return <button className="button-chip-status-1M Body5M">합격</button>;
      case 4:
        return <button className="button-chip-status-2M Body5M">불합격</button>;
      default:
    }
  }
}

export default ChipStatus4M;
