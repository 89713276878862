import Connection from "./Connection";

export const JS_KEY = "5a6ba0703c2d8f14618b07645bf10a1f";
export const REDIRECT_URI = "http://http://maclecj.theseung.com/formkakao";

export default function FormKakao({}) {
  const PARAMS = new URL(document.location).searchParams; // URL에 있는 파라미터(code) 받아오기
  const KAKAO_CODE = PARAMS.get("code");

  const Join = async () => {
    const res = await Connection(
      "/joinApplicant",
      {
        code: KAKAO_CODE,
        url:
          window.location.protocol + "//" + window.location.host + "/formkakao",
      },
      false
    );
    if (res.is_success) {
      sessionStorage.setItem("name", res.name);
      sessionStorage.setItem("phone", res.phone);
      sessionStorage.setItem("birthday", res.birthday);
      sessionStorage.setItem("gender", res.gender);
      window.location.href = sessionStorage.getItem("url");
    }
  };

  Join();
}
