import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import dayjs from "dayjs";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Etc from "../../asset/icon/Icon_Etc.svg";
import Icon_View_Gray from "../../asset/icon/Icon_view_Gray.svg";
import Icon_Application_Gray from "../../asset/icon/Icon_Application_Gray.svg";
import line from "../../asset/icon/line.svg";
import Icon_Share from "../../asset/icon/Icon_Share.svg";
import Icon_Edit from "../../asset/icon/Icon_Edit.svg";
import Icon_Delete from "../../asset/icon/Icon_Delete.svg";
import Icon_Link from "../../asset/icon/Icon_Link.svg";

import EditRecruit from "./EditRecruit";
import CommunityConnection from "../community/CommunityConnection";

const isDemo = localStorage.getItem("session_key") === "admin_demo";

const Modal = ({
  closed,
  setModal,
  onClickEdit,
  recruitId,
  selectedDate,
  ename,
}) => {
  const ModalRef = useRef(null);
  const session_key = window.localStorage.getItem("session_key");
  const params = window.location.href.split("/");
  const club_id = params[params.length - 2];
  useEffect(() => {
    const handleOnClickOutside = (e) => {
      if (!ModalRef.current.contains(e.target)) {
        setModal(-1); // 창 닫기
      }
    };
    document.addEventListener("click", handleOnClickOutside);
    return () => {
      document.removeEventListener("click", handleOnClickOutside);
    };
  }, []);

  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("모집 공고 URL이 복사되었습니다!");
    } catch (e) {
      alert("복사에 실패하였습니다");
    }
  };

  return (
    <>
      <StyledModal ref={ModalRef} onClick={(e) => e.stopPropagation()}>
        {closed || (
          <>
            {/* <div
              onClick={() =>
                handleCopyClipBoard(
                  window.location.protocol +
                    "//" +
                    window.location.host +
                    "/form/" +
                    params[params.length - 2] +
                    "/" +
                    recruitId
                )
              }
            >
              <img src={Icon_Share} alt="" />
              <span>URL 공유</span>
            </div> */}
            <div
              onClick={() =>
                handleCopyClipBoard(
                  window.location.protocol +
                    "//" +
                    window.location.host +
                    "/form/" +
                    ename +
                    "/" +
                    selectedDate.replace(/-/g, "").slice(-6) +
                    params[params.length - 2].slice(-3) +
                    recruitId.slice(-3)
                )
              }
            >
              <img src={Icon_Share} alt="" />
              <span>URL 공유</span>
            </div>
            <div onClick={onClickEdit}>
              <img src={Icon_Edit} alt="" />
              <span>공고 수정</span>
            </div>
            <div
              onClick={async () => {
                if (isDemo) {
                  window.alert("회원가입 후 이용 가능합니다🙂");
                } else {
                  if (window.confirm("정말 삭제하시겠습니까?")) {
                    const ret = await CommunityConnection("/deleteRecruit", {
                      club_id: club_id,
                      recruit_id: recruitId,
                    });
                    if (ret.is_success) {
                      window.alert("모집 공고 삭제가 완료되었습니다.");

                      window.location.reload();
                    }
                  }
                }
              }}
            >
              <img src={Icon_Delete} alt="" />
              <span>삭제</span>
            </div>
          </>
        )}
      </StyledModal>
    </>
  );
};

// Modal-----------------------------------------------
const StyledModal = styled.div.attrs({
  className: "Tag1M",
})`
  position: absolute;
  top: 0px;
  right: 32px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  div {
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    img {
      width: 18px;
      height: 18px;
    }
    span {
      /* width: 75px; */
    }
  }
`;
// -----------------------------------------------Modal

export default function RecruitList({ recruitList, setPage }) {
  const today = new Date();
  const [editRecruit, setEditRecruit] = useState(false); // 공고 수정 창 노출 여부
  const [etcModal, setEtcModal] = useState(-1); // 더보기 클릭 시 나오는 모달창
  const [selectedId, setSelectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const session_key = window.localStorage.getItem("session_key");
  const params = window.location.href.split("/");
  const club_id = params[params.length - 2];
  const [ename, setEname] = useState("");
  const getEname = async () => {
    const res = await CommunityConnection("/getEname", {
      club_id: club_id,
    });
    if (res.is_success) {
      setEname(res.ename);
    }
  };
  useEffect(() => {
    getEname();
  }, []);

  const getUrlParams = () => {
    return {
      clubId: club_id, // 동아리 id
      manageState: params[params.length - 1], // 관리 상태: manage, recruitlist, ...
    };
  };

  const [recruitApplicantsNum, setRecruitApplicantsNum] = useState();
  async function getRecruitApplicantsNum() {
    const res = await CommunityConnection("/getRecruitApplicantsNum", {
      id: club_id,
    });
    if (res.is_success) setRecruitApplicantsNum(res.applicants_num);
  }

  useEffect(() => {
    !isDemo && getRecruitApplicantsNum();
  }, []);

  return (
    <>
      {editRecruit && (
        <EditRecruit
          setPage={setEditRecruit}
          clubId={getUrlParams().clubId}
          recruitId={selectedId}
        />
      )}
      {editRecruit || (
        <ContentsWrapper>
          <Header>
            <BackBtn
              src={Icon_ArrowLeft}
              onClick={() => {
                setPage("manage");
                window.history.pushState(
                  {},
                  "",
                  `/${getUrlParams().clubId}/manage`
                );
              }}
            />
            <SpanHeader className="Sub2M">📣 모집 공고 리스트</SpanHeader>
            <div style={{ width: "24px" }} />
          </Header>
          <Body>
            {recruitList.map((rec, idx) => (
              <DivRecruit
                onClick={() => {
                  if (etcModal === -1) {
                    setPage(rec.id);
                    window.history.pushState(
                      {},
                      "",
                      `/${getUrlParams().clubId}/${rec.id}`
                    );
                  }
                }}
              >
                <DivRecruitHeader>
                  {rec.end_date === "2999-12-31" ? (
                    <div className="chip-manage-main background-orange Tag2M">
                      상시
                    </div>
                  ) : rec.state === 1 &&
                    parseInt(dayjs(rec.end_date).format("YYYYMMDD")) -
                      parseInt(dayjs(today).format("YYYYMMDD")) >=
                      0 ? (
                    <div
                      className="chip-manage-main background-orange Tag2M"
                      style={
                        parseInt(dayjs(rec.start_date).format("YYYYMMDD")) >
                        parseInt(dayjs(today).format("YYYYMMDD"))
                          ? { backgroundColor: "black" }
                          : { backgroundColor: "#FF7A00" }
                      }
                    >
                      D-
                      {dayjs(rec.end_date).format("YYYYMMDD") ===
                      dayjs(today).format("YYYYMMDD")
                        ? "DAY"
                        : dayjs(rec.end_date).diff(dayjs(today), "day") + 1}
                    </div>
                  ) : (
                    <div className="chip-manage-main background-black Tag2M">
                      마감
                    </div>
                  )}
                  {etcModal === idx && (
                    <Modal
                      setModal={setEtcModal}
                      onClickEdit={() => {
                        if (isDemo) {
                          window.alert("회원가입 후 이용 가능합니다🙂");
                        } else {
                          setSelectedDate(rec.start_date);
                          setSelectedId(rec.id);
                          setEditRecruit(true);
                        }
                      }}
                      recruitId={rec.id}
                      selectedDate={selectedDate}
                      ename={ename}
                    />
                  )}
                  <IconEtc
                    onClick={(e) => {
                      setEtcModal((prev) => (prev === idx ? -1 : idx));
                      setSelectedDate(rec.start_date);
                      e.stopPropagation();
                    }}
                  />
                </DivRecruitHeader>
                <DivRecruitBody>
                  <DivRecruitInfo>
                    <PRecruitHeadline>
                      {rec.title || "타이틀입니다"}
                    </PRecruitHeadline>
                    <DivRecruitDetails className="Body6M">
                      <SpanRecruitPeriod>
                        {rec.end_date === "2999-12-31"
                          ? "상시"
                          : `${rec.start_date} ~ ${rec.end_date}`}
                      </SpanRecruitPeriod>
                      <Line />
                      {isDemo
                        ? "30명"
                        : recruitApplicantsNum &&
                          recruitApplicantsNum[idx] + "명"}
                    </DivRecruitDetails>
                  </DivRecruitInfo>
                </DivRecruitBody>
              </DivRecruit>
            ))}
          </Body>
        </ContentsWrapper>
      )}
    </>
  );
}

const ContentsWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* min-width: 320px; */
  min-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
// Header-----------------------------------------------
const Header = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  background-color: white;
`;
const BackBtn = styled.img`
  width: 24px;
  height: 24px;
`;
const SpanHeader = styled.span`
  text-align: center;
  height: auto;
`;
// -----------------------------------------------Header

// DivRecruit-----------------------------------------------
const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;
const DivRecruit = styled.div`
  width: 100%;
  padding: 8px 12px 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid #ececec;
`;
const DivRecruitHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;
const DivRecruitBody = styled.div`
  display: flex;
  gap: 12px;
`;
const DivRecruitInfo = styled.div`
  /* width: calc(100% - 56px); */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const PRecruitHeadline = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;
const DivRecruitDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #6a6a6a;
`;
const SpanRecruitPeriod = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const DivRecruitPoster = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background-color: #ececec;
  flex-shrink: 0;
`;
// -----------------------------------------------DivRecruit

// Icon-----------------------------------------------
const IconEtc = styled.img.attrs({
  src: Icon_Etc,
  alt: "",
})`
  width: 24px;
  height: 24px;
`;
const Line = styled.img.attrs({
  src: line,
  alt: "",
})`
  width: 1px;
  height: 12px;
`;
const IconView = styled.img.attrs({
  src: Icon_View_Gray,
  alt: "",
})`
  width: 12px;
  height: 12px;
`;
const IconApplication = styled.img.attrs({
  src: Icon_Application_Gray,
  alt: "",
})`
  width: 12px;
  height: 12px;
`;
// -----------------------------------------------Icon
