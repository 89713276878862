import React from "react";
import Icon_Plus from "../asset/icon/Icon_Plus.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";

function TimeTableModal({ timeTable, split, num }) {
  return (
    <div className="div-modal-background">
      <div className="div-timetable-modal">
        <div className="H3">📅 면접자 타임테이블</div>
        <div className="div-timetable-modal-wrap">
          {timeTable.map((t, idx) => (
            <div className="div-timetable-section-03">
              <div className="div-timetable-section-03-sticky">
                <div className="div-timetable-section-03-01 Body3">
                  {t.d3.substr(5)}
                </div>
                <div className="div-timetable-section-03-02 Body3">
                  {num[idx]}
                </div>
              </div>
              <div className="div-timetable-section-03-03 Body4">
                {split.length > 0 &&
                  split[idx] &&
                  split[idx].map((s, s_id) => (
                    <div
                      className="div-timetable-section-03-03-row"
                      style={{ color: !s.checked && "#CDCDCD" }}
                    >
                      <div style={{ width: "75px" }}>{s.time}</div>
                      <div className="div-timetable-section-03-03-row-profile">
                        {[...Array(t.d2)].map((d) => (
                          <div className="Body5" style={{ width: "30px" }}>
                            정규용
                          </div>
                        ))}
                      </div>
                      <img
                        className="icon"
                        src={s.checked ? Icon_Close : Icon_Plus}
                        alt=""
                        style={{ width: "12px" }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <button className="main-black-button button-message-bottom Sub3">
          문자 전송하기
        </button>
      </div>
    </div>
  );
}

export default TimeTableModal;
