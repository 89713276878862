import React from "react";
import "../../asset/css/community/ClubMainHome.css";
import { useState } from "react";
import { useEffect } from "react";
import CommunityConnection from "./CommunityConnection";
import dayjs from "dayjs";

function ClubMainHome({ recruit, activiy_image }) {
  const today = new Date();
  const param_path = window.location.pathname.split("/");
  const clubId = param_path[3];

  const [isOverflown1, setIsOverflown1] = useState(false);
  useEffect(() => {
    const element1 = document.getElementById("poster-section");
    element1 && setIsOverflown1(element1.scrollWidth > element1.clientWidth);

    const element2 = document.getElementById("photo-section");
    element2 && setIsOverflown1(element2.scrollWidth > element2.clientWidth);
  }, []);

  const [posters, setPosters] = useState();
  async function getPosters() {
    const res = await CommunityConnection("/getPosters", {
      id: clubId,
    });
    if (res.is_success) setPosters(res.posters);
  }
  const [articles, setArticles] = useState();
  async function getLatestArticles() {
    const res = await CommunityConnection("/getLatestArticles", {
      id: clubId,
    });
    if (res.is_success) setArticles(res.articles);
  }
  useEffect(() => {
    getPosters();
    getLatestArticles();
  }, [param_path[3]]);

  return (
    <div className="div-club-main-home-section">
      🔥 모집 공고
      <div style={{ position: "relative" }}>
        {isOverflown1 && <div className="carousel-right" />}
        <div
          className="div-club-main-home-recruit-poster-section"
          id="poster-section"
          style={{ paddingRight: isOverflown1 && "24px" }}
        >
          {recruit?.map((recruit, idx) => (
            <div
              className="div-club-main-home-recruit"
              onClick={() =>
                window.open(
                  window.location.protocol +
                    "//" +
                    window.location.host +
                    "/form/" +
                    clubId +
                    "/" +
                    recruit.id
                )
              }
            >
              {recruit.state === 1 &&
              dayjs(recruit.end_date).format("YYYYMMDD") >=
                dayjs(today).format("YYYYMMDD") ? (
                <div
                  className="div-club-info-dday Tag1M"
                  style={
                    parseInt(dayjs(recruit.start_date).format("YYYYMMDD")) >
                    parseInt(dayjs(today).format("YYYYMMDD"))
                      ? { backgroundColor: "black" }
                      : { backgroundColor: "#FF7A00" }
                  }
                >
                  {dayjs(recruit.end_date).format("YYYYMMDD") === "29991231"
                    ? "상시"
                    : dayjs(recruit.end_date).format("YYYYMMDD") ===
                      dayjs(today).format("YYYYMMDD")
                    ? "D-DAY"
                    : "D-" +
                      (dayjs(recruit.end_date).diff(dayjs(today), "day") + 1)}
                </div>
              ) : (
                <>
                  <div className="div-club-main-home-recruit-poster-filter" />
                  <t>모집 마감</t>
                </>
              )}
              <div className="div-club-main-home-recruit-poster">
                <img
                  className="img-club-main-home-recruit-poster"
                  src={posters && posters[idx]?.poster}
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      📝 게시글
      <div className="div-club-main-home-board-section">
        {articles?.map((article, idx) => (
          <div
            className="div-club-main-home-board-article"
            onClick={() =>
              (window.location.href =
                "/community/club/" + clubId + "/board/9999/" + article.id)
            }
          >
            <div
              className={
                idx < 3
                  ? "div-club-main-home-board-article-rank123"
                  : "div-club-main-home-board-article-rank"
              }
            >
              {idx + 1}
            </div>
            <div className="div-club-main-home-board-article-title">
              {article.title}
            </div>
          </div>
        ))}
      </div>
      📸 활동 사진
      <div className="div-club-main-home-photo-section">
        {activiy_image?.map((image) => (
          <img
            src={image.image_url}
            className="div-club-main-home-photo"
            alt=""
          />
        ))}
      </div>
    </div>
  );
}

export default ClubMainHome;
