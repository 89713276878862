import React, { useState } from "react";
import Icon_Application from "../asset/icon/Icon_Application_Gray.svg";
import ChipStatus4M from "./ChipStatus4M";
import ViewFormM from "./ViewFormM";
import ChipStatusDocsM from "./ChipStatusDocsM";

const TableContent6M = ({
  recruitDocs,
  applicantList,
  columnList,
  modalViewForm,
  setModalViewForm,
  recruitInfo,
}) => {
  const [applicantNum, setApplicantNum] = useState();
  const showRows = applicantList.map((applicant, idx) => (
    <tr key={idx}>
      {/* 번호 */}
      <td className="td-sticky-01" style={{ height: "40px" }}>
        <span>
          <div className="Tag1M">{applicant.idx + 1}</div>
        </span>
      </td>
      {/* 상태 */}
      <td className="td-sticky-01" style={{ height: "40px" }}>
        {recruitInfo.step === "서류" ? (
          <span>
            <ChipStatusDocsM state={applicant.stateV} />
          </span>
        ) : (
          <span>
            {applicant.stateV === 2 ? (
              <button
                className="button-chip button-chip-status-1M Body5M"
                style={{ backgroundColor: "#ececec" }}
              >
                불합격
              </button>
            ) : (
              <ChipStatus4M state={applicant.stateV} />
            )}
          </span>
        )}
      </td>
      {/* 지원자명 */}
      <td
        className="td-sticky-02"
        style={{
          height: "40px",
          justifyContent: "center",
          gap: "15px",
          borderRight: "none",
        }}
      >
        <div
          className="Body4M"
          style={{
            width: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {applicant.profile.name}
        </div>
        <img
          className="div-click img-table-application"
          style={{ height: "24px" }}
          src={Icon_Application}
          alt=""
          onClick={() => {
            setApplicantNum(idx);
            setModalViewForm(true);
          }}
        />
      </td>
    </tr>
  ));

  return (
    <>
      {modalViewForm && (
        <ViewFormM
          questions={recruitDocs?.question}
          answers={applicantList[applicantNum]}
          applicantNum={applicantNum}
          setApplicantNum={setApplicantNum}
          setModalOpen={setModalViewForm}
          applicantListLength={applicantList.length}
        />
      )}
      {showRows}
    </>
  );
};

export default TableContent6M;
