import React, { useEffect, useState } from "react";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import RadioButton from "../asset/icon/RadioButton.svg";
import RadioButton_Checked from "../asset/icon/RadioButton_Checked.svg";
import Connection from "./Connection";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";

function MessageFinal({
  club_id,
  recruit_id,
  setModalOpen,
  countPass,
  countFail,
  countAll,
  setRecruitState,
}) {
  const session_key = localStorage.getItem("session_key");
  const [applicantList, setApplicantList] = useState([]);

  const [template, setTemplate] = useState("default");
  const [contentPass, setContentPass] = useState("");
  const [contentFail, setContentFail] = useState("");

  async function getMsgTemplate() {
    const res = await Connection("/getMsgTemplate", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
    });
    if (res.is_success) {
      setContentPass(res.template.interview_pass);
      setContentFail(res.template.interview_reject);
    }
  }

  useEffect(() => {
    getMsgTemplate();
  }, []);

  async function getRecruitStep1() {
    const res = await Connection("/getRecruitStep2", {
      club_id: club_id,
      recruit_id: recruit_id,
    });
    if (res.is_success) {
      setApplicantList(
        res.applicant_r.filter((a) => a.state === 3 || a.state === 4)
      );
    }
  }

  useEffect(() => {
    getRecruitStep1();
  }, []);

  const onChange = (e) => {
    switch (e.target.id) {
      case "contentPass":
        setContentPass(e.target.value);
        break;
      case "contentFail":
        setContentFail(e.target.value);
        break;
      default:
    }
  };

  return (
    <div className="div-modal-background">
      <div className="div-message-modal">
        <div className="H3">✉️ 면접 결과 안내 문자</div>
        <img
          className="icon img-message-section-close"
          src={Icon_Close}
          alt=""
          style={{ width: "21px" }}
          onClick={() => setModalOpen(false)}
        />
        <div className="div-message-section-01">
          <div
            className="div-message-section-01-left Sub3"
            style={{ width: "70px" }}
          >
            수신자
          </div>
          <div className="div-message-section-01-right">
            {applicantList &&
              applicantList.map((a) => (
                <>
                  <div
                    className={
                      a.state === 3
                        ? "div-message-section-01-right-chip-pass Body4"
                        : "div-message-section-01-right-chip Body4"
                    }
                  >
                    {a.profile["name"]}
                  </div>
                </>
              ))}
          </div>
        </div>
        <div className="div-message-section-01-right-count Body5">
          합격자 {countPass}명, 불합격자 {countFail}명, 총 {countAll}명
        </div>
        <div
          className="div-message-section-02-right-chip-section"
          style={{ paddingLeft: "71px" }}
        >
          {contentFail && contentFail.default.state === 2 && (
            <div
              className="div-click div-managemessage-message-select-section-01 Body4"
              onClick={() =>
                contentFail &&
                contentFail.default.state === 2 &&
                setTemplate("default")
              }
              style={{ marginRight: "9px" }}
            >
              <img
                className="icon"
                src={template === "default" ? RadioButton_Checked : RadioButton}
                alt=""
                style={{ marginRight: "6px" }}
              />
              기본 템플릿
            </div>
          )}
          {contentFail && contentFail.template1.state === 2 && (
            <div
              className="div-click div-managemessage-message-select-section-01 Body4"
              onClick={() => setTemplate("template1")}
              style={{ marginRight: "9px" }}
            >
              <img
                className="icon"
                src={
                  template === "template1" ? RadioButton_Checked : RadioButton
                }
                alt=""
                style={{ marginRight: "6px" }}
              />
              템플릿 1
            </div>
          )}
          {contentFail && contentFail.template2.state === 2 && (
            <div
              className="div-click div-managemessage-message-select-section-01 Body4"
              onClick={() => setTemplate("template2")}
              style={{ marginRight: "9px" }}
            >
              <img
                className="icon"
                src={
                  template === "template2" ? RadioButton_Checked : RadioButton
                }
                alt=""
                style={{ marginRight: "6px" }}
              />
              템플릿 2
            </div>
          )}
        </div>
        <div className="div-message-section-02">
          <div
            className="div-message-section-01-left Sub3"
            style={{ width: "70px" }}
          >
            합격자 내용
          </div>
          <div className="textarea-message-section-02-right-final Body4">
            <HighlightWithinTextarea
              value={
                contentPass && template && contentPass[template].con
                  ? contentPass && template && contentPass[template].con
                  : ""
              }
              highlight={
                /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
              }
            />
          </div>
        </div>
        <div className="div-message-section-02" style={{ marginTop: "9px" }}>
          <div
            className="div-message-section-01-left Sub3"
            style={{ width: "70px" }}
          >
            불합격자 내용
          </div>
          <div className="textarea-message-section-02-right-final Body4">
            <HighlightWithinTextarea
              value={
                contentFail && template && contentFail[template].con
                  ? contentFail && template && contentFail[template].con
                  : ""
              }
              highlight={
                /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
              }
            />
          </div>
        </div>
        <button
          className="outlined-black-button button-message-bottom-left Sub3"
          onClick={async () => {
            if (window.confirm("문자 전송을 건너뛰시겠습니까?")) {
              const res1 = await Connection("/completeStep2", {
                club_id: club_id,
                recruit_id: recruit_id,
                template_id: "none",
              });

              if (res1.is_success) {
                const res2 = await Connection("/sendInterviewResultMsg", {
                  club_id: club_id,
                  recruit_id: recruit_id,
                  template_id: "none",
                });
                if (res2.is_success) {
                  setModalOpen(false);
                  setRecruitState(6);
                }
              }
            }
          }}
        >
          건너뛰기
        </button>
        <button
          className="main-black-button button-message-bottom Sub3"
          onClick={async () => {
            if (window.confirm("문자를 전송하시겠습니까?")) {
              const res1 = await Connection("/completeStep2", {
                club_id: club_id,
                recruit_id: recruit_id,
                template_id: template,
              });

              if (res1.is_success) {
                const res2 = await Connection("/sendInterviewResultMsg", {
                  club_id: club_id,
                  recruit_id: recruit_id,
                  template_id: template,
                });
                if (res2.is_success) {
                  window.alert("문자 전송이 완료되었습니다.");
                  setModalOpen(false);
                  setRecruitState(6);
                }
              }
            }
          }}
        >
          문자 전송하기
        </button>
      </div>
    </div>
  );
}

export default MessageFinal;
