import React from "react";

function ChipStatus4({ state }) {
  if (state === 1)
    return (
      <button className="button-chip button-chip-status-0 Body5">지원</button>
    );
  else {
    switch (state) {
      case 3:
        return (
          <button className="button-chip button-chip-status-1 Body5">
            합격
          </button>
        );
      case 4:
        return (
          <button className="button-chip button-chip-status-2 Body5">
            불합격
          </button>
        );
      default:
    }
  }
}

export default React.memo(ChipStatus4);
