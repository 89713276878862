import Connection from "./Connection";

export const JS_KEY = "5a6ba0703c2d8f14618b07645bf10a1f";
export const REDIRECT_URI = "http://http://maclecj.theseung.com/kakaologin";

export default function KakaoLogin() {
  const PARAMS = new URL(document.location).searchParams; // URL에 있는 파라미터(code) 받아오기
  const KAKAO_CODE = PARAMS.get("code");

  const Join = async () => {
    const res = await Connection(
      "/joinKakao",
      {
        code: KAKAO_CODE,
        url:
          window.location.protocol +
          "//" +
          window.location.host +
          "/kakaologin",
      },
      false
    );

    if (res.is_success) {
      if (res.phone === "") {
        window.localStorage.setItem("kakaoAuth_ok", 1);
      } else {
        window.localStorage.setItem("kakaoAuth_ok", 2);
      }
      window.localStorage.setItem("register_birthday", res.birthday);
      window.localStorage.setItem("register_gender", res.gender);
      window.localStorage.setItem("register_tmp_token", res.tmp_token);
      window.localStorage.setItem("register_phone", res.phone);
      window.localStorage.setItem("register_name", res.name);
      window.location.href = "/";
    } else {
      if (res.session_key) {
        window.localStorage.setItem("session_key", res.session_key);
      } else {
        alert(res.error_message);
      }
      window.location.href = "/";
    }
  };

  Join();
}
