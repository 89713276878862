import React, { useState } from "react";
import "../asset/css/ViewImages.css";
import Icon_Close from "../asset/icon/Icon_Close.svg";

export default function ViewImages({ clubInfo, setModalOpen }) {
  // 배열 형태
  const [imageNum, setImageNum] = useState(0);

  return (
    <div className="div-modal-background">
      <div className="ViewImage-modal">
        <div className="ViewImage-header">
          <p className="Sub1">🎴 활동 사진</p>
          <img
            src={Icon_Close}
            alt=""
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </div>
        <div className="ViewImage-big">
          <img src={clubInfo.activiy_image[imageNum]} alt="" />
        </div>
        {clubInfo.is_success && clubInfo.activiy_image.length ? (
          <div className="ViewImage-images">
            {clubInfo.activiy_image.map((image, idx) => (
              <img
                className="ViewImage-image"
                src={image}
                alt=""
                onClick={() => setImageNum(idx)}
              />
            ))}
          </div>
        ) : (
          <div
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "8px",
              backgroundColor: "#D9D9D9",
            }}
          />
        )}
      </div>
    </div>
  );
}
