import React, { useEffect, useState } from "react";
import "../asset/css/SelectM.css";
import Dongari_Default from "../asset/recruit/Dongari_Default.svg";
import Connection from "./Connection";
import dayjs from "dayjs";

function SelectM() {
  const today = new Date();
  const [clubByUser, setClubByUser] = useState({
    is_success: false,
  });
  const getClub = async () => {
    const cbu = await Connection("/getClubByUser", {
      session_key: window.localStorage.session_key,
    });
    setClubByUser(cbu);
    setCheckedClub(cbu.club[0].id);
  };

  useEffect(() => {
    if (window.localStorage.session_key) {
      getClub();
    }
  }, []);
  const session_key = window.localStorage.getItem("session_key");

  const [checkedClub, setCheckedClub] = useState();
  const [checkedMozip, setCheckedMozip] = useState();
  const [clubInfo, setClubInfo] = useState({});

  useEffect(() => {
    checkedClub && getClubInfo();
  }, [checkedClub]);

  async function getClubInfo() {
    const ci = await Connection("/getClubInfo", {
      club_id: checkedClub,
    });

    setClubInfo(ci);

    setCheckedMozip(ci.recruit[0].id);
  }

  return (
    <div
      className="div-selectm-section H3M"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="div-selectm-section-info Tag1M">
        모바일 환경에서는 지원자 현황 확인만 가능합니다.
        <br />
        동아리/모집공고 등록, 합불처리는 PC를 이용해주세요🙂
      </div>
      <div className="H3M" style={{ marginBottom: "10px" }}>
        동아리를 선택해주세요.
      </div>
      <div className="div-selectm-clubs-section">
        <div id="recruit00-DefaultImgsM">
          {clubByUser.is_success &&
            clubByUser.club.map((cl, idx) => (
              <img
                src={cl.image}
                alt={Dongari_Default}
                onClick={() => {
                  setCheckedClub(cl.id);
                }}
                style={{
                  opacity: checkedClub && cl.id !== checkedClub && "0.2",
                }}
              />
            ))}
        </div>
      </div>
      {checkedClub && (
        <>
          <div
            className="H3M"
            style={{ marginTop: "40px", marginBottom: "10px" }}
          >
            모집 공고를 선택해주세요.
          </div>
          <div className="div-selectm-clubs-section" style={{ width: "100%" }}>
            {clubInfo.is_success && clubInfo.recruit.length > 0 && (
              <div
                className="div-manage-section-02-03-mozip-list"
                style={{ border: "none", maxHeight: "132px" }}
              >
                {clubInfo.is_success &&
                  clubInfo.recruit.map((rec, idx) => (
                    <button
                      className={
                        rec.id === checkedMozip
                          ? "button-manage-mozip-list-section-checked"
                          : "button-manage-mozip-list-section"
                      }
                      onClick={() => {
                        if (rec.id !== checkedMozip) {
                          setCheckedMozip(rec.id);
                        }
                      }}
                      key={idx}
                      style={{ padding: "16px 12px" }}
                    >
                      <div className="div-manage-mozip-listM Body4M">
                        {rec.title}
                      </div>
                      {rec.end_date === "2999-12-31" ? (
                        <div
                          className="div-chip-mozip-listM Tag1M"
                          style={{ backgroundColor: "#FF7A00" }}
                        >
                          상시
                        </div>
                      ) : rec.state === 1 &&
                        parseInt(dayjs(rec.end_date).format("YYYYMMDD")) -
                          parseInt(dayjs(today).format("YYYYMMDD")) >=
                          0 ? (
                        <div
                          className="div-chip-mozip-listM Tag1M"
                          style={{ backgroundColor: "#FF7A00" }}
                        >
                          D-
                          {dayjs(rec.end_date).format("YYYYMMDD") ===
                          dayjs(today).format("YYYYMMDD")
                            ? "DAY"
                            : dayjs(rec.end_date).diff(dayjs(today), "day") + 1}
                        </div>
                      ) : (
                        <div className="div-chip-mozip-listM Tag1M">마감</div>
                      )}
                    </button>
                  ))}
              </div>
            )}
          </div>
        </>
      )}
      {checkedClub && checkedMozip && (
        <div className="div-form-question-final-section">
          <button
            className="button-form-question-final Sub3M"
            onClick={() => {
              window.location.href =
                window.location.protocol +
                "//" +
                window.location.host +
                "/" +
                checkedClub +
                "/" +
                checkedMozip;
            }}
          >
            지원자 확인하기
          </button>
        </div>
      )}
    </div>
  );
}

export default SelectM;
