/*================================
* 지원자 현황 테이블
==================================*/

import React, { useState, useEffect } from "react";
import ChipStatus from "./ChipStatus";

import Icon_Application from "../asset/icon/Icon_Application_Gray.svg";
import Answer from "./Answer";
import Connection from "./Connection";
import ViewForm from "./ViewForm";

import { CoachMark } from "./guideModal";
import { useSelector } from "react-redux";

const TableContent = ({
  club_id,
  recruit_id,
  recruitDocs,
  applicantList,
  setApplicantList,
  columnList,
  columnWidth,
  setCountFail,
  setCountPass,
  modalViewForm,
  setModalViewForm,
  managerList,
  getAllScore,
}) => {
  const { guideRecruit_2, guideRecruit_2i } = useSelector((state) => ({
    guideRecruit_2: state.guide.recruit_2,
    guideRecruit_2i: state.guide.recruit_2i,
  }));
  const session_key = window.localStorage.getItem("session_key");

  const [applicantNum, setApplicantNum] = useState();

  useEffect(() => {
    setCountPass(applicantList.filter((a) => a.stateV === 1).length);
    setCountFail(applicantList.filter((a) => a.stateV === 2).length);
  }, [applicantList]);

  const updateState = (applicant_id, state) => {
    Connection("/updateRecruitStep1", {
      club_id: club_id,
      recruit_id: recruit_id,
      applicant_id: applicant_id,
      state: String(state),
    });
  };

  const showRows = applicantList.map((applicant, idx) => (
    <tr key={idx}>
      {/* 상태 */}
      <td className="td-sticky-01">
        <CoachMark
          guideIndex={guideRecruit_2i}
          ownIndex={1}
          isOpen={idx === 0 && guideRecruit_2}
        >
          <span
            onClick={async () => {
              setApplicantList(
                applicantList.map((a, i) =>
                  i === idx ? { ...a, stateV: (a.stateV + 1) % 3 } : a
                )
              );

              updateState(applicant.id, (applicant.stateV + 1) % 3);
            }}
            style={
              guideRecruit_2 && guideRecruit_2i === 0 && idx === 0
                ? { outline: "3px solid #FF7A00", borderRadius: "3px" }
                : null
            }
          >
            <ChipStatus state={applicant.stateV % 3} />
          </span>
        </CoachMark>
      </td>
      {/* 점수 */}
      <td className="td-sticky-02-score">
        <Answer
          question={columnList[1].data}
          answer={
            applicant.score % 1 > 0
              ? applicant.score.toFixed(1)
              : applicant.score
          }
        />
      </td>
      {/* 지원자명 */}
      <td className="td-sticky-03-score">
        <div className="td-sticky-02-index Tag1">{applicant.idx + 1}</div>
        {
          <Answer
            question={columnList[2].data}
            answer={applicant.profile.name}
          />
        }
        <CoachMark
          guideIndex={guideRecruit_2i}
          ownIndex={2}
          isOpen={idx === 0 && guideRecruit_2}
          eclipseStyle={{ transform: "translate(-120%, 0)" }}
        >
          <img
            className="div-click img-table-application"
            src={Icon_Application}
            alt=""
            onClick={() => {
              setApplicantNum(idx);
              setModalViewForm(true);
            }}
            style={
              guideRecruit_2 && guideRecruit_2i === 1 && idx === 0
                ? { outline: "3px solid #FF7A00", borderRadius: "3px" }
                : null
            }
          />
        </CoachMark>
      </td>
      {/* --------------------------- 질문 ---------------------------*/}
      <>
        {columnList[3].isView && (
          <td>
            <Answer
              question={columnList[3].data}
              answer={applicant.profile.phone}
            />
          </td>
        )}
      </>
      <>
        {columnList[4].isView && (
          <td>
            <Answer
              question={columnList[4].data}
              answer={applicant.profile.birthday}
            />
          </td>
        )}
      </>
      <>
        {columnList[5].isView && (
          <td>
            <Answer
              question={columnList[5].data}
              answer={applicant.profile.gender}
            />
          </td>
        )}
      </>
      {columnList.map(function (column, idxx) {
        if (idxx > 5 && applicant.answer[idxx - 6] && column.isView) {
          return (
            <td key={idxx}>
              <Answer
                question={column.data}
                answer={applicant.answer[idxx - 6].value}
                width={columnWidth[idxx]}
                sort_num={applicant.answer[idxx - 6].sort_num}
              />
            </td>
          );
        }
      })}
    </tr>
  ));

  return (
    <>
      {showRows}
      {modalViewForm && (
        <ViewForm
          questions={recruitDocs?.question}
          answers={applicantList[applicantNum]}
          applicantNum={applicantNum}
          setApplicantNum={setApplicantNum}
          setModalOpen={setModalViewForm}
          applicantListLength={applicantList.length}
          managerList={managerList}
          recruit_id={recruit_id}
          applicantList={applicantList}
          setApplicantList={setApplicantList}
          getAllScore={getAllScore}
        />
      )}
    </>
  );
};

export default React.memo(TableContent);
