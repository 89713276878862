/* 액션 타입 만들기 */
// Ducks 패턴을 따를땐 액션의 이름에 접두사를 넣어주세요.
// 이렇게 하면 다른 모듈과 액션 이름이 중복되는 것을 방지 할 수 있습니다.
const SET_RECRUIT_1 = "guide/SET_RECRUIT_1"; // 모집공고 리스트, URL 공유, 새로고침
const SET_RECRUIT_1I = "guide/SET_RECRUIT_1I";
const SET_RECRUIT_2 = "guide/SET_RECRUIT_2"; // 면접자 테이블
const SET_RECRUIT_2I = "guide/SET_RECRUIT_2I";
const SET_RECRUIT_3 = "guide/SET_RECRUIT_3"; // 모집공고 만들기 1
const SET_RECRUIT_4 = "guide/SET_RECRUIT_4"; // 모집공고 만들기 2
const SET_RECRUIT_4I = "guide/SET_RECRUIT_4I";
const SET_MESSAGE_1 = "guide/SET_MESSAGE_1"; // 탈락자 안내문자 발송
const SET_MESSAGE_1I = "guide/SET_MESSAGE_1I";
const SET_TEMPLATE_1 = "guide/SET_TEMPLATE_1"; // 문자 템플릿 관리
const SET_TEMPLATE_1I = "guide/SET_TEMPLATE_1I";
const SET_MAKEFORM_1 = "guide/SET_MAKEFORM_1"; // 지원 폼 만들기 1
const SET_MAKEFORM_1I = "guide/SET_MAKEFORM_1I";
const SET_MAKEFORM_2 = "guide/SET_MAKEFORM_2"; // 지원 폼 만들기 2
const SET_MAKEFORM_2I = "guide/SET_MAKEFORM_2I";
const SET_MAKEFORM_3 = "guide/SET_MAKEFORM_3"; // 지원 폼 만들기 3
const SET_MAKEFORM_3I = "guide/SET_MAKEFORM_4I";
const SET_TIMETABLE_1 = "guide/SET_TIMETABLE_1"; // 1차 합격자 타임테이블 관리
const SET_TIMETABLE_1I = "guide/SET_TIMETABLE_1I";

/* 액션 생성함수 만들기 */
// 액션 생성함수를 만들고 export 키워드를 사용해서 내보내주세요.
export const setRecruit1 = (value) => ({ type: SET_RECRUIT_1, value });
export const setRecruit1i = (index) => ({ type: SET_RECRUIT_1I, index });
export const setRecruit2 = (value) => ({ type: SET_RECRUIT_2, value });
export const setRecruit2i = (index) => ({ type: SET_RECRUIT_2I, index });
export const setRecruit3 = (value) => ({ type: SET_RECRUIT_3, value });
export const setRecruit4 = (value) => ({ type: SET_RECRUIT_4, value });
export const setRecruit4i = (index) => ({ type: SET_RECRUIT_4I, index });
export const setMessage1 = (value) => ({ type: SET_MESSAGE_1, value });
export const setMessage1i = (index) => ({ type: SET_MESSAGE_1I, index });
export const setTemplate1 = (value) => ({ type: SET_TEMPLATE_1, value });
export const setTemplate1i = (index) => ({ type: SET_TEMPLATE_1I, index });
export const setMakeForm1 = (value) => ({ type: SET_MAKEFORM_1, value });
export const setMakeForm1i = (index) => ({ type: SET_MAKEFORM_1I, index });
export const setMakeForm2 = (value) => ({ type: SET_MAKEFORM_2, value });
export const setMakeForm2i = (index) => ({ type: SET_MAKEFORM_2I, index });
export const setMakeForm3 = (value) => ({ type: SET_MAKEFORM_3, value });
export const setMakeForm3i = (index) => ({ type: SET_MAKEFORM_3I, index });
export const setTimetable1 = (value) => ({ type: SET_TIMETABLE_1, value });
export const setTimetable1i = (index) => ({ type: SET_TIMETABLE_1I, index });

/* 초기 상태 선언 */
const initialState = {
  recruit_1: false,
  recruit_1i: 0,
  recruit_2: false,
  recruit_2i: 0,
  recruit_3: false,
  recruit_4: false,
  recruit_4i: 0,
  message_1: false,
  message_1i: 0,
  template_1: false,
  template_1i: 0,
  makeform_1: false,
  makeform_1i: 0,
  makeform_2: false,
  makeform_2i: 0,
  makeform_3: false,
  makeform_3i: 0,
  timetable_1: false,
  timetable_1i: 0,
};

/* 리듀서 선언 */
// 리듀서는 export default 로 내보내주세요.
export default function counter(state = initialState, action) {
  switch (action.type) {
    case SET_RECRUIT_1:
      return {
        ...state,
        recruit_1: action.value,
      };
    case SET_RECRUIT_1I:
      return {
        ...state,
        recruit_1i: action.index,
      };
    case SET_RECRUIT_2:
      return {
        ...state,
        recruit_2: action.value,
      };
    case SET_RECRUIT_2I:
      return {
        ...state,
        recruit_2i: action.index,
      };
    case SET_RECRUIT_3:
      return {
        ...state,
        recruit_3: action.value,
      };
    case SET_RECRUIT_4:
      return {
        ...state,
        recruit_4: action.value,
      };
    case SET_RECRUIT_4I:
      return {
        ...state,
        recruit_4i: action.index,
      };
    case SET_MESSAGE_1:
      return {
        ...state,
        message_1: action.value,
      };
    case SET_MESSAGE_1I:
      return {
        ...state,
        message_1i: action.index,
      };
    case SET_TEMPLATE_1:
      return {
        ...state,
        template_1: action.value,
      };
    case SET_TEMPLATE_1I:
      return {
        ...state,
        template_1i: action.index,
      };
    case SET_MAKEFORM_1:
      return {
        ...state,
        makeform_1: action.value,
      };
    case SET_MAKEFORM_1I:
      return {
        ...state,
        makeform_1i: action.index,
      };
    case SET_MAKEFORM_2:
      return {
        ...state,
        makeform_2: action.value,
      };
    case SET_MAKEFORM_2I:
      return {
        ...state,
        makeform_2i: action.index,
      };
    case SET_MAKEFORM_3:
      return {
        ...state,
        makeform_3: action.value,
      };
    case SET_MAKEFORM_3I:
      return {
        ...state,
        makeform_3i: action.index,
      };
    case SET_TIMETABLE_1:
      return {
        ...state,
        timetable_1: action.value,
      };
    case SET_TIMETABLE_1I:
      return {
        ...state,
        timetable_1i: action.index,
      };
    default:
      return state;
  }
}
