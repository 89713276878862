import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import RecruitInfo from "./RecruitInfo";
import Table6 from "./Table6";
import ToastPopup from "./ToastPopup";

function RecruitPage6({
  club_id,
  recruit_id,
  recruitDocs,
  recruitInfo,
  recruitStep1,
  setRecruitState,
  clubInfo,
  info,
  isLeftSectionOpen,
  managerList,
}) {
  const isWide = useMediaQuery({
    query: isLeftSectionOpen ? "(min-width: 1065px)" : "(min-width: 785px)",
  });

  const [recruitDetailOpen, setRecruitDetailOpen] = useState(false);
  const [applicantList, setApplicantList] = useState([]);

  useEffect(() => {
    recruitInfo.step === "서류"
      ? recruitStep1.is_success && setApplicantList(recruitStep1.applicant)
      : recruitStep1.is_success &&
        setApplicantList(
          recruitStep1.applicant.filter((a) => a.state === 3 || a.state === 4)
        );
  }, [recruitStep1]);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const stateHeader = [
    { k: "상태", type: 0 },
    { k: "점수", type: 0 },
  ];
  const newHeader = [].concat(stateHeader, recruitDocs.question);

  const FINAL_STATE = recruitInfo.step === "서류" ? 1 : 3;

  return (
    <>
      <RecruitInfo
        club_id={club_id}
        recruit_id={recruit_id}
        recruitInfo={recruitInfo}
        recruitDetailOpen={recruitDetailOpen}
        setRecruitDetailOpen={setRecruitDetailOpen}
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        clubInfo={clubInfo}
        info={info}
      />
      <div
        className={
          isLeftSectionOpen
            ? "div-manage-section-03-02"
            : "div-manage-section-03-02-wide"
        }
      >
        <div
          className={
            isLeftSectionOpen
              ? "div-manage-section-03-02-01"
              : "div-manage-section-03-02-01-wide"
          }
        >
          <div>
            <div className="div-manage-section-03-01-01-01 H3">
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <div
                  className="H3"
                  style={{
                    width: "148px",
                    flexShrink: "0",
                  }}
                >
                  👋 최종 합격자 명단
                </div>
              </div>
              {!isWide && (
                <ToastPopup
                  status={"warning"}
                  children={"화면을 늘려주세요!"}
                  className={`Tag1`}
                />
              )}
            </div>
            {recruitDocs.is_success && recruitStep1.is_success && (
              <Table6
                club_id={club_id}
                recruit_id={recruit_id}
                recruitDocs={recruitDocs}
                headers={newHeader}
                applicantList={applicantList}
                isLeftSectionOpen={isLeftSectionOpen}
                recruitInfo={recruitInfo}
                managerList={managerList}
              />
            )}
          </div>
          <div className="div-manage-section-03-02-01-02">
            <div className="div-manage-section-03-02-01-02-count-section">
              <div
                className="div-manage-section-03-02-01-02-count Body4"
                style={{ width: "56px" }}
              >
                전체 지원자
                <div className="H3">{recruitStep1?.applicant?.length}명</div>
              </div>
              <div className="div-manage-section-03-02-01-02-count Body4">
                서류 탈락
                <div className="H3">
                  {recruitStep1?.applicant?.filter((a) => a.state === 2).length}
                  명
                </div>
              </div>
              <div className="div-manage-section-03-02-01-02-count Body4">
                면접 탈락
                <div className="H3">
                  {recruitStep1?.applicant?.filter((a) => a.state === 4).length}
                  명
                </div>
              </div>
              <div className="div-manage-section-03-02-01-02-count Body4">
                최종 합격
                <div className="H3" style={{ color: "#FF7A00" }}>
                  {
                    recruitStep1?.applicant?.filter(
                      (a) => a.state === FINAL_STATE
                    ).length
                  }
                  명
                </div>
              </div>
            </div>
            {/* 회원 목록 관리 기능 완성시 추가 */}
            {/* <button className="main-orange-button button-manage-section-03-02-01-02-complete H3">
              회원 목록 추가
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruitPage6;
