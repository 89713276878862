import { React, useState, useEffect } from "react";
import "../asset/css/ManageMessage.css";
import Icon_Reset from "../asset/icon/Icon_Reset.svg";
import Icon_Question from "../asset/icon/Icon_Question.svg";
import RadioButton from "../asset/icon/RadioButton.svg";
import RadioButton_Checked from "../asset/icon/RadioButton_Checked.svg";
import message_ex from "../asset/photos/message_ex.png";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import ChipMessage from "./ChipMessage";
import Connection from "./Connection";
import ToastPopup from "./ToastPopup";
import Loginform from "./Loginform";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import { GuideDefaultDim, CoachMark } from "./guideModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setTemplate1 as setGuide,
  setTemplate1i as setGuideIndex,
} from "../modules/guide";

function ManageMessage({ club_id, isLeftSectionOpen }) {
  const [loginModal, setLoginModal] = useState(false);

  const [template1, setTemplate1] = useState(0);
  const [template2, setTemplate2] = useState(0);
  const [template3, setTemplate3] = useState(0);
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [content4, setContent4] = useState("");
  const [animation1, setAnimation1] = useState("");
  const [isToastPopupOpen, setIsToastPopupOpen] = useState(false);
  const [displayGuideImg, setDisplayGuideImg] = useState(false);
  const onToggle = () => {
    setAnimation1("openAnimation-toast");
    setIsToastPopupOpen(true);
    setTimeout(() => {
      setAnimation1("closeAnimation-toast");
      setTimeout(() => {
        setIsToastPopupOpen(false);
      }, 450);
    }, 2000);
  };

  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("클립보드에 복사되었습니다.\n원하는 위치에 붙여넣기하세요!");
    } catch (e) {
      alert("복사에 실패하였습니다");
    }
  };

  const [msgTemplate, setMsgTemplate] = useState();
  async function getMsgTemplate() {
    const res = await Connection("/getMsgTemplate", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
    });

    if (res.is_success) {
      setTemplate1(0);
      setTemplate2(0);
      setTemplate3(0);
      setMsgTemplate(res.template);
      setContent1(res.template.applicant_reject.default.con);
      setContent2(res.template.interview_time.default.con);
      setContent3(res.template.interview_pass.default.con);
      setContent4(res.template.interview_reject.default.con);
    }
  }

  useEffect(() => {
    getMsgTemplate();
  }, []);

  useEffect(() => {
    switch (template1) {
      case 0:
        setContent1(msgTemplate?.applicant_reject.default.con);
        break;
      case 1:
        setContent1(msgTemplate?.applicant_reject.template1.con);
        break;
      case 2:
        setContent1(msgTemplate?.applicant_reject.template2.con);
        break;
      default:
    }
  }, [template1]);

  useEffect(() => {
    switch (template2) {
      case 0:
        setContent2(msgTemplate?.interview_time.default.con);
        break;
      case 1:
        setContent2(msgTemplate?.interview_time.template1.con);
        break;
      case 2:
        setContent2(msgTemplate?.interview_time.template2.con);
        break;
      default:
    }
  }, [template2]);

  useEffect(() => {
    switch (template3) {
      case 0:
        setContent3(msgTemplate?.interview_pass.default.con);
        setContent4(msgTemplate?.interview_reject.default.con);
        break;
      case 1:
        setContent3(msgTemplate?.interview_pass.template1.con);
        setContent4(msgTemplate?.interview_reject.template1.con);
        break;
      case 2:
        setContent3(msgTemplate?.interview_pass.template2.con);
        setContent4(msgTemplate?.interview_reject.template2.con);
        break;
      default:
    }
  }, [template3]);

  const onChange = (e) => {
    switch (e.target.id) {
      case "content1":
        setContent1(e.target.value);
        break;
      case "content2":
        setContent2(e.target.value);
        break;
      case "content3":
        setContent3(e.target.value);
        break;
      case "content4":
        setContent4(e.target.value);
        break;
      default:
    }
  };

  async function updateMsgTemplate(type, t_id, con) {
    var id;
    switch (t_id) {
      case 0:
        id = "default";
        break;
      case 1:
        id = "template1";
        break;
      case 2:
        id = "template2";
        break;
      default:
    }
    const res = await Connection("/updateMsgTemplate", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
      type: type,
      template_id: id,
      con: con,
    });

    if (res.is_success) {
      if (type === "interview_pass") {
        const res2 = await Connection("/updateMsgTemplate", {
          session_key: localStorage.getItem("session_key"),
          club_id: club_id,
          type: "interview_reject",
          template_id: id,
          con: content4,
        });
        if (res2.is_success) {
          window.alert("수정이 완료되었습니다.");
        }
      } else {
        window.alert("수정이 완료되었습니다.");
      }
    }
  }

  const message1 = (isGuideOpen, guideIndex) => (
    <div className="div-managemessage-message-section">
      <div className="div-managemessage-message-title H3">
        서류 불합격 안내 문자
      </div>
      <div className="div-managemessage-message-select-section">
        <div
          className="div-click div-managemessage-message-select-section-01 Body4"
          onClick={() => setTemplate1(0)}
        >
          <img
            className="icon"
            src={template1 === 0 ? RadioButton_Checked : RadioButton}
            alt=""
            style={{ marginRight: "6px" }}
          />
          기본 템플릿
          <ChipMessage state={msgTemplate?.applicant_reject.default.state} />
        </div>
        <div
          className="div-click div-managemessage-message-select-section-01 Body4"
          onClick={() => setTemplate1(1)}
        >
          <img
            className="icon"
            src={template1 === 1 ? RadioButton_Checked : RadioButton}
            alt=""
            style={{ marginRight: "6px" }}
          />
          템플릿 1
          <ChipMessage state={msgTemplate?.applicant_reject.template1.state} />
        </div>
        <div
          className="div-click div-managemessage-message-select-section-01 Body4"
          onClick={() => setTemplate1(2)}
          style={{ position: "relative" }}
        >
          <img
            className="icon"
            src={template1 === 2 ? RadioButton_Checked : RadioButton}
            alt=""
            style={{ marginRight: "6px" }}
          />
          템플릿 2
          <ChipMessage state={msgTemplate?.applicant_reject.template2.state} />
        </div>
      </div>
      <div className="div-managemessage-message-tag-section Body5">
        <div className="div-managemessage-message-tag-chip-section">
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{공고_이름}")}
          >
            # 공고 이름
          </div>
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{동아리_이름}")}
          >
            # 동아리 이름
          </div>
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{지원자_이름}")}
          >
            # 지원자 이름
          </div>
        </div>
        태그 선택 후 원하는 곳에 붙여넣기 하세요.
      </div>
      <GuideDefaultDim
        guideName="template_1"
        guideIndex={guideIndex}
        ownIndex={2}
        isOpen={isGuideOpen}
        CoachMark={false}
      >
        <div className="textarea-managemessage-message-content-section Body4">
          {
            <HighlightWithinTextarea
              value={content1 ? content1 : ""}
              highlight={
                /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
              }
              id="content1"
              onChange={(value) => setContent1(value)}
            />
          }
        </div>
      </GuideDefaultDim>
      {template1 !== 0 && (
        <button
          className="main-black-button button-managemessage-message Sub3"
          onClick={() => {
            if (localStorage.getItem("session_key") === "admin_demo") {
              window.alert("회원가입 후 이용 가능합니다🙂");
              setLoginModal(true);
            } else {
              if (content1 === "") window.alert("메세지 내용이 없습니다.");
              else {
                if (window.confirm("정말 수정하시겠습니까?")) {
                  updateMsgTemplate("applicant_reject", template1, content1);
                }
              }
            }
          }}
        >
          심사 요청
        </button>
      )}
      {template1 === 0 && (
        <>
          <CoachMark
            guideIndex={guideTemplate_1i}
            ownIndex={2}
            isOpen={guideTemplate_1}
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              zIndex: "1231",
            }}
            eclipseStyle={{
              bottom: "25px",
              right: "125px",
              top: "auto",
              left: "auto",
            }}
          >
            <button
              className="main-orange-button button-managemessage-message Sub3"
              onClick={() => setDisplayGuideImg(true)}
            >
              기본 템플릿 예시
            </button>
          </CoachMark>
        </>
      )}
    </div>
  );

  const message2 = (
    <div className="div-managemessage-message-section">
      <div className="div-managemessage-message-title H3">
        면접 시간 안내 문자
      </div>
      {/* 여기 */}
      <div className="div-managemessage-message-select-section">
        <div
          className="div-click div-managemessage-message-select-section-01 Body4"
          onClick={() => setTemplate2(0)}
        >
          <img
            className="icon"
            src={template2 === 0 ? RadioButton_Checked : RadioButton}
            alt=""
            style={{ marginRight: "6px" }}
          />
          기본 템플릿
          <ChipMessage state={msgTemplate?.interview_time.default.state} />
        </div>
        <div
          className="div-click div-managemessage-message-select-section-01 Body4"
          onClick={() => setTemplate2(1)}
        >
          <img
            className="icon"
            src={template2 === 1 ? RadioButton_Checked : RadioButton}
            alt=""
            style={{ marginRight: "6px" }}
          />
          템플릿 1
          <ChipMessage state={msgTemplate?.interview_time.template1.state} />
        </div>
        <div
          className="div-click div-managemessage-message-select-section-01 Body4"
          onClick={() => setTemplate2(2)}
        >
          <img
            className="icon"
            src={template2 === 2 ? RadioButton_Checked : RadioButton}
            alt=""
            style={{ marginRight: "6px" }}
          />
          템플릿 2
          <ChipMessage state={msgTemplate?.interview_time.template2.state} />
        </div>
      </div>
      <div className="div-managemessage-message-tag-section Body5">
        <div className="div-managemessage-message-tag-chip-section">
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{공고_이름}")}
          >
            # 공고 이름
          </div>
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{동아리_이름}")}
          >
            # 동아리 이름
          </div>
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{지원자_이름}")}
          >
            # 지원자 이름
          </div>
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{면접_시간_선택_링크}")}
          >
            # 면접 시간 선택 링크
          </div>
        </div>
        태그 선택 후 원하는 곳에 붙여넣기 하세요.
      </div>
      <div className="textarea-managemessage-message-content-section Body4">
        <HighlightWithinTextarea
          value={content2 ? content2 : ""}
          highlight={
            /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
          }
          id="content2"
          onChange={(value) => setContent2(value)}
        />
      </div>
      {template2 !== 0 && (
        <button
          className="main-black-button button-managemessage-message Sub3"
          onClick={() => {
            if (localStorage.getItem("session_key") === "admin_demo") {
              window.alert("회원가입 후 이용 가능합니다🙂");
              setLoginModal(true);
            } else {
              if (content2 === "") window.alert("메세지 내용이 없습니다.");
              else {
                if (window.confirm("정말 수정하시겠습니까?")) {
                  updateMsgTemplate("interview_time", template2, content2);
                }
              }
            }
          }}
        >
          심사 요청
        </button>
      )}
    </div>
  );

  const message3 = (
    <div className="div-managemessage-message-section">
      <div className="div-managemessage-message-title H3">
        최종 합불 안내 문자
      </div>
      <div className="div-managemessage-message-select-section">
        <div
          className="div-click div-managemessage-message-select-section-01 Body4"
          onClick={() => setTemplate3(0)}
        >
          <img
            className="icon"
            src={template3 === 0 ? RadioButton_Checked : RadioButton}
            alt=""
            style={{ marginRight: "6px" }}
          />
          기본 템플릿
          <ChipMessage state={msgTemplate?.interview_reject.default.state} />
        </div>
        <div
          className="div-click div-managemessage-message-select-section-01 Body4"
          onClick={() => setTemplate3(1)}
        >
          <img
            className="icon"
            src={template3 === 1 ? RadioButton_Checked : RadioButton}
            alt=""
            style={{ marginRight: "6px" }}
          />
          템플릿 1
          <ChipMessage state={msgTemplate?.interview_reject.template1.state} />
        </div>
        <div
          className="div-click div-managemessage-message-select-section-01 Body4"
          onClick={() => setTemplate3(2)}
        >
          <img
            className="icon"
            src={template3 === 2 ? RadioButton_Checked : RadioButton}
            alt=""
            style={{ marginRight: "6px" }}
          />
          템플릿 2
          <ChipMessage state={msgTemplate?.interview_reject.template2.state} />
        </div>
      </div>
      <div className="div-managemessage-message-tag-section Body5">
        <div className="div-managemessage-message-tag-chip-section">
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{공고_이름}")}
          >
            # 공고 이름
          </div>
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{동아리_이름}")}
          >
            # 동아리 이름
          </div>
          <div
            className="main-orange-button div-managemessage-copy-chip Tag1"
            onClick={() => handleCopyClipBoard("#{지원자_이름}")}
          >
            # 지원자 이름
          </div>
        </div>
        태그 선택 후 원하는 곳에 붙여넣기 하세요.
      </div>
      <div className="div-managemessage-message-content-section-title Sub3">
        합격자
      </div>
      <div className="textarea-managemessage-message-content-section-pass Body4">
        <HighlightWithinTextarea
          value={content3 ? content3 : ""}
          highlight={
            /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
          }
          id="content3"
          onChange={(value) => setContent3(value)}
        />
      </div>
      <div
        className="div-managemessage-message-content-section-title Sub3"
        style={{ marginTop: "7.5px" }}
      >
        불합격자
      </div>
      <div className="textarea-managemessage-message-content-section-fail Body4">
        <HighlightWithinTextarea
          value={content4 ? content4 : ""}
          highlight={
            /#{공고_이름}|#{동아리_이름}|#{지원자_이름}|#{면접_시간_선택_링크}/g
          }
          id="content4"
          onChange={(value) => setContent4(value)}
        />
      </div>
      {template3 !== 0 && (
        <button
          className="main-black-button button-managemessage-message Sub3"
          onClick={() => {
            if (localStorage.getItem("session_key") === "admin_demo") {
              window.alert("회원가입 후 이용 가능합니다🙂");
              setLoginModal(true);
            } else {
              if (content3 === "" || content4 === "")
                window.alert("메세지 내용이 없습니다.");
              else {
                if (window.confirm("정말 수정하시겠습니까?")) {
                  updateMsgTemplate("interview_pass", template3, content3);
                }
              }
            }
          }}
        >
          심사 요청
        </button>
      )}
    </div>
  );

  const [showInfo, setShowInfo] = useState(false);

  const dispatch = useDispatch();
  const onChangeGuide = (value) => dispatch(setGuide(value));
  const onChangeIndex = (index) => dispatch(setGuideIndex(index));

  const guideTemplate_1 = useSelector((state) => state.guide.template_1);
  const guideTemplate_1i = useSelector((state) => state.guide.template_1i);

  useEffect(() => {
    onChangeIndex(0);
    const guide = JSON.parse(window.localStorage.getItem("guide"));
    if (guide?.template_1) {
      onChangeGuide(false);
    } else {
      onChangeGuide(true);
    }
    return () => onChangeGuide(false);
  }, []);

  return (
    <>
      <div className="div-managemessage-section">
        <div className="div-managemessage-title H3">
          <div style={{ display: "flex", alignItems: "center", gap: "9px" }}>
            📩 합불 메세지 관리
            <div
              className="div-click div-manage-section-03-01-01-01-reset Tag1"
              onClick={async () => {
                if (!isToastPopupOpen) {
                  getMsgTemplate();
                  onToggle();
                }
              }}
            >
              <img src={Icon_Reset} alt="" style={{ width: "18px" }} />
              새로고침
            </div>
            {isToastPopupOpen && (
              <ToastPopup
                status={"success"}
                children={"새로고침이 완료되었습니다."}
                className={`${animation1} Tag1`}
              />
            )}
          </div>
          <div style={{ position: "relative" }}>
            <img
              className="icon"
              src={Icon_Question}
              alt=""
              onMouseOver={() => {
                setShowInfo(true);
              }}
              onMouseLeave={() => {
                setShowInfo(false);
              }}
            />
            {showInfo && (
              <ToastPopup
                className="popup-managemessage-info Body4"
                status="Gray_info"
              >
                광고성 문구를 포함한 경우, 심사과정에서 반려가 될 수 있습니다.
              </ToastPopup>
            )}
          </div>
        </div>
        <div className="div-managemessage-sub Sub1">
          ✔ 발송 내용을 미리 작성해주세요. 카카오 심사 과정에서 등록 완료된
          템플릿만 사용할 수 있습니다.
          <t className="Body3"> ※ 1000자 이내</t>
          <br />✔ 메세지는 카카오톡 플러스 친구 '동들(Dongdle)'을 통해
          발송됩니다.
          <t className="Body3"> ※ 특수기호가 사용되는 경우 LMS로 발송</t>
          <br />✔ 면접이 없는 '서류' 전형을 진행하실 경우 불합격 안내 문자는
          '서류 불합격 안내 문자'에,
          <br />
          &ensp;&ensp;합격 안내 문자는 '최종 합불 안내 문자(합격자)'에
          등록해주세요.
          <br />✔ 등록에는 1~3일이 소요됩니다. 빠른 승인을 위해 심사 요청 후
          카카오톡 채널 문의하기를 이용해주세요.😉
        </div>
        <div
          className={
            isLeftSectionOpen
              ? "div-managemessage-message-group-section"
              : "div-managemessage-message-group-section-wide"
          }
          id="row-scroll"
        >
          {message1(guideTemplate_1, guideTemplate_1i)}
          {message2}
          {message3}
        </div>
      </div>
      {displayGuideImg && (
        <div
          className="div-modal-background"
          onClick={() => {
            setDisplayGuideImg(false);
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <img
              style={{
                height: "258px",
                width: "278px",
                borderRadius: "8px",
              }}
              src={message_ex}
              alt=""
            />
            <img
              style={{
                width: "18px",
                height: "18px",
                position: "absolute",
                top: "9px",
                right: "9px",
                cursor: "pointer",
              }}
              src={Icon_Close}
              alt=""
              onClick={() => setDisplayGuideImg(false)}
            />
          </div>
        </div>
      )}
      {loginModal && <Loginform setModalOpen={setLoginModal} />}
    </>
  );
}

export default ManageMessage;
