import CommunityConnection from "./CommunityConnection";

export default async function IsManager(session_key, club_id) {
  const res = await CommunityConnection("/IsManager", {
    id: club_id,
  });
  if (res.is_success) {
    return res.is_manager;
  }
}
