import React, { useEffect, useState } from "react";
import "../../asset/css/community/community.css";
import "../../asset/css/community/Home.css";
import logo from "../../asset/header/logo.svg";
import event_banner from "../../asset/Home/event_banner.png";
import _3dicons_1 from "../../asset/icon/_3dicons_1.png";
import _3dicons_2 from "../../asset/icon/_3dicons_2.png";
import _3dicons_3 from "../../asset/icon/_3dicons_3.png";
import CommunityConnection from "./CommunityConnection";
import Icon_View from "../../asset/icon/Icon_View_6A6A6A.svg";
import Icon_Comment from "../../asset/icon/Icon_Comment_6A6A6A.svg";
import Icon_Thumbsup_Gray from "../../asset/icon/Icon_Thumbsup_Gray.svg";
import Icon_Notification from "../../asset/icon/Icon_Notification.svg";
import Icon_Search_9F9F9F from "../../asset/icon/Icon_Search_9F9F9F.svg";
import Icon_Edit_White from "../../asset/icon/Icon_Edit_White.svg";
import Article from "./Article";
import { detailDate } from "./detailDate";
import LoginformM from "../LoginformM";
import SearchPage from "./SearchPage";
import WriteArticle from "./WriteArticle";
import MyInterests from "./MyInterests";

function Home() {
  const session_key = window.localStorage.getItem("session_key");
  const param_path = window.location.pathname.split("/");
  const club_id = "986cccae-6181-45a5-a4cb-47c1e1065c7d";
  const board_id = "14a0b9f2-8a30-49fe-8369-2027ca5c4ee4";
  const [menu, setMenu] = useState(parseInt(param_path[3]));
  const [article, setArticle] = useState();
  const [isArticleOpen, setIsArticleOpen] = useState(param_path.length === 5);
  const [isWriteSectionOpen, setIsWriteSectionOpen] = useState(false);

  const [allBoardInfo, setAllBoardInfo] = useState();
  async function getAllBoardInfo() {
    const res = await CommunityConnection("/getAllBoardInfo");
    if (res.is_success) {
      setAllBoardInfo(res.boardInfo);
    }
  }
  const [allClubInfo, setAllClubInfo] = useState();
  async function getAllClubInfo() {
    const res = await CommunityConnection("/getAllClubInfo");
    if (res.is_success) {
      setAllClubInfo(res.clubInfo);
    }
  }
  const [allArticle, setAllArticle] = useState();
  async function getAllArticle() {
    const res = await CommunityConnection("/getAllArticle");
    if (res.is_success) {
      setAllArticle(res.article);
    }
  }

  async function getBoardArticle() {
    const res = await CommunityConnection("/getBoardArticle", {
      is_all: true,
      id: club_id,
      club_id: club_id,
    });
    if (res.is_success) {
      setArticle(res.article);
    }
  }

  const [interestsPage, setInterestsPage] = useState(false);
  const checkInterests = async () => {
    CommunityConnection("/getInterests", {
      session_key: window.localStorage.session_key,
    }).then((res) => {
      if (res.is_success) {
        if (!res.interests) {
          alert("관심사 설정을 완료해주세요!");
          setInterestsPage(true);
        }
      }
    });
  };

  useEffect(() => {
    getAllBoardInfo();
    getAllClubInfo();
    getAllArticle();
    getBoardArticle();
    if (session_key) checkInterests();
  }, []);

  useEffect(() => {
    isArticleOpen === false && getBoardArticle();
    isArticleOpen === false && getAllArticle();
  }, [isArticleOpen]);

  useEffect(() => {
    const handlePopState = () => {
      setIsArticleOpen(false);
    };
    window.addEventListener("popstate", handlePopState);

    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  async function updateViewCnt(id) {
    const res = await CommunityConnection("/updateViewCnt", {
      id: id,
    });
  }
  const [selectedArticle, setSelectedArticle] = useState();
  function openArticle(article) {
    setSelectedArticle(article);
    updateViewCnt(article.id);
    setIsArticleOpen(true);
    const state = { article_id: article.id };
    window.history.pushState(
      state,
      "",
      window.location.href + "/" + article.id
    );
  }

  useEffect(() => {
    window.history.pushState({}, "", menu);
  }, [menu]);

  async function completeWriting() {
    setIsWriteSectionOpen(false);
    getBoardArticle();
    window.scrollTo(0, 0);
  }

  const showArticle = (
    <>
      {article?.map(
        (article) =>
          (menu !== 0 || article.like_cnt > 0) && (
            <div
              className="div-club-main-board-article-section"
              style={{ width: "100vw" }}
              onClick={() => {
                openArticle(article);
              }}
            >
              <div className="div-club-main-board-article-text">
                <div className="div-club-main-board-article-title">
                  {article.title}
                </div>
                <div className="div-club-main-board-article-detail-section">
                  <div className="div-club-main-board-article-detail">
                    {article.nickname}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    {detailDate(new Date(article.created_at))}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    <img src={Icon_View} alt="" />
                    {article.view_cnt}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    <img src={Icon_Thumbsup_Gray} alt="" />
                    {article.like_cnt}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    <img src={Icon_Comment} alt="" />
                    {article.reply_cnt}
                  </div>
                </div>
              </div>
              {article.images && (
                <img
                  className="img-club-main-board-article"
                  src={article.images}
                  alt=""
                />
              )}
            </div>
          )
      )}
    </>
  );

  const showAllArticle = (
    <>
      {allArticle?.map((article) => (
        <div
          className="div-club-main-board-article-section"
          style={{ width: "100vw" }}
          onClick={() => {
            openArticle(article);
          }}
        >
          <div className="div-club-main-board-article-text">
            <div className="div-club-main-board-article-category">
              {allClubInfo?.find((club) => club.id === article.club_id)?.name} /{" "}
              {
                allBoardInfo?.find((board) => board.id === article.board_id)
                  ?.name
              }
            </div>
            <div className="div-club-main-board-article-title">
              {article.title}
            </div>
            <div className="div-club-main-board-article-detail-section">
              <div className="div-club-main-board-article-detail">
                {article.nickname}
              </div>
              <div
                style={{
                  width: "1px",
                  height: "12px",
                  background: "#ECECEC",
                }}
              />
              <div className="div-club-main-board-article-detail">
                {detailDate(new Date(article.created_at))}
              </div>
              <div
                style={{
                  width: "1px",
                  height: "12px",
                  background: "#ECECEC",
                }}
              />
              <div className="div-club-main-board-article-detail">
                <img src={Icon_View} alt="" />
                {article.view_cnt}
              </div>
              <div
                style={{
                  width: "1px",
                  height: "12px",
                  background: "#ECECEC",
                }}
              />
              <div className="div-club-main-board-article-detail">
                <img src={Icon_Thumbsup_Gray} alt="" />
                {article.like_cnt}
              </div>
              <div
                style={{
                  width: "1px",
                  height: "12px",
                  background: "#ECECEC",
                }}
              />
              <div className="div-club-main-board-article-detail">
                <img src={Icon_Comment} alt="" />
                {article.reply_cnt}
              </div>
            </div>
          </div>
          {article.images && (
            <img
              className="img-club-main-board-article"
              src={article.images}
              alt=""
            />
          )}
        </div>
      ))}
    </>
  );

  const [loginOpen, setLoginOpen] = useState(false);

  const [isSearchPageOpen, setIsSearchPageOpen] = useState(false);
  return (
    <>
      {interestsPage && <MyInterests setPage={setInterestsPage} />}
      {loginOpen && <LoginformM setModalOpen={setLoginOpen} />}
      <div
        className="div-community-section"
        style={{ overflow: isSearchPageOpen && "hidden" }}
      >
        <div className="div-community">
          {isSearchPageOpen ? (
            article && (
              <SearchPage
                article={article}
                setIsOpen={setIsSearchPageOpen}
                openArticle={openArticle}
              />
            )
          ) : (
            <>
              <div className="div-home-header-section">
                <img
                  className="img-home-header-logo"
                  src={logo}
                  alt=""
                  onClick={() => (window.location.href = "/")}
                />
                <div className="div-home-header-search-notification-section">
                  <div
                    className="div-home-header-search"
                    onClick={() => setIsSearchPageOpen(true)}
                  >
                    <input
                      className="Body4M"
                      placeholder="전체 커뮤니티 글 검색"
                    />
                    <img src={Icon_Search_9F9F9F} alt="" />
                  </div>
                  {/* <img
                    className="img-home-header-notification"
                    src={Icon_Notification}
                    alt=""
                  /> */}
                </div>
              </div>
            </>
          )}
          <div className="div-home-section">
            <div className="div-home-event-section">
              <img
                className="img-event"
                src={event_banner}
                alt=""
                onClick={() =>
                  window.open("https://www.instagram.com/dongdle_official/")
                }
              />
            </div>
            <div className="div-home-intro-section Sub3M">
              안녕하세요😀
              <br />
              {session_key ? (
                <>{window.localStorage.getItem("name")}님!</>
              ) : (
                <>
                  동아리의 모든 것, 동들입니다.
                  <br />
                  <button
                    className="main-orange-button Sub3M"
                    onClick={() => {
                      setLoginOpen(true);
                    }}
                  >
                    로그인 및 회원가입
                  </button>
                </>
              )}
              <div className="div-menu-section">
                <div
                  className="div-menu Tag1M"
                  onClick={() =>
                    (window.location.href = "/community/myclub/0/home/0")
                  }
                >
                  <img src={_3dicons_1} alt="" />내 동아리
                </div>
                <div
                  className="div-menu Tag1M"
                  onClick={() => (window.location.href = "/community/club")}
                >
                  <img src={_3dicons_2} alt="" />
                  동아리 찾기
                </div>
                <div
                  className="div-menu Tag1M"
                  onClick={() => {
                    if (session_key) setIsWriteSectionOpen(true);
                    else alert("로그인 후 이용 가능합니다.");
                  }}
                >
                  <img src={_3dicons_3} alt="" />글 작성하기
                </div>
              </div>
            </div>
            <div className="div-community-line" />
            <div className="div-home-title Sub1M">✍️ 전체 커뮤니티</div>
            <div className="div-home-article-section">
              <div className="div-home-menu-section">
                <div
                  className={
                    menu === 0
                      ? "div-club-main-menu-checked Sub3M"
                      : "div-club-main-menu Sub3M"
                  }
                  onClick={() => setMenu(0)}
                >
                  인기글
                </div>
                <div
                  className={
                    menu === 1
                      ? "div-club-main-menu-checked Sub3M"
                      : "div-club-main-menu Sub3M"
                  }
                  onClick={() => setMenu(1)}
                >
                  전체글
                </div>
                <div
                  className={
                    menu === 2
                      ? "div-club-main-menu-checked Sub3M"
                      : "div-club-main-menu Sub3M"
                  }
                  onClick={() => setMenu(2)}
                >
                  동아리글
                </div>
              </div>
              <div>{menu === 2 ? showAllArticle : showArticle}</div>
            </div>
            <div style={{ height: "100px" }} />
          </div>
        </div>
        <button
          className="bounce-once main-orange-buttonM button-club-main-board-write Body4M"
          onClick={() => {
            if (session_key) setIsWriteSectionOpen(true);
            else alert("로그인 후 이용 가능합니다.");
          }}
        >
          <img src={Icon_Edit_White} alt="" />글 작성
        </button>
        {isWriteSectionOpen && (
          <WriteArticle
            setIsOpen={setIsWriteSectionOpen}
            boardId={board_id}
            clubId={club_id}
            isPrivate={0}
            complete={completeWriting}
          />
        )}
        {isArticleOpen && (
          <Article
            setIsOpen={setIsArticleOpen}
            articleInfo={selectedArticle}
            board={{ name: "전체 커뮤니티" }}
          />
        )}
      </div>
    </>
  );
}

export default Home;
