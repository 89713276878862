import React, { useEffect, useState } from "react";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import Connection from "./Connection";
import EditQuestion from "./EditQuestion";
import MakeFormInfo from "./MakeFormInfo";
import SelectedQuestion from "./SelectedQuestion";
import SelectQustion from "./SelectQustion";
import ShowQuestion from "./ShowQuestion";
import Loginform from "./Loginform";
import GuideModal, { CoachMark } from "./guideModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setMakeForm1,
  setMakeForm1i,
  setMakeForm2,
  setMakeForm2i,
  setMakeForm3,
  setMakeForm3i,
} from "../modules/guide";

function MakeForm({
  setModal,
  setModalNum,
  club_id,
  recruit_id,
  info,
  clubInfo,
  recruitDocs,
}) {
  const [questionList, setQuestionList] = useState(null);
  const [qNum, setQNum] = useState(0);
  const [step, setStep] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const [loginModal, setLoginModal] = useState(false);

  useEffect(() => {
    if (recruitDocs.question) {
      setQuestionList(recruitDocs.question.filter((q, idx) => idx > 3));
      setCurrentQuestion(recruitDocs.question[4]);
      setStep(1);
    }
  }, []);

  const default_question = [
    { k: "🏫학교/학과/학년", q: "학교/학과/학년을 알려주세요.", type: 7 },
    { k: "🏠거주지", q: "거주지는 어디신가요?", type: 5 },
    { k: "🍻주량", q: "주량을 알려주세요!", type: 20 },
    {
      k: "🍀MBTI",
      q: "MBTI를 알려주세요!",
      type: 21,
    },
    {
      k: "✅약관동의",
      q: "약관/회칙",
      type: 22,
    },
    { k: "🎴사진", q: "본인 확인용 사진을 업로드해주세요!", type: 19 },
  ];

  let recommended_question = [];
  let clubType = "";
  if (info.is_success && clubInfo.is_success) {
    const clubCategory = clubInfo.category[0];
    clubType = info.category[clubCategory];
    Object.values(info.recommend_question).map((question) => {
      if (question.is_default === clubCategory) {
        recommended_question.push(question);
      }
    });
  }

  const [checkedDefaultQuestion, setCheckedDefaultQuestion] = useState(
    new Array(default_question.length).fill(true)
  );

  const [checkedRecommendedQuestion, setCheckedRecommendedQuestion] = useState(
    new Array(recommended_question.length).fill(false)
  );

  useEffect(() => {
    if (
      questionList &&
      questionList.filter((q) => q.id === currentQuestionId).length === 0
    ) {
      setCurrentQuestion(questionList[0]);
      setCurrentQuestionId(questionList[0].id);
    }
  }, [questionList]);

  const [isEditOn, setIsEditOn] = useState(true);

  // redux: 가이드 state
  const makeform_1 = useSelector((state) => state.guide.makeform_1);
  const makeform_1i = useSelector((state) => state.guide.makeform_1i);
  const makeform_2 = useSelector((state) => state.guide.makeform_2);
  const makeform_2i = useSelector((state) => state.guide.makeform_2i);
  const makeform_3 = useSelector((state) => state.guide.makeform_3);
  const makeform_3i = useSelector((state) => state.guide.makeform_3i);

  // redux: guide action
  const dispatch = useDispatch();
  const onChangeValue1 = (value) => dispatch(setMakeForm1(value));
  const onChangeIndex1 = (index) => dispatch(setMakeForm1i(index));
  const onChangeValue2 = (value) => dispatch(setMakeForm2(value));
  const onChangeIndex2 = (index) => dispatch(setMakeForm2i(index));
  const onChangeValue3 = (value) => dispatch(setMakeForm3(value));
  const onChangeIndex3 = (index) => dispatch(setMakeForm3i(index));

  useEffect(() => {
    const guide = JSON.parse(window.localStorage.getItem("guide"));

    onChangeIndex3(0);
    switch (step) {
      case 0:
        onChangeIndex1(0);
        if (guide?.makeform_1) {
          onChangeValue1(false);
        } else {
          onChangeValue1(true);
        }
        break;
      case 1:
        if (isEditOn) {
          onChangeIndex2(0);
          if (guide?.makeform_2) {
            onChangeValue2(false);
          } else {
            onChangeValue2(true);
          }
        } else {
          onChangeIndex3(0);
          if (guide?.makeform_3) {
            onChangeValue3(false);
          } else {
            onChangeValue3(true);
          }
        }
      default:
        break;
    }
  }, [step, isEditOn]);

  return (
    <>
      <GuideModal
        contents={[
          "동들에서 제공하는 추천 질문을 추가/삭제하여 질문을 구성할 수 있어요!",
          "다른 동아리의 질문을 살펴볼 수 있어요.\n체크 시 질문을 추가할 수 있어요!",
        ]}
        size={2}
        guideName="makeform_1"
        guideIndex={makeform_1i}
        onChangeIndex={onChangeIndex1}
        modal={makeform_1}
        setModal={onChangeValue1}
        showIndex={true}
        position="right"
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
      />
      <GuideModal
        contents={[
          "수정모드 시 지원폼을 작성 및 확인할 수 있어요!",
          "수정모드에서 질문의 키워드 및 내용을 직접 입력 및 수정할 수 있어요.",
          "질문에 맞춰, 답변 스타일을 설정할 수 있어요.",
          "미리보기 시 질문 수정은 불가하며, \n지원자가 실제 보게 될 양식을 확인할 수 있어요.",
        ]}
        size={4}
        guideName="makeform_2"
        guideIndex={makeform_2i}
        onChangeIndex={onChangeIndex2}
        modal={makeform_2}
        setModal={onChangeValue2}
        showIndex={true}
        position="right"
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
      />
      <GuideModal
        contents={[
          "질문 추가 버튼을 눌러, 새로운 질문을 추가해 보세요!",
          "좌측 핸들을 통해 질문 순서를 변경할 수 있어요.",
          "우측 더보기 버튼을 통해 질문을 삭제할 수 있어요.",
        ]}
        size={3}
        guideName="makeform_3"
        guideIndex={makeform_3i}
        onChangeIndex={onChangeIndex3}
        modal={makeform_3}
        setModal={onChangeValue3}
        showIndex={true}
        position="right"
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
      />
      <div
        style={
          makeform_1 || makeform_2 || makeform_3
            ? { pointerEvents: "none" }
            : null
        }
      >
        <div className="div-formmaking-header-section">
          <div className="div-formmaking-header-section-01 Sub1">
            📣 지원폼 만들기
          </div>
          <div className="div-formmaking-header-section-02 Sub3">
            <img
              className="icon"
              src={Icon_Close}
              alt=""
              onClick={() => {
                if (
                  window.confirm(
                    "모달을 닫을시 현재까지 작성한 지원폼이 초기화됩니다."
                  )
                ) {
                  setModal(false);
                }
              }}
            />
          </div>
        </div>
        <div className="div-formmaking-section">
          <div className="div-formmaking-leftright-section">
            <div className="div-formmaking-left-section Sub1">
              {step === 0 && <MakeFormInfo />}
              {step === 1 && (
                <div className="div-edit-background">
                  <div
                    className="div-edit-mode-section"
                    style={{ position: "relative " }}
                  >
                    <CoachMark
                      isOpen={makeform_2}
                      guideIndex={makeform_2i}
                      ownIndex={1}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <button
                        className={
                          isEditOn
                            ? "button-edit-mode-selected Sub3"
                            : "button-edit-mode Sub3"
                        }
                        onClick={() => setIsEditOn(true)}
                      >
                        수정모드
                      </button>
                    </CoachMark>
                    <CoachMark
                      isOpen={makeform_2}
                      guideIndex={makeform_2i}
                      ownIndex={4}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <button
                        className={
                          !isEditOn
                            ? "button-edit-mode-selected Sub3"
                            : "button-edit-mode Sub3"
                        }
                        onClick={() => setIsEditOn(false)}
                      >
                        미리보기
                      </button>
                    </CoachMark>
                  </div>
                  {isEditOn ? (
                    <EditQuestion
                      currentQuestion={
                        questionList &&
                        questionList.filter((q) => q.id === currentQuestionId)
                          .length === 0
                          ? questionList[0]
                          : currentQuestion
                      }
                      questionList={questionList}
                      setQuestionList={setQuestionList}
                      currentQuestionId={
                        questionList &&
                        questionList.filter((q) => q.id === currentQuestionId)
                          .length === 0
                          ? questionList[0].id
                          : currentQuestionId
                      }
                      qNum={qNum}
                    />
                  ) : (
                    <ShowQuestion
                      currentQuestion={
                        questionList &&
                        questionList.filter((q) => q.id === currentQuestionId)
                          .length === 0
                          ? questionList[0]
                          : currentQuestion
                      }
                      questionList={questionList}
                      currentQuestionId={
                        questionList &&
                        questionList.filter((q) => q.id === currentQuestionId)
                          .length === 0
                          ? questionList[0].id
                          : currentQuestionId
                      }
                      qNum={qNum}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="div-formmaking-right-section">
              {step === 0 && (
                <SelectQustion
                  club_type={clubType}
                  default_question={default_question}
                  recommended_question={recommended_question}
                  checkedDefaultQuestion={checkedDefaultQuestion}
                  checkedRecommendedQuestion={checkedRecommendedQuestion}
                  setCheckedDefaultQuestion={setCheckedDefaultQuestion}
                  setCheckedRecommendedQuestion={setCheckedRecommendedQuestion}
                />
              )}
              {step === 1 && (
                <SelectedQuestion
                  currentQuestion={currentQuestion}
                  setCurrentQuestion={setCurrentQuestion}
                  currentQuestionId={currentQuestionId}
                  setCurrentQuestionId={setCurrentQuestionId}
                  questionList={questionList}
                  setQuestionList={setQuestionList}
                  setQNum={setQNum}
                />
              )}
              <div className="div-formmaking-right-section-button">
                <button
                  className="button-formmaking-right-section-pre Sub3"
                  onClick={() => {
                    if (step === 0) {
                      setModalNum(1);
                    } else {
                      if (
                        window.confirm(
                          "이전 단계로 이동할시 현재까지 작성한 지원폼이 초기화됩니다."
                        )
                      ) {
                        setStep(step - 1);
                        setQuestionList(null);
                        setCurrentQuestion(null);
                        setCurrentQuestionId(0);
                      }
                    }
                  }}
                >
                  이전 단계
                </button>
                <button
                  className="button-formmaking-right-section-next Sub3"
                  onClick={async () => {
                    if (step === 0) {
                      setIsEditOn(true);
                      if (questionList === null) {
                        const tmp1 = await default_question
                          .filter((q, idx) => checkedDefaultQuestion[idx])
                          .concat(
                            recommended_question.filter(
                              (q, idx) => checkedRecommendedQuestion[idx]
                            )
                          );
                        const tmp2 = await tmp1.map((s, idx) => ({
                          id: idx,
                          ...tmp1[idx],
                        }));

                        if (tmp2.length === 0) {
                          setCurrentQuestion({
                            id: 0,
                            k: "📍키워드",
                            q: "질문을 입력해주세요.",
                            type: 9,
                            option: [],
                            multi: false,
                          });
                          setQuestionList([
                            {
                              id: 0,
                              k: "📍키워드",
                              q: "질문을 입력해주세요.",
                              type: 9,
                              option: [],
                              multi: false,
                            },
                          ]);
                        } else {
                          setCurrentQuestion(tmp2[currentQuestionId]);
                          setQuestionList(tmp2);
                        }
                      }
                      setStep(step + 1);
                    } else {
                      if (window.confirm("지원폼 작성을 완료하시겠습니까?")) {
                        if (
                          localStorage.getItem("session_key") === "admin_demo"
                        ) {
                          window.alert("회원가입 후 이용 가능합니다🙂");
                          setLoginModal(true);
                        } else {
                          const ret = await Connection(
                            "/updateRecruitQuestion",
                            {
                              session_key: localStorage.getItem("session_key"),
                              club_id: club_id,
                              recruit_id: recruit_id,
                              question_data: JSON.stringify(
                                [
                                  { k: "이름", type: 1 },
                                  { k: "연락처", type: 2 },
                                  { k: "생년월일", type: 3 },
                                  { k: "성별", type: 12 },
                                ].concat(questionList)
                              ),
                            }
                          );
                          if (ret.is_success) {
                            window.alert("지원폼 작성이 완료되었습니다.");
                            setModal(false);
                            const session_key =
                              window.localStorage.getItem("session_key");
                            const cbu = await Connection("/getClubByUser", {});

                            const ci = await Connection("/getClubInfo", {
                              club_id: cbu.club[0].id,
                            });

                            const club_id = cbu.club[0].id;
                            const recruit_id =
                              ci.recruit.length > 0
                                ? ci.recruit[0].id
                                : "no_recruit";

                            window.location.href =
                              "/" + club_id + "/" + recruit_id;
                          }
                        }
                      }
                    }
                  }}
                >
                  {step === 0 ? "다음 단계" : "작성 완료"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {loginModal && <Loginform setModalOpen={setLoginModal} />}
      </div>
    </>
  );
}

export default MakeForm;
