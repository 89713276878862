import React, { useState } from "react";
import logo from "../asset/header/logo.svg";
import Icon_Menu from "../asset/icon/Icon_Menu.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import LoginformM from "./LoginformM";
import Connection from "./Connection";

function HeaderMainM() {
  const [loginOpen, setLoginOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [animation1, setAnimation1] = useState("");
  const [animation2, setAnimation2] = useState("");

  const onToggle = () => {
    if (isMenuOpen) {
      setAnimation1("closeAnimation1");
      setAnimation2("closeAnimation2");
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 270);
    } else {
      setAnimation1("openAnimation1");
      setAnimation2("openAnimation2");
      setIsMenuOpen(true);
    }
  };

  const showMenu = (
    <div className={`div-mainm-menu-section ${animation1}`}>
      <div className="div-mainm-menu-section-top">
        <img
          className="div-click img-headerMainM"
          src={Icon_Close}
          alt=""
          onClick={onToggle}
        />
      </div>
      {!localStorage.getItem("session_key") && (
        <button
          className="main-black-button button-mainm-menu Sub1M"
          onClick={() => {
            if (window.localStorage.session_key === undefined) {
              setLoginOpen(true);
              onToggle();
            } else {
              alert("이미 로그인 하였습니다.");
            }
          }}
        >
          간편 회원가입
        </button>
      )}
      {localStorage.getItem("session_key") === "admin_demo" && (
        <button
          className="main-black-button button-mainm-menu Sub1M"
          onClick={() => {
            setLoginOpen(true);
            onToggle();
          }}
        >
          간편 회원가입
        </button>
      )}
      {window.localStorage.session_key === undefined ? (
        <button
          className="outlined-black-button button-mainm-menu Sub1M"
          onClick={() => {
            setLoginOpen(true);
            onToggle();
          }}
        >
          로그인
        </button>
      ) : (
        <button
          className="outlined-black-button button-mainm-menu Sub1M"
          onClick={async () => {
            if (window.localStorage.session_key) {
              const cbu = await Connection("/getClubByUser", {
                session_key: window.localStorage.session_key
              });
              if (!cbu.club.length) {
                window.location.href = "/no_club/manage";
              } else {
                const club_id = cbu.club[0].id;
                window.location.href = "/" + club_id + "/manage";
              }
            }
          }}
        >
          동아리 관리
        </button>
      )}
      <div className="div-mainm-menu-section-01">
        <div
          className="div-click Sub1M"
          onClick={() => {
            window.open("http://pf.kakao.com/_xnQWxfxj");
          }}
        >
          도입 문의
        </div>
        <div
          className="div-click Sub1M"
          onClick={() => {
            window.open(
              "https://www.notion.so/dongdle/cd13a0627d1748bfb9503a53e8e22874"
            );
          }}
        >
          가이드북
        </div>
        {localStorage.getItem("session_key") &&
          localStorage.getItem("session_key") !== "admin_demo" && (
            <div
              className="div-click Sub1M"
              onClick={() => {
                localStorage.clear();
                window.location.href = "/";
              }}
            >
              로그아웃
            </div>
          )}
      </div>
    </div>
  );
  return (
    <>
      {loginOpen ? <LoginformM setModalOpen={setLoginOpen} /> : <></>}
      <div className="div-headerM-section">
        <img
          className="img-headerM"
          src={logo}
          alt=""
          onClick={() => {
            if (localStorage.getItem("session_key") === "admin_demo") {
              localStorage.clear();
            }
            window.location.href = "/";
          }}
        />
        <img
          className="div-click img-headerM"
          src={Icon_Menu}
          alt=""
          onClick={onToggle}
        />
      </div>
      {isMenuOpen && (
        <div className={`div-mainm-menu-background ${animation2}`}>
          {showMenu}
          <div className="div-mainm-menu-background-left" onClick={onToggle} />
        </div>
      )}
    </>
  );
}

export default HeaderMainM;
