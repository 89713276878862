import React from "react";
import "../asset/css/ToastPopup.css";
import Icon_Info from "../asset/icon/Icon_Info.svg";
import Icon_Success from "../asset/icon/Icon_Success.svg";
import Icon_Error from "../asset/icon/Icon_Error.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";

ToastPopup.defaultProps = {
  close: false,
  icon: false,
  status: "error", // or 'warning' 'success' 'info'
  className: "-Body5",
};

export default function ToastPopup({
  id,
  className,
  status,
  icon,
  close,
  children,
  setModalDisplay,
}) {
  const getIcon = (status) => {
    switch (status) {
      case "error":
        return Icon_Error;
      case "warning":
        return Icon_Error;
      case "success":
        return Icon_Success;
      case "info":
        return Icon_Info;
      case "Gray_info":
        return Icon_Info;
      default:
        return;
    }
  };

  return (
    <div
      id={id}
      className={`ToastPopup ToastPopup-${status} ${className}`}
      style={{ display: "flex", flexShrink: "0" }}
    >
      {icon && <img src={getIcon(status)} alt="" />}
      {children}
      {close && (
        <img src={Icon_Close} alt="" onClick={() => setModalDisplay(false)} />
      )}
    </div>
  );
}
