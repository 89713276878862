import React, { useEffect, useRef, useState } from "react";
import "../asset/css/toggle.css";
import Icon_Plus from "../asset/icon/Icon_Plus.svg";
import Icon_Search from "../asset/icon/Icon_Search.svg";
import Icon_Delete from "../asset/icon/Icon_Delete.svg";
import CheckBox from "../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../asset/icon/CheckBox_Checked.svg";
import "../asset/css/editPolicy.css";
import { CoachMark } from "./guideModal";
import { useSelector } from "react-redux";

function EditQuestion({
  currentQuestion,
  questionList,
  setQuestionList,
  currentQuestionId,
  qNum,
}) {
  const [isChecked, setIsChecked] = useState(false);

  const [answer, setAnswer] = useState([{}]);

  useEffect(() => {
    setAnswer(
      (currentQuestion.type === 9 || currentQuestion.type === 13) &&
        questionList.filter((question) => question.id === currentQuestionId)[0]
          .option.length > 0
        ? questionList.filter(
            (question) => question.id === currentQuestionId
          )[0].option
        : [{ id: 0, a: "답변 1" }]
    );
  }, [currentQuestion]);

  useEffect(() => {
    setQuestionList(
      questionList.map((q, q_idx) =>
        q.id === currentQuestionId
          ? { ...questionList[q_idx], option: answer }
          : q
      )
    );
  }, [answer]);

  const showType13 = (
    <>
      <div className="div-edit-type-04" id="type4">
        {answer.map((a, id) => (
          <div className="div-edit-type-04-option">
            <input
              className="input-edit-type-04 Sub3"
              value={a.a}
              onChange={(e) => {
                setAnswer(
                  answer.map((a, a_idx) =>
                    a_idx === id ? { id: id, a: e.target.value } : a
                  )
                );
              }}
            />
            <img
              className="icon img-edit-type-04-delete"
              src={Icon_Delete}
              alt=""
              onClick={() => {
                if (answer.length === 1) {
                  window.alert("답변은 최소 1개 이상 있어야합니다.");
                } else {
                  setAnswer(answer.filter((a, a_idx) => a_idx !== id));
                }
              }}
            />
          </div>
        ))}
        <button
          className="outlined-black-button button-edit-type-04"
          onClick={() => {
            if (answer.length < 15) {
              setAnswer([
                ...answer,
                {
                  id: answer[answer.length - 1].id + 1,
                  a: "답변 " + (answer[answer.length - 1].id + 2),
                },
              ]);
            } else {
              window.alert("답변은 최대 15개까지 추가 가능합니다.");
            }
          }}
        >
          <img src={Icon_Plus} alt="" style={{ width: "18px" }} />
        </button>
      </div>
    </>
  );

  useEffect(() => {
    currentQuestion.type === 9 &&
      document.getElementById("type4").scrollTo({
        top: document.getElementById("type4").scrollHeight,
        behavior: "smooth",
      });
  }, [answer]);

  const showType9 = (
    <>
      <div
        className="div-click div-edit-section-checkbox Body4"
        onClick={() => {
          setIsChecked(!isChecked);
          setQuestionList(
            questionList.map((q, q_idx) =>
              q.id === currentQuestionId
                ? {
                    ...q,
                    multi: !q.multi,
                  }
                : q
            )
          );
        }}
      >
        <img
          className="icon"
          src={
            questionList.filter((q) => q.id === currentQuestionId)[0].multi
              ? CheckBox_Checked
              : CheckBox
          }
          alt=""
        />
        중복선택 가능
      </div>

      <div className="div-edit-type-04" id="type4">
        {answer.map((a, id) => (
          <div className="div-edit-type-04-option">
            <input
              className="input-edit-type-04 Sub3"
              value={a.a}
              onChange={(e) => {
                setAnswer(
                  answer.map((a, a_idx) =>
                    a_idx === id ? { id: id, a: e.target.value } : a
                  )
                );
              }}
            />
            <img
              className="icon img-edit-type-04-delete"
              src={Icon_Delete}
              alt=""
              onClick={() => {
                if (answer.length === 1) {
                  window.alert("답변은 최소 1개 이상 있어야합니다.");
                } else {
                  setAnswer(answer.filter((a, a_idx) => a_idx !== id));
                }
              }}
            />
          </div>
        ))}
        <button
          className="outlined-black-button button-edit-type-04"
          onClick={() => {
            if (answer.length < 15) {
              setAnswer([
                ...answer,
                {
                  id: answer[answer.length - 1].id + 1,
                  a: "답변 " + (answer[answer.length - 1].id + 2),
                },
              ]);
            } else {
              window.alert("답변은 최대 15개까지 추가 가능합니다.");
            }
          }}
        >
          <img src={Icon_Plus} alt="" style={{ width: "18px" }} />
        </button>
      </div>
    </>
  );

  // 단답형
  const showType7 = (
    <input
      className="input-edit-type-05"
      placeholder="답변을 입력해주세요."
      disabled
      style={{ backgroundColor: "#F8F8F8" }}
    />
  );

  // 장문형
  const showType8 = (
    <textarea
      className="textarea-edit-type-06"
      placeholder="답변을 입력해주세요."
      disabled
      style={{ backgroundColor: "#F8F8F8" }}
    />
  );

  // 시간형
  const showType18 = (
    <div className="div-edit-type-20 Sub3">
      동들에서 제공하는
      <br />
      "시간" 답변 양식이 보여집니다!
      <br />
      미리보기에서 확인해보세요.
    </div>
  );

  // 지역 선택
  const showType5 = (
    <div className="div-question-13-section" style={{ marginTop: "45px" }}>
      <div className="div-question-13-dropdown Body4">
        <div
          className="div-question-05-dropdown Body4"
          style={{
            height: "33px",
            alignItems: "center",
            backgroundColor: "#F8F8F8",
          }}
        >
          <img src={Icon_Search} alt="" style={{ width: "15px" }} />
          <input
            className="div-question-13-dropdown-box Body4"
            placeholder="동 이름으로 검색하세요!"
            style={{ padding: "0 12px" }}
            disabled
          />
        </div>
      </div>
    </div>
  );

  // 달력형
  const showType10 = (
    <div className="div-edit-type-20 Sub3">
      동들에서 제공하는
      <br />
      "달력" 답변 양식이 보여집니다!
      <br />
      미리보기에서 확인해보세요.
    </div>
  );

  // 사진 업로드형
  const showType19 = (
    <div className="div-edit-type-20 Sub3">
      동들에서 제공하는
      <br />
      "사진 업로드" 답변 양식이 보여집니다!
      <br />
      미리보기에서 확인해보세요.
    </div>
  );

  // 파일 업로드형
  const showType14 = (
    <div className="div-edit-type-20 Sub3">
      동들에서 제공하는
      <br />
      "파일 업로드" 답변 양식이 보여집니다!
      <br />
      미리보기에서 확인해보세요.
    </div>
  );

  // 주량
  const showType20 = (
    <div className="div-edit-type-20 Sub3">
      동들에서 제공하는
      <br />
      "주량" 답변 양식이 보여집니다!
      <br />
      미리보기에서 확인해보세요.
    </div>
  );

  // MBTI
  const showType21 = (
    <div className="div-edit-type-20 Sub3">
      동들에서 제공하는
      <br />
      "MBTI" 답변 양식이 보여집니다!
      <br />
      미리보기에서 확인해보세요.
    </div>
  );

  // 약관동의
  const showType22 = (
    <div className="div-question-policy-container-edit">
      <textarea
        className="textarea-question-policy-request-edit Body4"
        value={
          questionList.filter(
            (question) => question.id === currentQuestionId
          )[0].q
        }
        onChange={(e) => {
          setQuestionList(
            questionList.map((question) =>
              question.id === currentQuestionId
                ? {
                    ...question,
                    q: e.target.value,
                  }
                : question
            )
          );
        }}
      />
    </div>
  );

  const makeform_2 = useSelector(state => state.guide.makeform_2);
  const makeform_2i = useSelector(state => state.guide.makeform_2i);

  return (
    <>
      <div className="div-edit-section">
        <div
          className="div-edit-section-num Sub1"
          style={{ position: "relative" }}
        >
          Q{qNum + 1}.
          <input
            className="div-edit-section-keyword Tag1"
            style={{ width: "100px" }}
            value={
              questionList.filter(
                (question) => question.id === currentQuestionId
              )[0].k
            }
            maxLength={15}
            onChange={(e) => {
              setQuestionList(
                questionList.map((question) =>
                  question.id === currentQuestionId
                    ? {
                        ...question,
                        k: e.target.value,
                      }
                    : question
                )
              );
            }}
          />
        </div>
        <CoachMark isOpen={makeform_2} guideIndex={makeform_2i} ownIndex={2}>
          {currentQuestion.type !== 22 && (
            <textarea
              className="textarea-edit-section-question Body3"
              value={
                questionList.filter(
                  (question) => question.id === currentQuestionId
                )[0].q
              }
              onChange={(e) => {
                setQuestionList(
                  questionList.map((question) =>
                    question.id === currentQuestionId
                      ? {
                          ...question,
                          q: e.target.value,
                        }
                      : question
                  )
                );
              }}
            />
          )}
          <div style={{ position: "relative" }}>
            {currentQuestion.type === 5 && showType5}
            {currentQuestion.type === 7 && showType7}
            {currentQuestion.type === 8 && showType8}
            {currentQuestion.type === 9 && showType9}
            {currentQuestion.type === 10 && showType10}
            {currentQuestion.type === 13 && showType13}
            {currentQuestion.type === 14 && showType14}
            {currentQuestion.type === 18 && showType18}
            {currentQuestion.type === 19 && showType19}
            {currentQuestion.type === 20 && showType20}
            {currentQuestion.type === 21 && showType21}
            {currentQuestion.type === 22 && showType22}
          </div>
        </CoachMark>
      </div>
    </>
  );
}

export default EditQuestion;
