import CommunityConnection from "./CommunityConnection";

export default async function IsMember(session_key, club_id) {
  const res = await CommunityConnection("/IsMember", {
    id: club_id,
  });
  if (res.is_success) {
    return res.is_member;
  }
}
