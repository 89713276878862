import React, { useState, useEffect } from "react";
import Connection from "./Connection";
import Loginform from "./Loginform";
import CreateClub from "./CreateClub";
import logo from "../asset/header/logo.svg";

function HeaderMain() {
  const [createOpen, setCreateOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [info, setInfo] = useState({});
  const session_key = window.localStorage.getItem("session_key");

  const getInfo = async () => {
    const res = await Connection("/getInfo", {});
    setInfo(res);
  };
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      {modalOpen && (
        <div
          className="div-loginform-modal-wrapper"
          style={{
            backgroundColor: modalOpen
              ? "rgba(0, 0, 0, 0.3)"
              : "rgba(0, 0, 0, 0)",
          }}
        >
          <Loginform setModalOpen={setModalOpen} />
        </div>
      )}
      {createOpen && (
        <div
          className="div-loginform-modal-wrapper"
          style={{
            backgroundColor: createOpen
              ? "rgba(0, 0, 0, 0.3)"
              : "rgba(0, 0, 0, 0)",
          }}
        >
          <CreateClub setModalOpen={setCreateOpen} info={info} />
        </div>
      )}
      <div className="div-header-section">
        <div className="div-header-section-01">
          <img
            className="div-click img-header-logo"
            src={logo}
            alt=""
            onClick={() => (window.location.href = "/")}
          />
        </div>
        <div className="div-headerMain-section">
          <button
            className="Sub1"
            onClick={() => {
              window.open("http://pf.kakao.com/_xnQWxfxj");
            }}
          >
            도입 문의
          </button>
          <button
            className="Sub1"
            onClick={() => {
              window.open(
                "https://www.notion.so/dongdle/cd13a0627d1748bfb9503a53e8e22874"
              );
            }}
          >
            가이드북
          </button>
        </div>
        {session_key === null ? (
          <button
            className="main-black-button button-headerMain-01 Sub1"
            onClick={() => setModalOpen(true)}
          >
            시작하기
          </button>
        ) : (
          <button
            className="button-headerMain-01 Sub1"
            onClick={async () => {
              const session_key = window.localStorage.getItem("session_key");
              const cbu = await Connection("/getClubByUser", {});
              if (!cbu.club.length) {
                alert("동아리 등록 후 이용해주세요!!😀");
                setCreateOpen(true);
              } else {
                const ci = await Connection("/getClubInfo", {
                  club_id: cbu.club[0].id,
                });
                const club_id = cbu.club[0].id;
                const recruit_id =
                  ci.recruit.length > 0 ? ci.recruit[0].id : "no_recruit";

                window.location.href = "/" + club_id + "/" + recruit_id;
              }
            }}
          >
            동아리 관리
          </button>
        )}
      </div>
    </>
  );
}

export default HeaderMain;
