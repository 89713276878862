import React, { useState, useRef } from "react";
import "../asset/css/createClub.css";
import close from "../asset/icon/Icon_Close.svg";
import RadioButton from "../asset/icon/RadioButton.svg";
import RadioButton_Checked from "../asset/icon/RadioButton_Checked.svg";
import CheckBox from "../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../asset/icon/CheckBox_Checked.svg";
import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import Icon_Search from "../asset/icon/Icon_Search.svg";
import Button_Upload from "../asset/upload/Button_Upload.svg";

export default function CreateClub({ setModalOpen, info }) {
  const [clubName, setClubName] = useState("");
  const [contents, setContents] = useState("");

  const [purpose, setPurpose] = useState(null);
  const [interest, setInterest] = useState(0);
  const [region, setRegion] = useState(0);
  const [fee, setFee] = useState("");
  const [additionalFee, setAdditionalFee] = useState(false);

  const [select1, setSelect1] = useState(false);

  const fileInputRef1 = useRef(null);
  const [imageFile1, setImageFile1] = useState(null);
  const [fMainHover, setFMainHover] = useState(false);

  const [subImageFile, setSubImageFile] = useState([]);

  const [fSub1Hover, setFSub1Hover] = useState(false);
  const [fSub2Hover, setFSub2Hover] = useState(false);
  const [fSub3Hover, setFSub3Hover] = useState(false);
  const [fSub4Hover, setFSub4Hover] = useState(false);

  const fileInputRefSub = useRef(null);

  const handleClickFileInput = (e) => {
    switch (e.target.id) {
      case "main-image":
        fileInputRef1.current?.click();
        break;
      case "sub-image":
        fileInputRefSub.current?.click();
        break;
      case "default":
      default:
    }
  };

  const uploadProfile = (e) => {
    const fileList = e.target.files;
    if (e.target.id === "sub-inputImg") {
      if (fileList.length) {
        setSubImageFile((prev) => {
          const fileArr = prev.concat(Object.values(fileList));
          if (fileArr.length > 4) {
            alert("활동사진은 4장 넘게 업로드 할 수 없습니다.🥲");
            return fileArr.slice(0, 4);
          } else {
            return fileArr;
          }
        });
      }
    } else {
      if (fileList && fileList[0]) {
        if (fileList[0].size <= 10 * 1024 * 1024) {
          const url = URL.createObjectURL(fileList[0]);
          const imageFile = {
            file: fileList[0],
            name: fileList[0].name,
            type: fileList[0].type.slice(0, 5), // image
            size: fileList[0].size,
            thumbnail: url,
          };
          switch (e.target.id) {
            case "main-inputImg":
              setImageFile1(imageFile);
              break;
            case "default":
            default:
          }
        } else {
          alert("10MB 이하의 이미지만 첨부할 수 있습니다.");
        }
      }
    }
  };

  const deleteFile = (e) => {
    switch (e.currentTarget.id) {
      case "delete-main":
        setImageFile1(null);
        fileInputRef1.current.value = null;
        break;
      case "default":
      default:
    }
  };

  const onChange = (e) => {
    switch (e.target.id) {
      case "clubName":
        setClubName(e.target.value);
        break;
      case "contents":
        setContents(e.target.value);
        break;
      default:
    }
  };

  const onClick = (e) => {
    switch (e.target.id) {
      case "purpose-hobby":
        setPurpose("hobby");
        break;
      case "purpose-job":
        setPurpose("job");
        break;
      case "fee-true":
        setFee("true");
        break;
      case "fee-false":
        setFee("false");
        break;
      case "additional-fee":
        setAdditionalFee(e.target.checked);
        break;
      case "default":
      default:
    }
  };

  const interests = ["관심사 선택", ...Object.values(info.category)];
  const regions = Object.entries(info.location);

  const dropdown = (data, select, setSelect, value, setValue) => (
    <div className="div-createClub-dropdown-wrapper">
      <div
        className="div-createClub-dropdown Body4"
        style={{
          borderColor: select && "black",
          zIndex: select && 2,
        }}
      >
        <div
          className="div-createClub-dropdown-box"
          style={{
            borderBottom: select && "1px solid #cdcdcd",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelect((select) => !select);
          }}
        >
          {data[value]}
          {select ? (
            <img
              className="img-createClub-dropdown-arrow"
              src={Icon_ArrowUp}
              alt=""
            />
          ) : (
            <img
              className="img-createClub-dropdown-arrow"
              src={Icon_ArrowDown}
              alt=""
            />
          )}
        </div>
        {select && (
          <div className="div-createClub-dropdown-scroll">
            {data.map((d, idx) => (
              <button
                className="div-createClub-dropdown-selection Body4"
                style={{
                  color: value === idx && "#FF7A00",
                }}
                onClick={() => {
                  setValue(idx);
                  setSelect(false);
                }}
              >
                {d}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    setIsSubmitted(true);
    const formdata = new FormData();
    formdata.append("session_key", window.localStorage.session_key);
    formdata.append("name", clubName);
    formdata.append("introduce", contents);
    formdata.append("purpose", purpose === "hobby" ? "취미" : "취업");
    formdata.append(
      "cost",
      `${fee ? "유" : "무"}${additionalFee ? ", 가입비 별도" : ""}`
    );
    formdata.append("category", interest);
    formdata.append("location", region);
    formdata.append("main", imageFile1 && imageFile1?.file);
    formdata.append("activity1", subImageFile[0] ? subImageFile[0] : null);
    formdata.append("activity2", subImageFile[1] ? subImageFile[1] : null);
    formdata.append("activity3", subImageFile[2] ? subImageFile[2] : null);
    formdata.append("activity4", subImageFile[3] ? subImageFile[3] : null);

    const api_host =
      // window.location.host === "dongdle.com"
      true ? "https://api.dongdle.com" : "http://api_test.dongdle.com:405";

    await fetch(api_host + "/api/v1/createClub", {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((json) => {
        window.location.href = "/" + json.club_id + "/clubManager";
      });
  };

  const [dropdownRegion, setDropdownRegion] = useState(false);
  const [search, setSearch] = useState("");

  const selectRegion = (
    <div className="div-createClub-dropdown-wrapper">
      <div className="div-createClub-dropdown Body4" style={{ width: "180px" }}>
        <div className="div-createClub-dropdown-box Body4">
          <img
            src={Icon_Search}
            alt=""
            style={{ width: "18px", height: "18px" }}
          />
          <input
            className="div-createClub-dropdown-box Body4"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setDropdownRegion(true);
              if (e.target.value === "") setDropdownRegion(false);
            }}
            placeholder="활동 지역을 검색하세요!"
          />
        </div>
        {dropdownRegion && (
          <div
            className="div-createClub-dropdown-scroll"
            style={{ height: "fit-content", maxHeight: "132px" }}
          >
            {regions.length > 0 &&
              regions.map(
                (d) =>
                  d[1].includes(search) && (
                    <button
                      className="div-createClub-dropdown-selection Body4"
                      onClick={() => {
                        setDropdownRegion(false);
                        setRegion(Number(d[0]));
                        setSearch(d[1]);
                      }}
                      style={{ width: "180px" }}
                    >
                      {d[1]}
                    </button>
                  )
              )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="div-modal-background">
        <div id="div-createClub-wrapper">
          <div id="createClub-header">
            <p className="Sub1">🚩 동아리 등록</p>
            <div id="createClub-header-right">
              <img
                src={close}
                alt=""
                onClick={() => {
                  setModalOpen(false);
                }}
              />
            </div>
          </div>
          <form id="form-createClub-questions" onSubmit={handleSubmit}>
            <div className="div-createClub-question">
              <label htmlFor="clubName" className="Sub3">
                동아리 명
              </label>
              <input
                id="clubName"
                className="input-createClub-inputText Body4 -input-box"
                placeholder="내용을 입력해주세요"
                onChange={onChange}
                maxLength="30"
              />
            </div>
            <div className="div-createClub-question">
              <label htmlFor="contents" className="Sub3">
                한 줄 소개
              </label>
              <input
                id="contents"
                className="input-createClub-inputText Body4 -input-box"
                placeholder="내용을 입력해주세요"
                onChange={onChange}
                maxLength="30"
              />
            </div>
            <div
              className="div-createClub-question"
              style={{ alignItems: "center" }}
            >
              <label htmlFor="images" className="Sub3">
                동아리 로고
              </label>
              <input
                id="main-inputImg"
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                ref={fileInputRef1}
                onChange={uploadProfile}
              />
              <div
                className="div-createClub-image-wrapper"
                onMouseOver={() => {
                  setFMainHover(true);
                }}
                onMouseLeave={() => {
                  setFMainHover(false);
                }}
              >
                {imageFile1 && fMainHover && (
                  <div
                    id="delete-main"
                    className="div-createClub-deleteImg"
                    onClick={deleteFile}
                  >
                    <img src={close} alt="" />
                  </div>
                )}
                <img
                  id="main-image"
                  src={imageFile1?.thumbnail || Button_Upload}
                  alt=""
                  onClick={handleClickFileInput}
                />
              </div>
            </div>
            <div className="div-createClub-question">
              <label htmlFor="purpose" className="Sub3">
                목적
              </label>
              <div className="div-createClub-selections">
                <input
                  className="createClub-selection"
                  type="radio"
                  name="purpose"
                  id="purpose-hobby"
                  onClick={onClick}
                />
                <label htmlFor="purpose-hobby">
                  <img
                    src={
                      purpose === "hobby" ? RadioButton_Checked : RadioButton
                    }
                    alt=""
                  />
                  <p className="Body4">취미</p>
                </label>
                <input
                  className="createClub-selection"
                  type="radio"
                  name="purpose"
                  id="purpose-job"
                  onClick={onClick}
                />
                <label htmlFor="purpose-job">
                  <img
                    src={purpose === "job" ? RadioButton_Checked : RadioButton}
                    alt=""
                  />
                  <p className="Body4">취업</p>
                </label>
              </div>
            </div>
            <div className="div-createClub-question">
              <label className="Sub3">관심사</label>
              {dropdown(interests, select1, setSelect1, interest, setInterest)}
            </div>
            <div className="div-createClub-question">
              <label className="Sub3">활동 지역</label>
              {selectRegion}
            </div>
            <div className="div-createClub-question">
              <label htmlFor="fee" className="Sub3">
                회비
              </label>
              <div className="div-createClub-selections">
                <input
                  className="createClub-selection"
                  type="radio"
                  name="fee"
                  id="fee-true"
                  onClick={onClick}
                />
                <label htmlFor="fee-true">
                  <img
                    src={fee === "true" ? RadioButton_Checked : RadioButton}
                    alt=""
                  />
                  <p className="Body4">유</p>
                </label>
                <input
                  className="createClub-selection"
                  type="radio"
                  name="fee"
                  id="fee-false"
                  onClick={onClick}
                />
                <label htmlFor="fee-false">
                  <img
                    src={fee === "false" ? RadioButton_Checked : RadioButton}
                    alt=""
                  />
                  <p className="Body4">무</p>
                </label>
                <input
                  className="createClub-selection"
                  type="checkbox"
                  name="fee"
                  id="additional-fee"
                  onClick={onClick}
                />
                <label htmlFor="additional-fee">
                  <img
                    src={additionalFee ? CheckBox_Checked : CheckBox}
                    alt=""
                  />
                  <p className="Body4">가입비 별도</p>
                </label>
              </div>
            </div>
            <div className="div-createClub-question">
              <label className="Sub3">
                활동 사진 <span style={{ color: "#cdcdcd" }}>(1장 필요)</span>
              </label>
              <input
                id="sub-inputImg"
                type="file"
                multiple={true}
                accept="image/jpg, image/jpeg, image/png"
                ref={fileInputRefSub}
                onChange={uploadProfile}
              />
              <input
                type="button"
                className="button-createClub-uploadImg Sub3"
                value="이미지 추가"
                id="sub-image"
                onClick={handleClickFileInput}
              />
              <div className="div-createClub-subImgTags">
                {subImageFile[0] && (
                  <div
                    className="div-createClub-subImgTag"
                    onClick={() => {
                      setSubImageFile((prev) =>
                        prev.filter((value, idx) => idx !== 0)
                      );
                      setFSub1Hover(false);
                    }}
                    onMouseOver={() => setFSub1Hover(true)}
                    onMouseLeave={() => setFSub1Hover(false)}
                  >
                    <p className="Tag1">{subImageFile[0].name}</p>
                    <img
                      className="div-createClub-subImgTag-close"
                      src={close}
                      alt=""
                    />
                    {fSub1Hover && (
                      <img
                        className="div-createClub-subImgTag-thumb"
                        src={URL.createObjectURL(subImageFile[0])}
                        alt=""
                      />
                    )}
                  </div>
                )}
                {subImageFile[1] && (
                  <div
                    className="div-createClub-subImgTag"
                    onClick={() => {
                      setSubImageFile((prev) =>
                        prev.filter((value, idx) => idx !== 1)
                      );
                      setFSub2Hover(false);
                    }}
                    onMouseOver={() => setFSub2Hover(true)}
                    onMouseLeave={() => setFSub2Hover(false)}
                  >
                    <p className="Tag1">{subImageFile[1].name}</p>
                    <img
                      className="div-createClub-subImgTag-close"
                      src={close}
                      alt=""
                    />
                    {fSub2Hover && (
                      <img
                        className="div-createClub-subImgTag-thumb"
                        src={URL.createObjectURL(subImageFile[1])}
                        alt=""
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </div>
                )}
                {subImageFile[2] && (
                  <div
                    className="div-createClub-subImgTag"
                    onClick={() => {
                      setSubImageFile((prev) =>
                        prev.filter((value, idx) => idx !== 2)
                      );
                      setFSub3Hover(false);
                    }}
                    onMouseOver={() => setFSub3Hover(true)}
                    onMouseLeave={() => setFSub3Hover(false)}
                  >
                    <p className="Tag1">{subImageFile[2].name}</p>
                    <img
                      className="div-createClub-subImgTag-close"
                      src={close}
                      alt=""
                    />
                    {fSub3Hover && (
                      <img
                        className="div-createClub-subImgTag-thumb"
                        src={URL.createObjectURL(subImageFile[2])}
                        alt=""
                      />
                    )}
                  </div>
                )}
                {subImageFile[3] && (
                  <div
                    className="div-createClub-subImgTag"
                    onClick={() => {
                      setSubImageFile((prev) =>
                        prev.filter((value, idx) => idx !== 3)
                      );
                      setFSub4Hover(false);
                    }}
                    onMouseOver={() => setFSub4Hover(true)}
                    onMouseLeave={() => setFSub4Hover(false)}
                  >
                    <p className="Tag1">{subImageFile[3].name}</p>
                    <img
                      className="div-createClub-subImgTag-close"
                      src={close}
                      alt=""
                    />
                    {fSub4Hover && (
                      <img
                        className="div-createClub-subImgTag-thumb"
                        src={URL.createObjectURL(subImageFile[3])}
                        alt=""
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </form>
          <div id="createClub-footer">
            <button
              id="createClub-submit"
              className="main-black-button"
              onClick={handleSubmit}
              disabled={
                isSubmitted ||
                !clubName ||
                !contents ||
                !purpose ||
                !interest ||
                !region ||
                !imageFile1
              }
            >
              <p className="Sub3">등록 요청</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
