import React, { useEffect, useState, useLayoutEffect } from "react";
import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import Connection from "./Connection";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

function FormTime() {
  const param = window.location.href.split("/");

  const [selectCompleted, setSelectCompleted] = useState(false);

  const [dropdown, setDropdown] = useState(false);
  const [checkedDate, setCheckedDate] = useState(0);
  const [checkedTime, setCheckedTime] = useState(-1);

  const [timetable, setTimetable] = useState([]);
  const [interviewDate, setInterviewDate] = useState("");
  const [interviewTime, setInterviewTime] = useState("");

  const [isModalopen, setIsModalOpen] = useState(false);

  const getTimetable = async () => {
    const tt = await Connection("/getTimetable", {
      recruit_id: param[param.length - 2],
      applicant_id: param[param.length - 1],
    });

    if (tt.is_success) {
      setTimetable(tt.interview_time);
    }
  };

  useEffect(() => {
    getTimetable();
  }, []);

  useEffect(() => {
    timetable.length > 0 && setInterviewDate(timetable[0].date);
  }, [timetable]);

  const [showDate, setShowDate] = useState();
  useLayoutEffect(() => {
    setShowDate(interviewDate);
  }, [interviewDate]);

  return (
    <>
      {isModalopen && (
        <div className="div-modal-background">
          <div className="div-form-time-modal">
            <div className="Sub3M">
              이미 다른 지원자분이
              <br />
              선택한 일정이예요😥
            </div>
            <div className="Body5M">다른 면접 시간대를 선택해주세요!</div>
            <buttton
              className="div-click button-form-time-modal Sub3"
              onClick={() => window.location.reload()}
            >
              다시 선택하기
            </buttton>
          </div>
        </div>
      )}

      <div className="div-form-mobile-section">
        <div className="div-form-question-background">
          <div className="div-form-question-section">
            {!selectCompleted ? (
              <>
                <div className="div-form-time-title H3M">면접 일정 선택</div>
                <div
                  className="div-question-13-dropdown-formtime Body4M"
                  style={{ borderColor: dropdown && "black" }}
                >
                  <div
                    className="div-question-13-dropdown-box Body4M"
                    style={{
                      borderBottom: dropdown && "1px solid #cdcdcd",
                    }}
                    onClick={() => setDropdown(!dropdown)}
                  >
                    {showDate}
                    {dropdown ? (
                      <img
                        className="iconM"
                        src={Icon_ArrowUp}
                        alt=""
                        onClick={() => setDropdown(false)}
                      />
                    ) : (
                      <img className="iconM" src={Icon_ArrowDown} alt="" />
                    )}
                  </div>
                  {dropdown && (
                    <div className="div-question-13-dropdown-scroll">
                      {timetable.map((t, idx) => (
                        <button
                          className="button-question-13 Body4M"
                          style={{
                            color: checkedDate === idx && "#FF7A00",
                          }}
                          onClick={() => {
                            setCheckedDate(idx);
                            setInterviewDate(t.date);
                            setDropdown(false);
                          }}
                        >
                          {t.date}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="div-form-time-button-section" id="scroll">
                  {timetable.length > 0 &&
                    timetable[checkedDate].split.map((s, s_idx) =>
                      s.time_m.map(
                        (m, m_idx) =>
                          m.checked && (
                            <button
                              className="button-form-time Sub3M"
                              onClick={() => {
                                setCheckedTime(m);
                                setInterviewTime(m.time);
                              }}
                              style={{
                                backgroundColor: checkedTime === m && "#FF7A00",
                                color: checkedTime === m && "white",
                              }}
                              disabled={!m.available}
                            >
                              {m.time}
                            </button>
                          )
                      )
                    )}
                </div>
                <button
                  className="button-form-time-select Sub3M"
                  onClick={async () => {
                    if (interviewDate === "" || interviewTime === "") {
                      window.alert("선택된 시간대가 없습니다.");
                    } else {
                      const ret = await Connection(
                        "/updateInterviewTime",
                        {
                          recruit_id: param[param.length - 2],
                          applicant_id: param[param.length - 1],
                          interview_date: interviewDate,
                          interview_time: interviewTime,
                        },
                        false
                      );

                      if (ret.is_success) {
                        setSelectCompleted(true);
                      } else {
                        if (
                          ret.error_message === "이미 마감된 면접 시간입니다."
                        ) {
                          setIsModalOpen(true);
                        } else {
                          window.alert(ret.error_message);
                          window.reload();
                        }
                      }
                    }
                  }}
                >
                  선택 완료
                </button>
              </>
            ) : (
              <>
                <div className="div-form-time-completed-title H3M">
                  면접 일정 선택이
                  <br />
                  완료되었어요!👏🏻
                  <div className="div-form-time-completed-section-t Body4M">
                    <div className="div-form-time-completed-section-01 Sub3M">
                      면접 일시
                    </div>
                    {interviewDate !== "" &&
                      interviewDate + " (" + interviewTime + ")"}
                  </div>
                  <br />
                  <div className="Body4M" style={{ textAlign: "center" }}>
                    면접 일시는 반드시 기억해주세요! <br />
                    (화면을 나가게 되면, 다시 확인하기 어렵습니다..😅)
                  </div>
                  <div className="div-form-question-final-section">
                    <div className="Body4M">
                      카카오톡채널 '동들(Dongdle)'을 통해
                      <br />
                      면접일시를 다시 확인할 수 있습니다.
                    </div>
                    <button
                      className="button-form-question-final-time Sub3M"
                      onClick={() => {
                        window.open("http://pf.kakao.com/_xnQWxfxj");
                      }}
                    >
                      👉 카카오톡채널 추가하기 👈
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FormTime;
