import React from "react";
import line from "../asset/main/line.svg";
import footerLogo from "../asset/main/footerLogo.svg";

function Footer() {
  return (
    <div className="div-main-footer-section">
      <div className="div-main-footer-section-01">
        <div
          className="div-main-footer-section-01-button Body4"
          onClick={() => (window.location.href = "/terms")}
        >
          이용약관
        </div>
        <div
          className="div-main-footer-section-01-button Body4"
          onClick={() => (window.location.href = "/privacy")}
        >
          개인정보처리방침
        </div>
        <img className="img-main-footer-logo" src={footerLogo} alt="" />
      </div>
      <div className="div-main-footer-section-02">
        <div className="Body5">더블인터넷</div>
        <img src={line} alt="" style={{ height: "13.5px" }} />
        <div className="Body5">대표 : 박승한</div>
        <img src={line} alt="" style={{ height: "13.5px" }} />
        <div className="Body5">
          대전광역시 서구 문정로 150번길 19, 3층(탄방동)
        </div>
        <img src={line} alt="" style={{ height: "13.5px" }} />
        <div className="Body5">사업자등록번호 : 593-31-00146</div>
      </div>
      <div className="div-main-footer-section-02">
        <div className="Body5">개인정보책임자: 박승한</div>
        <img src={line} alt="" style={{ height: "13.5px" }} />
        <div className="Body5">이메일 : system@doubleinternet.com</div>
      </div>
      <div className="div-main-footer-section-02 Body5">
        더블인터넷 All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
