import React, { useState, useEffect } from "react";
import "../asset/css/RecruitPage6M.css";
import Table6M from "./Table6M";
import Connection from "./Connection";

function RecruitPage6M({ club_id, recruit_id, recruitInfo }) {
  const session_key = localStorage.getItem("session_key");
  const [applicantList, setApplicantList] = useState([]);
  const [recruitDocs, setRecruitDocs] = useState({});
  const [recruitStep1, setRecruitStep1] = useState({});

  useEffect(() => {
    recruitInfo.step === "서류"
      ? recruitStep1.is_success && setApplicantList(recruitStep1.applicant)
      : recruitStep1.is_success &&
        setApplicantList(
          recruitStep1.applicant.filter((a) => a.state === 3 || a.state === 4)
        );
  }, [recruitStep1]);

  const stateHeader = { k: "상태", type: "0" };
  const newHeader = [].concat(stateHeader, recruitDocs.question);

  async function getRecruitDocs() {
    setRecruitDocs(
      await Connection("/getRecruitDocs", {
        club_id: club_id,
        recruit_id: recruit_id,
      })
    );
  }
  async function getRecruitStep1() {
    setRecruitStep1(
      await Connection("/getRecruitStep1", {
        club_id: club_id,
        recruit_id: recruit_id,
      })
    );
  }

  useEffect(() => {
    getRecruitDocs();
    getRecruitStep1();
  }, []);

  return (
    <div className="div-recruitpage6m-section H3M">
      <div className="div-recruitpage6m-section-title H3M">
        👋 최종 합격자 명단
        <div className="div-recruitpage6m-top-gradient" />
      </div>
      <div className="div-recruitpage6m-section-content">
        {recruitDocs.is_success && recruitStep1.is_success && (
          <Table6M
            club_id={club_id}
            recruit_id={recruit_id}
            recruitDocs={recruitDocs}
            headers={newHeader}
            applicantList={applicantList}
            recruitInfo={recruitInfo}
          />
        )}
      </div>
    </div>
  );
}

export default RecruitPage6M;
