import React, { useState, useEffect } from "react";
import "../asset/css/RecruitPage6M.css";
import Icon_Reset from "../asset/icon/Icon_Reset.svg";
import Connection from "./Connection";
import ToastPopup from "./ToastPopup";
import TableM from "./TableM";

function RecruitPage1M({ club_id, recruit_id }) {
  const session_key = localStorage.getItem("session_key");
  const [countAll, setCountAll] = useState(0);
  const [countFail, setCountFail] = useState(0);
  const [countPass, setCountPass] = useState(0);
  const [recruitDocs, setRecruitDocs] = useState({});
  const [recruitStep1, setRecruitStep1] = useState({});

  async function getRecruitDocs() {
    setRecruitDocs(
      await Connection("/getRecruitDocs", {
        club_id: club_id,
        recruit_id: recruit_id,
      })
    );
  }
  async function getRecruitStep1() {
    setRecruitStep1(
      await Connection("/getRecruitStep1", {
        club_id: club_id,
        recruit_id: recruit_id,
      })
    );
  }

  useEffect(() => {
    getRecruitDocs();
    getRecruitStep1();
  }, []);

  useEffect(() => {
    recruitStep1.is_success && setCountAll(recruitStep1.applicant.length);
  }, [recruitStep1]);

  const stateHeader = { k: "상태", type: 0 };
  const newHeader = [].concat(stateHeader, recruitDocs.question);

  const [animation1, setAnimation1] = useState("");
  const [isToastPopupOpen, setIsToastPopupOpen] = useState(false);
  const onToggle = () => {
    setAnimation1("openAnimation-toast");
    setIsToastPopupOpen(true);
    setTimeout(() => {
      setAnimation1("closeAnimation-toast");
      setTimeout(() => {
        setIsToastPopupOpen(false);
      }, 450);
    }, 2000);
  };

  return (
    <>
      <div className="div-recruitpage6m-section H3M">
        <div className="div-recruitpage6m-section-title H3M">
          🙌 지원자 현황
          <div className="div-recruitpage6m-top-gradient" />
        </div>
        <div className="div-recruitpage6m-section-content">
          <div className="div-recruitpage6m-reset-section">
            <div
              className="div-recruitpage6m-reset Tag1M"
              onClick={async () => {
                if (!isToastPopupOpen) {
                  setRecruitStep1(
                    await Connection("/getRecruitStep1", {
                      session_key: localStorage.getItem("session_key"),
                      club_id: club_id,
                      recruit_id: recruit_id,
                    })
                  );
                  onToggle();
                }
              }}
            >
              <img src={Icon_Reset} alt="" style={{ width: "18px" }} />
              새로고침
            </div>
            <div className="div-recruitpage6m-reset-toastpopup-section">
              {isToastPopupOpen && (
                <ToastPopup
                  status={"success"}
                  children={"새로고침이 완료되었습니다."}
                  className={`${animation1} Tag1`}
                />
              )}
            </div>
          </div>

          {recruitDocs.is_success && recruitStep1.is_success && (
            <TableM
              club_id={club_id}
              recruit_id={recruit_id}
              recruitDocs={recruitDocs}
              headers={newHeader}
              applicantList={recruitStep1.applicant}
              setCountFail={setCountFail}
              setCountPass={setCountPass}
            />
          )}
        </div>
      </div>
      <div className="div-recruitpage6m-bottom-gradient" />
      <div className="div-recruitpage6m-bottom">
        <div className="div-recruitpage6m-bottom-section">
          <div
            className="div-manage-section-03-02-01-02-count Body4M"
            style={{ width: "64px" }}
          >
            총 지원자
            <div className="H3">{countAll}명</div>
          </div>
          <div
            className="div-manage-section-03-02-01-02-count Body4M"
            style={{ width: "64px" }}
          >
            불합격
            <div className="H3">{countFail}명</div>
          </div>
          <div
            className="div-manage-section-03-02-01-02-count Body4M"
            style={{ width: "64px" }}
          >
            합격
            <div className="H3" style={{ color: "#FF7A00" }}>
              {countPass}명
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruitPage1M;
