function TimeSplit(term, start, end) {
  var ret = [];

  for (let h = parseInt(start); h < parseInt(end); ++h) {
    var ret_m = [];
    var m = 0;
    for (let m = 0; m < 60; m += term) {
      if (m + term === 60) {
        ret_m.push({
          time: `${h % 10 === h ? "0" + h : h}:${
            m % 10 === m ? "0" + m : m
          } ~  ${(h + 1) % 10 === h + 1 ? "0" + (h + 1) : h + 1}:00`,
          checked: true,
        });
      } else {
        ret_m.push({
          time: `${h % 10 === h ? "0" + h : h}:${
            m % 10 === m ? "0" + m : m
          } ~ ${h % 10 === h ? "0" + h : h}:${
            (m + term) % 10 === m + term ? "0" + (m + term) : m + term
          }`,
          checked: true,
        });
      }
    }

    ret.push({
      time_h: `${h % 10 === h ? "0" + h : h}:00 ~ ${
        (h + 1) % 10 === h + 1 ? "0" + (h + 1) : h + 1
      }:00`,
      time_m: ret_m,
    });
  }

  return ret;
}

export default TimeSplit;
