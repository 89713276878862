import React, { useState, useEffect } from "react";
import Icon_Application from "../asset/icon/Icon_Application_Gray.svg";
import Connection from "./Connection";
import ViewFormM from "./ViewFormM";
import ChipStatus4M from "./ChipStatus4M";

const TableContent4M = ({
  club_id,
  recruit_id,
  recruitDocs,
  applicantList,
  setApplicantList,
  columnList,
  columnWidth,
  setCountFail,
  setCountPass,
  stateList,
  setStateList,
  modalViewForm,
  setModalViewForm,
  showWhole,
}) => {
  const session_key = window.localStorage.getItem("session_key");

  const [applicantNum, setApplicantNum] = useState();

  useEffect(() => {
    setCountPass(applicantList.filter((a) => a.stateV === 3).length);
    setCountFail(applicantList.filter((a) => a.stateV === 4).length);
  }, [applicantList]);

  const updateState = (applicant_id, state) => {
    Connection("/updateRecruitStep2", {
      club_id: club_id,
      recruit_id: recruit_id,
      applicant_id: applicant_id,
      state: String(state),
    });
  };

  const showRows = applicantList.map((applicant, idx) => (
    <tr key={idx}>
      {/* 번호 */}
      <td className="td-sticky-01" style={{ height: "40px" }}>
        <span>
          <div className="Tag1M">{applicant.idx + 1}</div>
        </span>
      </td>
      {/* 상태 */}
      <td className="td-sticky-01" style={{ height: "40px" }}>
        <span
          onClick={async () => {
            if (!showWhole) {
              if (applicant.stateV === 1) {
                setApplicantList(
                  applicantList.map((a, i) =>
                    i === idx ? { ...a, stateV: 3 } : a
                  )
                );

                updateState(applicant.id, 3);
              }
              if (applicant.stateV === 3) {
                setApplicantList(
                  applicantList.map((a, i) =>
                    i === idx ? { ...a, stateV: 4 } : a
                  )
                );
                updateState(applicant.id, 4);
              }
              if (applicant.stateV === 4) {
                setApplicantList(
                  applicantList.map((a, i) =>
                    i === idx ? { ...a, stateV: 1 } : a
                  )
                );
                updateState(applicant.id, 1);
              }
            }
          }}
        >
          {applicant.stateV === 2 ? (
            <button
              className="button-chip button-chip-status-1M Body5M"
              style={{ backgroundColor: "#ececec" }}
            >
              불합격
            </button>
          ) : (
            <ChipStatus4M state={applicant.stateV} />
          )}
        </span>
      </td>
      {/* 면접 시간 */}
      <td
        className="td-sticky-02"
        style={{
          height: "40px",
          justifyContent: "center",
          gap: "15px",
        }}
      >
        {applicant.stateV !== 2 && (
          <div className="Body4">
            {applicant.interview_date === "" ? (
              "(미배정)"
            ) : (
              <div
                className="Body4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="Body4">{applicant.interview_date}</div>(
                {applicant.interview_time})
              </div>
            )}
          </div>
        )}
      </td>

      {/* 지원자명 */}
      <td
        className="td-sticky-02"
        style={{
          height: "40px",
          justifyContent: "center",
          gap: "15px",
          borderRight: "none",
        }}
      >
        <div
          className="Body4M"
          style={{
            width: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {applicant.profile.name}
        </div>
        <img
          className="div-click img-table-application"
          style={{ height: "24px" }}
          src={Icon_Application}
          alt=""
          onClick={() => {
            setApplicantNum(idx);
            setModalViewForm(true);
          }}
        />
      </td>
    </tr>
  ));

  return (
    <>
      {modalViewForm && (
        <ViewFormM
          questions={recruitDocs?.question}
          answers={applicantList[applicantNum]}
          applicantNum={applicantNum}
          setApplicantNum={setApplicantNum}
          setModalOpen={setModalViewForm}
          applicantListLength={applicantList.length}
        />
      )}
      {showRows}
    </>
  );
};

export default TableContent4M;
