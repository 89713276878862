import { useState, useEffect, useRef } from "react";
import "./styles.css";
import Icon_Group from "../asset/icon/Icon_Group.svg";
import Connection from "./Connection";
import Icon_Download from "../asset/icon/Icon_Download.svg";

import TableContent6M from "./TableContent6M";

const createHeaders = (headers) => {
  return headers.map((item, idx) => ({
    data: { ...item, id: idx },
    text: item.k,
    isView: true,
    filter: item.type === 5,
    a_id: item.id,
    ref: useRef(),
  }));
};
const Table6M = ({
  club_id,
  recruit_id,
  recruitDocs,
  headers,
  applicantList,
  recruitInfo,
}) => {
  const [applicantListV, setApplicantListV] = useState([]);
  const [modalViewForm, setModalViewForm] = useState(false);
  useEffect(() => {
    setApplicantListV(
      applicantList.map((a, idx) => ({ ...a, idx: idx, stateV: a.state }))
    );
  }, [applicantList]);

  async function downloadRecruitStep1() {
    const ret = await Connection("/downloadRecruitStep1", {
      club_id: club_id,
      recruit_id: recruit_id,
    });

    window.open(ret.file_url);
  }

  const [columns, setColumns] = useState(createHeaders(headers));
  const session_key = localStorage.getItem("session_key");

  const [applicantListW, setApplicantListW] = useState([]);
  async function getRecruitStep2() {
    const res = await Connection("/getRecruitStep1", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
      recruit_id: recruit_id,
    });
    if (res.is_success) {
      setApplicantListW(
        res.applicant.map((a, idx) => ({ ...a, idx: idx, stateV: a.state }))
      );
    }
  }
  useEffect(() => {
    getRecruitStep2();
  }, [applicantListV]);

  const [showWhole, setShowWhole] = useState(false);

  return (
    <div
      className="table-wrapper Body4M"
      style={{ marginTop: "32px", marginBottom: "60px" }}
    >
      <div className="div-table-filter-button-sectionM">
        {recruitInfo.step !== "서류" && (
          <button
            className={
              showWhole
                ? "button-table-filterM-selected Tag1M"
                : "button-table-filterM Tag1M"
            }
            onClick={() => setShowWhole(!showWhole)}
          >
            전체 지원자 보기
            <img
              className="img-table-filter"
              src={Icon_Group}
              alt=""
              style={{ filter: showWhole && "invert(100)" }}
            />
          </button>
        )}
        <button
          className="button-table-filterM Tag1M"
          onClick={() => downloadRecruitStep1()}
        >
          표로 내보내기
          <img className="img-table-filter" src={Icon_Download} alt="" />
        </button>
      </div>

      <table
        className="recruit-table"
        style={{
          gridTemplateColumns: "0.5fr 1fr 1.5fr",
          maxHeight: "100%",
          overflow: "auto",
          paddingBottom: "30px",
        }}
      >
        <thead>
          <tr>
            <th className="thead-test th-sticky-01" style={{ height: "30px" }}>
              <span className="Body4M"></span>
            </th>
            <th className="thead-test th-sticky-01" style={{ height: "30px" }}>
              <span className="Body4M">상태</span>
            </th>
            <th className="thead-test th-sticky-02" style={{ height: "30px" }}>
              <span className="Body4M">이름</span>
            </th>
          </tr>
        </thead>
        <tbody className="tbody-test">
          <TableContent6M
            recruitDocs={recruitDocs}
            applicantList={showWhole ? applicantListW : applicantListV}
            columnList={columns}
            modalViewForm={modalViewForm}
            setModalViewForm={setModalViewForm}
            recruitInfo={recruitInfo}
          />
        </tbody>
      </table>
    </div>
  );
};

export default Table6M;
