import React, { useEffect, useState } from "react";
import CommunityConnection from "./CommunityConnection";
import "../../asset/css/community/ManageMember.css";
import Icon_Delete_DD0000 from "../../asset/icon/Icon_Delete_DD0000.svg";
import Icon_Delete_6A6A6A from "../../asset/icon/Icon_Delete_6A6A6A.svg";
import { BiCheckSquare } from "react-icons/bi";
import { BiUserPlus } from "react-icons/bi";

function ManageMember({ clubId }) {
  const session_key = window.localStorage.getItem("session_key");
  const [clubMember, setClubMember] = useState();
  async function getClubMember() {
    const res = await CommunityConnection("/getClubMember", {
      id: clubId,
    });
    if (res.is_success) setClubMember(res.member);
  }

  useEffect(() => {
    getClubMember();
  }, []);

  const [deleteMember, setDeleteMember] = useState([]);
  async function deleteClubMember() {
    const res = await CommunityConnection("/deleteClubMember", {
      id: clubId,
      phone: deleteMember,
    });
    if (res.is_success) {
      window.confirm("삭제 완료되었습니다.");
      setDeleteMember([]);
      setIsDeleteOn(false);
      getClubMember();
    }
  }

  const [isDeleteOn, setIsDeleteOn] = useState(false);

  const [newMember, setNewMember] = useState({ name: "", phone: "", info: "" });
  async function insertClubMember() {
    const res = await CommunityConnection("/insertClubMember", {
      id: clubId,
      name: newMember.name,
      phone: newMember.phone,
      info: newMember.info,
    });
    if (res.is_success) {
      setNewMember({ name: "", phone: "", info: "" });
      getClubMember();
    }
  }

  return (
    <div className="div-manage-member-section">
      <div className="div-manage-member-header">
        <div className="Body5M">이름</div>
        <div className="Body5M">연락처</div>
        <div className="Body5M">비고</div>
        {deleteMember?.length === 0 ? (
          <img
            src={Icon_Delete_6A6A6A}
            alt=""
            style={{ width: "20px" }}
            onClick={() => {
              setIsDeleteOn(!isDeleteOn);
              setDeleteMember([]);
            }}
          />
        ) : (
          <img
            src={Icon_Delete_DD0000}
            alt=""
            style={{ width: "20px" }}
            onClick={() => {
              if (window.confirm("정말 삭제하시겠습니까?")) deleteClubMember();
            }}
          />
        )}
      </div>
      {clubMember?.map(({ name, phone, info }) => (
        <div className="div-manage-member-content">
          <div className="Body5M">{name}</div>
          <div className="Body5M">{phone}</div>
          <div className="Body5M">{info ? info : "없음"}</div>
          {isDeleteOn &&
            (deleteMember.includes(phone) ? (
              <BiCheckSquare
                size={20}
                color={"#DD0000"}
                onClick={() =>
                  setDeleteMember(
                    deleteMember.filter((member) => member !== phone)
                  )
                }
              />
            ) : (
              <BiCheckSquare
                size={20}
                color={"#6A6A6A"}
                onClick={() => setDeleteMember([...deleteMember, phone])}
              />
            ))}
        </div>
      ))}
      <div className="div-manage-member-header">
        <input
          className="Body5M"
          placeholder="이름"
          value={newMember.name}
          onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
        />
        <input
          className="Body5M"
          placeholder="연락처"
          value={newMember.phone}
          maxLength={11}
          onChange={(e) => {
            setNewMember({
              ...newMember,
              phone: e.target.value.replace(/[^0-9]/g, ""),
            });
          }}
        />
        <input
          className="Body5M"
          placeholder="비고"
          value={newMember.info}
          onChange={(e) => setNewMember({ ...newMember, info: e.target.value })}
        />
        {newMember.name !== "" && newMember.phone !== "" ? (
          <BiUserPlus
            size={20}
            color={"#DD0000"}
            onClick={() => insertClubMember()}
          />
        ) : (
          <BiUserPlus size={20} color={"#6A6A6A"} />
        )}
      </div>
    </div>
  );
}

export default ManageMember;
