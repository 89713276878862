import React, { useEffect, useRef, useState } from "react";
import Icon_Plus from "../asset/icon/Icon_Plus.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import line from "../asset/icon/line.svg";
import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import CheckBox from "../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../asset/icon/CheckBox_Checked.svg";
import TimeSplit from "./TimeSplit";
import NewCalendar from "./newCalendar";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

function TimeTable({ club_id, recruit_id, step2, setStep2, setCountTime }) {
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dropdown5, setDropdown5] = useState(false);

  const [checked1, setChecked1] = useState(-1);
  const [checked2, setChecked2] = useState(-1);
  const [checked4, setChecked4] = useState(0);
  const [checked5, setChecked5] = useState(0);

  const [data1, setData1] = useState([5, 10, 12, 15, 20, 30, 60]);
  const [data2, setData2] = useState([1, 2, 3, 4, 5]);
  const [data3, setData3] = useState(""); // 날짜
  const [data4, setData4] = useState([
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ]);
  const [data5, setData5] = useState([
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ]);

  const outSection = useRef();
  const [timeTable, setTimeTable] = useState([]);
  const [split, setSplit] = useState([]);
  const [num, setNum] = useState([]);

  function createTime() {
    const d1 = data1[checked1];
    const d2 = data2[checked2];
    const d3 = data3;
    const d4 = data4[checked4];
    const d5 = data5[checked5];

    setTimeTable([
      ...timeTable,
      {
        d1: d1,
        d2: d2,
        d3: d3,
        d4: d4,
        d5: d5,
      },
    ]);

    setSplit([...split, TimeSplit(d1, d4, d5)]);
    setNum([...num, ((parseInt(d5) - parseInt(d4)) * 60) / d1]);

    setStep2([
      ...step2,
      {
        date: d3,
        person: d2,
        person_sum: ((parseInt(d5) - parseInt(d4)) * 60) / d1,
        split: [TimeSplit(d1, d4, d5)],
        deleted: false,
      },
    ]);
  }

  useEffect(() => {
    setStep2(step2.map((s, s_idx) => ({ ...s, split: split[s_idx] })));
  }, [split]);

  function deleteTime(idx) {
    setTimeTable(timeTable.filter((t, id) => id !== idx));
    setSplit(split.filter((sp, id) => id !== idx));
    setStep2(step2.filter((st, id) => id !== idx));
  }

  function getCountTime() {
    var cnt = 0;
    split.map((s, s_idx) =>
      s.map((ss) =>
        ss.time_m.map((sss) => {
          if (sss.checked) cnt += timeTable[s_idx].d2;
        })
      )
    );

    return cnt;
  }

  useEffect(() => {
    setCountTime(getCountTime());
  }, [split]);

  const [clickedTime, setClickedTime] = useState("-1.-1");

  return (
    <>
      <div className="div-timetable-section-top Sub3">
        면접 일정 추가
        <div className="div-timetable-section-01">
          <div style={{ height: "33px" }}>
            <div
              className="div-timetable-section-01-02-dropdown Body4"
              style={{ borderColor: dropdown1 && "black" }}
            >
              <div
                className="div-click div-timetable-section-01-01-dropdown-box Body4"
                style={{
                  borderBottom: dropdown1
                    ? "1px solid #cdcdcd"
                    : "1px solid transparent",
                  width: "91.5px",
                }}
                onClick={() => setDropdown1(!dropdown1)}
              >
                {checked1 === -1 ? "소요시간" : data1[checked1] + " 분"}
                {dropdown1 ? (
                  <img className="icon" src={Icon_ArrowUp} alt="" />
                ) : (
                  <img className="icon" src={Icon_ArrowDown} alt="" />
                )}
              </div>
              {dropdown1 && (
                <div
                  className="div-timetable-section-01-02-dropdown-scroll"
                  style={{ width: "91.5px" }}
                >
                  {data1.map((d, idx) => (
                    <button
                      className="button-timetable-section-01-01-dropdown Body4"
                      style={{
                        color: checked1 === idx && "#FF7A00",
                      }}
                      onClick={() => {
                        setChecked1(idx);
                        setDropdown1(false);
                      }}
                    >
                      {d} 분
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div style={{ height: "33px" }}>
            <div
              className="div-timetable-section-01-02-dropdown Body4"
              style={{ borderColor: dropdown2 && "black" }}
            >
              <div
                className="div-click div-timetable-section-01-01-dropdown-box Body4"
                style={{
                  borderBottom: dropdown2
                    ? "1px solid #cdcdcd"
                    : "1px solid transparent",
                  width: "117.75px",
                }}
                onClick={() => setDropdown2(!dropdown2)}
              >
                {checked2 === -1 ? "동시 면접 인원" : data2[checked2] + " 명"}
                {dropdown2 ? (
                  <img className="icon" src={Icon_ArrowUp} alt="" />
                ) : (
                  <img className="icon" src={Icon_ArrowDown} alt="" />
                )}
              </div>
              {dropdown2 && (
                <div
                  className="div-timetable-section-01-02-dropdown-scroll"
                  style={{ width: "117.75px" }}
                >
                  {data2.map((d, idx) => (
                    <button
                      className="button-timetable-section-01-01-dropdown Body4"
                      style={{
                        color: checked2 === idx && "#FF7A00",
                      }}
                      onClick={() => {
                        setChecked2(idx);
                        setDropdown2(false);
                      }}
                    >
                      {d} 명
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              height: "33px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={line} alt="" style={{ height: "21px" }} />
          </div>
          <div style={{ height: "33px" }}>
            <NewCalendar
              value={data3 || "날짜"}
              onClick={async (e) => setData3(e.target.id)}
            />
          </div>
          <div style={{ height: "33px" }}>
            <div
              className="div-timetable-section-01-02-dropdown Body4"
              style={{ borderColor: dropdown4 && "black" }}
            >
              <div
                className="div-click div-timetable-section-01-02-dropdown-box Body4"
                style={{
                  borderBottom: dropdown4
                    ? "1px solid #cdcdcd"
                    : "1px solid transparent",
                }}
                onClick={() => setDropdown4(!dropdown4)}
              >
                {data4[checked4] + " 시"}
                {dropdown4 ? (
                  <img className="icon" src={Icon_ArrowUp} alt="" />
                ) : (
                  <img className="icon" src={Icon_ArrowDown} alt="" />
                )}
              </div>
              {dropdown4 && (
                <div
                  className="div-timetable-section-01-02-dropdown-scroll"
                  style={{ width: "75px" }}
                >
                  {data4.map((d, idx) => (
                    <button
                      className="button-timetable-section-01-02-dropdown Body4"
                      style={{
                        color: checked4 === idx && "#FF7A00",
                      }}
                      onClick={() => {
                        setChecked4(idx);
                        setDropdown4(false);
                        setChecked5(idx);
                      }}
                    >
                      {d} 시
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className="Body3"
            style={{
              height: "33px",
              display: "flex",
              alignItems: "center",
            }}
          >
            ~
          </div>
          <div style={{ height: "33px" }}>
            <div
              className="div-click div-timetable-section-01-02-dropdown Body4"
              style={{ borderColor: dropdown5 && "black" }}
            >
              <div
                className="div-timetable-section-01-02-dropdown-box Body4"
                style={{
                  borderBottom: dropdown5
                    ? "1px solid #cdcdcd"
                    : "1px solid transparent",
                }}
                onClick={() => setDropdown5(!dropdown5)}
              >
                {data5[checked5 === 0 ? checked4 : checked5] + " 시"}
                {dropdown5 ? (
                  <img className="icon" src={Icon_ArrowUp} alt="" />
                ) : (
                  <img className="icon" src={Icon_ArrowDown} alt="" />
                )}
              </div>
              {dropdown5 && (
                <div
                  className="div-timetable-section-01-02-dropdown-scroll"
                  style={{ width: "75px" }}
                >
                  {data5.map(
                    (d, idx) =>
                      parseInt(d) > parseInt(data4[checked4]) && (
                        <button
                          className="button-timetable-section-01-02-dropdown Body4"
                          style={{
                            color: checked5 === idx && "#FF7A00",
                          }}
                          onClick={() => {
                            setChecked5(idx);
                            setDropdown5(false);
                          }}
                        >
                          {d} 시
                        </button>
                      )
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className="Body3"
            style={{
              width: "5px",
              height: "33px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              className="icon"
              src={Icon_Plus}
              alt=""
              onClick={() => {
                if (checked1 === -1)
                  window.alert("면접 소요시간을 설정해주세요.");
                else if (checked2 === -1)
                  window.alert("동시 면접 인원을 설정해주세요.");
                else if (data3 === "")
                  window.alert("면접 날짜를 설정해주세요.");
                else {
                  var check = 0;
                  timeTable.map((tt) => {
                    if (tt.d3 === data3) {
                      window.alert("이미 추가한 날짜입니다.");
                    } else check++;
                  });
                  if (check === timeTable.length) {
                    createTime();
                    setDropdown1(false);
                    setDropdown2(false);
                    setDropdown4(false);
                    setDropdown5(false);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
      {timeTable.length === 0 ? (
        <div className="div-timetable-no-time H2">
          면접 일정을 추가하면
          <br />
          자동으로 면접 시간표가 생성돼요!
        </div>
      ) : (
        <div className="div-timetable-section-03-wrap">
          {timeTable.map((t, idx) => (
            <div
              style={{
                zIndex: "150",
                height: "fit-content",
              }}
            >
              <div
                className="div-click div-timetable-section-03-01 Body3"
                onClick={() => {
                  if (window.confirm("정말 삭제하시겠습니까?")) {
                    deleteTime(idx);
                  }
                }}
              >
                {dayjs(t.d3).format("YYYY. MM. DD ddd")}
                <img
                  className="img-formmaking-chip-question-close"
                  src={Icon_Close}
                  alt=""
                />
              </div>
              <div className="div-timetable-section-03-03 Body4">
                {split.length > 0 &&
                  split[idx] &&
                  split[idx].map((h, h_idx) => (
                    <>
                      <div style={{ position: "relative" }}>
                        <div
                          className="div-timetable-section-03-03-row Body4"
                          style={{
                            backgroundColor:
                              clickedTime === idx + "." + h_idx && "#FF7A00",
                            color: clickedTime === idx + "." + h_idx && "white",
                            opacity:
                              h.time_m.filter((m) => m.checked).length === 0 &&
                              "0.2",
                          }}
                          onClick={() =>
                            clickedTime === idx + "." + h_idx
                              ? setClickedTime(-1 + "." + -1)
                              : setClickedTime(idx + "." + h_idx)
                          }
                        >
                          <div className="Body4" style={{ width: "80px" }}>
                            {h.time_h}
                          </div>
                          <div className="Body4">
                            {t.d2 * h.time_m.filter((m) => m.checked).length}명
                          </div>
                          <img
                            className="icon"
                            src={
                              clickedTime === idx + "." + h_idx
                                ? Icon_ArrowUp
                                : Icon_ArrowDown
                            }
                            alt=""
                            style={{
                              width: "15px",
                              filter:
                                clickedTime === idx + "." + h_idx &&
                                "invert(100%)",
                            }}
                          />
                        </div>
                        {clickedTime === idx + "." + h_idx && (
                          <div className="div-timetable-section-03-03-row-modal">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                              }}
                            >
                              <button
                                className="main-black-button button-timetable-modal-row Tag1"
                                onClick={() => {
                                  setSplit(
                                    split.map((s, s_idx) =>
                                      s_idx === idx
                                        ? s.map((ss, ss_idx) =>
                                            ss_idx === h_idx
                                              ? {
                                                  time_h: ss.time_h,
                                                  time_m: ss.time_m.map(
                                                    (sss, sss_idx) => ({
                                                      time: sss.time,
                                                      checked:
                                                        h.time_m.filter(
                                                          (m) => m.checked
                                                        ).length === 0
                                                          ? true
                                                          : false,
                                                    })
                                                  ),
                                                }
                                              : ss
                                          )
                                        : s
                                    )
                                  );
                                  setClickedTime("-1.-1");
                                }}
                              >
                                {h.time_m.filter((m) => m.checked).length === 0
                                  ? "전체 선택"
                                  : "전체 취소"}
                              </button>
                            </div>
                            {h.time_m.map((m, m_idx) => (
                              <div className="div-timetable-section-03-03-row-modal-row Body4">
                                <img
                                  className="icon"
                                  src={m.checked ? CheckBox_Checked : CheckBox}
                                  alt=""
                                  onClick={() =>
                                    setSplit(
                                      split.map((s, s_idx) =>
                                        s_idx === idx
                                          ? s.map((ss, ss_idx) =>
                                              ss_idx === h_idx
                                                ? {
                                                    time_h: ss.time_h,
                                                    time_m: ss.time_m.map(
                                                      (sss, sss_idx) =>
                                                        sss_idx === m_idx
                                                          ? {
                                                              time: sss.time,
                                                              checked:
                                                                !sss.checked,
                                                            }
                                                          : sss
                                                    ),
                                                  }
                                                : ss
                                            )
                                          : s
                                      )
                                    )
                                  }
                                />
                                {m.time}
                                <div className="Body4">{t.d2}명</div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </>
                  ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {clickedTime !== "-1.-1" && (
        <div
          className="div-header-outsection"
          ref={outSection}
          onClick={(e) => {
            if (outSection.current === e.target) setClickedTime("-1.-1");
          }}
          style={{ zIndex: "100" }}
        />
      )}
    </>
  );
}

export default TimeTable;
