import React, { useState } from "react";
import LoginformM from "../LoginformM";

const Protected = ({ element: Element, ...rest }) => {
  const [loginOpen, setLoginOpen] = useState(false);
  const kakaoAuth_ok = window.localStorage.kakaoAuth_ok || 0;

  if (window.localStorage.getItem("session_key")) {
    return <Element />;
  } else {
    alert("로그인 후 이용해주세요!");
    return (
      <LoginformM setModalOpen={setLoginOpen} registerNumber={kakaoAuth_ok} />
    );
  }
};

export default Protected;
