/*================================
* 최종 합격자 현황 테이블
==================================*/

import React, { useState } from "react";
import Icon_Application from "../asset/icon/Icon_Application_Gray.svg";
import Answer from "./Answer";
import ViewForm from "./ViewForm";
import ChipStatus4 from "./ChipStatus4";
import ChipStatusDocs from "./ChipStatusDocs";

const TableContent6 = ({
  recruit_id,
  recruitDocs,
  applicantList,
  columnList,
  columnWidth,
  modalViewForm,
  setModalViewForm,
  recruitInfo,
  managerList,
  getAllScore,
}) => {
  const [applicantNum, setApplicantNum] = useState();
  const showRows = applicantList.map((applicant, idx) => (
    <tr key={idx}>
      {/* 상태 */}
      <td className="td-sticky-01">
        {recruitInfo.step === "서류" ? (
          <span>
            <ChipStatusDocs state={applicant.stateV} />
          </span>
        ) : (
          <span>
            {applicant.stateV === 2 ? (
              <button
                className="button-chip button-chip-status-1 Body5"
                style={{ backgroundColor: "#ececec" }}
              >
                불합격
              </button>
            ) : (
              <ChipStatus4 state={applicant.stateV} />
            )}
          </span>
        )}
      </td>
      {/* 점수 */}
      <td className="td-sticky-02-score">
        {applicant.stateV !== 2 && (
          <Answer
            question={columnList[1].data}
            answer={
              applicant.score % 1 > 0
                ? applicant.score.toFixed(1)
                : applicant.score
            }
          />
        )}
      </td>
      {/* 지원자명 */}
      <td className="td-sticky-03-score">
        <div className="td-sticky-02-index Tag1">{applicant.idx + 1}</div>
        {
          <Answer
            question={columnList[2].data}
            answer={applicant.profile.name}
          />
        }
        <img
          className="div-click img-table-application"
          src={Icon_Application}
          alt=""
          onClick={() => {
            setApplicantNum(idx);
            setModalViewForm(true);
          }}
        />
      </td>
      {/* --------------------------- 질문 ---------------------------*/}
      <>
        {columnList[3].isView && (
          <td>
            <Answer
              question={columnList[3].data}
              answer={applicant.profile.phone}
            />
          </td>
        )}
      </>
      <>
        {columnList[4].isView && (
          <td>
            <Answer
              question={columnList[4].data}
              answer={applicant.profile.birthday}
            />
          </td>
        )}
      </>
      <>
        {columnList[5].isView && (
          <td>
            <Answer
              question={columnList[5].data}
              answer={applicant.profile.gender}
            />
          </td>
        )}
      </>
      {columnList.map(function (column, idxx) {
        if (idxx > 4 && applicant.answer[idxx - 6] && column.isView) {
          return (
            <td key={idxx}>
              <Answer
                question={column.data}
                answer={applicant.answer[idxx - 6].value}
                width={columnWidth[idxx]}
              />
            </td>
          );
        }
      })}
    </tr>
  ));

  return (
    <>
      {showRows}
      {modalViewForm && (
        <ViewForm
          questions={recruitDocs?.question}
          answers={applicantList[applicantNum]}
          applicantNum={applicantNum}
          setApplicantNum={setApplicantNum}
          setModalOpen={setModalViewForm}
          applicantListLength={applicantList.length}
          managerList={managerList}
          recruit_id={recruit_id}
          applicantList={applicantList}
          getAllScore={getAllScore}
          isClosed={true}
        />
      )}
    </>
  );
};

export default React.memo(TableContent6);
