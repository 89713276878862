import React, { useState, useEffect } from "react";
import Connection from "./Connection";
import RecruitInfo from "./RecruitInfo";
import Message from "./Message";
import TimeTable from "./TimeTable";

function RecruitPage2({
  club_id,
  recruit_id,
  recruitDocs,
  recruitInfo,
  recruitStep1,
  setRecruitState,
  clubInfo,
  info,
  isLeftSectionOpen,
}) {
  const [recruitDetailOpen, setRecruitDetailOpen] = useState(false);
  const [countAll, setCountAll] = useState(0);
  const [countFail, setCountFail] = useState(0);
  const [countPass, setCountPass] = useState(0);

  const session_key = localStorage.getItem("session_key");
  useEffect(() => {
    recruitStep1.is_success && setCountAll(recruitStep1.applicant.length);
  }, [recruitStep1]);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const stateHeader = { id: 0, title: "상태", type: 0 };
  const newHeader = [].concat(stateHeader, recruitDocs.question);

  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [out, setOut] = useState();
  const [step2, setStep2] = useState([]);

  const [countTime, setCountTime] = useState();

  const [recipient, setRecipient] = useState([]);
  async function getRecipient() {
    const res = await Connection("/getRecruitStep1", {
      club_id: club_id,
      recruit_id: recruit_id,
    });

    setRecipient(res.applicant.filter((a) => a.state === 1));
  }

  useEffect(() => {
    getRecipient();
  }, []);

  return (
    <>
      <RecruitInfo
        club_id={club_id}
        recruit_id={recruit_id}
        recruitInfo={recruitInfo}
        recruitDetailOpen={recruitDetailOpen}
        setRecruitDetailOpen={setRecruitDetailOpen}
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        clubInfo={clubInfo}
        info={info}
      />
      <div
        className={
          isLeftSectionOpen
            ? "div-manage-section-03-02"
            : "div-manage-section-03-02-wide"
        }
      >
        <div
          className={
            isLeftSectionOpen
              ? "div-manage-section-03-02-01"
              : "div-manage-section-03-02-01-wide"
          }
        >
          <div>
            <div className="div-manage-section-03-01-01-01 H3">
              📅 면접자 타임테이블 설정하기
            </div>
            <TimeTable
              club_id={club_id}
              recruit_id={recruit_id}
              step2={step2}
              setStep2={setStep2}
              setCountTime={setCountTime}
            />
          </div>
          <div className="div-manage-section-03-02-01-02">
            <div className="div-manage-section-03-02-01-02-count-section">
              <div
                className="div-manage-section-03-02-01-02-count Body4"
                style={{ width: "75px" }}
              >
                서류 합격 인원
                <div className="H3">{recipient.length}명</div>
              </div>
              <div
                className="div-manage-section-03-02-01-02-count Body4"
                style={{ width: "75px" }}
              >
                배정 가능 인원
                <div className="H3" style={{ color: "#FF7A00" }}>
                  {countTime}명
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "12px" }}>
              <button
                className="outlined-black-button button-manage-section-03-02-01-02-complete H3"
                onClick={async () => {
                  if (window.confirm("문자 전송을 건너뛰시겠습니까?")) {
                    const ci = await Connection("/setStep2Timetable", {
                      club_id: club_id,
                      recruit_id: recruit_id,
                      timetable: JSON.stringify(step2),
                    });

                    if (ci.is_success) {
                      const ret = await Connection("/sendTimetableMsg", {
                        club_id: club_id,
                        recruit_id: recruit_id,
                        template_id: "none",
                      });
                      if (ret.is_success) {
                        setIsMessageModalOpen(false);
                        setRecruitState(4);
                      }
                    }
                  }
                }}
              >
                건너뛰기
              </button>
              <button
                className="main-orange-button button-manage-section-03-02-01-02-complete H3"
                onClick={() => {
                  if (recipient.length > countTime)
                    window.alert(
                      "면접 시간대를 추가해주세요.\n(배정 가능 인원이 서류 합격 인원보다 적어요😅)"
                    );
                  else {
                    setIsMessageModalOpen(true);
                  }
                }}
              >
                면접 연락 발송
              </button>
            </div>
          </div>
        </div>
      </div>
      {isMessageModalOpen && (
        <Message
          club_id={club_id}
          recruit_id={recruit_id}
          setModalOpen={setIsMessageModalOpen}
          recipient={recipient}
          recruitStep1={recruitStep1}
          setRecruitState={setRecruitState}
          step2={step2}
        />
      )}
    </>
  );
}

export default RecruitPage2;
