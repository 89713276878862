import { React, useEffect } from "react";
import "../../asset/css/community/ClubMain.css";
import "../../asset/css/community/MyClub.css";
import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_ArrowLeft_White from "../../asset/icon/Icon_ArrowLeft_White.svg";
import Icon_Search from "../../asset/icon/Icon_Search.svg";
import Icon_Search_White from "../../asset/icon/Icon_Search_White.svg";
import Icon_Etc from "../../asset/icon/Icon_Etc.svg";
import Icon_Etc_White from "../../asset/icon/Icon_Etc_White.svg";
import badge from "../../asset/clubList/badge.png";
import { useState } from "react";
import ClubMainHome from "./ClubMainHome";
import ClubMainInfo from "./ClubMainInfo";
import ClubMainBoard from "./ClubMainBoard";
import ClubMainSchedule from "./ClubMainSchedule";
import CommunityConnection from "./CommunityConnection";
import Connection from "../Connection";
import MyClubBottomSheetPopup from "./MyClubBottomSheetPopup";
import ComingSoonM from "../ComingSoonM";
import IsManager from "./IsManager";
import IsMember from "./IsMember";
import ManageMember from "./ManageMember";
import logo from "../../asset/header/logo.svg";
import "../../asset/css/community/Home.css";

function MyClub() {
  const session_key = window.localStorage.getItem("session_key");
  const param_path = window.location.pathname.split("/");
  const [selectedClub, setSelectedClub] = useState(param_path[3]);
  const [boardId, setBoardId] = useState(
    param_path[4] === "board" ? param_path[5] : "9999"
  );
  const club_id = param_path[3];

  const [isClubListOpen, setIsClubListOpen] = useState(false);

  const [clubByUser, setClubByUser] = useState();
  const [noClub, setNoClub] = useState(false);
  const [isOnlyClub, setIsOnlyClub] = useState(true);
  const [scrollHeight, setScrollHeight] = useState(190);
  async function getClubByUser() {
    const res = await CommunityConnection("/getMyClubList", {});
    if (res.is_success) {
      setClubByUser(res.club_list);
      setNoClub(res.club_list.length === 0);
      setIsOnlyClub(res.club_list.length === 1);
      res.club_list.length > 1 && setScrollHeight(244);

      if (param_path[3] === "0") {
        getCommunityClubInfo(res.club_list[0].id);
        getCommunityClubRecruits(res.club_list[0].id);
        getCommunityClubImages(res.club_list[0].id);
        getCommunityClubCategory(res.club_list[0].id);
        getCommunityClubLocation(res.club_list[0].id);
        window.history.pushState(
          {},
          "",
          "/community/myclub/" +
            res.club_list[0].id +
            "/" +
            param_path[4] +
            "/0"
        );
      } else {
        getCommunityClubInfo(selectedClub);
        getCommunityClubRecruits(selectedClub);
        getCommunityClubImages(selectedClub);
        getCommunityClubCategory(selectedClub);
        getCommunityClubLocation(selectedClub);
      }
    }
  }

  const [info, setInfo] = useState();
  async function getInfo() {
    setInfo(await Connection("/getInfo", {}));
  }

  const [isManager, setIsManager] = useState();
  async function getIsManager(clubId) {
    setIsManager(await IsManager(session_key, clubId));
  }

  useEffect(() => {
    if (clubByUser) {
      getIsManager(selectedClub);
    }
  }, [clubByUser, selectedClub]);

  useEffect(() => {
    setMenu(0);
    if (clubByUser) {
      getCommunityClubInfo(selectedClub);
      getCommunityClubRecruits(selectedClub);
      getCommunityClubImages(selectedClub);
      getCommunityClubCategory(selectedClub);
      getCommunityClubLocation(selectedClub);
      window.history.pushState(
        {},
        "",
        "/community/myclub/" + selectedClub + "/" + param_path[4] + "/0"
      );
      setBoardId("9999");
    }
  }, [selectedClub]);

  const [clubInfo, setClubInfo] = useState();
  const [recruits, setRecruits] = useState();
  const [images, setImages] = useState();
  const [category, setCategory] = useState();
  const [location, setLocation] = useState();

  async function getCommunityClubInfo(club_id) {
    const res = await CommunityConnection("/getCommunityClubInfo", {
      id: club_id,
    });
    if (res.is_success) setClubInfo(res.club_info);
  }
  async function getCommunityClubRecruits(club_id) {
    const res = await CommunityConnection("/getCommunityClubRecruits", {
      id: club_id,
    });
    if (res.is_success) setRecruits(res.recruits);
  }
  async function getCommunityClubImages(club_id) {
    const res = await CommunityConnection("/getCommunityClubImages", {
      id: club_id,
    });
    if (res.is_success) setImages(res.images);
  }
  async function getCommunityClubCategory(club_id) {
    const res = await CommunityConnection("/getCommunityClubCategory", {
      id: club_id,
    });
    if (res.is_success) setCategory(res.category);
  }
  async function getCommunityClubLocation(club_id) {
    const res = await CommunityConnection("/getCommunityClubLocation", {
      id: club_id,
    });
    if (res.is_success) setLocation(res.location);
  }

  const [menu, setMenu] = useState();
  useEffect(() => {
    getInfo();
    getClubByUser();

    switch (param_path[4]) {
      case "home":
        setMenu(0);
        break;
      case "info":
        setMenu(1);
        break;
      case "board":
        setMenu(2);
        break;
      case "schedule":
        setMenu(3);
        break;
      default:
    }
  }, []);

  useEffect(() => {
    if (menu === 0) {
      const state = { menu: 0 };
      window.history.pushState(
        state,
        "",
        "/community/myclub/" + club_id + "/home/0"
      );
    }
    if (menu === 1) {
      const state = { menu: 1 };
      window.history.pushState(
        state,
        "",
        "/community/myclub/" + club_id + "/info/0"
      );
    }
    if (menu === 2) {
      const state = { menu: 2 };
      if (param_path.length !== 7) {
        window.history.pushState(
          state,
          "",
          "/community/myclub/" + club_id + "/board/9999"
        );
      }
    }
    if (menu === 3) {
      const state = { menu: 3 };
      window.history.pushState(
        state,
        "",
        "/community/myclub/" + club_id + "/schedule/0"
      );
    }
    if (menu === 4) {
      const state = { menu: 4 };
      window.history.pushState(
        state,
        "",
        "/community/myclub/" + club_id + "/member/0"
      );
    }
  }, [menu]);

  const [isScrolled, setIsScrolled] = useState(false);

  const [isWriteSectionOpen, setIsWriteSectionOpen] = useState(false);

  const [isArticleOpen, setIsArticleOpen] = useState(param_path.length === 7);

  function handleScroll() {
    if (window.scrollY > scrollHeight) setIsScrolled(true);
    else setIsScrolled(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    function checkScroll() {
      window.addEventListener("scroll", handleScroll);
    }
    checkScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handlePopState1 = (e) => {
      setIsArticleOpen(false);
    };
    window.addEventListener("popstate", handlePopState1);

    return () => window.removeEventListener("popstate", handlePopState1);
  }, []);

  return noClub ? (
    <div className="div-myclub-noclub-section Sub4M">
      <div className="div-home-header-section">
        <img className="img-home-header-logo" src={logo} alt="" />
      </div>
      가입한 동아리가 없어요!
      <br />
      👇 어떤 동아리가 있는지 탐색해 볼까요?👇
      <button
        className="main-orange-buttonM button-myclub-noclub Sub3M"
        onClick={() => (window.location.href = "/community/club")}
      >
        동아리 탐색하기
      </button>
    </div>
  ) : (
    <div
      style={{ overflow: (isWriteSectionOpen || isArticleOpen) && "hidden" }}
    >
      {isScrolled ? (
        <div className="div-club-main-top-section-scrolled">
          <div style={{ display: "flex", gap: "8px" }}>
            {/* <img
            src={Icon_ArrowLeft}
            alt=""
            onClick={() => (window.location.href = "/community/club")}
          /> */}
            {clubInfo?.name}
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            {/* <img src={Icon_Search} alt="" />
          <img src={Icon_Etc} alt="" /> */}
          </div>
        </div>
      ) : (
        <div className="div-club-main-top-section">
          <img
            src={Icon_ArrowLeft_White}
            alt=""
            onClick={() => (window.location.href = "/community/club")}
            style={{ visibility: "hidden" }}
          />
          <div style={{ display: "flex", gap: "16px" }}>
            {/* <img src={Icon_Search_White} alt="" />
          <img src={Icon_Etc_White} alt="" /> */}
          </div>
        </div>
      )}
      {!isScrolled && images && images[0]?.image_url && (
        <img
          className="img-club-main-top"
          src={images && images[0]?.image_url}
          alt=""
        />
      )}
      <div className="div-club-main-scroll-section">
        <div className="div-club-main-scroll-background" />
        {isScrolled ? (
          <div className="div-club-main-infobox-section-scrolled">
            {clubInfo?.name}
          </div>
        ) : (
          <div
            className={
              isOnlyClub
                ? "div-club-main-infobox-section"
                : "div-myclub-infobox-section"
            }
          >
            <div className="div-myclub-infobox-logo-text">
              {clubInfo?.is_certified === 1 && (
                <img className="img-club-info-badge" src={badge} alt="" />
              )}
              <img
                className="img-club-main-infobox-logo"
                src={clubInfo?.image}
                alt=""
              />
              <div className="div-myclub-infobox-text-section">
                <div className="div-club-main-infobox-name Sub2M">
                  {clubInfo?.name}
                </div>
                <div className="div-club-main-infobox-introduce Body5M">
                  {clubInfo?.introduce}
                </div>
              </div>
            </div>
            {!isOnlyClub && (
              <button
                className="main-orange-button Sub3M"
                onClick={() => {
                  setIsClubListOpen(true);
                }}
              >
                다른 동아리 선택하기
              </button>
            )}
          </div>
        )}
        <div
          className={
            isScrolled
              ? "div-club-main-menu-section-scrolled"
              : `div-${isOnlyClub ? "club-main" : "myclub"}-menu-section`
          }
        >
          <div
            className={
              menu === 0
                ? "div-club-main-menu-checked Sub3M"
                : "div-club-main-menu Sub3M"
            }
            onClick={() => {
              setMenu(0);
              if (window.scrollY >= scrollHeight)
                window.scrollTo(0, scrollHeight + 1);
            }}
          >
            홈
          </div>
          <div
            className={
              menu === 1
                ? "div-club-main-menu-checked Sub3M"
                : "div-club-main-menu Sub3M"
            }
            onClick={() => {
              setMenu(1);
              if (window.scrollY >= scrollHeight)
                window.scrollTo(0, scrollHeight + 1);
            }}
          >
            정보
          </div>
          <div
            className={
              menu === 2
                ? "div-club-main-menu-checked Sub3M"
                : "div-club-main-menu Sub3M"
            }
            onClick={() => {
              setMenu(2);
              if (window.scrollY >= scrollHeight)
                window.scrollTo(0, scrollHeight + 1);
            }}
          >
            게시판
          </div>
          <div
            className={
              menu === 3
                ? "div-club-main-menu-checked Sub3M"
                : "div-club-main-menu Sub3M"
            }
            onClick={() => {
              setMenu(3);
              if (window.scrollY >= scrollHeight)
                window.scrollTo(0, scrollHeight + 1);
            }}
          >
            일정
          </div>
          {isManager === 1 && (
            <div
              className={
                menu === 4
                  ? "div-club-main-menu-checked Sub3M"
                  : "div-club-main-menu Sub3M"
              }
              onClick={() => {
                setMenu(4);
                if (window.scrollY >= 190) window.scrollTo(0, 191);
              }}
            >
              회원관리
            </div>
          )}
        </div>
        <div
          className={`div-${
            isOnlyClub ? "club-main" : "myclub"
          }-contents-section`}
        >
          {menu === 0 && (
            <ClubMainHome
              recruit={recruits && recruits}
              activiy_image={images && images}
            />
          )}
          {menu === 1 && (
            <ClubMainInfo
              purpose={clubInfo?.purpose}
              category={info?.category[category?.category]}
              location={info?.location[location?.location_id]}
              sns_link={clubInfo?.sns_link}
              info={clubInfo?.info}
            />
          )}
          {menu === 2 && (
            <ClubMainBoard
              isManager={isManager}
              isMember={1}
              isWriteSectionOpen={isWriteSectionOpen}
              setIsWriteSectionOpen={setIsWriteSectionOpen}
              isArticleOpen={isArticleOpen}
              setIsArticleOpen={setIsArticleOpen}
              boardId={boardId}
              setBoardId={setBoardId}
            />
          )}
          {/* {menu === 3 && <ClubMainSchedule />} */}
          {menu === 3 && <ComingSoonM />}
          {menu === 4 && <ManageMember clubId={club_id} />}
        </div>
      </div>
      {isClubListOpen && (
        <MyClubBottomSheetPopup
          list={clubByUser}
          currentValue={club_id}
          setValue={setSelectedClub}
          setIsOpen={setIsClubListOpen}
        />
      )}
    </div>
  );
}

export default MyClub;
