import { useState, useEffect, useRef } from "react";
import "./styles.css";
import Icon_Group from "../asset/icon/Icon_Group.svg";
import Icon_Table from "../asset/icon/Icon_Table.svg";
import Icon_Download from "../asset/icon/Icon_Download.svg";
import Connection from "./Connection";
import TableContent4M from "./TableContent4M";
import ShowTimeTableM from "./ShowTimeTableM";

const createHeaders = (headers) => {
  return headers.map((item, idx) => ({
    data: { ...item, id: idx },
    text: item.k,
    isView: true,
    filter: item.type === 5,
    a_id: item.id,
    ref: useRef(),
  }));
};
const Table4M = ({
  club_id,
  recruit_id,
  recruitDocs,
  headers,
  applicantList,
  setCountFail,
  setCountPass,
  timetable,
}) => {
  const [applicantListV, setApplicantListV] = useState([]);
  const [modalViewForm, setModalViewForm] = useState(false);
  useEffect(() => {
    setApplicantListV(
      applicantList.map((a, idx) => ({ ...a, idx: idx, stateV: a.state }))
    );
  }, [applicantList]);

  const [isSorted, setIsSorted] = useState(-1);
  async function sortApplicantList(idx) {
    if (idx === 1) {
      isSorted === -1
        ? setApplicantListV(
            [...applicantListV].sort(function (a, b) {
              if (
                `${a.interview_date} (${a.interview_time})` <
                `${b.interview_date} (${b.interview_time})`
              )
                return -1;
              else if (
                `${a.interview_date} (${a.interview_time})` >
                `${b.interview_date} (${b.interview_time})`
              )
                return 1;
              else return 0;
            })
          )
        : setApplicantListV([...applicantListV].sort((a, b) => a.idx - b.idx));
    } else {
      isSorted === -1
        ? setApplicantListV(
            [...applicantListV].sort(
              (a, b) => a.answer[idx - 6].sort_num - b.answer[idx - 6].sort_num
            )
          )
        : setApplicantListV([...applicantListV].sort((a, b) => a.idx - b.idx));
    }

    setIsSorted(isSorted === -1 ? idx : -1);
  }

  const [columns, setColumns] = useState(createHeaders(headers));

  const [stateList, setStateList] = useState([]);

  const session_key = localStorage.getItem("session_key");

  const [applicantListW, setApplicantListW] = useState([]);
  async function getRecruitStep2() {
    const res = await Connection("/getRecruitStep2", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
      recruit_id: recruit_id,
    });

    if (res.is_success) {
      setApplicantListW(
        res.applicant_r.map((a, idx) => ({ ...a, idx: idx, stateV: a.state }))
      );
    }
  }
  useEffect(() => {
    getRecruitStep2();
  }, [applicantListV]);

  async function downloadRecruitStep1() {
    const ret = await Connection("/downloadRecruitStep1", {
      club_id: club_id,
      recruit_id: recruit_id,
    });

    window.open(ret.file_url);
  }

  const [isTimeTableModalOpen, setIsTimeTableModalOpen] = useState(false);

  const [showWhole, setShowWhole] = useState(false);

  return (
    <div
      className="table-wrapper Body4"
      id="tt"
      style={{
        marginTop: "12px",
        marginBottom: "60px",
      }}
    >
      {isTimeTableModalOpen && (
        <ShowTimeTableM
          setModalOpen={setIsTimeTableModalOpen}
          session_key={session_key}
          club_id={club_id}
          recruit_id={recruit_id}
          timetable={timetable}
          applicantList={applicantList}
          stateList={stateList}
        />
      )}
      <div className="div-table-filter-button-sectionM">
        <button
          className="button-table-filterM Tag1M"
          onClick={() => setIsTimeTableModalOpen(true)}
        >
          타임테이블 보기
          <img className="img-table-filter" src={Icon_Table} alt="" />
        </button>
        <button
          className={
            showWhole
              ? "button-table-filterM-selected Tag1M"
              : "button-table-filterM Tag1M"
          }
          onClick={() => setShowWhole(!showWhole)}
        >
          전체 지원자 보기
          <img
            className="img-table-filter"
            src={Icon_Group}
            alt=""
            style={{ filter: showWhole && "invert(100)" }}
          />
        </button>
        <button
          className="button-table-filterM Tag1M"
          onClick={() => downloadRecruitStep1()}
        >
          표로 내보내기
          <img className="img-table-filter" src={Icon_Download} alt="" />
        </button>
      </div>
      <table
        className="recruit-table"
        style={{
          gridTemplateColumns: "0.5fr 1fr 2fr 1.5fr",
          maxHeight: "100%",
          overflow: "auto",
          paddingBottom: "80px",
        }}
      >
        <thead>
          <tr>
            <th className="thead-test th-sticky-01" style={{ height: "30px" }}>
              <span className="Body4M"></span>
            </th>
            <th className="thead-test th-sticky-01" style={{ height: "30px" }}>
              <span className="Body4M">상태</span>
            </th>
            <th className="thead-test th-sticky-02" style={{ height: "30px" }}>
              <span className="Body4">📆 면접 날짜 (시간)</span>
            </th>
            <th className="thead-test th-sticky-02" style={{ height: "30px" }}>
              <span className="Body4M">이름</span>
            </th>
          </tr>
        </thead>
        <tbody className="tbody-test">
          <TableContent4M
            club_id={club_id}
            recruit_id={recruit_id}
            recruitDocs={recruitDocs}
            applicantList={showWhole ? applicantListW : applicantListV}
            setApplicantList={setApplicantListV}
            columnList={columns}
            setCountFail={setCountFail}
            setCountPass={setCountPass}
            stateList={stateList}
            setStateList={setStateList}
            modalViewForm={modalViewForm}
            setModalViewForm={setModalViewForm}
            showWhole={showWhole}
          />
        </tbody>
      </table>
    </div>
  );
};

export default Table4M;
