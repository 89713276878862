import React, { useState } from "react";
import styled from "styled-components/macro";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";

export default function Mypage({ ...props }) {
  const getPhone = () => {
    const inputPhone = props.profile?.phone;
    let first, second, third;
    if (inputPhone.length === 10) {
      first = inputPhone.slice(0, 3);
      second = inputPhone.slice(3, 6);
      third = inputPhone.slice(6, 10);
    } else if (inputPhone.length === 11) {
      first = inputPhone.slice(0, 3);
      second = inputPhone.slice(3, 7);
      third = inputPhone.slice(7, 11);
    } else return inputPhone;
    return `${first}-${second}-${third}`;
  };
  const getBirth = () => {
    const inputDate = props.profile?.birthday;
    const year = inputDate.slice(0, 4);
    const month = inputDate.slice(4, 6);
    const day = inputDate.slice(6, 8);
    return `${year}.${month}.${day}`;
  };
  const textGender = props.profile?.gender === "male" ? "남자" : "여자";

  return (
    <>
      <MainContainer>
        <Header className="Sub1M">
          <img
            src={Icon_ArrowLeft}
            alt=""
            onClick={() => {
              props.setPage("manage");
              window.history.back();
            }}
          />
          내 정보
        </Header>
        <Body>
          <div>
            <MainTitle>회원 정보</MainTitle>
            <ProfileBox>
              <ProfileRow>
                <p>이름</p>
                <p>{props.profile?.name}</p>
              </ProfileRow>
              <ProfileRow>
                <p>전화번호</p>
                <p>{getPhone()}</p>
              </ProfileRow>
              <ProfileRow>
                <p>생년월일</p>
                <p>{getBirth()}</p>
              </ProfileRow>
              <ProfileRow>
                <p>성별</p>
                <p>{textGender}</p>
              </ProfileRow>
            </ProfileBox>
            <ButtonModifyProfile
              className="Sub4M"
              onClick={() => {
                window.open("http://pf.kakao.com/_xnQWxfxj");
              }}
            >
              정보 변경 문의하기
            </ButtonModifyProfile>
          </div>
        </Body>
      </MainContainer>
    </>
  );
}
const MainContainer = styled.div`
  min-width: 280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  padding: 0px 12px;
  height: 56px;
  display: flex;
  gap: 8px;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
const Body = styled.div`
  padding: 12px;
  > div:first-child {
    border-bottom: 1px solid #ececec;
    padding-bottom: 16px;
    padding-top: 8px;
  }
  > div:nth-child(2) {
    padding-top: 20px;
  }
`;
const MainTitle = styled.p.attrs({
  className: "Sub3M",
})`
  margin-bottom: 14px;
`;
const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;
const ProfileRow = styled.div.attrs({
  className: "Body5M",
})`
  display: flex;
  gap: 20px;
  color: #6a6a6a;
  > p:first-child {
    width: 68px;
  }
`;
const ButtonModifyProfile = styled.button`
  padding: 9px 16px;
  width: 100%;
  height: 36px;
  text-align: center;
  border: 1px solid black;
  border-radius: 8px;
`;
