import React, { useState } from "react";
import styled from "styled-components/macro";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";

export default function NotiList({ ...props }) {
  return (
    <>
      <ContentsWrapper>
        <Header>
          <img
            src={Icon_ArrowLeft}
            alt=""
            onClick={() => props.setPage("mypage-main")}
          />
          이벤트
        </Header>
        <Body>
          {true && (
            <DivNoLike>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M23.0456 2.81641L37.1878 16.9585L34.8308 19.3155L33.6523 18.137L26.5811 25.2082L25.4026 31.1007L23.0456 33.4577L15.9745 26.3867L7.72495 34.6362L5.36792 32.2792L13.6175 24.0297L6.54644 16.9585L8.90345 14.6015L14.796 13.423L21.8671 6.35194L20.6886 5.17344L23.0456 2.81641ZM24.2241 8.70897L16.4394 16.4937L11.7363 17.4343L22.5698 28.2678L23.5104 23.5647L31.2953 15.78L24.2241 8.70897Z"
                  fill="#CDCDCD"
                />
              </svg>
              <p className="Sub4M">등록된 이벤트가 없어요!</p>
            </DivNoLike>
          )}
        </Body>
      </ContentsWrapper>
    </>
  );
}
const ContentsWrapper = styled.div`
  min-width: 280px;
`;
const Header = styled.div.attrs({
  className: "Sub1M",
})`
  padding: 0px 12px;
  height: 48px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #1e1e1e;
  > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DivNoLike = styled.div`
  text-align: center;
  margin-top: 108px;
  > p {
    margin-top: 20px;
    color: #cdcdcd;
  }
`;
