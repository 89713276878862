import { React, useEffect, useState } from "react";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import Connection from "./Connection";
import dayjs from "dayjs";
import CommunityConnection from "./community/CommunityConnection";

function FormIntro() {
  const [recruit, setRecruit] = useState({});
  const [isClosed, setIsClosed] = useState(false);
  const today = new Date();

  const param0 = window.location.href.split("?")[0];
  const param = param0.split("/");

  const [clubId, setClubId] = useState(param[param.length - 2]);
  const [recruitId, setRecruitId] = useState(param[param.length - 1]);

  // const club_id = param[param.length - 2];
  // const recruit_id = param[param.length - 1];

  const getUrlInfo = async () => {
    const res = await CommunityConnection("/getUrlInfo", {
      session_key: "admin_demo",
      club_name: param[param.length - 2],
      recruit_date: param[param.length - 1],
    });
    if (res.is_success) {
      setClubId(res.club_id);
      setRecruitId(res.recruit_id);
      const res2 = await Connection("/getRecruit", {
        club_id: res.club_id,
        recruit_id: res.recruit_id,
      });
      if (res2.is_success) {
        setRecruit(res2);
        setIsClosed(res2.state !== 1);
      }
    }
  };

  const [formUrl, setFormUrl] = useState(
    window.location.protocol +
      "//" +
      window.location.host +
      "/form/" +
      clubId +
      "/" +
      recruitId +
      "/question"
  );

  useEffect(() => {
    setFormUrl(
      window.location.protocol +
        "//" +
        window.location.host +
        "/form/" +
        clubId +
        "/" +
        recruitId +
        "/question"
    );
  }, [clubId, recruitId]);

  const [isDetailOpen, setIsDetailOpen] = useState(false);

  async function getRecruit() {
    if (param[param.length - 1].length < 36) await getUrlInfo();
    else {
      const res = await Connection("/getRecruit", {
        club_id: clubId,
        recruit_id: recruitId,
      });
      if (res.is_success) {
        setRecruit(res);
        setIsClosed(res.state !== 1);
      }
    }
  }

  useEffect(() => {
    document.body.style.overflow = "auto";
    getRecruit();
  }, []);

  const [isKakaoModalOpen, setIsKakaoModalOpen] = useState(false);
  const showKakaoModal = (
    <div className="div-modal-background">
      <div className="div-form-kakao-modal-section Sub3M">
        <img
          src={Icon_Close}
          alt=""
          className="img-form-kakao-modal-close"
          onClick={() => {
            setIsKakaoModalOpen(false);
            document.body.style.overflow = "auto";
          }}
        />
        카카오톡에서 기본 정보를 불러올까요?
        <button
          className="button-form-kakao-modal-yes Sub3M"
          onClick={() => {
            setIsKakaoModalOpen(false);
            sessionStorage.setItem("url", formUrl);
            sessionStorage.setItem("image", recruit.image);
            window.Kakao.Auth.authorize({
              redirectUri:
                window.location.protocol +
                "//" +
                window.location.host +
                "/formkakao",
            });
          }}
        >
          네, 불러올래요
        </button>
        <button
          className="button-form-kakao-modal-no"
          onClick={() => {
            setIsKakaoModalOpen(false);
            window.sessionStorage.clear();
            sessionStorage.setItem("image", recruit.image);
            window.location.href = formUrl;
          }}
        >
          아니요, 괜찮아요
        </button>
      </div>
    </div>
  );

  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    const element = document.getElementById("recruit-content");
    element && setIsOverflown(element.scrollHeight > element.clientHeight);
  }, [recruit]);

  useEffect(() => {
    let ins = document.createElement("ins");
    let scr = document.createElement("script");
    ins.className = "kakao_ad_area";
    ins.style = "display:none; width:100%;";
    scr.async = "true";
    scr.type = "text/javascript";
    scr.src = "//t1.daumcdn.net/kas/static/ba.min.js";
    ins.setAttribute("data-ad-width", "320");
    ins.setAttribute("data-ad-height", "50");
    ins.setAttribute("data-ad-unit", "DAN-QxtZZiHiszRp9orD");
    document.querySelector(".adfit").appendChild(ins);
    document.querySelector(".adfit").appendChild(scr);
  }, []);

  return (
    <div className="div-form-mobile-section">
      {isKakaoModalOpen && showKakaoModal}
      {isClosed ? (
        recruit.end_date &&
        dayjs(today).format("YYYYMMDD") <
          dayjs(recruit.end_date).format("YYYYMMDD") ? (
          <div className="div-form-question-background">
            <div
              className="div-form-question-section H3M"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="H3M" style={{ marginBottom: "90px" }}>
                모집이 조기 마감되었습니다.😭
              </div>
            </div>
          </div>
        ) : (
          <div className="div-form-question-background">
            <div
              className="div-form-question-section H3M"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="H3M" style={{ marginBottom: "90px" }}>
                모집이 마감되었습니다.😥
              </div>
            </div>
          </div>
        )
      ) : dayjs(today).format("YYYYMMDD") <
        dayjs(recruit.start_date).format("YYYYMMDD") ? (
        <div className="div-form-question-background">
          <div
            className="div-form-question-section H3M"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div className="H3M" style={{ marginBottom: "90px" }}>
              모집 시작 전입니다.😎
            </div>
          </div>
        </div>
      ) : dayjs(today).format("YYYYMMDD") >
        dayjs(recruit.end_date).format("YYYYMMDD") ? (
        <div className="div-form-question-background">
          <div
            className="div-form-question-section H3M"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div className="H3M" style={{ marginBottom: "90px" }}>
              모집이 마감되었습니다.😥
            </div>
          </div>
        </div>
      ) : (
        <div className="div-form-mobile">
          <div className="div-form-section">
            <div className="div-form-club-info Sub2M">
              {recruit.image ? (
                <img
                  className="img-form-club-info"
                  src={recruit.image}
                  alt=""
                />
              ) : (
                <div
                  className="img-form-club-info skeleton-form-intro"
                  style={{ borderRadius: "100%" }}
                />
              )}
              {recruit.name ? (
                recruit.name
              ) : (
                <div
                  className="skeleton-form-intro"
                  style={{ height: 22, width: 100 }}
                />
              )}
            </div>
            <div className="div-form-poster">
              {recruit.poster ? (
                <img
                  className="img-form-club-poster"
                  src={recruit.poster}
                  alt=""
                />
              ) : (
                <div className="img-form-club-poster skeleton-form-intro" />
              )}
            </div>
            {recruit.title ? (
              <div className="div-form-recruit-title-section Sub1M">
                {recruit.title}
              </div>
            ) : (
              <div
                className="div-form-recruit-title-section Sub1M skeleton-form-intro"
                style={{ height: 24, width: "100%" }}
              />
            )}
            <div className="div-form-recruit-detail-section">
              <div
                className="div-form-recruit-detail-01 Sub3M"
                style={{ marginTop: 0 }}
              >
                모집 기간
              </div>
              {recruit.start_date && recruit.end_date ? (
                <div className="div-form-recruit-detail-02 Body4M">
                  {recruit.end_date === "2999-12-31"
                    ? "상시"
                    : `${recruit.start_date} ~ ${recruit.end_date}`}
                </div>
              ) : (
                <div
                  className="div-form-recruit-detail-02 Body4M skeleton-form-intro"
                  style={{ height: 20 }}
                />
              )}
              <div className="div-form-recruit-detail-01 Sub3M">모집 절차</div>
              {recruit.step ? (
                <div className="div-form-recruit-detail-02 Body4M">
                  {recruit.step}
                </div>
              ) : (
                <div
                  className="div-form-recruit-detail-02 Body4M skeleton-form-intro"
                  style={{ height: 20 }}
                />
              )}
              <div className="div-form-recruit-detail-01 Sub3M">활동 지역</div>
              {recruit.location ? (
                <div className="div-form-recruit-detail-02 Body4M">
                  {recruit.is_success && recruit.location}
                </div>
              ) : (
                <div
                  className="div-form-recruit-detail-02 Body4M skeleton-form-intro"
                  style={{ height: 20 }}
                />
              )}
              <div className="div-form-recruit-detail-01 Sub3M">회비</div>
              {recruit.cost ? (
                <div className="div-form-recruit-detail-02 Body4M">
                  {recruit.cost}
                </div>
              ) : (
                <div
                  className="div-form-recruit-detail-02 Body4M skeleton-form-intro"
                  style={{ height: 20 }}
                />
              )}
            </div>
            <pre
              className="div-form-recruit-content"
              style={{
                height: isDetailOpen && "auto",
              }}
              id="recruit-content"
            >
              {!isDetailOpen && isOverflown && (
                <div className="div-form-recruit-content-hidden" />
              )}
              {recruit.description}
            </pre>
            {!isDetailOpen && isOverflown && (
              <button
                className="button-form-recruit-content Sub3M"
                onClick={() => setIsDetailOpen(true)}
              >
                자세히 보기
              </button>
            )}
          </div>
          <div className="div-form-apply-section">
            <button
              className="button-form-apply Sub3M"
              onClick={() => {
                window.scrollTo(0, 0);
                document.body.style.overflow = "hidden";
                setIsKakaoModalOpen(true);
              }}
            >
              지원하러 가기
            </button>
          </div>
          <div className="div-form-adfit" style={{ padding: "0 20px" }}>
            <div className="adfit"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormIntro;
