import React from "react";
import clubInfo from "../../clubInfoTest.json";
import "../../asset/css/community/community.css";
import "../../asset/css/community/ClubList.css";
import "../../asset/css/community/Home.css";
import logo from "../../asset/header/logo.svg";
import Icon_ArrowDown from "../../asset/icon/Icon_ArrowDown.svg";
import Icon_Search from "../../asset/icon/Icon_Search.svg";
import Icon_Search_Orange from "../../asset/icon/Icon_Search_Orange.svg";
import Icon_Group_Gray from "../../asset/icon/Icon_Group_Gray.svg";
import Icon_view_Gray from "../../asset/icon/Icon_view_Gray.svg";
import Icon_Time_Gray from "../../asset/icon/Icon_Time_Gray.svg";
import Icon_Heart_Gray from "../../asset/icon/Icon_Heart_Gray.svg";
import Icon_Heart_Orange from "../../asset/icon/Icon_Heart_Orange.svg";
import Icon_Close from "../../asset/icon/Icon_Close.svg";
import badge from "../../asset/clubList/badge.png";
import { useState } from "react";
import BottomSheetPopup from "./BottomSheetPopup";
import sample_img from "../../asset/main/background.png";
import { useEffect } from "react";
import CommunityConnection from "./CommunityConnection";
import dayjs from "dayjs";
import Connection from "../Connection";

function ClubList() {
  const session_key = window.localStorage.getItem("session_key");
  const today = new Date();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [info, setInfo] = useState();
  async function getInfo() {
    setInfo(await Connection("/getInfo", {}));
  }

  const categoryList = [
    "전체",
    "문화/예술/공연",
    "봉사/사회활동",
    "학술/교양",
    "창업/취업",
    "어학",
    "체육",
    "친목",
    "기타",
  ];
  const [checkedCategory, setCheckedCategory] = useState(0);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [clubList, setClubList] = useState();

  async function getCommunityClubList() {
    const res = await CommunityConnection("/getCommunityClubList");
    if (res.is_success) setClubList(res.club_list);
  }
  const [latestRecruitInfo, setLatestRecruitInfo] = useState();
  async function getLatestRecruitInfo() {
    const res = await CommunityConnection("/getLatestRecruitInfo");
    if (res.is_success) setLatestRecruitInfo(res.latest_recruit);
  }
  const [clubCategory, setClubCategory] = useState();
  async function getClubCategory() {
    const res = await CommunityConnection("/getClubCategory");
    if (res.is_success) setClubCategory(res.club_category);
  }
  const [likedClubs, setLikedClubs] = useState();
  async function getLikedClubs() {
    const res = await CommunityConnection("/getLikedClubs", {});
    if (res.is_success)
      setLikedClubs(res.liked_clubs ? res.liked_clubs.split(",") : []);
  }
  async function updateLikedClubs(newLikedClubs) {
    const res = await CommunityConnection("/updateLikedClubs", {
      liked_clubs: newLikedClubs,
    });
    if (res.is_success) setLikedClubs(res.liked_clubs.split(","));
  }

  useEffect(() => {
    getInfo();
    getCommunityClubList();
    getLatestRecruitInfo();
    getClubCategory();
    getLikedClubs();
  }, []);

  const ClubLatestRecruit = (id) => {
    return latestRecruitInfo?.find(function (recruit) {
      return recruit.club_id === id;
    });
  };

  const highlightedText = (text, query) => {
    if (query !== "" && text.includes(query)) {
      const parts = text.split(new RegExp(`(${query})`, "gi"));

      return (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
              <mark key={index}>{part}</mark>
            ) : (
              part
            )
          )}
        </>
      );
    }

    return text;
  };

  async function updateClubViewCnt(id) {
    const res = await CommunityConnection("/updateClubViewCnt", {
      id: id,
    });
  }

  const showClubs = (
    <div className="div-club-list-section">
      {clubList?.map(
        (club, idx) =>
          (checkedCategory === 0 ||
            (clubCategory &&
              clubCategory[idx].category_id === checkedCategory)) &&
          (!isSearchOpen || search === "" || club.name.includes(search)) && (
            <div
              className="div-club-info-section"
              onClick={() => {
                updateClubViewCnt(club.id);
                window.location.href = "/community/club/" + club.id + "/home/0";
              }}
            >
              <div className="div-club-info-top-section">
                {club.is_certified === 1 && (
                  <img className="img-club-info-badge" src={badge} alt="" />
                )}
                {ClubLatestRecruit(club.id)?.state === 1 &&
                  dayjs(ClubLatestRecruit(club.id)?.end_date).format(
                    "YYYYMMDD"
                  ) >= dayjs(today).format("YYYYMMDD") && (
                    <div
                      className="div-club-info-dday Tag1M"
                      style={
                        parseInt(
                          dayjs(ClubLatestRecruit(club.id)?.start_date).format(
                            "YYYYMMDD"
                          )
                        ) > parseInt(dayjs(today).format("YYYYMMDD"))
                          ? { backgroundColor: "black" }
                          : { backgroundColor: "#FF7A00" }
                      }
                    >
                      {dayjs(ClubLatestRecruit(club.id)?.end_date).format(
                        "YYYYMMDD"
                      ) === "29991231"
                        ? "상시"
                        : dayjs(ClubLatestRecruit(club.id)?.end_date).format(
                            "YYYYMMDD"
                          ) === dayjs(today).format("YYYYMMDD")
                        ? "D-DAY"
                        : "D-" +
                          (dayjs(ClubLatestRecruit(club.id)?.end_date).diff(
                            dayjs(today),
                            "day"
                          ) +
                            1)}
                    </div>
                  )}
                <img
                  className="img-club-info-heart"
                  src={
                    likedClubs?.includes(club.id)
                      ? Icon_Heart_Orange
                      : Icon_Heart_Gray
                  }
                  alt=""
                  onClick={(e) => {
                    if (likedClubs?.includes(club.id)) {
                      setLikedClubs(
                        likedClubs.filter((clubId) => clubId !== club.id)
                      );
                      updateLikedClubs(
                        likedClubs.filter((clubId) => clubId !== club.id)
                      );
                    } else {
                      setLikedClubs([...likedClubs, club.id]);
                      updateLikedClubs([...likedClubs, club.id]);
                    }
                    e.stopPropagation();
                  }}
                />
                <img src={club.image} className="img-club-info-main" alt="" />
              </div>
              <div className="div-club-info-bottom-section">
                <div className="div-club-info-name Sub4M">
                  {search === ""
                    ? club.name
                    : highlightedText(club.name, search)}
                </div>
                <div className="div-club-info-people Body6M">
                  <img src={Icon_Group_Gray} alt="" />
                  {club.mem_cnt}
                  <img
                    src={Icon_view_Gray}
                    style={{ marginLeft: "8px" }}
                    alt=""
                  />
                  {club.view_cnt}
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
  return (
    <div className="div-community-section">
      <div className="div-community">
        <div className="div-home-header-section">
          <img className="img-home-header-logo" src={logo} alt="" />
        </div>
        <div className="div-club-list-header-section">
          {isSearchOpen ? (
            <div className="div-club-list-header-search">
              <input
                className="input-club-list-header-search Body4M"
                placeholder="동아리명을 입력해주세요!"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <img
                className="div-click"
                src={search === "" ? Icon_Search_Orange : Icon_Close}
                alt=""
                style={{ width: "20px" }}
                onClick={() => {
                  if (search === "") setIsSearchOpen(false);
                  else setSearch("");
                }}
              />
            </div>
          ) : (
            <>
              <div
                className="div-club-list-header-select Sub1M"
                onClick={() => setIsCategoryOpen(true)}
              >
                {categoryList[checkedCategory]}
                <img src={Icon_ArrowDown} alt="" />
              </div>
              <button
                className="button-club-list-header-search"
                onClick={() => setIsSearchOpen(true)}
              >
                <img src={Icon_Search} alt="" style={{ width: "20px" }} />
              </button>
            </>
          )}
        </div>
        {showClubs}
      </div>
      {isCategoryOpen && (
        <BottomSheetPopup
          isList={true}
          list={categoryList}
          title={"카테고리를 선택해주세요!"}
          currentValue={checkedCategory}
          setValue={setCheckedCategory}
          setIsOpen={setIsCategoryOpen}
        />
      )}
    </div>
  );
}

export default ClubList;
