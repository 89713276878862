import React, { useState, useEffect } from "react";
import "../asset/css/RecruitPageAlways.css";
import Icon_Reset from "../asset/icon/Icon_Reset.svg";
import Connection from "./Connection";
import RecruitInfo from "./RecruitInfo";
import ToastPopup from "./ToastPopup";
import Loginform from "./Loginform";
import MessageAlways from "./MessageAlways";
import TableAlways from "./TableAlways";
import { useMediaQuery } from "react-responsive";
import CommunityConnection from "./community/CommunityConnection";

function RecruitPageAlways({
  club_id,
  recruit_id,
  recruitDocs,
  recruitInfo,
  recruitStep1,
  setRecruitStep1,
  setRecruitState,
  clubInfo,
  info,
  isLeftSectionOpen,
  managerList,
}) {
  const isWide = useMediaQuery({
    query: isLeftSectionOpen ? "(min-width: 1265px)" : "(min-width: 985px)",
  });

  const [tableType, setTableType] = useState(0);
  const session_key = localStorage.getItem("session_key");

  const [loginModal, setLoginModal] = useState(false);
  const [recruitDetailOpen, setRecruitDetailOpen] = useState(false);
  const [countAll, setCountAll] = useState(0);
  const [countFail, setCountFail] = useState(0);
  const [countPass, setCountPass] = useState(0);

  useEffect(() => {
    recruitStep1.is_success && setCountAll(recruitStep1.applicant.length);
  }, [recruitStep1]);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const stateHeader1 = [
    { k: "상태", type: 0 },
    { k: "지원일", type: 0 },
  ];
  const stateHeader2 = [
    { k: "상태", type: 0 },
    { k: "처리일", type: 0 },
  ];
  const newHeader1 = [].concat(stateHeader1, recruitDocs.question);
  const newHeader2 = [].concat(stateHeader2, recruitDocs.question);

  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [animation1, setAnimation1] = useState("");
  const [isToastPopupOpen, setIsToastPopupOpen] = useState(false);
  const onToggle = () => {
    setAnimation1("openAnimation-toast");
    setIsToastPopupOpen(true);
    setTimeout(() => {
      setAnimation1("closeAnimation-toast");
      setTimeout(() => {
        setIsToastPopupOpen(false);
      }, 450);
    }, 2000);
  };

  const [stepInfo, setStepInfo] = useState();
  async function getRecruitAlwaysApplicant() {
    const res = await CommunityConnection("/getRecruitAlwaysApplicant", {
      club_id: club_id,
      recruit_id: recruit_id,
    });
    if (res.is_success) {
      setStepInfo(res.applicant);
    }
  }

  useEffect(() => {
    getRecruitAlwaysApplicant();
  }, []);

  const [applicantList, setApplicantList] = useState();
  useEffect(() => {
    setApplicantList(
      recruitStep1?.applicant?.map((a, idx) => ({
        ...a,
        step: stepInfo && stepInfo[idx],
      }))
    );
  }, [recruitStep1, stepInfo]);

  return (
    <>
      <RecruitInfo
        club_id={club_id}
        recruit_id={recruit_id}
        recruitInfo={recruitInfo}
        recruitDetailOpen={recruitDetailOpen}
        setRecruitDetailOpen={setRecruitDetailOpen}
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        clubInfo={clubInfo}
        info={info}
      />
      <div
        className={
          isLeftSectionOpen
            ? "div-manage-section-03-02"
            : "div-manage-section-03-02-wide"
        }
      >
        <div
          className={
            isLeftSectionOpen
              ? "div-manage-section-03-02-01"
              : "div-manage-section-03-02-01-wide"
          }
        >
          <div>
            <div className="div-manage-section-03-01-01-01 H3">
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <div
                  className="H3"
                  style={{
                    width: tableType === 2 ? "132px" : "112px",
                    flexShrink: "0",
                  }}
                >
                  {tableType === 0 && "🙌 지원자 현황"}
                  {tableType === 1 && "👋 합격자 명단"}
                  {tableType === 2 && "😢 불합격자 명단"}
                </div>
                {tableType === 0 && isWide && (
                  <div
                    className="div-click div-manage-section-03-01-01-01-reset Tag1"
                    onClick={async () => {
                      if (!isToastPopupOpen) {
                        setRecruitStep1(
                          await Connection("/getRecruitStep1", {
                            session_key: localStorage.getItem("session_key"),
                            club_id: club_id,
                            recruit_id: recruit_id,
                          })
                        );
                        onToggle();
                      }
                    }}
                  >
                    <img src={Icon_Reset} alt="" style={{ width: "18px" }} />
                    새로고침
                  </div>
                )}

                {isToastPopupOpen && (
                  <ToastPopup
                    status={"success"}
                    children={"새로고침이 완료되었습니다."}
                    className={`${animation1} Tag1`}
                  />
                )}
              </div>
              {!isWide && (
                <ToastPopup
                  status={"warning"}
                  children={"화면을 늘려주세요!"}
                  className={`Tag1`}
                />
              )}
            </div>
            {recruitDocs.is_success &&
              recruitStep1.is_success &&
              applicantList && (
                <TableAlways
                  club_id={club_id}
                  recruit_id={recruit_id}
                  recruitDocs={recruitDocs}
                  headers={tableType === 0 ? newHeader1 : newHeader2}
                  applicantList={applicantList}
                  setCountFail={setCountFail}
                  setCountPass={setCountPass}
                  isLeftSectionOpen={isLeftSectionOpen}
                  tableType={tableType}
                  managerList={managerList}
                />
              )}
          </div>
          <div className="div-manage-section-03-02-01-02 Body3">
            <div
              className="div-manage-section-03-02-01-02-count-section"
              style={{ gap: "8px" }}
            >
              <button
                className={
                  tableType === 0
                    ? "main-black-button  H3"
                    : "outlined-black-button  H3"
                }
                onClick={() => setTableType(0)}
              >
                지원자 현황
              </button>
              <button
                className={
                  tableType === 1
                    ? "main-black-button  H3"
                    : "outlined-black-button  H3"
                }
                onClick={() => setTableType(1)}
              >
                합격자 명단
              </button>
              <button
                className={
                  tableType === 2
                    ? "main-black-button  H3"
                    : "outlined-black-button  H3"
                }
                onClick={() => setTableType(2)}
              >
                불합격자 명단
              </button>
            </div>
            {tableType === 0 && (
              <button
                className="main-orange-button button-manage-section-03-02-01-02-complete H3"
                onClick={async () => {
                  if (window.confirm("합불 처리를 완료하시겠습니까?")) {
                    setIsMessageModalOpen(true);
                  }
                }}
              >
                합불 처리
              </button>
            )}
          </div>
        </div>
      </div>
      {isMessageModalOpen && (
        <MessageAlways
          club_id={club_id}
          recruit_id={recruit_id}
          setModalOpen={setIsMessageModalOpen}
          countPass={countPass}
          countFail={countFail}
          countAll={countPass + countFail}
          setRecruitState={setRecruitState}
        />
      )}
      {loginModal && <Loginform setModalOpen={setLoginModal} />}
    </>
  );
}

export default RecruitPageAlways;
