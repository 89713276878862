import React from "react";

function ChipMessage({ state, font }) {
  switch (state) {
    case 0:
      return (
        <div className={`button-chip-message chip-message-0 ${font || "Tag1"}`}>등록 전</div>
      );
    case 1:
      return (
        <div className={`button-chip-message chip-message-1 ${font || "Tag1"}`}>심사중</div>
      );
    case 2:
      return (
        <div className={`button-chip-message chip-message-2 ${font || "Tag1"}`}>등록 완료</div>
      );
    case 3:
      return (
        <div className={`button-chip-message chip-message-3 ${font || "Tag1"}`}>등록 거절</div>
      );
    default:
  }
}

export default ChipMessage;
