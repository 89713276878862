import React from "react";
import "../../asset/css/community/BottomSheetPopup.css";
import { useRef } from "react";

function BottomSheetPopup({
  isList,
  list,
  title,
  currentValue,
  setValue,
  setIsOpen,
}) {
  const outSection = useRef();

  return (
    <div
      className="div-bottom-sheet-popup-background"
      ref={outSection}
      onClick={(e) => {
        if (outSection.current === e.target) setIsOpen(false);
      }}
    >
      {isList ? (
        <div
          className="div-bottom-sheet-popup-list-section"
          style={title ? { height: "248px" } : { height: "198px" }}
        >
          {title && (
            <div className="div-bottom-sheet-popup-list-title Sub3M">
              {title}
            </div>
          )}
          {list.map((li, idx) => (
            <div
              className="div-bottom-sheet-popup-list Body4M"
              onClick={() => {
                setValue(idx);
                setIsOpen(false);
              }}
              style={{ backgroundColor: idx === currentValue && "#F8F8F8" }}
            >
              {li}
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default BottomSheetPopup;
