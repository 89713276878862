import React, { useState, useRef, useEffect } from "react";
import logo from "../asset/header/logo.svg";
import Connection from "./Connection";
import Recruit from "./Recruit";
import Icon_Notification from "../asset/icon/Icon_Notification.svg";
import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import sample from "../asset/manage/sample.png";
import Icon_Profile from "../asset/icon/Icon_Profile.svg";
import Icon_Logout from "../asset/icon/Icon_Logout.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import Icon_Question from "../asset/icon/Icon_Question.svg"

function Header() {
  const [recruit, setRecruit] = useState(false);
  const [modal, setModal] = useState(-1);
  const outSection = useRef();
  const recruitBtn = useRef();

  const [userInfo, setUserInfo] = useState({
    name: window.localStorage.getItem("name"),
    kakaoID: "카카오톡 ID",
    phone: window.localStorage.getItem("phone"),
    profileImg: sample,
  });

  const [clubByUser, setClubByUser] = useState({
    is_success: false,
  });

  // modal 1
  const Mypage = (
    <div className="div-mypage-section">
      <div className="div-mypage-section-top">
        <div className="div-mypage-section-top-left">
          <div className="Sub2">{userInfo.name}</div>
          <div className="Body5">{localStorage.getItem("phone")}</div>
        </div>
      </div>
      <div className="div-mypage-section-middle Sub3">
        <div className="div-click div-mypage-section-middle-menu">
          <img
            className="img-mypage-section-middle"
            src={Icon_Profile}
            alt=""
          />
          <div onClick={() => setModal(1)}>내 계정</div>
        </div>
        <div
          className="div-click div-mypage-section-middle-menu"
          onClick={() => {
            localStorage.clear();
            window.location.href = "/";
          }}
        >
          <img className="img-mypage-section-middle" src={Icon_Logout} alt="" />
          로그아웃
        </div>
      </div>
      <div className="div-mypage-section-bottom">
        <div className="div-click Tag1" onClick={() => window.open("/privacy")}>
          개인정보 처리 방침
        </div>
        <div className="div-click Tag1" onClick={() => window.open("/terms")}>
          회원 이용약관
        </div>
      </div>
    </div>
  );

  // modal 2
  const MyAccount = (
    <div className="div-myaccount-section">
      <div className="div-myaccount-section-top Sub1">
        <img
          className="icon"
          src={Icon_Close}
          alt=""
          style={{ visibility: "hidden" }}
        />
        내 계정
        <img
          className="icon"
          src={Icon_Close}
          alt=""
          onClick={() => setModal(-1)}
        />
      </div>
      <div className="div-myaccount-section-middle Sub3">
        <div>이름</div>
        <div className="div-myaccount-section-middle-menu">
          <input
            className="input-myaccount-section-middle-menu Body4"
            value={userInfo.name}
            name="name"
            readOnly
          />
        </div>
        <div>전화번호</div>
        <div className="div-myaccount-section-middle-menu">
          <input
            className="input-myaccount-section-middle-menu Body4"
            value={userInfo.phone}
            name="phone"
            maxLength="11"
            readOnly
          />
        </div>
      </div>
      <div className="div-myaccount-section-bottom">
        <button
          className="div-myaccount-section-bottom-text Tag1"
          onClick={() => {
            window.open("http://pf.kakao.com/_xnQWxfxj");
          }}
        >
          정보 변경 문의하기
        </button>
        <button
          className="div-myaccount-section-bottom-text Tag1"
          onClick={() => {
            window.open("http://pf.kakao.com/_xnQWxfxj");
          }}
        >
          계정 삭제
        </button>
      </div>
    </div>
  );

  const [checkedNotification, setCheckedNotification] = useState(0);
  // modal 3
  const Notification = (
    <div className="div-notification-section Sub2">
      <div className="div-notification-section-top">
        <div
          className={
            checkedNotification === 0 ? "div-click checked" : "div-click"
          }
          onClick={() => setCheckedNotification(0)}
        >
          전체 알림
        </div>
        <div
          className={
            checkedNotification === 1 ? "div-click checked" : "div-click"
          }
          onClick={() => setCheckedNotification(1)}
        >
          모집 알림
        </div>
        <div
          className={
            checkedNotification === 2 ? "div-click checked" : "div-click"
          }
          onClick={() => setCheckedNotification(2)}
        >
          접속 알림
        </div>
      </div>
      <div className="div-notification-section-bottom ">
        도착한 알림이 없습니다.
      </div>
    </div>
  );

  const getClub = async () => {
    const cbu = await Connection("/getClubByUser", {
      session_key: window.localStorage.session_key,
    });
    setClubByUser(cbu);
  };

  useEffect(() => {
    if (window.localStorage.session_key) {
      getClub();
    }
  }, []);

  return (
    <div className="div-header-section">
      {modal !== -1 && (
        <div
          className="div-header-outsection"
          ref={outSection}
          onClick={(e) => {
            if (outSection.current === e.target) setModal(-1);
          }}
        />
      )}
      <div className="div-header-section-01">
        <img
          className="div-click img-header-logo"
          src={logo}
          alt=""
          onClick={() => {
            if (localStorage.getItem("session_key") === "admin_demo") {
              localStorage.clear();
            }
            window.location.href = "/";
          }}
        />
      </div>
      <div className="div-header-section-02 Body2">
        <div className="div-header-section-02-01 Body2">
          안녕하세요,
          <div style={{ display: "flex", gap: 0 }}>
            <div style={{ fontWeight: 600 }}>{userInfo.name}</div>님
          </div>
          {modal === 0 ? (
            <>
              <img
                className="img-header-arrowDown"
                src={Icon_ArrowUp}
                alt=""
                onClick={() => setModal(-1)}
              />
              <div className="div-header-mypage">{Mypage}</div>
            </>
          ) : (
            <img
              className="img-header-arrowDown"
              src={Icon_ArrowDown}
              alt=""
              onClick={() => setModal(0)}
            />
          )}
        </div>

        {modal === 3 ? (
          <>
            <img
              className="img-header-notification"
              src={Icon_Notification}
              alt=""
              onClick={() => setModal(-1)}
            />
            <div className="div-header-notification">{Notification}</div>
          </>
        ) : (
          <img
            className="img-header-notification"
            src={Icon_Notification}
            alt=""
            onClick={() => setModal(3)}
          />
        )}
        <img
          className="img-header-notification"
          src={Icon_Question}
          alt=""
          onClick={() => {
            if (window.confirm("가이드를 다시 보여드릴까요?")) {
              window.localStorage.setItem("guide", "{}");
              window.location.reload();
            }
          }}
        />
      </div>
      <button
        className="button-header-01 Sub1"
        onClick={() => setRecruit((prev) => !prev)}
        ref={recruitBtn}
      >
        모집 공고 만들기
      </button>
      {recruit && (
        <Recruit setModal={setRecruit} btnRef={recruitBtn} cbu={clubByUser} />
      )}
      {modal === 1 && (
        <div
          className="div-modal-background"
          ref={outSection}
          onClick={(e) => {
            if (outSection.current === e.target) setModal(-1);
          }}
        >
          {MyAccount}
        </div>
      )}
    </div>
  );
}

export default Header;
