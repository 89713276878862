import React from "react";
import "../../asset/css/community/BottomSheetPopup.css";
import { useRef } from "react";
import Icon_Plus_Club from "../../asset/icon/Icon_Plus_Club.png";

function MyClubBottomSheetPopup({ list, currentValue, setValue, setIsOpen }) {
  const outSection = useRef();

  return (
    <div
      className="div-bottom-sheet-popup-background"
      ref={outSection}
      onClick={(e) => {
        if (outSection.current === e.target) setIsOpen(false);
      }}
    >
      <div
        className="div-bottom-sheet-popup-list-section"
        style={{ height: "50vh", padding: "8px 0" }}
      >
        {list.map((li, idx) => (
          <div
            className="div-bottom-sheet-popup-list-myclub"
            onClick={() => {
              setValue(li.id);
              setIsOpen(false);
            }}
            style={{ backgroundColor: li.id === currentValue && "#F8F8F8" }}
          >
            <img src={li.image} alt="" />
            <div className="div-bottom-sheet-popup-list-myclub-name Sub3M">
              {li.name}
            </div>
          </div>
        ))}
        {/* <div className="div-bottom-sheet-popup-list-myclub" onClick={() => {}}>
          <img src={Icon_Plus_Club} alt="" />
          <div className="div-bottom-sheet-popup-list-myclub-name Sub3M">
            동아리 개설하기
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default MyClubBottomSheetPopup;
