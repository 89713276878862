import React from "react";

function ChipOption({ option, name, qType }) {
  var bgColor;
  switch (option % 10) {
    case 0:
      bgColor = "#FFEAEA";
      break;
    case 1:
      bgColor = " #FFF4EA";
      break;
    case 2:
      bgColor = "#FFFEEA";
      break;
    case 3:
      bgColor = "#EEFFEA";
      break;
    case 4:
      bgColor = "#EAFFFB";
      break;
    case 5:
      bgColor = "#EAF7FF";
      break;
    case 6:
      bgColor = "#EAECFF";
      break;
    case 7:
      bgColor = "#F3EAFF";
      break;
    case 8:
      bgColor = "#FFEAFD";
      break;
    case 9:
      bgColor = "#F4F4F4";
      break;
    default:
  }

  if (qType === 12)
    return (
      <div
        className="div-chip-option-12 Body5"
        style={{ backgroundColor: bgColor }}
      >
        {name}
      </div>
    );
  else if (qType === 13)
    return (
      <div
        className="div-chip-option-13 Body5"
        style={{ backgroundColor: bgColor }}
      >
        {name}
      </div>
    );
  else
    return (
      <div
        className="div-chip-option Body5"
        style={{ backgroundColor: bgColor }}
      >
        {name}
      </div>
    );
}

export default ChipOption;
