import React from "react";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import Icon_Download from "../asset/icon/Icon_Download.svg";
import Default_Image from "../asset/ViewForm/Default_Image.svg";
import "../asset/css/ViewFormM.css";

// api 통해 가져온 질문 객체, 답변 객체, and 지원자 번호
export default function ViewFormM({
  questions,
  answers,
  applicantNum,
  setApplicantNum,
  setModalOpen,
  applicantListLength,
}) {
  return (
    <div className="div-viewform-background">
      <div id="viewform-modalM">
        <div id="viewform-headerM">
          <p className="H3M">🗂️ 지원서</p>
          <img
            id="viewform-close"
            src={Icon_Close}
            alt=""
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </div>
        <div id="viewform-scroll">
          <div style={{ width: "100%" }}>
            <hr
              style={{ border: 0, height: "2px" }}
              width="100%"
              color="#FF7A00"
            />
            <p className="Sub1M" style={{ marginTop: "12px" }}>
              필수 정보
            </p>
          </div>
          <div className="viewform-basicInfo-rowM">
            <div className="viewform-statM">
              <p className="Sub3M">이름</p>
              <div className="viewform-basicInfo-answerM">
                {answers?.profile?.name}
              </div>
            </div>
            <div className="viewform-statM">
              <p className="Sub3M">연락처</p>
              <div className="viewform-basicInfo-answerM">
                {answers?.profile?.phone}
              </div>
            </div>
            <div className="viewform-statM">
              <p className="Sub3M">생년월일</p>
              <div className="viewform-basicInfo-answerM">
                {answers?.profile?.birthday}
              </div>
            </div>
            <div className="viewform-statM">
              <p className="Sub3M">성별</p>
              <span className="Sub3M">
                {answers?.profile?.gender === "male" ? (
                  <div
                    className="div-chip-optionM Body5M"
                    style={{ backgroundColor: "#EAF7FF" }}
                  >
                    남자
                  </div>
                ) : (
                  <div
                    className="div-chip-optionM Body5M"
                    style={{ backgroundColor: "#FFEAEA" }}
                  >
                    여자
                  </div>
                )}
              </span>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <hr
              style={{ border: 0, height: "2px" }}
              width="100%"
              color="#6A6A6A"
            />
            <p className="Sub1M" style={{ marginTop: "12px" }}>
              추가 정보
            </p>
          </div>
          <div className="viewform-moreInfo-columnM">
            {questions
              .filter((q) => q.id != null)
              .map((q, idx) => {
                switch (q.type) {
                  case 14:
                    return (
                      <div className="viewform-moreInfo-question">
                        <p className="Sub3M">
                          ({q.k} )
                          <pre className="viewformm-moreInfo-question-title Sub3M">
                            {q.q}
                          </pre>
                        </p>
                        <a
                          href={answers.answer[idx].value}
                          download
                          style={{ textDecorationLine: "none" }}
                        >
                          <button className="viewform-moreInfo-answer-file">
                            <img src={Icon_Download} alt="" />
                            <span className="Sub3M">파일 다운로드</span>
                          </button>
                        </a>
                      </div>
                    );
                  case 19:
                    return (
                      <div className="viewform-moreInfo-question">
                        <p className="Sub3M">
                          ({q.k} )
                          <pre className="viewformm-moreInfo-question-title Sub3M">
                            {q.q}
                          </pre>
                        </p>
                        <img
                          src={answers.answer[idx].value}
                          alt={Default_Image}
                          className="viewform-moreInfo-answer-image"
                          onClick={() =>
                            window.open(
                              `${answers.answer[idx].value}`,
                              "_blank"
                            )
                          }
                        />
                      </div>
                    );
                  case 22:
                    return (
                      <div className="viewform-moreInfo-question">
                        <p className="Sub3M">{`(${q.k} )`}</p>
                        <pre className="viewform-moreInfo-answer-defaultM">
                          {answers.answer[idx].value}
                        </pre>
                      </div>
                    );
                  default:
                    return (
                      <div className="viewform-moreInfo-question">
                        <p className="Sub3M">
                          ({q.k} )
                          <pre className="viewformm-moreInfo-question-title Sub3M">
                            {q.q}
                          </pre>
                        </p>
                        <pre className="viewform-moreInfo-answer-defaultM">
                          {Array.isArray(answers.answer[idx].value)
                            ? answers.answer[idx].value.map((line) => (
                                <>
                                  {line}
                                  <br />
                                </>
                              ))
                            : answers.answer[idx].value}
                        </pre>
                      </div>
                    );
                }
              })}
          </div>
          <div className="div-viewformM-arrow-section">
            {applicantNum !== 0 && (
              <button
                className="viewform-arrow-leftM Sub3M"
                onClick={() => {
                  setApplicantNum(applicantNum - 1);
                  document.getElementById("viewform-scroll").scrollTo(0, 0);
                }}
              >
                이전 지원자 보기
              </button>
            )}
            {applicantNum !== applicantListLength - 1 && (
              <button
                className="viewform-arrow-rightM Sub3M"
                onClick={() => {
                  setApplicantNum(applicantNum + 1);
                  document.getElementById("viewform-scroll").scrollTo(0, 0);
                }}
              >
                다음 지원자 보기
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
