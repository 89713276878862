import React, { useEffect, useState } from "react";
import Icon_Search_9F9F9F from "../../asset/icon/Icon_Search_9F9F9F.svg";
import Icon_Search_FF7A00 from "../../asset/icon/Icon_Search_FF7A00.svg";
import Icon_Close_4C4C4C from "../../asset/icon/Icon_Close_4C4C4C.svg";
import Icon_Close from "../../asset/icon/Icon_Close.svg";
import Icon_View from "../../asset/icon/Icon_View_6A6A6A.svg";
import Icon_Comment from "../../asset/icon/Icon_Comment_6A6A6A.svg";
import Icon_Thumbsup_Gray from "../../asset/icon/Icon_Thumbsup_Gray.svg";
import "../../asset/css/community/community.css";
import "../../asset/css/community/SearchPage.css";
import { detailDate } from "./detailDate";

function SearchPage({ article, setIsOpen, openArticle }) {
  const [search, setSearch] = useState("");
  const [isSearched, setIsSearched] = useState(false);

  const [keywords, setKeywords] = useState(
    JSON.parse(localStorage.getItem("keywords") || "[]")
  );

  useEffect(() => {
    localStorage.setItem("keywords", JSON.stringify(keywords));
  }, [keywords]);

  const addKeyword = (text) => {
    const newKeyword = {
      id: Date.now(),
      text: text,
    };
    if (keywords.length === 10) {
      const deletedKeyword = keywords.filter((keyword, idx) => {
        return idx !== 9;
      });
      setKeywords([newKeyword, ...deletedKeyword]);
    } else {
      setKeywords([newKeyword, ...keywords]);
    }
  };
  const deleteKeyword = (id) => {
    const deletedKeyword = keywords.filter((thisKeyword) => {
      return thisKeyword.id != id;
    });
    setKeywords(deletedKeyword);
  };
  const deleteAllKeyword = () => {
    setKeywords([]);
  };

  const showRecent = (
    <>
      <div className="div-community-line" style={{ marginTop: "56px" }} />
      <div className="div-searchpage-recent-header">
        <div className="Sub4M">최근 검색어</div>
        <div
          className="Sub4M"
          style={{ color: "#9F9F9F" }}
          onClick={() => deleteAllKeyword()}
        >
          전체 삭제
        </div>
      </div>
      {keywords.map((keyword) => (
        <div className="div-searchpage-recent Body4M">
          <t
            onClick={() => {
              setSearch(keyword.text);
              setIsSearched(true);
            }}
          >
            {keyword.text}
          </t>
          <img
            src={Icon_Close_4C4C4C}
            alt=""
            onClick={() => deleteKeyword(keyword.id)}
          />
        </div>
      ))}
    </>
  );

  const highlightedText = (text, query) => {
    if (query !== "" && text.includes(query)) {
      const parts = text.split(new RegExp(`(${query})`, "gi"));

      return (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
              <mark key={index}>{part}</mark>
            ) : (
              part
            )
          )}
        </>
      );
    }

    return text;
  };

  const showArticle = (
    <>
      {article?.map(
        (article) =>
          article.title.includes(search) && (
            <div
              className="div-club-main-board-article-section"
              style={{ width: "100vw" }}
              onClick={() => {
                openArticle(article);
              }}
            >
              <div className="div-club-main-board-article-text">
                <div className="div-club-main-board-article-title">
                  {highlightedText(article.title, search)}
                </div>
                <div className="div-club-main-board-article-detail-section">
                  <div className="div-club-main-board-article-detail">
                    {article.nickname}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    {detailDate(new Date(article.created_at))}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    <img src={Icon_View} alt="" />
                    {article.view_cnt}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    <img src={Icon_Thumbsup_Gray} alt="" />
                    {article.like_cnt}
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "12px",
                      background: "#ECECEC",
                    }}
                  />
                  <div className="div-club-main-board-article-detail">
                    <img src={Icon_Comment} alt="" />
                    {article.reply_cnt}
                  </div>
                </div>
              </div>
              {article.images && (
                <img
                  className="img-club-main-board-article"
                  src={article.images}
                  alt=""
                />
              )}
            </div>
          )
      )}
    </>
  );

  return (
    <div className="div-searchpage-section">
      <div className="div-searchpage-header-section">
        <div className="div-searchpage-search">
          <input
            className="Body4M"
            placeholder="전체 커뮤니티 글 검색"
            value={search}
            ss
            onChange={(e) => {
              if (e.target.value.length < 20) {
                if (isSearched && e.target.value !== search)
                  setIsSearched(false);
                setSearch(e.target.value);
              }
            }}
          />
          {search === "" ? (
            <img src={Icon_Search_9F9F9F} alt="" />
          ) : isSearched ? (
            <img
              src={Icon_Close}
              alt=""
              onClick={() => {
                setSearch("");
                setIsSearched(false);
              }}
            />
          ) : (
            <img
              src={Icon_Search_FF7A00}
              alt=""
              onClick={() => {
                setIsSearched(true);
                addKeyword(search);
              }}
            />
          )}
        </div>
      </div>
      {isSearched ? (
        <div style={{ marginTop: "56px" }}>
          {showArticle}
          <div style={{ height: "100px" }} />
        </div>
      ) : (
        showRecent
      )}

      <img
        className="img-searchpage-close"
        src={Icon_Close}
        alt=""
        onClick={() => setIsOpen(false)}
      />
    </div>
  );
}

export default SearchPage;
