import React from "react";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

function ShowTimeTableM({
  setModalOpen,
  session_key,
  club_id,
  recruit_id,
  timetable,
  applicantList,
  stateList,
}) {
  const compareDate = (d1, d2) => {
    if (
      d1.substr(0, 4) === d2.substr(0, 4) &&
      d1.substr(5, 2) === d2.substr(5, 2) &&
      d1.substr(8, 2) === d2.substr(8, 2)
    )
      return true;
    else return false;
  };

  return (
    <div className="div-modal-background" style={{ zIndex: "2000" }}>
      <div className="div-show-timetablem-section">
        <div id="viewform-headerM">
          <p className="H3M">📆 면접자 타임테이블</p>
          <img
            id="viewform-close"
            src={Icon_Close}
            alt=""
            onClick={() => setModalOpen(false)}
          />
        </div>
        <div className="div-show-timetable-scroll-section">
          {timetable.map((t) => (
            <div className="div-show-timetable-section-01">
              <div className="div-show-timetablem-section-01-date Sub3M">
                {applicantList && applicantList[0].interview_date}
              </div>
              <div className="div-show-timetable-section-01-table">
                {t.split.map((s) =>
                  s.time_m.map(
                    (m) =>
                      m.checked && (
                        <div className="div-show-timetable-section-01-time-name">
                          <div className="div-show-timetablem-section-01-time Body4M">
                            {m.time}
                          </div>
                          <div className="div-show-timetablem-section-01-name-section">
                            {applicantList.map(
                              (a) =>
                                compareDate(a.interview_date, t.date) &&
                                a.interview_time === m.time && (
                                  <div className="div-show-timetablem-section-01-name Body4M">
                                    {a.profile.name}
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      )
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShowTimeTableM;
