import React from "react";
import "../../asset/css/community/ClubMainInfo.css";

function ClubMainInfo({ purpose, category, location, sns_link, info }) {
  return (
    <div className="div-club-main-info-section">
      🎯 동아리 정보
      <div className="div-club-main-info-contents-section">
        <div className="div-club-main-info-contents">
          <div className="div-club-main-info-contents-title">목적</div>
          {purpose}
        </div>
        <div className="div-club-main-info-contents">
          <div className="div-club-main-info-contents-title">관심사</div>
          {category}
        </div>
        <div className="div-club-main-info-contents">
          <div className="div-club-main-info-contents-title">활동 지역</div>
          {location}
        </div>
        <div className="div-club-main-info-contents">
          <div className="div-club-main-info-contents-title">SNS</div>
          <t onClick={() => window.open(sns_link)}> 바로가기</t>
        </div>
      </div>
      <pre className="pre-club-main-info-description">{info}</pre>
    </div>
  );
}

export default ClubMainInfo;
