import React from "react";
import { useState } from "react";
import Connection from "./Connection";
import Question from "./Question";
import { useEffect } from "react";
import { TbArrowsMaximize } from "react-icons/tb";
import Icon_Close from "../asset/icon/Icon_Close.svg";

const pnumHypen = function (str) {
  str = str.replace(/[^0-9]/g, "");
  let tmp = "";
  if (str.length < 4) {
    return str;
  } else if (str.length < 8) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3);
    return tmp;
  } else {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3, 4);
    tmp += "-";
    tmp += str.substr(7);
    return tmp;
  }
};

const birthHyphen = function (str) {
  str = str.replace(/[^0-9]/g, "");
  let tmp = "";
  if (str.length < 5) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substr(0, 4);
    tmp += ".";
    tmp += str.substr(4);
    return tmp;
  } else {
    tmp += str.substr(0, 4);
    tmp += ".";
    tmp += str.substr(4, 2);
    tmp += ".";
    tmp += str.substr(6);
    return tmp;
  }

  return str;
};

function FormQuestionCheck({
  club_id,
  recruit_id,
  setIsFormCheckOpen,
  profile,
  setProfile,
  step,
  setStep,
}) {
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const [questionList, setQuestionList] = useState([]);
  const [questionNum, setQuestionNum] = useState(-1);
  const [progress, setProgress] = useState(0);
  const [recruit, setRecruit] = useState({});

  const [completed, setCompleted] = useState(false);
  async function getRecruit() {
    const res = await Connection("/getRecruit", {
      club_id: club_id,
      recruit_id: recruit_id,
    });
    setRecruit(res);
    setQuestionList(JSON.parse(res.question).filter((q, q_idx) => q_idx > 3));
  }
  useEffect(() => {
    document.body.style.overflow = "auto";
    getRecruit();
  }, []);

  const [isContentOverflown, setIsContentOverflown] = useState(false);

  useEffect(() => {
    const element = document.getElementById("recruit-content");
    element &&
      setIsContentOverflown(element.scrollHeight > element.clientHeight);
  }, [recruit]);

  useEffect(() => {
    setProgress(
      questionList.length > 0 && (questionNum + 1) / questionList.length
    );
  }, [questionNum]);

  const [answer, setAnswer] = useState(new Array(questionList.length));

  useEffect(() => {
    setAnswer(
      Array.from({ length: questionList.length }, (q, i) => ({
        id: questionList[i].id,
        value: "",
        option_idx: -1,
        multi_idx: [],
      }))
    );
  }, [questionList.length]);

  const [fileList, setFileList] = useState([]);

  const showDefaultQuestion = (
    <>
      <div className="div-form-question-section-01">
        <img
          className="img-form-question-section-01"
          src={recruit.image}
          alt=""
        />
      </div>
      <div className="div-form-question-section-02 H3M">
        지원자님 안녕하세요!👋🏻
        <br />
        아래 정보를 입력해주세요!
      </div>
      <div className="div-form-question-section-03">
        <div className="div-form-question-section-03-01">
          <div className="div-form-question-default-q Sub3M">이름</div>
          <input
            className="input-form-question-section Body4M"
            value={profile["name"]}
            onChange={(e) => setProfile({ ...profile, name: e.target.value })}
            placeholder="김동들"
          />
        </div>
        <div className="div-form-question-section-03-01">
          <div className="div-form-question-default-q Sub3M">성별</div>
          <div className="div-form-question-section-03-a">
            <button
              className={
                profile["gender"] === "male"
                  ? "button-form-question-section-03-a-selected Sub3M"
                  : "button-form-question-section-03-a Sub3M"
              }
              onClick={() => setProfile({ ...profile, gender: "male" })}
            >
              남
            </button>
            <button
              className={
                profile["gender"] === "female"
                  ? "button-form-question-section-03-a-selected Sub3M"
                  : "button-form-question-section-03-a Sub3M"
              }
              onClick={() => setProfile({ ...profile, gender: "female" })}
            >
              여
            </button>
          </div>
        </div>
      </div>
      <div className="div-form-question-section-04">
        <div className="div-form-question-default-q Sub3M">생년월일</div>
        <input
          className="input-form-question-section Body4M"
          value={profile["birthday"]}
          placeholder="2000.01.01"
          onChange={(e) =>
            setProfile({ ...profile, birthday: birthHyphen(e.target.value) })
          }
          maxLength="10"
        />
      </div>
      <div className="div-form-question-section-04">
        <div className="div-form-question-default-q Sub3M">전화번호</div>
        <input
          className="input-form-question-section Body4M"
          value={profile["phone"]}
          placeholder="010-0000-0000"
          onChange={(e) =>
            setProfile({ ...profile, phone: pnumHypen(e.target.value) })
          }
          maxLength="13"
        />
      </div>
    </>
  );

  const [isOverflown, setIsOverflown] = useState(false);
  const [showWholeQuestion, setShowWholeQuestion] = useState(false);

  useEffect(() => {
    const element = document.getElementById("question-title");
    element && setIsOverflown(element.scrollHeight > element.clientHeight);
  }, [questionNum]);

  return (
    <div className="div-form-mobile-section">
      {step === 0 && (
        <div className="div-form-mobile-check" style={{ position: "relative" }}>
          <div className="div-form-section">
            <div className="div-form-club-info Sub2M" style={{ marginTop: 0 }}>
              {recruit.image ? (
                <img
                  className="img-form-club-info"
                  src={recruit.image}
                  alt=""
                />
              ) : (
                <div
                  className="img-form-club-info skeleton-form-intro"
                  style={{ borderRadius: "100%" }}
                />
              )}
              {recruit.name ? (
                recruit.name
              ) : (
                <div
                  className="skeleton-form-intro"
                  style={{ height: 22, width: 100 }}
                />
              )}
            </div>
            <div className="div-form-poster">
              {recruit.poster ? (
                <img
                  className="img-form-club-poster"
                  src={recruit.poster}
                  alt=""
                />
              ) : (
                <div className="img-form-club-poster skeleton-form-intro" />
              )}
            </div>
            {recruit.title ? (
              <div className="div-form-recruit-title-section Sub1M">
                {recruit.title}
              </div>
            ) : (
              <div
                className="div-form-recruit-title-section Sub1M skeleton-form-intro"
                style={{ height: 24, width: "100%" }}
              />
            )}
            <div className="div-form-recruit-detail-section">
              <div
                className="div-form-recruit-detail-01 Sub3M"
                style={{ marginTop: 0 }}
              >
                모집 기간
              </div>
              {recruit.start_date && recruit.end_date ? (
                <div className="div-form-recruit-detail-02 Body4M">
                  {recruit.end_date === "2999-12-31"
                    ? "상시"
                    : `${recruit.start_date} ~ ${recruit.end_date}`}
                </div>
              ) : (
                <div
                  className="div-form-recruit-detail-02 Body4M skeleton-form-intro"
                  style={{ height: 20 }}
                />
              )}
              <div className="div-form-recruit-detail-01 Sub3M">모집 절차</div>
              {recruit.step ? (
                <div className="div-form-recruit-detail-02 Body4M">
                  {recruit.step}
                </div>
              ) : (
                <div
                  className="div-form-recruit-detail-02 Body4M skeleton-form-intro"
                  style={{ height: 20 }}
                />
              )}
              <div className="div-form-recruit-detail-01 Sub3M">활동 지역</div>
              {recruit.location ? (
                <div className="div-form-recruit-detail-02 Body4M">
                  {recruit.is_success && recruit.location}
                </div>
              ) : (
                <div
                  className="div-form-recruit-detail-02 Body4M skeleton-form-intro"
                  style={{ height: 20 }}
                />
              )}
              <div className="div-form-recruit-detail-01 Sub3M">회비</div>
              {recruit.cost ? (
                <div className="div-form-recruit-detail-02 Body4M">
                  {recruit.cost}
                </div>
              ) : (
                <div
                  className="div-form-recruit-detail-02 Body4M skeleton-form-intro"
                  style={{ height: 20 }}
                />
              )}
            </div>
            <pre
              className="div-form-recruit-content"
              style={{
                height: isDetailOpen && "auto",
              }}
              id="recruit-content"
            >
              {!isDetailOpen && isContentOverflown && (
                <div className="div-form-recruit-content-hidden" />
              )}
              {recruit.description}
            </pre>
            {!isDetailOpen && isContentOverflown && (
              <button
                className="button-form-recruit-content Sub3M"
                onClick={() => setIsDetailOpen(true)}
              >
                자세히 보기
              </button>
            )}
          </div>
        </div>
      )}

      {step === 1 && (
        <div className="div-form-question-background-check">
          {!completed ? (
            <div
              className="div-form-question-section"
              id="question-section"
              style={{ minHeight: "540px" }}
            >
              {/* 질문 부분 */}
              <div className="div-form-question-contents-section">
                {questionNum === -1 ? (
                  showDefaultQuestion
                ) : (
                  <>
                    <div className="div-form-question-progressbar-section">
                      <div className="div-form-question-progressbar">
                        <div
                          className="div-form-question-progressbar-black"
                          style={{ width: progress * 100 + "%" }}
                        />
                      </div>
                    </div>
                    {questionList.length > 0 &&
                      questionList.map(
                        (q, idx) =>
                          idx === questionNum && (
                            <>
                              <div className="div-question-num Sub1M">
                                Q{idx + 1}.
                                <div className="div-question-keyword Tag1M">
                                  {q.k}
                                </div>
                              </div>
                              {q.type !== 22 && (
                                <pre
                                  className="pre-question-title H3M"
                                  id="question-title"
                                >
                                  {q.q}
                                </pre>
                              )}
                              {q.type !== 22 && isOverflown && (
                                <button
                                  className="button-question-title-show-whole Tag1M"
                                  onClick={() => setShowWholeQuestion(true)}
                                >
                                  <TbArrowsMaximize size="16" /> 전체 질문 보기
                                </button>
                              )}
                              <Question
                                questionNum={questionNum}
                                question={q}
                                answer={answer}
                                setAnswer={setAnswer}
                                fileList={fileList}
                                setFileList={setFileList}
                              />
                            </>
                          )
                      )}
                  </>
                )}
              </div>
              {/* 버튼 부분 */}
              <div
                className="div-form-question-button-section-check"
                style={{ justifyContent: questionNum === 0 && "right" }}
              >
                {questionNum !== -1 && (
                  <button
                    className="button-form-question-pre Sub3M"
                    onClick={() => {
                      setQuestionNum(questionNum - 1);
                      setShowWholeQuestion(false);
                      document
                        .getElementById("question-section")
                        .scrollTo(0, 0);
                    }}
                  >
                    이전
                  </button>
                )}
                {progress === 1 ? (
                  <button
                    className="button-form-question-next-last Sub3M"
                    onClick={async () => {
                      if (!completed) {
                        var notAnswered = [];
                        answer.filter((a, idx) => {
                          a.value === "" && notAnswered.push(idx);
                        });
                        if (notAnswered.length > 0) {
                          window.alert(
                            "아직 답변하지 않은 질문이 있습니다.\n\n" +
                              notAnswered.map((na) => " Q" + (na + 1))
                          );
                        } else {
                          if (
                            window.confirm("지원서 제출을 완료하시겠습니까?")
                          ) {
                            setIsFormCheckOpen(false);
                          }
                        }
                      }
                    }}
                  >
                    지원서 제출
                  </button>
                ) : questionNum === -1 ? (
                  <button
                    className="button-form-question-next Sub3M"
                    onClick={() => {
                      if (answer.length > 0) {
                        document
                          .getElementById("question-section")
                          .scrollTo(0, 0);
                        if (
                          profile.name &&
                          profile.gender &&
                          profile.birthday &&
                          profile.phone
                        ) {
                          setQuestionNum(questionNum + 1);
                        } else {
                          window.alert("아직 작성하지 않은 정보가 있습니다.");
                        }
                      }
                    }}
                    style={{ width: "100%" }}
                  >
                    지원서 작성하러 가기
                  </button>
                ) : (
                  <button
                    className="button-form-question-next Sub3M"
                    onClick={() => {
                      setQuestionNum(questionNum + 1);
                      setShowWholeQuestion(false);
                      document
                        .getElementById("question-section")
                        .scrollTo(0, 0);
                    }}
                  >
                    다음
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div
              className="div-form-question-section H3M"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="H3M" style={{ marginBottom: "90px" }}>
                지원이 완료되었어요!👏🏻
              </div>
              <div className="div-form-question-final-section">
                <div className="Body4M">
                  본 지원폼 서비스는
                  <br />
                  동들에서 제공하였습니다.
                </div>
                <button
                  className="button-form-question-final Sub3M"
                  onClick={() => {
                    window.open("https://dongdle.com");
                  }}
                >
                  👉 동들 알아보러 가기 👈
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {showWholeQuestion && (
        <div className="div-modal-background">
          <div className="div-question-whole-question-section">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="div-question-num Sub1M" style={{ marginTop: 0 }}>
                Q{questionNum + 1}.
                <div className="div-question-keyword Tag1M">
                  {questionList[questionNum].k}
                </div>
              </div>
              <img
                className="div-click"
                src={Icon_Close}
                alt=""
                onClick={() => setShowWholeQuestion(false)}
              />
            </div>
            <pre className="pre-question-whole-question Sub2M">
              {questionList[questionNum].q}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormQuestionCheck;
