import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import hold from "../asset/icon/hold.svg";
import Icon_Plus from "../asset/icon/Icon_Plus.svg";
import Icon_Etc2 from "../asset/icon/Icon_Etc2.svg";
import Icon_Delete from "../asset/icon/Icon_Delete.svg";
import Icon_Copy from "../asset/icon/Icon_Copy.svg";
import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import { CoachMark } from "./guideModal";
import { useSelector } from "react-redux";

function SelectedQuestion({
  selectedQuestion,
  currentQuestion,
  setCurrentQuestion,
  currentQuestionId,
  setCurrentQuestionId,
  questionList,
  setQuestionList,
  setQNum,
}) {
  const [dropdown, setDropdown] = useState(false);
  const [checked, setChecked] = useState(0);
  const [data, setData] = useState([
    { idx: 0, type: 9, name: "객관식" },
    { idx: 1, type: 13, name: "드롭다운" },
    { idx: 2, type: 7, name: "단답형" },
    { idx: 3, type: 8, name: "장문형" },
    { idx: 4, type: 18, name: "시간형" },
    { idx: 5, type: 10, name: "달력형" },
    { idx: 6, type: 19, name: "사진 업로드형" },
    { idx: 7, type: 14, name: "파일 업로드형" },
    { idx: 8, type: 5, name: "지역 선택" },
    { idx: 9, type: 20, name: "주량" },
    { idx: 10, type: 21, name: "MBTI" },
    { idx: 11, type: 22, name: "약관 동의" },
  ]);

  const handleChange = (result) => {
    if (!result.destination) return;
    const items = [...questionList];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    if (result.source.droppableId !== result.destination.droppableId)
      window.alert("삭제");

    setQuestionList(items);
    setQNum(items.findIndex((q) => q.id === currentQuestionId));
  };

  useEffect(() => {
    document.getElementById("selected-list").scrollTo({
      top: questionList.length * 33,
      behavior: "smooth",
    });
  }, [questionList.length]);

  useEffect(() => {
    setChecked(data.filter((d) => d.type === currentQuestion.type)[0].idx);
  }, [currentQuestion]);

  const [questionModal, setQuestionModal] = useState(-1);

  function findMaxId() {
    var max = questionList[0].id;
    questionList.map((q) => {
      if (q.id > max) max = q.id;
    });
    return max;
  }

  const makeform_2 = useSelector((state) => state.guide.makeform_2);
  const makeform_2i = useSelector((state) => state.guide.makeform_2i);
  const makeform_3 = useSelector((state) => state.guide.makeform_3);
  const makeform_3i = useSelector((state) => state.guide.makeform_3i);

  return (
    <>
      <div className="div-selected-question-type-section Sub1">
        답변 스타일
        <CoachMark
          isOpen={makeform_2}
          guideIndex={makeform_2i}
          ownIndex={3}
          eclipseStyle={{ zIndex: "302" }}
        >
          <div
            className="div-selected-question-dropdown Body4"
            style={{ borderColor: dropdown && "black" }}
          >
            <div
              className="div-click div-selected-question-dropdown-box Body4"
              style={{
                borderBottom: dropdown
                  ? "1px solid #cdcdcd"
                  : "1px solid transparent",
              }}
              onClick={() => setDropdown(!dropdown)}
            >
              {data[checked].name}
              {dropdown ? (
                <img className="icon" src={Icon_ArrowUp} alt="" />
              ) : (
                <img className="icon" src={Icon_ArrowDown} alt="" />
              )}
            </div>
            {dropdown && (
              <div
                className="div-selected-question-dropdown-scroll"
                style={{ width: "100%" }}
              >
                {data.map((d, idx) => (
                  <button
                    className="button-selected-question-dropdown Body4"
                    style={{
                      color: checked === idx && "#FF7A00",
                    }}
                    onClick={() => {
                      setQuestionList(
                        questionList.map((q) =>
                          q.id === currentQuestionId
                            ? {
                                ...q,
                                type: d.type,
                              }
                            : q
                        )
                      );
                      setCurrentQuestion({ ...currentQuestion, type: d.type });
                      setChecked(idx);
                      setDropdown(false);
                    }}
                  >
                    {d.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        </CoachMark>
      </div>
      <div className="div-selected-header-section">
        <div className="div-selected-header-section-01 Sub1">
          질문 리스트
          <div className="div-selected-header-section-01-num Sub1">
            {questionList.length}
          </div>
        </div>
        <CoachMark isOpen={makeform_3} guideIndex={makeform_3i} ownIndex={1}>
          <button
            className="outlined-black-button button-selected-header-section Body4"
            onClick={() => {
              setCurrentQuestion({
                id: findMaxId() + 1,
                k: "📍키워드",
                q: "질문을 입력해주세요.",
                type: 9,
                option: [],
                multi: false,
              });
              setCurrentQuestionId(findMaxId() + 1);
              setQuestionList([
                ...questionList,
                {
                  id: findMaxId() + 1,
                  k: "📍키워드",
                  q: "질문을 입력해주세요.",
                  type: 9,
                  option: [],
                  multi: false,
                },
              ]);
              setQuestionModal(-1);
            }}
          >
            질문 추가
            <img src={Icon_Plus} alt="" style={{ width: "15px" }} />
          </button>
        </CoachMark>
      </div>
      <div className="div-selected-section">
        <DragDropContext onDragEnd={handleChange}>
          <div className="div-selected-list-section" id="selected-list">
            <Droppable droppableId="questions">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {questionList.map((q, index) => (
                    <CoachMark
                      isOpen={makeform_3 && index === 0}
                      guideIndex={makeform_3i}
                      ownIndex={2}
                      eclipseStyle={{
                        transform: "translate(10%, 10%)",
                      }}
                    >
                      <Draggable
                        key={String(q.id)}
                        draggableId={String(q.id)}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <CoachMark
                              isOpen={makeform_3 && index === 0}
                              guideIndex={makeform_3i}
                              ownIndex={3}
                              eclipseStyle={{
                                top: "2px",
                                left: "auto",
                                right: "20px",
                                transform: "none",
                              }}
                            >
                              <div
                                className="div-selected-question-section Body4"
                                onClick={() => {
                                  setCurrentQuestion(q);
                                  setCurrentQuestionId(q.id);
                                  setQNum(index);

                                  q.id !== currentQuestionId &&
                                    setQuestionModal(-1);
                                }}
                                style={{
                                  backgroundColor:
                                    currentQuestionId === q.id &&
                                    "rgba(255, 122, 0, 0.3)",
                                }}
                              >
                                <img
                                  src={hold}
                                  alt=""
                                  style={{ width: "15px" }}
                                  {...provided.dragHandleProps}
                                />
                                <div className="div-selected-question-section-text">
                                  {q.k}
                                </div>

                                <img
                                  className="img-selected-etc"
                                  src={Icon_Etc2}
                                  alt=""
                                  onClick={() => {
                                    questionModal === q.id
                                      ? setQuestionModal(-1)
                                      : setQuestionModal(q.id);
                                  }}
                                />

                                {questionModal === q.id && (
                                  <div className="div-selected-question-modal">
                                    <div
                                      className="div-click div-selected-question-modal-row Body4"
                                      onClick={() => {
                                        if (questionList.length === 1) {
                                          window.alert(
                                            "질문은 최소 1개 이상 있어야 합니다."
                                          );
                                        } else {
                                          if (
                                            window.confirm(
                                              "정말 삭제하시겠습니까?"
                                            )
                                          ) {
                                            setQuestionList(
                                              questionList.filter(
                                                (ql) => ql.id !== q.id
                                              )
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      <img
                                        src={Icon_Delete}
                                        style={{ width: "15px" }}
                                        alt=""
                                      />
                                      삭제
                                    </div>
                                    <div
                                      className="div-click div-selected-question-modal-row Body4"
                                      onClick={() => {
                                        setCurrentQuestionId(findMaxId() + 1);
                                        setQuestionList([
                                          ...questionList,
                                          {
                                            ...q,
                                            id: findMaxId() + 1,
                                          },
                                        ]);

                                        setQuestionModal(-1);
                                      }}
                                    >
                                      <img
                                        src={Icon_Copy}
                                        style={{ width: "15px" }}
                                        alt=""
                                      />
                                      복제
                                    </div>
                                  </div>
                                )}
                              </div>
                            </CoachMark>
                          </div>
                        )}
                      </Draggable>
                    </CoachMark>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    </>
  );
}

export default SelectedQuestion;
