import React, { useState, useEffect } from "react";
import Icon_Application from "../asset/icon/Icon_Application_Gray.svg";
import Connection from "./Connection";
import ViewFormM from "./ViewFormM";
import ChipStatusM from "./ChipStatusM";

const TableContentM = ({
  club_id,
  recruit_id,
  recruitDocs,
  applicantList,
  setApplicantList,
  columnList,
  setCountFail,
  setCountPass,
  modalViewForm,
  setModalViewForm,
}) => {
  const session_key = window.localStorage.getItem("session_key");

  const [applicantNum, setApplicantNum] = useState();

  useEffect(() => {
    setCountPass(applicantList.filter((a) => a.stateV === 1).length);
    setCountFail(applicantList.filter((a) => a.stateV === 2).length);
  }, [applicantList]);

  const updateState = (applicant_id, state) => {
    Connection("/updateRecruitStep1", {
      club_id: club_id,
      recruit_id: recruit_id,
      applicant_id: applicant_id,
      state: String(state),
    });
  };

  const showRows = applicantList.map((applicant, idx) => (
    <tr key={idx}>
      {/* 번호 */}
      <td className="td-sticky-01" style={{ height: "40px" }}>
        <span>
          <div className="Tag1M">{applicant.idx + 1}</div>
        </span>
      </td>
      {/* 상태 */}
      <td className="td-sticky-01" style={{ height: "40px" }}>
        <span
          onClick={async () => {
            setApplicantList(
              applicantList.map((a, i) =>
                i === idx ? { ...a, stateV: (a.stateV + 1) % 3 } : a
              )
            );

            updateState(applicant.id, (applicant.stateV + 1) % 3);
          }}
        >
          <ChipStatusM state={applicant.stateV % 3} />
        </span>
      </td>
      {/* 지원자명 */}
      <td
        className="td-sticky-02"
        style={{
          height: "40px",
          justifyContent: "center",
          gap: "15px",
          borderRight: "none",
        }}
      >
        <div
          className="Body4M"
          style={{
            width: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {applicant.profile.name}
        </div>
        <img
          className="div-click img-table-application"
          style={{ height: "24px" }}
          src={Icon_Application}
          alt=""
          onClick={() => {
            setApplicantNum(idx);
            setModalViewForm(true);
          }}
        />
      </td>
    </tr>
  ));

  return (
    <>
      {modalViewForm && (
        <ViewFormM
          questions={recruitDocs?.question}
          answers={applicantList[applicantNum]}
          applicantNum={applicantNum}
          setApplicantNum={setApplicantNum}
          setModalOpen={setModalViewForm}
          applicantListLength={applicantList.length}
        />
      )}
      {showRows}
    </>
  );
};

export default TableContentM;
