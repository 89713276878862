import React, { useRef } from "react";
import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Close_ECECEC from "../../asset/icon/Icon_Close_ECECEC.svg";
import Icon_Plus_Square from "../../asset/icon/Icon_Plus_Square.png";
import "../../asset/css/community/WriteArticle.css";
import { TextareaAutosize } from "@mui/material";
import sample_photo from "../../asset/sample/photo.png";
import { useEffect } from "react";
import { useState } from "react";
import CommunityConnection from "./CommunityConnection";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import uuid from "react-uuid";
import loadImage from "blueimp-load-image";

function WriteArticle({ setIsOpen, boardId, isPrivate, clubId, complete }) {
  const session_key = window.localStorage.getItem("session_key");
  const param = window.location.href.split("/");
  const club_id = clubId || param[param.length - 3];
  const [isAnonymous, setIsAnonymous] = useState(1);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const writeArticle = async () => {
    const photo_id = uuid();
    const formdata = new FormData();
    formdata.append("session_key", session_key);
    formdata.append("board_id", boardId);
    formdata.append("club_id", club_id);
    formdata.append("is_private", isPrivate);
    formdata.append("is_anonymous", isAnonymous);
    formdata.append("title", title);
    formdata.append("content", content);
    formdata.append("photos", photo_id);
    formdata.append("image0", subImageFile[0] ? subImageFile[0] : null);
    formdata.append("image1", subImageFile[1] ? subImageFile[1] : null);
    formdata.append("image2", subImageFile[2] ? subImageFile[2] : null);
    formdata.append("image3", subImageFile[3] ? subImageFile[3] : null);
    formdata.append("image4", subImageFile[4] ? subImageFile[4] : null);
    formdata.append("image5", subImageFile[5] ? subImageFile[5] : null);
    formdata.append("image6", subImageFile[6] ? subImageFile[6] : null);
    formdata.append("image7", subImageFile[7] ? subImageFile[7] : null);
    formdata.append("image8", subImageFile[8] ? subImageFile[8] : null);
    formdata.append("image9", subImageFile[9] ? subImageFile[9] : null);

    const api_host =
      window.location.protocol === "https:"
        ? "https://api2.dongdle.com"
        : "http://localhost:3001";

    await fetch(api_host + "/writeArticle", {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.is_success) {
          complete();
        }
      });
  };

  const [textareaHeight, setTextareaHeight] = useState(0);
  useEffect(() => {
    if (textareaHeight + 330 > window.innerHeight)
      document
        .getElementById("write-article")
        .scrollTo(0, window.innerHeight + 150);
  }, [textareaHeight]);

  // 사진 업로드
  const fileInputRefSub = useRef(null);
  const handleClickFileInput = () => {
    fileInputRefSub.current?.click();
  };
  const [subImageFile, setSubImageFile] = useState([]);

  const uploadProfile = (e) => {
    const fileList = e.target.files;
    if (fileList.length) {
      setSubImageFile((prev) => {
        const fileArr = prev.concat(Object.values(fileList));
        if (fileArr.length > 10) {
          alert("사진은 10장까지 첨부 가능합니다.");
          return fileArr.slice(0, 10);
        } else {
          for (let i = 0; i < fileArr.length; ++i) {
            const file = fileArr[i];
            loadImage(
              file,
              function (img, data) {
                if (data.imageHead && data.exif) {
                  loadImage.writeExifData(
                    data.imageHead,
                    data,
                    "Orientation",
                    1
                  );
                  img.toBlob(function (blob) {
                    loadImage.replaceHead(
                      blob,
                      data.imageHead,
                      async function (newBlob) {
                        newBlob.name = file.name;
                        fileArr[i] = newBlob;
                      }
                    );
                  }, "image/jpeg");
                } else {
                  fileArr[i] = file;
                }
              },
              { meta: true, orientation: true, canvas: true }
            );
          }

          return fileArr;
        }
      });
    }
  };

  return (
    <div className="div-write-article-section" id="write-article">
      <div className="div-write-article-header-section">
        <div style={{ display: "flex", gap: "8px" }}>
          <img src={Icon_ArrowLeft} alt="" onClick={() => setIsOpen(false)} />글
          작성
        </div>
        <button
          className={
            isAnonymous
              ? "button-write-article-anonymous-checked Sub3M"
              : "button-write-article-anonymous Sub3M"
          }
          onClick={() => setIsAnonymous(!isAnonymous)}
        >
          {isAnonymous ? (
            <BiCheckboxChecked size={20} />
          ) : (
            <BiCheckbox size={20} />
          )}
          익명
        </button>
      </div>
      <input
        className="Body4M"
        placeholder="제목을 입력해주세요."
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextareaAutosize
        minRows={5}
        defaultValue=""
        placeholder="내용을 입력해주세요."
        className="Body4M"
        id="textarea"
        onChange={(e) => {
          setContent(e.target.value);
          if (
            textareaHeight !== document.getElementById("textarea").clientHeight
          )
            setTextareaHeight(document.getElementById("textarea").clientHeight);
        }}
        value={content}
      />
      <div className="div-write-article-photo-title">사진 추가</div>
      <div className="div-write-article-photo-section no-scrollbar">
        <div className="div-write-article-photo">
          <input
            id="sub-inputImg"
            type="file"
            multiple={true}
            accept="image/jpg, image/jpeg, image/png"
            ref={fileInputRefSub}
            onChange={uploadProfile}
            style={{ display: "none" }}
          />
          <img
            className="image"
            src={Icon_Plus_Square}
            alt=""
            type="button"
            onClick={handleClickFileInput}
          />
        </div>
        {subImageFile.map((image, image_idx) => (
          <div className="div-write-article-photo">
            <img className="image" src={URL.createObjectURL(image)} alt="" />
            <img
              className="close"
              src={Icon_Close_ECECEC}
              alt=""
              onClick={() => {
                setSubImageFile((prev) =>
                  prev.filter((value, idx) => idx !== image_idx)
                );
              }}
            />
          </div>
        ))}
      </div>
      <button
        className="main-orange-button button-write-article Sub3M"
        onClick={() => {
          if (window.confirm("작성하시겠습니까?")) {
            writeArticle();
          }
        }}
        disabled={title === "" || content === ""}
      >
        게시하기
      </button>
    </div>
  );
}

export default WriteArticle;
