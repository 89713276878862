import React, { useEffect, useRef, useState } from "react";
import "../asset/css/toggle.css";
import "../asset/css/editDrink.css";
import "../asset/css/editMbti.css";
import Icon_ArrowDown from "../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../asset/icon/Icon_ArrowUp.svg";
import Icon_Search from "../asset/icon/Icon_Search.svg";
import CheckBox from "../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../asset/icon/CheckBox_Checked.svg";
import Icon_OneGlass from "../asset/form/Icon_OneGlass.svg";
import Icon_EmptyGlass from "../asset/form/Icon_EmptyGlass.svg";
import Icon_HalfBottle from "../asset/form/Icon_HalfBottle.svg";
import Icon_OneBottle from "../asset/form/Icon_OneBottle.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import CalendarEQ from "./CalendarEQ";
import Connection from "./Connection";
import TimePickerEQ from "./TimePickerEQ";
import { Slider } from "@mui/material";
import { TbArrowsMaximize } from "react-icons/tb";
import { useDebounce } from "../customhooks/useDebounce";

function ShowQuestion({
  currentQuestion,
  questionList,
  currentQuestionId,
  qNum,
}) {
  // 객관식
  const [answer, setAnswer] = useState([{}]);
  useEffect(() => {
    setAnswer(
      (currentQuestion.type === 9 || currentQuestion.type === 13) &&
        questionList.filter((question) => question.id === currentQuestionId)[0]
          .option.length > 0
        ? questionList.filter(
            (question) => question.id === currentQuestionId
          )[0].option
        : [{ id: 0, a: "답변 1" }]
    );
  }, [currentQuestion]);

  const [selectedAnswer, setSelectedAnswer] = useState();
  const [selectedMultiAnswer, setSelectedMultiAnswer] = useState([]);
  const showType9 = (
    <>
      <div
        className="div-edit-type-09-multi-info Sub3"
        style={{ marginTop: "12px" }}
      >
        {questionList.filter((question) => question.id === currentQuestionId)[0]
          .multi && "(중복선택이 가능해요)"}
      </div>
      <div
        className={
          answer.length > 5
            ? answer.length > 10
              ? "div-edit-type-03-section"
              : "div-edit-type-02-section"
            : "div-edit-type-01-section"
        }
        style={{ marginTop: "20px" }}
      >
        {answer.map(({ id, a }, idx) => (
          <div
            className={
              answer.length > 5
                ? answer.length > 10
                  ? "outlined-black-button div-edit-type-03"
                  : "outlined-black-button div-edit-type-02"
                : "outlined-black-button div-edit-type-01"
            }
            onClick={() => {
              questionList.filter(
                (question) => question.id === currentQuestionId
              )[0].multi
                ? selectedMultiAnswer.indexOf(idx) === -1
                  ? setSelectedMultiAnswer([...selectedMultiAnswer, idx])
                  : setSelectedMultiAnswer(
                      selectedMultiAnswer.filter((m) => m !== idx)
                    )
                : setSelectedAnswer(idx);
            }}
            style={{
              backgroundColor: questionList.filter(
                (question) => question.id === currentQuestionId
              )[0].multi
                ? selectedMultiAnswer.indexOf(idx) !== -1 && "black"
                : selectedAnswer === idx && "black",
            }}
          >
            <div
              className={
                answer.length > 5
                  ? answer.length > 10
                    ? "input-edit-type-03 Sub3"
                    : "input-edit-type-02 Sub3"
                  : "input-edit-type-01 Sub3"
              }
              style={{
                color: questionList.filter(
                  (question) => question.id === currentQuestionId
                )[0].multi
                  ? selectedMultiAnswer.indexOf(idx) !== -1 && "white"
                  : selectedAnswer === idx && "white",
              }}
              // disabled
            >
              {a}
            </div>
          </div>
        ))}
      </div>
    </>
  );

  // 드롭다운
  const [dropdown13, setDropdown13] = useState(false);
  const [checked13, setChecked13] = useState(0);

  const showType13 = (
    <div
      className="div-selected-question-dropdown Body4"
      style={{ borderColor: dropdown13 && "black", marginTop: "45px" }}
    >
      <div
        className="div-click div-selected-question-dropdown-box Body4"
        style={{
          borderBottom: dropdown13
            ? "1px solid #cdcdcd"
            : "1px solid transparent",
        }}
        onClick={() => setDropdown13(!dropdown13)}
      >
        {answer[checked13].a}
        {dropdown13 ? (
          <img className="icon" src={Icon_ArrowUp} alt="" />
        ) : (
          <img className="icon" src={Icon_ArrowDown} alt="" />
        )}
      </div>
      {dropdown13 && (
        <div style={{ width: "100%" }}>
          {answer.map((a, idx) => (
            <button
              className="button-selected-question-dropdown Body4"
              style={{
                color: checked13 === idx && "#FF7A00",
              }}
              onClick={() => {
                setChecked13(idx);
                setDropdown13(false);
              }}
            >
              {a.a}
            </button>
          ))}
        </div>
      )}
    </div>
  );

  // 단답형
  const showType7 = (
    <input className="input-edit-type-05" placeholder="답변을 입력해주세요." />
  );

  // 장문형
  const showType8 = (
    <textarea
      className="textarea-edit-type-06"
      placeholder="답변을 입력해주세요."
    />
  );

  const [value, setValue] = useState("10:00");
  const onChange = (timeValue) => {
    setValue(timeValue);
  };

  // 시간형
  const showType18 = (
    <div style={{ marginTop: "45px" }}>
      <TimePickerEQ
        questionNum={0}
        answer={answer}
        time={"오전 0시 00분"}
        setAnswer={setAnswer}
      />
    </div>
  );

  // 지역 선택
  const [dropdown5, setDropdown5] = useState(false);
  const [dong, setDong] = useState([]);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const [searchedDong, setSearchedDong] = useState([]);

  async function getDong() {
    const d = await Connection("/getDong", {});
    d.is_success && setDong(d.data);
  }

  useEffect(() => {
    getDong();
  }, []);

  useEffect(() => {
    const newDong = dong?.filter((d) => d.includes(debouncedSearch));
    setSearchedDong(newDong);
    console.log("search!!!");
  }, [debouncedSearch]);

  const showType5 = (
    <div className="div-question-13-section" style={{ marginTop: "45px" }}>
      <div className="div-question-13-dropdown Body4">
        <div
          className="div-question-05-dropdown Body4"
          style={{ height: "33px", alignItems: "center" }}
        >
          <img src={Icon_Search} alt="" style={{ width: "15px" }} />
          <input
            className="div-question-13-dropdown-box Body4"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setDropdown5(true);
              if (e.target.value === "") setDropdown5(false);
            }}
            placeholder="동 이름으로 검색하세요!"
            style={{ padding: "0 12px", height: "33px" }}
          />
        </div>
      </div>
      {search !== "" && (
        <div
          className="div-question-05-sub Tag1"
          style={{
            textAlign: "left",
            marginTop: "9px",
            marginLeft: "12px",
            marginBottom: "6px",
          }}
        >{`'(${search})' 검색 결과`}</div>
      )}
      {dropdown5 && (
        <div
          className="div-question-13-dropdown-scroll"
          style={{ maxHeight: "165px" }}
        >
          {searchedDong.length > 0 &&
            searchedDong.map((d, d_idx) => (
              <button
                className="button-question-05 Body4"
                onClick={() => {
                  setDropdown5(false);
                  setSearch(d);
                }}
                style={{ height: "33px", padding: "9px 12px" }}
                key={d_idx}
              >
                {d}
              </button>
            ))}
        </div>
      )}
    </div>
  );

  // 달력형
  const showType10 = (
    <div className="div-edit-type-10-section">
      <CalendarEQ />
    </div>
  );

  const [imageFile, setImageFile] = useState(null);
  const [showThumb, setShowThumb] = useState(false);
  const fileInputRef = useRef(null);
  const handleClickFileInput = () => {
    fileInputRef.current?.click();
  };
  const uploadProfile = (e) => {
    const fileList = e.target.files;
    if (fileList && fileList[0]) {
      if (fileList[0].size <= 10 * 1024 * 1024) {
        const url = URL.createObjectURL(fileList[0]);
        setImageFile({
          file: fileList[0],
          name: fileList[0].name,
          type: fileList[0].type.slice(0, 5), // image
          size: fileList[0].size,
          thumbnail: url,
        });
      } else {
        alert("10MB 이하의 이미지만 첨부할 수 있습니다.");
      }
    }
  };

  // 사진 업로드형
  const showType19 = (
    <>
      <form className="form-question-19">
        <input
          type="button"
          className="button-question-19 Sub3"
          onClick={handleClickFileInput}
          value="사진 추가하기"
          style={{ marginTop: "54px", padding: "9px 12px", height: "33px" }}
        />
        <input
          type="file"
          accept="image/jpg, image/jpeg, image/png"
          ref={fileInputRef}
          onChange={uploadProfile}
        />
        <input
          type="file"
          accept="image/jpg, image/jpeg, image/png"
          ref={fileInputRef}
          onChange={uploadProfile}
        />
        <p
          className="Tag1"
          onMouseOver={() => {
            setShowThumb(true);
          }}
          onMouseLeave={() => {
            setShowThumb(false);
          }}
        >
          {imageFile?.name}
        </p>
        <img id="thumb" src={imageFile?.thumbnail} alt="" />
      </form>
    </>
  );

  // 파일 업로드형
  const showType14 = (
    <>
      <form className="form-question-19">
        <input
          type="button"
          className="button-question-19 Sub3"
          onClick={handleClickFileInput}
          value="파일 추가하기"
          style={{ marginTop: "54px", padding: "9px 12px", height: "33px" }}
        />
        <input type="file" ref={fileInputRef} onChange={uploadProfile} />
        <input type="file" ref={fileInputRef} onChange={uploadProfile} />
        <p className="Tag1">{imageFile?.name}</p>
      </form>
    </>
  );

  useEffect(() => {
    setImageFile(null);
    setShowThumb(false);
  }, [currentQuestionId]);

  // 주량
  const drinkInfo = [
    { value: "0", type: "잔" },
    { value: "1", type: "잔" },
    { value: "2", type: "잔" },
    { value: "반", type: "병" },
    { value: "1", type: "병" },
    { value: "1", type: "병 반" },
    { value: "2", type: "병" },
    { value: "2", type: "병 반" },
    { value: "3", type: "병" },
    { value: "3", type: "병 반" },
    { value: "4", type: "병 이상" },
  ];
  const [drink, setDrink] = useState(3); // 슬라이더 값
  const [drinkState, setDrinkState] = useState("잔"); // 표시되는 문장
  const handleDrink = (e, v) => {
    setDrink(v);
  };

  const showIcons = (drink) => {
    let iconArray = [];
    const icons =
      drinkInfo[drink].type === "잔" ? (
        drinkInfo[drink].value === "0" ? (
          <img src={Icon_EmptyGlass} alt="" className="img-form-drink-glass" />
        ) : (
          <img src={Icon_OneGlass} alt="" className="img-form-drink-glass" />
        )
      ) : (
        <img
          src={Icon_OneBottle}
          alt=""
          className="img-form-drink-bottle-edit"
        />
      );
    for (let i = 0; i < Number(drinkInfo[drink].value); i++) {
      iconArray.push(i);
    }
    if (Number(drinkInfo[drink].value) === 0) iconArray.push(0);
    return (
      <>
        {iconArray.map(() => icons)}
        {drinkInfo[drink].type === "병 반" ||
        drinkInfo[drink].value === "반" ? (
          <img
            src={Icon_HalfBottle}
            alt=""
            className="img-form-drink-bottle-edit"
          />
        ) : (
          <></>
        )}
      </>
    );
  };
  useEffect(() => {
    setDrinkState(drinkInfo[drink].value + drinkInfo[drink].type);
  }, [drink]);
  const showType20 = (
    <div className="div-form-drink-container-edit">
      <div className="div-form-drink-icons-edit">{showIcons(drink)}</div>
      <p className="Sub3M">{drinkState}</p>
      <div style={{ padding: "0px 7.5px" }}>
        <Slider
          value={drink}
          min={0}
          max={10}
          onChange={handleDrink}
          sx={{ color: "#FF7A00" }}
        />
      </div>
    </div>
  );

  // MBTI
  const [ei, setEi] = useState("");
  const [sn, setSn] = useState("");
  const [tf, setTf] = useState("");
  const [jp, setJp] = useState("");
  const showType21 = (
    <div className="div-question-mbti-section-edit">
      <div className="div-question-mbti-row-edit">
        <div
          className={`div-question-mbti-cell-edit ${
            ei === "E" ? "mbti-cell-selectedE" : "mbti-cell-default"
          }`}
          onClick={() => setEi("E")}
        >
          <span className="Sub3">외향(E)</span>
          <span className="Tag1">단체,개방</span>
        </div>
        <div
          className={`div-question-mbti-cell-edit ${
            ei === "I" ? "mbti-cell-selectedI" : "mbti-cell-default"
          }`}
          onClick={() => setEi("I")}
        >
          <span className="Sub3">내향(I)</span>
          <span className="Tag1">개인,내면</span>
        </div>
      </div>
      <div className="div-question-mbti-row-edit">
        <div
          className={`div-question-mbti-cell-edit ${
            sn === "S" ? "mbti-cell-selectedS" : "mbti-cell-default"
          }`}
          onClick={() => setSn("S")}
        >
          <span className="Sub3">감각(S)</span>
          <span className="Tag1">현실,경험,정확</span>
        </div>
        <div
          className={`div-question-mbti-cell-edit ${
            sn === "N" ? "mbti-cell-selectedN" : "mbti-cell-default"
          }`}
          onClick={() => setSn("N")}
        >
          <span className="Sub3">직관(N)</span>
          <span className="Tag1">미래,가능성,신속</span>
        </div>
      </div>
      <div className="div-question-mbti-row-edit">
        <div
          className={`div-question-mbti-cell-edit ${
            tf === "T" ? "mbti-cell-selectedT" : "mbti-cell-default"
          }`}
          onClick={() => setTf("T")}
        >
          <span className="Sub3">사고(T)</span>
          <span className="Tag1">원칙,논리</span>
        </div>
        <div
          className={`div-question-mbti-cell-edit ${
            tf === "F" ? "mbti-cell-selectedF" : "mbti-cell-default"
          }`}
          onClick={() => setTf("F")}
        >
          <span className="Sub3">감정(F)</span>
          <span className="Tag1">관계,협조</span>
        </div>
      </div>
      <div className="div-question-mbti-row-edit">
        <div
          className={`div-question-mbti-cell-edit ${
            jp === "J" ? "mbti-cell-selectedJ" : "mbti-cell-default"
          }`}
          onClick={() => setJp("J")}
        >
          <span className="Sub3">판단(J)</span>
          <span className="Tag1">계획,목적성</span>
        </div>
        <div
          className={`div-question-mbti-cell-edit ${
            jp === "P" ? "mbti-cell-selectedP" : "mbti-cell-default"
          }`}
          onClick={() => setJp("P")}
        >
          <span className="Sub3">인식(P)</span>
          <span className="Tag1">자율,융통성</span>
        </div>
      </div>
    </div>
  );

  // 약관동의
  const [check, setCheck] = useState(false);
  const showType22 = (
    <div className="div-question-policy-container-edit">
      <textarea
        className="textarea-question-policy-request-show-edit Body4"
        value={
          questionList.filter(
            (question) => question.id === currentQuestionId
          )[0].q
        }
      />
      <div
        className="div-click div-edit-section-checkbox"
        onClick={(e) => setCheck(!check)}
      >
        <img
          src={check ? CheckBox_Checked : CheckBox}
          alt=""
          style={{ width: "18px" }}
        />
        <p className="Body4">위 내용을 확인하였습니다.</p>
      </div>
    </div>
  );

  const [isOverflown, setIsOverflown] = useState(false);
  const [showWholeQuestion, setShowWholeQuestion] = useState(false);

  useEffect(() => {
    setShowWholeQuestion(false);
    const element = document.getElementById("question-title");
    element && setIsOverflown(element.scrollHeight > element.clientHeight);
  }, [currentQuestionId]);

  return (
    <>
      <div className="div-edit-section">
        {showWholeQuestion && (
          <div className="div-modal-background" style={{ borderRadius: "8px" }}>
            <div className="div-question-whole-question-section">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="div-question-num Sub1" style={{ marginTop: 0 }}>
                  Q{qNum + 1}.
                  <div className="div-question-keyword Tag1">
                    {questionList[qNum].k}
                  </div>
                </div>
                <img
                  className="div-click"
                  src={Icon_Close}
                  alt=""
                  onClick={() => setShowWholeQuestion(false)}
                  style={{ width: "18px" }}
                />
              </div>
              <pre className="pre-question-whole-question Sub2">
                {questionList[qNum].q}
              </pre>
            </div>
          </div>
        )}
        <div className="div-edit-section-num Sub1">
          Q{qNum + 1}.
          <div className="div-show-section-keyword Tag1">
            {
              questionList.filter(
                (question) => question.id === currentQuestionId
              )[0].k
            }
          </div>
        </div>
        {currentQuestion.type !== 22 && (
          <pre className="pre-showquestion-title Sub1" id="question-title">
            {
              questionList.filter(
                (question) => question.id === currentQuestionId
              )[0].q
            }
          </pre>
        )}
        {currentQuestion.type !== 22 && isOverflown && (
          <button
            className="button-question-title-show-whole Tag1"
            onClick={() => setShowWholeQuestion(true)}
          >
            <TbArrowsMaximize size="12" /> 전체 질문 보기
          </button>
        )}
        {currentQuestion.type === 5 && showType5}
        {currentQuestion.type === 7 && showType7}
        {currentQuestion.type === 8 && showType8}
        {currentQuestion.type === 9 && showType9}
        {currentQuestion.type === 10 && showType10}
        {currentQuestion.type === 13 && showType13}
        {currentQuestion.type === 14 && showType14}
        {currentQuestion.type === 18 && showType18}
        {currentQuestion.type === 19 && showType19}
        {currentQuestion.type === 20 && showType20}
        {currentQuestion.type === 21 && showType21}
        {currentQuestion.type === 22 && showType22}
      </div>
    </>
  );
}

export default ShowQuestion;
