import React from "react";
import "../../asset/css/community/ClubMainBoard.css";
import "../../asset/css/community/community.css";
import { useState } from "react";
import ToastPopup from "../../components/ToastPopup.js";
import { detailDate } from "./detailDate";
import Icon_View from "../../asset/icon/Icon_View_6A6A6A.svg";
import Icon_Close from "../../asset/icon/Icon_Close.svg";
import Icon_Comment from "../../asset/icon/Icon_Comment_6A6A6A.svg";
import Icon_Edit_White from "../../asset/icon/Icon_Edit_White.svg";
import Icon_Thumbsup_Gray from "../../asset/icon/Icon_Thumbsup_Gray.svg";
import Icon_Thumbsdown_Gray from "../../asset/icon/Icon_Thumbsdown_Gray.svg";
import WriteArticle from "./WriteArticle";
import { useEffect } from "react";
import Article from "./Article";
import CommunityConnection from "./CommunityConnection";
import BoardSetting from "./BoardSetting";

function ClubMainBoard({
  isManager,
  isMember,
  isWriteSectionOpen,
  setIsWriteSectionOpen,
  isArticleOpen,
  setIsArticleOpen,
  boardId,
  setBoardId,
}) {
  const session_key = window.localStorage.getItem("session_key");
  const param_path = window.location.pathname.split("/");
  const clubId = param_path[3];

  const [article, setArticle] = useState();
  const [board, setBoard] = useState();
  async function getBoard() {
    const res1 = await CommunityConnection("/getBoard", {
      id: clubId,
    });
    if (res1.is_success) {
      setBoard(res1.board);

      if (res1.board.length) {
        const res2 = await CommunityConnection("/getBoardArticle", {
          is_all: boardId === "9999" ? true : false,
          id: boardId === "9999" ? clubId : res1.board[0].id,
          club_id: clubId,
        });
        if (res2.is_success) {
          setArticle(res2.article);
        }
      } else {
        setArticle([]);
      }
    }
  }

  useEffect(() => {
    getBoard();
  }, [clubId]);

  useEffect(() => {
    getBoard();
  }, []);

  async function getBoardArticle(is_all) {
    const res = await CommunityConnection("/getBoardArticle", {
      is_all: is_all,
      id: is_all ? clubId : board && boardId,
      club_id: clubId,
    });
    if (res.is_success) {
      setArticle(res.article);
    }
  }
  useEffect(() => {
    isArticleOpen === false &&
      (boardId === "9999" ? getBoardArticle(true) : getBoardArticle(false));
  }, [boardId, isArticleOpen]);

  async function updateViewCnt(id) {
    const res = await CommunityConnection("/updateViewCnt", {
      id: id,
    });
  }

  useEffect(() => {
    if (param_path.length !== 7) {
      const state = { boardId: boardId };
      window.history.pushState(state, "", boardId);
    }
  }, [boardId]);

  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const element = document.getElementById("top-section");
    element && setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isFullyScrolled, setIsFullyScrolled] = useState(false);
  function handleScroll() {
    const element = document.getElementById("top-section");
    if (element.scrollLeft === element.scrollWidth - element.clientWidth)
      setIsFullyScrolled(true);
    else setIsFullyScrolled(false);

    if (element.scrollLeft > 12) setIsScrolled(true);
    else setIsScrolled(false);
  }

  useEffect(() => {
    const element = document.getElementById("top-section");
    function checkScroll() {
      element.addEventListener("scroll", handleScroll);
    }
    checkScroll();
    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [selectedArticle, setSelectedArticle] = useState();
  function openArticle(article) {
    setSelectedArticle(article);
    updateViewCnt(article.id);
    setIsArticleOpen(true);
    const state = { article_id: article.id };
    window.history.pushState(
      state,
      "",
      window.location.href + "/" + article.id
    );
  }

  async function completeWriting() {
    setIsWriteSectionOpen(false);
    getBoardArticle();
    window.scrollTo(0, 0);
  }

  const [isBoardSettingOpen, setIsBoardSettingOpen] = useState(false);

  useEffect(() => {
    !isBoardSettingOpen && getBoard();
  }, [isBoardSettingOpen]);

  return (
    <div>
      {isBoardSettingOpen && (
        <BoardSetting
          setIsOpen={setIsBoardSettingOpen}
          board={board}
          clubId={clubId}
          getBoard={getBoard}
          setBoardId={setBoardId}
          setArticle={setArticle}
        />
      )}
      <div style={{ position: "relative" }}>
        {isScrolled && <div className="carousel-left" />}
        {isOverflown && <div className="carousel-right" />}
        {/* {isOverflown && !isFullyScrolled && <div className="carousel-right" />} */}
        <div
          className="div-club-main-board-top-section no-scrollbar"
          id="top-section"
          style={{ paddingRight: isOverflown && "12px" }}
        >
          <button
            className={
              boardId === "9999"
                ? "outlined-black-button-selected button-club-main-board-top-chip"
                : "outlined-black-button button-club-main-board-top-chip"
            }
            onClick={() => setBoardId("9999")}
          >
            전체
          </button>
          {board?.map(({ name, id }, idx) => (
            <button
              className={
                boardId === id
                  ? "outlined-black-button-selected button-club-main-board-top-chip"
                  : "outlined-black-button button-club-main-board-top-chip"
              }
              onClick={() => setBoardId(id)}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
      <div className="div-club-main-board-section">
        {isManager === 1 && (
          <button
            className="outlined-black-button button-club-main-board-setting Sub3M"
            onClick={() => setIsBoardSettingOpen(true)}
          >
            게시판 설정
          </button>
        )}
        {boardId !== "9999" &&
          board &&
          board[board?.findIndex((board) => board.id === boardId)]
            ?.is_private === 1 && (
            <ToastPopup
              className="toastpopup-club-main-board-warning Body5M"
              status="info"
              icon={true}
            >
              멤버에게만 공개되는 게시판이에요!
            </ToastPopup>
          )}
        {article?.map((article) => (
          <div
            className="div-club-main-board-article-section"
            onClick={() => {
              openArticle(article);
            }}
          >
            <div className="div-club-main-board-article-text">
              {boardId === "9999" && (
                <div className="div-club-main-board-article-category">
                  {board?.find((board) => board.id === article.board_id)?.name}
                </div>
              )}
              <div className="div-club-main-board-article-title">
                {article.title}
              </div>
              <div className="div-club-main-board-article-detail-section">
                <div className="div-club-main-board-article-detail">
                  {article.nickname}
                </div>
                <div
                  style={{
                    width: "1px",
                    height: "12px",
                    background: "#ECECEC",
                  }}
                />
                <div className="div-club-main-board-article-detail">
                  {detailDate(new Date(article.created_at))}
                </div>
                <div
                  style={{
                    width: "1px",
                    height: "12px",
                    background: "#ECECEC",
                  }}
                />
                <div className="div-club-main-board-article-detail">
                  <img src={Icon_View} alt="" />
                  {article.view_cnt}
                </div>
                <div
                  style={{
                    width: "1px",
                    height: "12px",
                    background: "#ECECEC",
                  }}
                />
                <div className="div-club-main-board-article-detail">
                  <img src={Icon_Thumbsup_Gray} alt="" />
                  {article.like_cnt}
                </div>
                <div
                  style={{
                    width: "1px",
                    height: "12px",
                    background: "#ECECEC",
                  }}
                />
                <div className="div-club-main-board-article-detail">
                  <img src={Icon_Comment} alt="" />
                  {article.reply_cnt}
                </div>
              </div>
            </div>
            {article.images && (
              <img
                className="img-club-main-board-article"
                src={article.images}
                alt=""
              />
            )}
          </div>
        ))}
      </div>
      {boardId !== "9999" &&
        board &&
        (!board[board?.findIndex((board) => board.id === boardId)]
          ?.is_private ||
          isMember === 1) && (
          <button
            className="bounce-once main-orange-buttonM button-club-main-board-write Body4M"
            onClick={() => setIsWriteSectionOpen(true)}
          >
            <img src={Icon_Edit_White} alt="" />글 작성
          </button>
        )}
      {isWriteSectionOpen && (
        <WriteArticle
          setIsOpen={setIsWriteSectionOpen}
          boardId={board && boardId}
          isPrivate={
            board &&
            board[board?.findIndex((board) => board.id === boardId)]?.is_private
          }
          complete={completeWriting}
        />
      )}
      {isArticleOpen && (
        <Article
          setIsOpen={setIsArticleOpen}
          articleInfo={selectedArticle}
          boardNameInfo={
            board?.find((board) => board.id === selectedArticle?.board_id)?.name
          }
        />
      )}
    </div>
  );
}

export default ClubMainBoard;
