import React, { useState } from "react";
import styled from "styled-components/macro";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";

export default function EventList({ ...props }) {
  return (
    <>
      <ContentsWrapper>
        <Header>
          <img
            src={Icon_ArrowLeft}
            alt=""
            onClick={() => props.setPage("mypage-main")}
          />
          이벤트
        </Header>
        <Body>
          {true && (
            <DivNoLike>
              {/* <img src={Icon_Event_Gray} alt="" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M25.0082 3.33838C28.69 3.33838 31.6748 6.32315 31.6748 10.005C31.6748 11.2201 31.3497 12.3592 30.7818 13.3402L38.3415 13.3384V16.6717H35.0082V33.3383C35.0082 34.2588 34.262 35.005 33.3415 35.005H6.6748C5.75434 35.005 5.00814 34.2588 5.00814 33.3383V16.6717H1.6748V13.3384L9.23442 13.3402C8.66654 12.3592 8.34147 11.2201 8.34147 10.005C8.34147 6.32315 11.3262 3.33838 15.0081 3.33838C17.0002 3.33838 18.7882 4.21206 20.0097 5.59715C21.2282 4.21206 23.0162 3.33838 25.0082 3.33838ZM18.3415 16.6717H8.34147V31.6717H18.3415V16.6717ZM31.6748 16.6717H21.6748V31.6717H31.6748V16.6717ZM15.0081 6.67171C13.1672 6.67171 11.6748 8.1641 11.6748 10.005C11.6748 11.7623 13.0346 13.202 14.7594 13.3292L15.0081 13.3384H18.3415V10.005C18.3415 8.33565 17.1143 6.95286 15.5128 6.70966L15.2569 6.68086L15.0081 6.67171ZM25.0082 6.67171C23.2508 6.67171 21.8112 8.03151 21.684 9.75628L21.6748 10.005V13.3384H25.0082C26.7653 13.3384 28.205 11.9786 28.3323 10.2538L28.3415 10.005C28.3415 8.1641 26.8492 6.67171 25.0082 6.67171Z"
                  fill="#CDCDCD"
                />
              </svg>
              <p className="Sub4M">등록된 이벤트가 없어요!</p>
            </DivNoLike>
          )}
        </Body>
      </ContentsWrapper>
    </>
  );
}
const ContentsWrapper = styled.div`
  min-width: 280px;
`;
const Header = styled.div.attrs({
  className: "Sub1M",
})`
  padding: 0px 12px;
  height: 48px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #1e1e1e;
  > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DivNoLike = styled.div`
  text-align: center;
  margin-top: 108px;
  > p {
    margin-top: 20px;
    color: #cdcdcd;
  }
`;
