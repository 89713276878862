import React, { useState, useRef, useEffect } from "react";

import "../asset/css/loginform.css";
import ToastPopup from "./ToastPopup";
import Connection from "./Connection";
import logo from "../asset/header/logo.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import Button_KakaoLogin from "../asset/icon/Button_KakaoLogin.svg";
import LoginIntro from "../asset/login/LoginIntro.svg";

Loginform.defaultProps = {
  registerNumber: 0,
};
export default function Loginform({ setModalOpen, registerNumber }) {
  const [pageNumber, setPageNumber] = useState(Number(registerNumber));

  const [send, setSend] = useState(0); // 인증번호 전송 횟수
  const [pnum, setPnum] = useState(""); // 전화번호 입력값
  const [authNum, setAuthNum] = useState(""); // 인증번호 입력값

  const [sendMsgBtn, setSendMsgBtn] = useState("인증번호 받기"); //shpark. 인증번호 재전송 버튼
  const [timer, setTimer] = useState(0);
  const timer_ref = useRef(0);

  const [pnumOkay, setPnumOkay] = useState(false); // 전화번호 입력했을시 true
  const [authNumOkay, setAuthNumOkay] = useState(false); // 인증번호 입력했을시 true
  const [certificate, setCertificate] = useState(false); // 인증 성공 시 true

  const [name, setName] = useState(""); // 이름 입력값
  const [birth, setBirth] = useState(""); // 생일 입력값
  const [gender, setGender] = useState(null); // 성별 입력값
  const [nameOkay, setNameOkay] = useState(false); // 이름 입력 시 true
  const [birthOkay, setBirthOkay] = useState(false); // 생일 입력 시 true
  const maleBtn = useRef(null);
  const femaleBtn = useRef(null);

  const [showTinfo, setShowTinfo] = useState(false);

  const register_name = useRef();
  const register_gender = useRef();
  const register_birthday = useRef();
  const register_tmp_token = useRef();
  const register_phone = useRef();

  if (window.localStorage.kakaoAuth_ok) {
    register_gender.current = window.localStorage.register_gender;
    register_birthday.current = window.localStorage.register_birthday;
    register_tmp_token.current = window.localStorage.register_tmp_token;
    register_phone.current = window.localStorage.register_phone;
    register_name.current = window.localStorage.register_name;
    window.localStorage.removeItem("kakaoAuth_ok");
    window.localStorage.removeItem("register_gender");
    window.localStorage.removeItem("register_birthday");
    window.localStorage.removeItem("register_tmp_token");
    window.localStorage.removeItem("register_phone");
    window.localStorage.removeItem("register_name");
  }

  useEffect(() => {
    let timerChk;
    setSendMsgBtn("인증번호 받기");
    if (send > 0) {
      setTimer(timer_ref.current);
      timerChk = setInterval(function () {
        if (timer_ref.current > 0) {
          timer_ref.current -= 1;
          setTimer(timer_ref.current);
          if (timer_ref.current <= 170) {
            setSendMsgBtn("재발송");
          }
        } else {
          setTimer(0);
          clearInterval(timerChk);
        }
      }, 1000);
    }
    return () => clearInterval(timerChk);
  }, [send]);

  useEffect(() => {
    let timerChk = setInterval(() => {
      setShowTinfo(false);
      clearInterval(timerChk);
    }, 3000);
    return () => clearInterval(timerChk);
  }, [showTinfo]);

  const onChange = (e) => {
    switch (e.target.name) {
      case "pnum":
        e.target.value = pnumHypen(e.target.value);
        setPnum(e.target.value);
        e.target.value.length === 13 ? setPnumOkay(true) : setPnumOkay(false);
        break;
      case "authNum":
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
        setAuthNum(e.target.value);
        e.target.value.length === 6
          ? setAuthNumOkay(true)
          : setAuthNumOkay(false);
        break;
      case "name":
        setName(e.target.value);
        break;
      case "birth":
        e.target.value = birthHyphen(e.target.value);
        setBirth(e.target.value);
        break;
      default:
    }
  };

  const pnumHypen = function (str) {
    str = str.replace(/[^0-9]/g, "");
    let tmp = "";
    if (str.length < 4) {
      return str;
    } else if (str.length < 8) {
      tmp += str.substr(0, 3);
      tmp += "-";
      tmp += str.substr(3);
      return tmp;
    } else {
      tmp += str.substr(0, 3);
      tmp += "-";
      tmp += str.substr(3, 4);
      tmp += "-";
      tmp += str.substr(7);
      return tmp;
    }
  };

  const birthHyphen = function (str) {
    str = str.replace(/[^0-9]/g, "");
    let tmp = "";
    if (str.length < 5) {
      return str;
    } else if (str.length < 7) {
      tmp += str.substr(0, 4);
      tmp += ".";
      tmp += str.substr(4);
      return tmp;
    } else {
      tmp += str.substr(0, 4);
      tmp += ".";
      tmp += str.substr(4, 2);
      tmp += ".";
      tmp += str.substr(6);
      return tmp;
    }

    return str;
  };

  const selectGender = (e) => {
    setGender(e.currentTarget.value);
  };

  useEffect(() => {
    if (register_birthday.current) {
      setBirth(birthHyphen(register_birthday.current));
    }
    if (register_gender.current) {
      setGender(register_gender.current);
    }
    if (register_name.current) {
      setName(register_name.current);
    }
  }, []);

  useEffect(() => {
    if (name) {
      name.length >= 1 ? setNameOkay(true) : setNameOkay(false);
    }
    if (birth) {
      birth.length === 10 ? setBirthOkay(true) : setBirthOkay(false);
    }
  }, [name, birth]);

  useEffect(() => {
    pnumHypen(pnum);
  }, [pnum]);
  useEffect(() => {
    birthHyphen(birth);
  }, [birth]);
  useEffect(() => {
    if (pageNumber === 2) {
      if (gender === "male") {
        maleBtn.current.style.cssText = "background: black; color: white";
        femaleBtn.current.style.cssText = "background: white; color: #1E1E1E;";
      } else if (gender === "female") {
        femaleBtn.current.style.cssText = "background: black; color: white";
        maleBtn.current.style.cssText = "background: white; color: #1E1E1E;";
      }
    }
  }, [gender, pageNumber]);

  const showPage1 = (
    <div id="div-loginform-wrapper">
      <img id="img-loginform-icon" src={logo} alt="" />
      <p id="p-loginform-welcome-small" className="-Body5">
        간편하게 로그인하고
      </p>
      <p id="p-loginform-welcome-big" className="-H3">
        지원자 모집부터
        <br />
        동아리 관리까지 한번에
      </p>
      <img id="img-loginform-loginIntro" src={LoginIntro} alt="" />
      <button
        id="button-loginform-kakaoLogin"
        onClick={() => {
          window.Kakao.Auth.authorize({
            redirectUri:
              window.location.protocol +
              "//" +
              window.location.host +
              "/kakaologin",
          });
        }}
      >
        <img src={Button_KakaoLogin} style={{ width: "300px" }} alt="" />
      </button>
    </div>
  );

  const showPage2 = (
    <div id="div-loginform-wrapper">
      <p className="p-loginform-statement H3">
        서비스 이용을 위해
        <br />
        휴대폰 번호를 인증해주세요!
      </p>
      <div id="div-loginform-smsAuth-section">
        <p className="-Sub3">휴대폰 번호</p>
        <div className="div-loginform-smsAuth-input-row">
          <input
            className="input-loginform-smsAuth-input-number -Body4"
            placeholder="숫자만 입력"
            value={pnum}
            onChange={onChange}
            name="pnum"
            maxLength="13"
            disabled={timer_ref.current > 170}
          />
          <button
            id="button-loginform-send"
            className="-Sub3 -outlined-black-button"
            onClick={async () => {
              const res = await Connection("/sendCertificateSMS", {
                tmp_token: register_tmp_token.current,
                phone: pnum.replace(/[^0-9]/g, ""),
              });
              if (res.is_success) {
                setSend((prev) => prev + 1);
                timer_ref.current = 180;
                setShowTinfo(true);
              }
            }}
            // disabled={(send || !pnumOkay) && timer_ref.current !== 0}
          >
            <p>{sendMsgBtn}</p>
          </button>
        </div>
        {send >= 1 ? ( //shpark. send가 1이상이면 (0이면 안 보낸 상태 / 1부터면 보낸 횟수 저장)
          <div className="div-loginform-smsAuth-input-row">
            <input
              onChange={onChange}
              className="-Body4 input-loginform-smsAuth-input-number"
              value={authNum}
              name="authNum"
              maxLength="6"
            />
            <p className="p-loginform-smsAuth-input-number-timer -Body4">
              {/* shpark. 따로 분 / 초 시간 저장 없이 출력 형태만 바꿈 (이에 따라 min/sec 변수 삭제) */}
              {`${Math.floor(timer / 60)}:${
                timer % 60 < 10 ? "0" + (timer % 60) : timer % 60
              }`}
            </p>
          </div>
        ) : (
          <div style={{ height: "45px" }} />
        )}
      </div>
      {showTinfo && (
        <ToastPopup
          id="toastInfo-loginform"
          className="-Body5"
          status="info"
          opacity="50%"
        >
          인증번호가 발송되었습니다.
        </ToastPopup>
      )}
      <button
        className="button-loginform-register-next -Sub3 main-black-button"
        onClick={async () => {
          const res = await Connection("/certificateSMS", {
            tmp_token: register_tmp_token.current,
            phone: pnum.replace(/[^0-9]/g, ""),
            certificale_num: authNum,
          });
          if (res.is_success) {
            setPageNumber(2);
          }
        }}
        disabled={!authNumOkay}
      >
        다음
      </button>
    </div>
  );

  const showPage3 = (
    <div id="div-loginform-wrapper">
      <p className="p-loginform-statement -Sub1">
        필수 정보를 입력하여
        <br />
        회원가입을 완료해 주세요!
      </p>
      <div id="div-loginform-basicInfo-section">
        <div className="div-loginform-basicInfo-row">
          <div className="div-loginform-basicInfo-column -Sub3">
            <p>이름</p>
            <input
              maxLength="10"
              className="-Body4"
              placeholder="김동들"
              value={name}
              name="name"
              id="name"
              onChange={onChange}
            />
          </div>
          <div className="div-loginform-basicInfo-column -Sub3">
            <p>성별</p>
            <div id="gender">
              <button
                onClick={selectGender}
                name="gender"
                value="male"
                ref={maleBtn}
              >
                <p className="-Sub3">남</p>
              </button>
              <button
                onClick={selectGender}
                name="gender"
                value="female"
                ref={femaleBtn}
              >
                <p className="-Sub3">여</p>
              </button>
            </div>
          </div>
        </div>
        <div className="div-loginform-basicInfo-row">
          <div className="div-loginform-basicInfo-column -Sub3">
            <p>생년월일</p>
            <input
              maxLength="10"
              className="-Body4"
              placeholder="2001.12.31"
              value={birth}
              name="birth"
              id="birth"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <button
        className="button-loginform-register-end -Sub3 main-black-button"
        onClick={async () => {
          const res = await Connection("/insertUserInfo", {
            tmp_token: register_tmp_token.current,
            name: name,
            birthday: birth.replace(/[^0-9]/g, ""),
            gender: gender,
          });
          if (res.is_success) {
            setModalOpen(false);
            window.localStorage.setItem("session_key", res.api_token);
            alert("회원가입이 완료되었습니다!");
          }
        }}
        disabled={!nameOkay || !birthOkay || !gender}
      >
        회원가입 완료
      </button>
    </div>
  );

  return (
    <div
      className="div-loginform-background"
      onClick={() => setModalOpen(false)}
    >
      <div
        id="div-loginform-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {pageNumber === 0 && showPage1}
        {pageNumber === 1 && showPage2}
        {pageNumber === 2 && showPage3}
        <img
          className="img-loginform-close"
          src={Icon_Close}
          alt=""
          onClick={() => {
            setModalOpen(false);
          }}
        />
      </div>
    </div>
  );
}
