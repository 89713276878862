import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../asset/css/slick-theme.css";
import intro1 from "../asset/formmaking/intro1.svg";
import intro2 from "../asset/formmaking/intro2.svg";
import intro3 from "../asset/formmaking/intro3.svg";
import intro4 from "../asset/formmaking/intro4.svg";

function MakeFormInfo() {
  const settings = {
    dots: true,
    autoplay: true,
  };
  return (
    <Slider {...settings} className="div-formmaking-left-slider-section">
      <div className="div-formmaking-left-slider Sub2">
        <img
          src={intro1}
          alt=""
          style={{ height: "240px", width: "100%", marginBottom: "24px" }}
        />
        필수 질문은 동들에서 지원자에게 <br />
        가져오는 기본 정보이며, 반드시 포함되는 질문입니다.
      </div>
      <div className="div-formmaking-left-slider Sub2">
        <img
          src={intro2}
          alt=""
          style={{ height: "240px", width: "100%", marginBottom: "24px" }}
        />
        기본 질문은 모든 동아리에서 지원자에게
        <br />
        공통으로 물어보는 정보이며, 필요에 따라 선택하실 수 있습니다.
      </div>
      <div className="div-formmaking-left-slider Sub2">
        <img
          src={intro3}
          alt=""
          style={{ height: "240px", width: "100%", marginBottom: "24px" }}
        />
        추천 질문은 해당 분야의 동아리들이 자주 물어보는 질문들과
        <br />
        동들에서 제공하는 질문들로 구성되어 있습니다.
      </div>
      <div className="div-formmaking-left-slider Sub2">
        <img
          src={intro4}
          alt=""
          style={{ height: "240px", width: "100%", marginBottom: "24px" }}
        />
        현재 페이지에 원하는 질문이 없으면,
        <br />
        다음 단계에서 직접 추가하고 수정하실 수 있습니다.
      </div>
    </Slider>
  );
}

export default MakeFormInfo;
