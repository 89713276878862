import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import "../../asset/css/community/community.css";
import "../../asset/css/community/ClubList.css";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Group_Gray from "../../asset/icon/Icon_Group_Gray.svg";
import Icon_view_Gray from "../../asset/icon/Icon_view_Gray.svg";
import Icon_Time_Gray from "../../asset/icon/Icon_Time_Gray.svg";
import Icon_Heart_Gray from "../../asset/icon/Icon_Heart_Gray.svg";
import Icon_Heart_Orange from "../../asset/icon/Icon_Heart_Orange.svg";
import badge from "../../asset/clubList/badge.png";
import sample_img from "../../asset/main/background.png";
import CommunityConnection from "./CommunityConnection";
import dayjs from "dayjs";

export default function LikedClubs({ ...props }) {
  const session_key = window.localStorage.getItem("session_key");
  const today = new Date();

  const [likedClubs, setLikedClubs] = useState();
  async function getLikedClubs() {
    const res = await CommunityConnection("/getLikedClubs", {});
    if (res.is_success) {
      setLikedClubs(res.liked_clubs.split(","));
    }
  }
  const [clubList, setClubList] = useState();
  async function getCommunityClubList() {
    const res = await CommunityConnection("/getCommunityClubList");
    if (res.is_success) setClubList(res.club_list);
  }
  async function updateLikedClubs(newLikedClubs) {
    const res = await CommunityConnection("/updateLikedClubs", {
      liked_clubs: newLikedClubs,
    });
    if (res.is_success) setLikedClubs(res.liked_clubs.split(","));
  }
  const [latestRecruitInfo, setLatestRecruitInfo] = useState();
  async function getLatestRecruitInfo() {
    const res = await CommunityConnection("/getLatestRecruitInfo");
    if (res.is_success) setLatestRecruitInfo(res.latest_recruit);
  }
  useEffect(() => {
    getLikedClubs();
    getCommunityClubList();
    getLatestRecruitInfo();
  }, []);

  const ClubLatestRecruit = (id) => {
    return latestRecruitInfo?.find(function (recruit) {
      return recruit.club_id === id;
    });
  };

  return (
    <>
      <Container>
        <Header>
          <img
            src={Icon_ArrowLeft}
            alt=""
            onClick={() => props.setPage("mypage-main")}
          />
          찜한 동아리
        </Header>
        <Body>
          {likedClubs?.length === 0 ? (
            <DivNoHeart>
              <p className="Sub4M">
                찜한 동아리가 없어요!
                <br />
                👇 어떤 동아리가 있는지 탐색해 볼까요?👇
              </p>
              <button
                className="Sub3M"
                onClick={() => (window.location.href = "/community/club")}
              >
                동아리 탐색하기
              </button>
            </DivNoHeart>
          ) : (
            <div className="div-community">
              <div className="div-club-list-section">
                {clubList?.map(
                  (club, idx) =>
                    likedClubs?.includes(club.id) && (
                      <div
                        className="div-club-info-section"
                        onClick={() =>
                          (window.location.href =
                            "/community/club/" + club.id + "/home/0")
                        }
                      >
                        <div className="div-club-info-top-section">
                          {club.is_certified === 1 && (
                            <img
                              className="img-club-info-badge"
                              src={badge}
                              alt=""
                            />
                          )}
                          {ClubLatestRecruit(club.id)?.state === 1 &&
                            dayjs(ClubLatestRecruit(club.id)?.end_date).format(
                              "YYYYMMDD"
                            ) >= dayjs(today).format("YYYYMMDD") && (
                              <div
                                className="div-club-info-dday Tag1M"
                                style={
                                  parseInt(
                                    dayjs(
                                      ClubLatestRecruit(club.id)?.start_date
                                    ).format("YYYYMMDD")
                                  ) > parseInt(dayjs(today).format("YYYYMMDD"))
                                    ? { backgroundColor: "black" }
                                    : { backgroundColor: "#FF7A00" }
                                }
                              >
                                D-
                                {dayjs(
                                  ClubLatestRecruit(club.id)?.end_date
                                ).format("YYYYMMDD") ===
                                dayjs(today).format("YYYYMMDD")
                                  ? "DAY"
                                  : dayjs(
                                      ClubLatestRecruit(club.id)?.end_date
                                    ).diff(dayjs(today), "day") + 1}
                              </div>
                            )}
                          <img
                            className="img-club-info-heart"
                            src={
                              likedClubs?.includes(club.id)
                                ? Icon_Heart_Orange
                                : Icon_Heart_Gray
                            }
                            alt=""
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "찜한 동아리에서 삭제하시겠습니까?"
                                )
                              ) {
                                setLikedClubs(
                                  likedClubs.filter(
                                    (clubId) => clubId !== club.id
                                  )
                                );
                                updateLikedClubs(
                                  likedClubs.filter(
                                    (clubId) => clubId !== club.id
                                  )
                                );
                              }

                              e.stopPropagation();
                            }}
                          />
                          <img
                            src={club.image}
                            className="img-club-info-main"
                            alt=""
                          />
                        </div>
                        <div className="div-club-info-bottom-section">
                          <div className="div-club-info-name Sub4M">
                            {club.name}
                          </div>
                          <div className="div-club-info-people Body6M">
                            <img src={Icon_Group_Gray} alt="" />
                            {club.member_cnt}
                            <img
                              src={Icon_view_Gray}
                              style={{ marginLeft: "8px" }}
                              alt=""
                            />
                            {club.view_cnt}
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          )}
        </Body>
      </Container>
    </>
  );
}
const Container = styled.div`
  min-width: 280px;
`;
const Header = styled.div.attrs({
  className: "Sub1M",
})`
  padding: 0px 12px;
  height: 48px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #1e1e1e;
  > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
const Body = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DivNoHeart = styled.div.attrs({
  className: "Sub4M",
})`
  text-align: center;
  margin-top: 108px;
  > p {
    color: #6a6a6a;
    margin-bottom: 22px;
  }
  > button {
    color: white;
    padding: 12px 16px;
    width: 232px;
    border-radius: 29px;
    background: #ff7a00;
  }
`;
