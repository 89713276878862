import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import Icon_Profile from "../../asset/icon/Icon_Profile.svg";
import Icon_Logout from "../../asset/icon/Icon_Logout.svg";
import Icon_Heart from "../../asset/icon/Icon_Heart.svg";
import Icon_Edit from "../../asset/icon/Icon_Edit.svg";
import Icon_Thubmsup from "../../asset/icon/Icon_Thumbsup.svg";
import Icon_Event from "../../asset/icon/Icon_Event.svg";
import Icon_Noti from "../../asset/icon/Icon_Noti.svg";
import Icon_Message from "../../asset/icon/Icon_Message.svg";
import logo from "../../asset/header/logo.svg";
import Icon_Notification from "../../asset/icon/Icon_Notification.svg";

import Connection from "../Connection";
import CommunityConnection from "./CommunityConnection";
import MyActivity from "./MyActivity";
import EventList from "./EventList";
import NotiList from "./NotiList";
import MyProfile from "./MyProfile";
import LoginPage from "./LoginPage";
import LikedClubs from "./LikedClubs";

export default function MyPageMain() {
  const session_key = window.localStorage.getItem("session_key");
  const [profile, setProfile] = useState({});
  const [interests, setInterests] = useState("");
  const [nickname, setNickname] = useState("");
  const getUserInfo = async () => {
    Connection("/getUserInfo", {}).then((res) => setProfile(res));
  };
  const getInterests = async () => {
    CommunityConnection("/getInterests", {}).then(
      (res) => res.is_success && setInterests(res.interests || "")
    );
  };
  const getNickname = async () => {
    CommunityConnection("/getNickname", {}).then(
      (res) => res.is_success && setNickname(res.nickname || "")
    );
  };
  useEffect(() => {
    session_key && getUserInfo();
    session_key && getInterests();
    session_key && getNickname();
  }, []);

  const [page, setPage] = useState("mypage-main"); // 마이페이지 상태
  const [loginPage, setLoginPage] = useState(false);

  return (
    <>
      {page === "mypage-main" && (
        <Container>
          <Header>
            <img
              src={logo}
              alt=""
              onClick={() => (window.location.href = "/")}
            />
            <img src={Icon_Notification} alt="" />
          </Header>
          <Body>
            {window.localStorage.getItem("session_key") ? (
              <>
                <MainTopic>내 계정</MainTopic>
                <DivSubMenu onClick={() => setPage("mypage-profile")}>
                  <img src={Icon_Profile} alt="" />내 정보
                </DivSubMenu>
                <DivSubMenu
                  onClick={() => {
                    window.localStorage.clear();
                    window.location.reload();
                  }}
                >
                  <img src={Icon_Logout} alt="" />
                  로그아웃
                </DivSubMenu>
              </>
            ) : (
              <>
                <ParagraphLogin>
                  안녕하세요, <br />
                  동아리의 모든 것, 동들입니다
                </ParagraphLogin>
                <DivLoginBtn onClick={() => setLoginPage(true)}>
                  로그인 및 회원가입
                </DivLoginBtn>
              </>
            )}
            <MainTopic>동아리 활동</MainTopic>
            <DivSubMenu onClick={() => setPage("mypage-heart")}>
              <img src={Icon_Heart} alt="" />
              찜한 동아리
            </DivSubMenu>
            <MainTopic>커뮤니티 활동</MainTopic>
            <DivSubMenu onClick={() => setPage("mypage-written")}>
              <img src={Icon_Edit} alt="" />
              작성한 게시물
            </DivSubMenu>
            <DivSubMenu onClick={() => setPage("mypage-liked")}>
              <img src={Icon_Thubmsup} alt="" />
              공감한 게시물
            </DivSubMenu>
            <MainTopic>서비스 안내</MainTopic>
            <DivSubMenu onClick={() => setPage("mypage-event")}>
              <img src={Icon_Event} alt="" />
              이벤트
            </DivSubMenu>
            <DivSubMenu onClick={() => setPage("mypage-noti")}>
              <img src={Icon_Noti} alt="" />
              공지사항
            </DivSubMenu>
            <DivSubMenu>
              <img src={Icon_Message} alt="" />
              1:1 문의
            </DivSubMenu>
          </Body>
          <Footer></Footer>
        </Container>
      )}
      {loginPage && <LoginPage setPage={setLoginPage} />}
      {page === "mypage-profile" && (
        <MyProfile
          setPage={setPage}
          profile={profile}
          interests={interests}
          getInterests={getInterests}
          nickname={nickname}
          getNickname={getNickname}
        />
      )}
      {page === "mypage-heart" && <LikedClubs setPage={setPage} />}
      {page === "mypage-written" && (
        <MyActivity setPage={setPage} firstTab={0} />
      )}
      {page === "mypage-liked" && <MyActivity setPage={setPage} firstTab={1} />}
      {page === "mypage-event" && <EventList setPage={setPage} />}
      {page === "mypage-noti" && <NotiList setPage={setPage} />}
    </>
  );
}
const Container = styled.div`
  min-width: 280px;
`;
const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  > img:first-child {
    width: 80px;
    height: 20px;
    cursor: pointer;
  }
  > img:last-child {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
const Body = styled.div`
  padding: 0px 12px;
`;
const ParagraphLogin = styled.p.attrs({
  className: "Sub3M",
})`
  margin-bottom: 16px;
`;
const DivLoginBtn = styled.div.attrs({
  className: "Sub3M",
})`
  margin-bottom: 16px;
  padding: 12px 16px;
  color: white;
  background-color: #ff7a00;
  border-radius: 29px;
  text-align: center;
`;
const MainTopic = styled.p.attrs({
  className: "Tag1M",
})`
  padding: 8px 0px;
  color: #9f9f9f;
`;
const DivSubMenu = styled.div.attrs({
  className: "Sub3M",
})`
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
  padding: 8px 0px;
  > img {
    width: 20px;
    height: 20px;
  }
`;
const Footer = styled.div``;
