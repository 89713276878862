import React from "react";
import styled from "styled-components/macro";

import icon_close from "../asset/icon/Icon_Close.svg";

export function GuideDefaultDim({
  style,
  className,
  guideName, // 소개하는 가이드 종류, string
  guideIndex, // 가이드에서 요구하는 단계, var <number>
  ownIndex, // 이 코치 마크의 고유 index, const <number>
  children,
  CoachMark, // 코치 마크 여부, boolean
  isOpen,
}) {
  const guide = { ...JSON.parse(window.localStorage.getItem("guide")) };
  if (guideName in guide && guide[guideName]) {
    return(children);
  }
  if (isOpen && ownIndex == (guideIndex + 1)) {
    return (
      <>
        <div 
          style={ Object.assign({ zIndex: "1231", position: "relative" }, style) }
          className={className}    
        >
        {CoachMark && (
            <GuideEclipse
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1,
                transform: "translate(-50%, -50%)",
              }}
            >
              {ownIndex}
            </GuideEclipse>
          )}
          {children}
        </div>
        <Dim />
      </>
    );
  } else return(children);
}
const Dim = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1230;
`;

export function GuidePunctureDim({
  style,
  className,
  guideName, // 소개하는 가이드 종류, string
  guideIndex, // 가이드에서 요구하는 단계, var <number>
  ownIndex, // 이 코치 마크의 고유 index, const <number>
  children,
  CoachMark, // 코치 마크 여부, boolean
  isOpen,
}) {
  const guide = { ...JSON.parse(window.localStorage.getItem("guide")) };
  if (guideName in guide && guide[guideName]) {
    return;
  }
  if (isOpen && ownIndex == guideIndex + 1) {
    return (
      <Puncture className={className} style={style}>
        {CoachMark && (
          <GuideEclipse
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
              transform: "translate(-50%, -50%)",
            }}
          >
            {ownIndex}
          </GuideEclipse>
        )}
        {children}
      </Puncture>
    );
  } else return;
}
GuideDefaultDim.defaultProps = {
  ownIndex: 1,
  guideIndex: 0,
  coachMark: false,
};
GuidePunctureDim.defaultProps = {
  ownIndex: 1,
  guideIndex: 0,
  coachMark: false,
};

const Puncture = styled.div`
  position: fixed;
  z-index: 1230;
  outline-style: solid;
  outline-width: 150vmax;
  outline-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
`;

export function CoachMark({
  guideIndex, // 현재 가이드 단계, var <number>
  ownIndex, // 이 코치 마크의 고유 index, const <number>
  style,
  isOpen, // 가이드가 열려있는지 확인하는 flag, <boolean>
  children,
  eclipseStyle,
}) {
  if (isOpen && ownIndex == guideIndex + 1) {
    return (
      <div style={{
        position: 'relative',
        ...style
      }}
      >
        {children}
        <GuideEclipse style={eclipseStyle}>
          {ownIndex}
        </GuideEclipse>
      </div>
    );
  } else return(children);
}

const GuideEclipse = styled.div.attrs({
  className: "Sub3"
})`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: 2px solid rgba(255, 122, 0, 0.3);
  background: #ff7a00;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translate(-50%, -50%);
`;

export default function GuideModal({
  contents, // 가이드 내용, 문자열 배열
  size, // 가이드 내용 개수, number
  guideIndex, // 현재 가이드 단계, number
  onChangeIndex, // 가이드 단계 변경 시, f()
  guideName, // 소개하는 가이드 종류, string
  modal, // 가이드 모달창 표시 여부
  setModal, // setModal(false): 가이드 닫기, f()
  setNextModal, // 다음 가이드 모달창 열기, f()
  showIndex, // 인덱스 버튼 표시 여부, boolean
  position, // 디자인 스타일, 'right' or 'left'
  style,
}) {
  function RenderComponents(size) {
    const components = Array.from({ length: size }, (_, index) => (
      <GuideBox_IndexBtn
        key={index}
        className={
          guideIndex === index
            ? "Tag1M guide-current-index"
            : "Tag1M guide-other-index"
        }
        onClick={() => onChangeIndex(index)}
      >
        {index + 1}
      </GuideBox_IndexBtn>
    ));
    return <>{components}</>;
  }

  const guide = { ...JSON?.parse(window.localStorage.getItem("guide")) };
  if (guideName in guide && guide[guideName]) {
    return;
  } else if (!modal) {
    return;
  } else {
    return (
      <>
        <GuideBox
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={style}
        >
          <CloseButton
            src={icon_close}
            alt=""
            onClick={() => {
              var prevGuide = {
                ...JSON.parse(window.localStorage.getItem("guide")),
              };
              prevGuide[guideName] = true;
              window.localStorage.setItem("guide", JSON.stringify(prevGuide));
              setModal(false);
              setNextModal(true);
            }}
            position={position}
          />
          <GuideBoxTop>
            {showIndex ? (
              <GuideBoxBtns>{RenderComponents(size)}</GuideBoxBtns>
            ) : (
              <></>
            )}
            <GuideBoxStatement className="Body5M">
              {contents[guideIndex]}
            </GuideBoxStatement>
          </GuideBoxTop>
          <GuideBoxBottom position={position}>
            <GuideBoxBtns>
              <GuideBox_PrevBtn
                className="Sub3M"
                onClick={() => onChangeIndex(guideIndex - 1)}
                disabled={guideIndex <= 0}
              >
                이전
              </GuideBox_PrevBtn>
              {guideIndex < size - 1 ? (
                <GuideBox_NextBtn
                  className="Sub3M"
                  onClick={() => onChangeIndex(guideIndex + 1)}
                >
                  다음
                </GuideBox_NextBtn>
              ) : (
                <GuideBox_NextBtn
                  className="Sub3M"
                  onClick={() => {
                    var prevGuide = {
                      ...JSON.parse(window.localStorage.getItem("guide")),
                    };
                    prevGuide[guideName] = true;
                    window.localStorage.setItem("guide", JSON.stringify(prevGuide));
                    setModal(false);
                    setNextModal(true);
                  }}
                >
                  닫기
                </GuideBox_NextBtn>
              )}
            </GuideBoxBtns>
            <CloseText
              className="Body5M"
              onClick={() => {
                const skipGuide = {
                  recruit_1: true,
                  recruit_2: true,
                  recruit_3: true,
                  recruit_4: true,
                  message_1: true,
                  template_1: true,
                  makeform_1: true,
                  makeform_2: true,
                  makeform_3: true,
                  timetable_1: true,
                };
                window.localStorage.setItem("guide", JSON.stringify(skipGuide));
                setModal(false);
                setNextModal(true);
              }}
            >
              그만 볼래요
            </CloseText>
          </GuideBoxBottom>
        </GuideBox>
      </>
    );
  }
}

GuideModal.defaultProps = {
  position: "left",
  showIndex: true,
  size: 1,
  guideIndex: 0,
  setNextModal: () => null,
};

const GuideBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 343px;
  /* width: 318.75px; */
  border-radius: 8px;
  background-color: #333333;
  padding: 12px;
  position: relative;
  z-index: 1232;
`;
// top------------------------------
const GuideBoxTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;
const GuideBoxBtns = styled.div`
  display: flex;
  gap: 8px;
`;
const GuideBox_IndexBtn = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GuideBoxStatement = styled.div`
  min-height: 35px;
  color: white;
  word-break: keep-all;
`;
// ------------------------------top

// bottom------------------------------
const GuideBoxBottom = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) =>
    props.position === "left" ? "row" : "row-reverse"};
`;
const GuideBox_NextBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 44px;
  border-radius: 8px;
  background-color: black;
  color: white;
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;
const GuideBox_PrevBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 44px;
  border-radius: 8px;
  background-color: white;
  color: black;
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;
// ------------------------------bottom

const CloseButton = styled.img`
  position: absolute;
  top: 4px;
  ${(props) => (props.position === "left" ? "right: -24px" : "left: -24px")};
  filter: invert(100%);
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1;
`;
const CloseText = styled.p`
  text-decoration-line: underline;
  color: #808080;
  align-self: flex-end;
  margin-right: 7px;
  cursor: pointer;
`;
