import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components/macro";
import Connection from "../Connection";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_Picture from "../../asset/icon/Icon_Picture.svg";
import Icon_Picture_Gray from "../../asset/icon/Icon_Picture_Gray.svg";
import Icon_Close from "../../asset/icon/Icon_Close.svg";
import RadioButton from "../../asset/icon/RadioButton.svg";
import RadioButton_Checked from "../../asset/icon/RadioButton_Checked.svg";
import CheckBox from "../../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../../asset/icon/CheckBox_Checked.svg";
import Icon_Search from "../../asset/icon/Icon_Search.svg";
import Icon_ArrowDown from "../../asset/icon/Icon_ArrowDown.svg";
import Icon_ArrowUp from "../../asset/icon/Icon_ArrowUp.svg";

export default function CreateClub() {
  const [clubName, setClubName] = useState(""); // 동아리 명
  const [contents, setContents] = useState(""); // 한 줄 소개
  const [logo, setLogo] = useState(null); // 동아리 로고
  const [purpose, setPurpose] = useState(null); // 목적
  const [interest, setInterest] = useState(0); // 관심사
  const [region, setRegion] = useState(0); // 활동 지역
  const [fee, setFee] = useState(""); // 회비
  const [additionalFee, setAdditionalFee] = useState(false); // 가입비 별도
  const [activities, setActivities] = useState([]); // 활동 사진

  const inputLogoRef = useRef(null);
  const inputActivityRef = useRef(null);

  // for dropdown -------------------------------------------
  const [interestsList, setInterestList] = useState(null);
  const [regionsList, setRegionsList] = useState(null);
  const [dropdownRegion, setDropdownRegion] = useState(false);
  const [search, setSearch] = useState("");
  const getLists = async () => {
    const info = await Connection("/getInfo", {});
    setInterestList(["관심사 선택", ...Object.values(info.category)]);
    setRegionsList(Object.entries(info.location));
  };
  useEffect(() => {
    if (!(interestsList && regionsList)) {
      getLists();
    }
  }, []);

  const [select, setSelect] = useState(false);
  const dropdown = (data, select, setSelect, value, setValue) => {
    return (
      <div
        className="div-createClub-dropdown-wrapper"
        style={{ height: "44px" }}
      >
        <div
          className="div-createClub-dropdown Body4M"
          style={{
            borderColor: select && "black",
            zIndex: select && 2,
            width: "165px",
          }}
        >
          <div
            className="div-createClub-dropdown-box"
            style={{
              borderBottom: select && "1px solid #cdcdcd",
              cursor: "pointer",
              height: "44px",
              padding: "12px 16px",
            }}
            onClick={() => {
              setSelect((select) => !select);
            }}
          >
            {data[value]}
            {select ? (
              <img
                className="img-createClub-dropdown-arrow"
                src={Icon_ArrowUp}
                alt=""
              />
            ) : (
              <img
                className="img-createClub-dropdown-arrow"
                src={Icon_ArrowDown}
                alt=""
              />
            )}
          </div>
          {select && (
            <div className="div-createClub-dropdown-scroll">
              {data.map((d, idx) => (
                <button
                  className="div-createClub-dropdown-selection Body4M"
                  style={{
                    color: value === idx && "#FF7A00",
                    padding: "12px 16px",
                    height: "44px",
                  }}
                  onClick={() => {
                    setValue(idx);
                    setSelect(false);
                  }}
                >
                  {d}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };
  // ------------------------------------------- for dropdown

  const onChangeBasicInfo = (e) => {
    switch (e.target.id) {
      case "clubName":
        setClubName(e.target.value);
        break;
      case "contents":
        setContents(e.target.value);
        break;
      default:
    }
  };

  const handleOnClick = (e) => {
    switch (e.target.id) {
      case "purpose-hobby":
        setPurpose("hobby");
        break;
      case "purpose-job":
        setPurpose("job");
        break;
      case "fee-true":
        setFee("true");
        break;
      case "fee-false":
        setFee("false");
        break;
      case "additional-fee":
        setAdditionalFee(e.target.checked);
        break;
      default:
        break;
    }
  };

  const uploadFile = (e) => {
    const fileList = e.target.files;
    switch (e.target.id) {
      case "logo-upload":
        if (fileList && fileList[0]) {
          if (fileList[0].size <= 10 * 1024 * 1024) {
            const url = URL.createObjectURL(fileList[0]);
            const imageFile = {
              file: fileList[0],
              name: fileList[0].name,
              type: fileList[0].type.slice(0, 5), // image
              size: fileList[0].size,
              thumbnail: url,
            };
            setLogo(imageFile);
          } else {
            alert("10MB 이하의 이미지만 첨부할 수 있습니다.");
          }
        }
        break;
      case "activity-upload":
        if (fileList.length) {
          setActivities((prev) => {
            let fileArr = prev.concat(Object.values(fileList));
            if (fileArr.length > 4) {
              alert("활동사진은 4장 넘게 업로드 할 수 없습니다.🥲");
              fileArr = fileArr.slice(0, 4);
            }
            fileArr.filter((file) => {
              if (file.size <= 10 * 1024 * 1024) {
                return true;
              } else {
                alert("10MB 이하의 이미지만 첨부할 수 있습니다.");
                return false;
              }
            });
            fileArr = fileArr.map((file) => ({
              file: file,
              name: file.name,
            }));
            return fileArr;
          });
        }
        break;
      default:
        break;
    }
  };

  const deleteFile = (deleteIndex) => {
    if (deleteIndex === -1) {
      setLogo(null);
    } else {
      setActivities((prev) => prev.filter((_, idx) => idx !== deleteIndex));
    }
  };

  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleSubmit = async (e) => {
    setIsSubmitted(true);
    const formdata = new FormData();
    formdata.append("session_key", window.localStorage.session_key);
    formdata.append("name", clubName);
    formdata.append("introduce", contents);
    formdata.append("purpose", purpose === "hobby" ? "취미" : "취업");
    formdata.append(
      "cost",
      `${fee ? "유" : "무"}${additionalFee ? ", 가입비 별도" : ""}`
    );
    formdata.append("category", interest);
    formdata.append("location", region);
    formdata.append("main", logo && logo?.file);
    formdata.append("activity1", activities[0] ? activities[0].file : null);
    formdata.append("activity2", activities[1] ? activities[1].file : null);
    formdata.append("activity3", activities[2] ? activities[2].file : null);
    formdata.append("activity4", activities[3] ? activities[3].file : null);

    const api_host =
      // window.location.host === "dongdle.com"
      true ? "https://api.dongdle.com" : "http://api_test.dongdle.com:405";

    await fetch(api_host + "/api/v1/createClub", {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((json) => {
        // window.location.href = "/" + json.club_id + "/clubManager";
        window.location.reload();
      });
  };

  return (
    <>
      <ContentsWrapper>
        <Header>
          <BackBtn src={Icon_ArrowLeft} onClick={() => window.history.back()} />
          <SpanHeader className="Sub2M">🚩 동아리 개설</SpanHeader>
          <div style={{ width: "24px" }} />
        </Header>
        <Form>
          <Segment>
            <LabelType htmlFor="clubName">동아리 명</LabelType>
            <InputClubBasicInfo
              id="clubName"
              className="Body4M -input-box"
              placeholder="내용을 입력해주세요"
              onChange={onChangeBasicInfo}
              maxLength="30"
            />
          </Segment>
          <Segment>
            <LabelType>한 줄 소개</LabelType>
            <InputClubBasicInfo
              id="contents"
              className="Body4M -input-box"
              placeholder="내용을 입력해주세요"
              onChange={onChangeBasicInfo}
              maxLength="30"
            />
          </Segment>
          <Segment>
            <LabelType>동아리 로고</LabelType>
            <DivUploadImageSection>
              <InputDisplayNone
                id="logo-upload"
                type="file"
                accept=".jpg, .jpeg, .png"
                ref={inputLogoRef}
                onChange={uploadFile}
              />
              <LabelUploadImage htmlFor="logo-upload">
                <ImgPictureIcon src={Icon_Picture} alt="" />
                사진 추가하기
              </LabelUploadImage>
              <DivShowLogo>
                <ImgLogo src={logo?.thumbnail} alt="" />
                {logo?.thumbnail && (
                  <ImgDeleteIcon onClick={() => deleteFile(-1)} />
                )}
              </DivShowLogo>
            </DivUploadImageSection>
          </Segment>
          <Segment>
            <LabelType>목적</LabelType>
            <DivSelections>
              <InputDisplayNone
                // type="radio"
                // name="purpose"
                id="purpose-hobby"
                onClick={handleOnClick}
              />
              <LabelSelection htmlFor="purpose-hobby">
                <ImgSelection
                  src={purpose === "hobby" ? RadioButton_Checked : RadioButton}
                  alt=""
                />
                취미
              </LabelSelection>
              <InputDisplayNone
                // type="radio"
                // name="purpose"
                id="purpose-job"
                onClick={handleOnClick}
              />
              <LabelSelection htmlFor="purpose-job">
                <ImgSelection
                  src={purpose === "job" ? RadioButton_Checked : RadioButton}
                  alt=""
                />
                취업
              </LabelSelection>
              <LabelSelection></LabelSelection>
            </DivSelections>
          </Segment>
          <Segment>
            <LabelType>관심사</LabelType>
            {interestsList &&
              dropdown(interestsList, select, setSelect, interest, setInterest)}
          </Segment>
          <Segment>
            <LabelType>활동 지역</LabelType>
            <div
              style={{ position: "relative", width: "100%", height: "44px" }}
            >
              <div
                className="div-createClub-dropdown"
                style={{ width: "100%" }}
              >
                <div
                  className="div-createClub-dropdown-box"
                  style={{ height: "44px" }}
                >
                  <img
                    src={Icon_Search}
                    alt=""
                    style={{ width: "24px", height: "24px" }}
                  />
                  <input
                    className="div-createClub-dropdown-box Body4M"
                    style={{ height: "44px", padding: "12px 16px" }}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setDropdownRegion(true);
                      if (e.target.value === "") setDropdownRegion(false);
                    }}
                    placeholder="활동 지역을 검색하세요!"
                  />
                </div>
                {dropdownRegion && (
                  <div
                    className="div-createClub-dropdown-scroll"
                    style={{ height: "fit-content", maxHeight: "132px" }}
                  >
                    {regionsList?.length > 0 &&
                      regionsList.map(
                        (d) =>
                          d[1].includes(search) && (
                            <button
                              className="div-createClub-dropdown-selection Body4M"
                              onClick={() => {
                                setDropdownRegion(false);
                                setRegion(Number(d[0]));
                                setSearch(d[1]);
                              }}
                              style={{
                                width: "180px",
                                padding: "12px 16px",
                                height: "44px",
                              }}
                            >
                              {d[1]}
                            </button>
                          )
                      )}
                  </div>
                )}
              </div>
            </div>
          </Segment>
          <Segment>
            <LabelType>회비</LabelType>
            <DivSelections>
              <InputDisplayNone
                // type="radio"
                // name="fee"
                id="fee-true"
                onClick={handleOnClick}
              />
              <LabelSelection htmlFor="fee-true">
                <ImgSelection
                  src={fee === "true" ? RadioButton_Checked : RadioButton}
                  alt=""
                />
                유
              </LabelSelection>
              <InputDisplayNone
                // type="radio"
                // name="fee"
                id="fee-false"
                onClick={handleOnClick}
              />
              <LabelSelection htmlFor="fee-false">
                <ImgSelection
                  src={fee === "false" ? RadioButton_Checked : RadioButton}
                  alt=""
                />
                무
              </LabelSelection>
              <InputDisplayNone
                type="checkbox"
                id="additional-fee"
                onClick={handleOnClick}
              />
              <LabelSelection htmlFor="additional-fee">
                <ImgSelection
                  src={additionalFee ? CheckBox_Checked : CheckBox}
                  alt=""
                />
                가입비 별도
              </LabelSelection>
            </DivSelections>
          </Segment>
          <Segment>
            <LabelType>활동 사진</LabelType>
            <DivUploadImageSection>
              <InputDisplayNone
                id="activity-upload"
                type="file"
                accept=".jpg, .jpeg, .png"
                ref={inputActivityRef}
                multiple={true}
                onChange={uploadFile}
              />
              <LabelUploadImage htmlFor="activity-upload">
                <ImgPictureIcon src={Icon_Picture} alt="" />
                사진 추가하기
              </LabelUploadImage>
              <DivShowImageName>
                {activities.map((activity, idx) => (
                  <DivImageName>
                    <SpanImageName>{activity.name}</SpanImageName>
                    <ImgDeleteIcon
                      key={idx}
                      alt=""
                      color="orange"
                      onClick={() => deleteFile(idx)}
                    />
                  </DivImageName>
                ))}
              </DivShowImageName>
            </DivUploadImageSection>
          </Segment>
        </Form>
        <ButtonSubmit
          onClick={handleSubmit}
          disabled={
            isSubmitted ||
            !clubName ||
            !contents ||
            !purpose ||
            !interest ||
            !region ||
            !logo ||
            !activities?.length
          }
        >
          저장
        </ButtonSubmit>
      </ContentsWrapper>
    </>
  );
}

const ContentsWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Header-----------------------------------------------
const Header = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  background-color: white;
`;
const BackBtn = styled.img`
  width: 24px;
  height: 24px;
`;
const SpanHeader = styled.span`
  text-align: center;
  height: auto;
`;
// -----------------------------------------------Header

// BasicInfo -----------------------------------------------
const Form = styled.form`
  width: 100%;
  height: calc(100vh - 100px);
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  /* width: 296px; */
  overflow-y: scroll;
`;
const Segment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
const LabelType = styled.label.attrs({
  className: "Sub3M",
})``;
const InputClubBasicInfo = styled.input`
  width: 100%;
`;
// ----------------------------------------------- BasicInfo

// imageUpload -----------------------------------------------
const DivUploadImageSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
const LabelUploadImage = styled.label.attrs({
  className: "Sub3M",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  height: auto;
  padding: 12px 16px;
  border: 1px solid black;
`;
const ImgPictureIcon = styled.img`
  width: 20px;
  height: 20px;
`;
const DivShowLogo = styled.div`
  display: flex;
`;
const ImgLogo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  ${(props) => (props.src ? "object-fit: cover" : "opacity: 0")}
`;
const DivShowImageName = styled.div.attrs({
  className: "Body5M",
})`
  width: 100%;
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  color: #ff7a00;
`;
const DivImageName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  width: 50vw;
  min-width: 150px;
`;
const SpanImageName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ImgDeleteIcon = styled.img.attrs({
  src: Icon_Close,
  alt: "",
})`
  width: 20px;
  height: 20px;
  ${(props) =>
    props?.color === "orange" &&
    "filter: invert(50%) sepia(82%) saturate(2596%) hue-rotate(2deg) brightness(103%) contrast(106%);"}
`;
// ----------------------------------------------- imageUpload

// radio -----------------------------------------------
const DivSelections = styled.div`
  display: flex;
  gap: 20px;
`;
const InputDisplayNone = styled.input`
  display: none;
`;
const LabelSelection = styled.label.attrs({
  className: "Body4M",
})`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;
const ImgSelection = styled.img`
  width: 24px;
  height: 24px;
`;
// ----------------------------------------------- radio

// Footer -----------------------------------------------
const ButtonSubmit = styled.button.attrs({
  className: "Sub3M",
})`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  ${(props) => props.disabled && "background-color: #ECECEC"}
`;

// ----------------------------------------------- Footer
