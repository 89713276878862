import React, { useState, useRef, useEffect } from "react";

import "../asset/css/recruit.css";

import Dongari_Default from "../asset/recruit/Dongari_Default.svg";
import close from "../asset/icon/Icon_Close.svg";
import RadioButton from "../asset/icon/RadioButton.svg";
import RadioButton_Checked from "../asset/icon/RadioButton_Checked.svg";
import CheckBox from "../asset/icon/CheckBox.svg";
import CheckBox_Checked from "../asset/icon/CheckBox_Checked.svg";
import Icon_Delete from "../asset/icon/Icon_Delete.svg";
import Icon_Question from "../asset/icon/Icon_Question.svg";

import NewCalendar from "./newCalendar";
import ToastPopup from "./ToastPopup";
import Connection from "./Connection";
import MakeForm from "./MakeForm";
import Loginform from "./Loginform";
import GuideModal, { CoachMark, GuideDefaultDim } from "./guideModal";

import { useDispatch, useSelector } from "react-redux";
import { setRecruit3, setRecruit4, setRecruit4i } from "../modules/guide";

const today = new Date();
const strToday = `${today.getFullYear()}.${String(
  today.getMonth() + 1
).padStart(2, "0")}.${String(today.getDate()).padStart(2, "0")}`;
export default function Recruit({ setModal, btnRef, cbu }) {
  const [loginModal, setLoginModal] = useState(false);

  const session_key = window.localStorage.session_key;
  const [info, setInfo] = useState({ is_success: false });
  const [clubInfo, setClubInfo] = useState({ is_success: false });

  const [modalNum, setModalNum] = useState(0);
  const [firstModalNum, setFirstModalNum] = useState(0);
  const recruitStart = useRef();

  const [recruitType, setRecruitType] = useState(1); // 이전 공고: 0, 임시 공고: 1
  const [recruitId, setRecruitId] = useState(0); // 공고 넘버
  const [clubId, setClubId] = useState();

  const [recruits, setRecruits] = useState({
    is_success: false,
    recruit: [{}],
  });

  const fileInputRef = useRef(null);
  const [poster, setPoster] = useState(null);
  const [showPoster, setShowPoster] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [always, setAlways] = useState(false);

  const [isInterview, setIsInterview] = useState(null);
  const [region, setRegion] = useState(false);
  const [fee, setFee] = useState(false);
  const publicData = () => {
    if (region && fee) return "활동지역, 회비";
    else if (region) return "활동지역";
    else if (fee) return "회비";
    else return "";
  };

  const [tmpAlert, setTmpAlert] = useState("임시저장");
  const [popup2, setPopup2] = useState(false);
  const [popup3, setPopup3] = useState(false);

  const [infoCheck, setInfoCheck] = useState(false);

  async function connect() {
    const res = await Connection("/getInfo", {});
    if (res.is_success) {
      setInfo(res);
    }
  }

  async function connect2() {
    const res2 = await Connection("/getClubInfo", {
      club_id: clubId,
    });
    if (res2.is_success) {
      setClubInfo(res2);
    }
  }

  const onChange = (e) => {
    switch (e.target.id) {
      case "title":
        setTitle(e.target.value);
        break;
      case "description":
        setDescription(e.target.value);
        break;
      case "default":
        break;
      default:
    }
  };

  const handleClickFileInput = () => {
    fileInputRef.current?.click();
  };
  const uploadProfile = (e) => {
    const fileList = e.target.files;
    if (fileList && fileList[0]) {
      if (fileList[0].size <= 10 * 1024 * 1024) {
        const url = URL.createObjectURL(fileList[0]);
        setPoster({
          file: fileList[0],
          name: fileList[0].name,
          type: fileList[0].type.slice(0, 5), // image
          size: fileList[0].size,
          thumbnail: url,
        });
      } else {
        alert("10MB 이하의 이미지만 첨부할 수 있습니다.");
      }
    }
  };

  const insertRecruit = async (
    session_key,
    clubId,
    recruitId,
    title,
    description,
    poster,
    startDate,
    endDate,
    always,
    state
  ) => {
    const formdata = new FormData();
    formdata.append("session_key", session_key);
    formdata.append("club_id", clubId);
    formdata.append("recruit_id", recruitId);
    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("poster", poster ? poster.file : "");
    formdata.append("start_date", startDate ? startDate : "2000.01.01");
    formdata.append("end_date", endDate ? endDate : "2999.12.31");
    formdata.append("public_data", publicData());
    formdata.append("is_interview", isInterview === "true" ? 1 : 0);
    formdata.append("always", always ? 1 : 0);
    formdata.append("state", state);

    const api_host =
      // window.location.host === "dongdle.com"
      true ? "https://api.dongdle.com" : "http://api_test.dongdle.com:405";
    const res = await fetch(api_host + "/api/v1/insertRecruit", {
      method: "POST",
      body: formdata,
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.is_success) {
          return json;
        } else {
          window.alert(json.error_message);
          if (
            json.error_message === "만료된 세션입니다. 다시 로그인 해주세요."
          ) {
            window.localStorage.clear();
            window.location.href = "/";
          }
          return json;
        }
      });
    return res;
  };

  const [recruitDocs, setRecruitDocs] = useState({});
  const loadRecruit = async () => {
    setRecruitDocs(
      await Connection("/getRecruitDocs", {
        club_id: clubId,
        recruit_id: recruitId,
      })
    );

    const res = await Connection("/getRecruitInfo", {
      club_id: clubId,
      recruit_id: recruitId,
    });
    if (res.is_success) {
      let resRegion = res.public_data.indexOf("활동지역") !== -1;
      let resFee = res.public_data.indexOf("회비") !== -1;
      let resIsInterview = res.step === "서류/면접" ? "true" : "false";
      let resAlways = res.always === 1 ? true : false;
      if (!recruitType) {
        setRecruitId(0);
      }
      setTitle(res.title);
      setDescription(res.description);
      setPoster(
        recruitType && res.poster
          ? {
              name: "미리보기",
              thumbnail: res.poster,
            }
          : null
      );
      if (!resAlways) {
        setStartDate(res.start_date);
        setEndDate(res.end_date);
      }
      setAlways(resAlways);
      setFee(resFee);
      setRegion(resRegion);
      setIsInterview(resIsInterview);
      setModalNum(1);
    }
  };

  const onClick = (e) => {
    switch (e.target.id) {
      case "isMeeting-true":
        setIsInterview("true");
        break;
      case "isMeeting-false":
        setIsInterview("false");
        break;
      case "moreInfo-region":
        setRegion(e.target.checked);
        break;
      case "moreInfo-fee":
        setFee(e.target.checked);
        break;
      case "always-recruit":
        setAlways(e.target.checked);
        setIsInterview("false");
        setEndDate();
        setStartDate();
        break;
      case "default":
      default:
    }
  };

  let timeChk;
  useEffect(() => {
    if (tmpAlert !== "임시저장") {
      timeChk = setInterval(() => {
        setTmpAlert("임시저장");
        clearInterval(timeChk);
      }, 2000);
    }
    return () => {
      clearInterval(timeChk);
    };
  }, [tmpAlert]);

  useEffect(() => {
    if (clubId) {
      connect2();
    }
  }, [clubId]);

  const getRecruits = async (clubId) => {
    const res = await Connection("/getInsertableRecruitList", {
      club_id: clubId,
    });
    setRecruits(res);
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (
        !recruitStart.current.contains(e.target) &&
        !btnRef.current.contains(e.target) &&
        !guideRecruit_3
      ) {
        setModal(false);
        document.removeEventListener("mousedown", handleClick);
      }
    };
    document.addEventListener("mousedown", handleClick);
    connect();
    return () => document.removeEventListener("mousedown", handleClick);
  }, []);

  useEffect(() => {
    const guide = JSON.parse(window.localStorage.getItem("guide"));
    if (modalNum === 0 && firstModalNum === 1) {
      if (guide?.recruit_3) {
        onChangeRec3(false);
      } else {
        onChangeRec3(true);
      }
    }
    if (modalNum === 1) {
      onChangeRec4i(0);
      if (guide?.recruit_4) {
        onChangeRec4(false);
      } else {
        onChangeRec4(true);
      }
    }
  }, [modalNum, firstModalNum]);

  const modal_0_0 = (
    <div id="recruit00-wrapper">
      <p className="Sub1">동아리를 먼저 선택해주세요😀</p>
      <div id="recruit00-DefaultImgs">
        {cbu.is_success &&
          cbu.club.map((cl, idx) => (
            <img
              src={cl.image}
              alt={Dongari_Default}
              onClick={() => {
                setFirstModalNum(1);
                getRecruits(cl.id);
                setClubId(cl.id);
              }}
            />
          ))}
      </div>
    </div>
  );

  const guideRecruit_3 = useSelector((state) => state.guide.recruit_3);
  const guideRecruit_4 = useSelector((state) => state.guide.recruit_4);
  const guideRecruit_4i = useSelector((state) => state.guide.recruit_4i);

  const dispatch = useDispatch();
  const onChangeRec3 = (value) => dispatch(setRecruit3(value));
  const onChangeRec4 = (value) => dispatch(setRecruit4(value));
  const onChangeRec4i = (index) => dispatch(setRecruit4i(index));

  const modal_0_1 = (
    <>
      {/* 가이드 구간 =========================== */}
      <GuideModal
        contents={[
          "모집 공고를 만들어주세요.\n이전공고와 임시저장 공고를 확인하고 선택할 수 있어요!",
        ]}
        showIndex={false}
        guideName={"recruit_3"}
        modal={guideRecruit_3}
        setModal={onChangeRec3}
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
        position="right"
      />
      {/* 가이드 구간 =========================== */}
      <div
        id="recruit01-wrapper"
        style={guideRecruit_3 ? { pointerEvents: "none" } : null}
      >
        <p className="Sub1">모집 공고 만들기</p>
        <img
          id="recruit01-close"
          src={close}
          alt=""
          onClick={() => {
            setModal(false);
          }}
        />
        <div id="recruit01-body">
          <div id="recruit01-textBtns" className="Sub3">
            <div
              className="div-recruit01-textBtn"
              onClick={() => {
                setRecruitType(1);
                setRecruitId(0);
              }}
              style={{ color: recruitType === 1 ? "black" : "#B4B4B4" }}
            >
              임시저장
            </div>
            <div
              className="div-recruit01-textBtn"
              onClick={() => {
                setRecruitType(0);
              }}
              style={{ color: recruitType === 0 ? "black" : "#B4B4B4" }}
            >
              이전공고
            </div>
          </div>
          <div id="div-recruit01-selections">
            {recruits.is_success ? (
              recruits.recruit.map(
                (rec, idx) =>
                  rec.is_tmp === recruitType && (
                    <div
                      className="div-recruit01-selection"
                      style={{
                        backgroundColor:
                          recruitId === rec.id && "rgba(255, 122, 0, 0.3)",
                      }}
                      onClick={(e) => {
                        setRecruitId(
                          recruitId === e.currentTarget.id
                            ? 0
                            : e.currentTarget.id
                        );
                      }}
                      id={rec.id}
                    >
                      <div className="div-recruit01-selection-left">
                        <p className="Sub3">{rec.title}</p>
                        <p className="Body5">{rec.date}</p>
                      </div>
                      {rec.is_tmp ? (
                        <img
                          src={Icon_Delete}
                          alt=""
                          onClick={async () => {
                            if (window.confirm("정말로 삭제하시겠습니까?")) {
                              setRecruitId(0);
                              const res = await Connection(
                                "/deleteTmpRecruit",
                                {
                                  club_id: clubId,
                                  recruit_id: rec.id,
                                }
                              );
                              if (res.is_success) {
                                alert("삭제되었습니다.");
                                getRecruits(clubId);
                              }
                            }
                          }}
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                  )
              )
            ) : (
              <>
                <div className="skeleton-div-recruit01-selection" />
                <div className="skeleton-div-recruit01-selection" />
              </>
            )}
          </div>
        </div>
        <GuideDefaultDim
          ownIndex={1}
          guideIndex={0}
          isOpen={guideRecruit_3}
          CoachMark={true}
          style={{
            width: "255px",
            height: "55.5px",
            position: "absolute",
            bottom: "0",
          }}
        >
          <button
            id="recruit01-footer"
            className="Sub3 main-black-button"
            onClick={() => {
              if (recruitId) {
                loadRecruit();
              } else {
                setModalNum(1);
              }
            }}
          >
            {recruitId ? "불러오기" : "새로 만들기"}
          </button>
        </GuideDefaultDim>
      </div>
    </>
  );
  const modal_0 = (
    <div>
      {firstModalNum === 0 && modal_0_0}
      {firstModalNum === 1 && modal_0_1}
    </div>
  );
  const modal_1 = (
    <>
      <GuideModal
        contents={[
          "공고 제목과 모집 글, 이미지 등을 모두 작성해 주세요.\n해당 내용은 지원자가 바로 확인하는 내용이에요.",
          "공고 작성이 완료되면, 체크 후 작성 다음 단계로 넘어갈 수 있어요.",
        ]}
        size={2}
        guideIndex={guideRecruit_4i}
        onChangeIndex={onChangeRec4i}
        guideName="recruit_4"
        modal={guideRecruit_4}
        setModal={onChangeRec4}
        position="right"
        style={{
          position: "fixed",
          right: "20px",
          bottom: "20px",
        }}
      />
      <div className="div-modal-background">
        <div
          className="div-recruit-wrapper"
          style={guideRecruit_4 ? { pointerEvents: "none" } : null}
        >
          <div id="recruit-header">
            <p className="Sub1">📣공고 작성</p>
            <div id="recruit-header-right">
              <button
                className="Sub3"
                onClick={async () => {
                  if (localStorage.getItem("session_key") === "admin_demo") {
                    window.alert("회원가입 후 이용 가능합니다🙂");
                    setLoginModal(true);
                  } else {
                    setTmpAlert("임시저장이 완료되었습니다!");
                    const res = await insertRecruit(
                      session_key,
                      clubId,
                      recruitId,
                      title,
                      description,
                      poster,
                      startDate,
                      endDate,
                      always,
                      0
                    );
                    if (res.is_success && !recruitId) {
                      setRecruitId(res.recruit_id);
                    }
                  }
                }}
                disabled={tmpAlert !== "임시저장"}
              >
                {tmpAlert}
              </button>
              <img
                src={close}
                alt=""
                onClick={() => {
                  setModal(false);
                }}
              />
            </div>
          </div>
          <CoachMark
            isOpen={guideRecruit_4}
            guideIndex={guideRecruit_4i}
            ownIndex={1}
          >
            <form className="div-recruit-questions">
              <div className="div-recruit-question">
                <div className="recruit-label-wrapper">
                  <label className="Sub3">공고 제목</label>
                </div>
                <input
                  id="title"
                  onChange={onChange}
                  className="input-recruit-headline Body4 -input-box"
                  placeholder="내용을 입력해주세요"
                  value={title}
                />
              </div>
              <div className="div-recruit-question">
                <div className="recruit-label-wrapper">
                  <label className="Sub3">모집 글</label>
                </div>
                <textarea
                  id="description"
                  onChange={onChange}
                  className="input-recruit-contents Body4 -input-box"
                  placeholder="내용을 입력해주세요"
                  value={description}
                />
              </div>
              <div className="div-recruit-question">
                <div className="recruit-label-wrapper">
                  <label className="Sub3">포스터 첨부</label>
                </div>
                <div className="div-recruit-question-right">
                  <div className="input-poster">
                    <input
                      type="button"
                      className="button-recruit Sub3"
                      onClick={handleClickFileInput}
                      value="이미지 등록"
                    />
                    {poster && poster.file !== -1 && (
                      <div
                        className="input-poster-info"
                        onMouseOver={() => {
                          setShowPoster(true);
                        }}
                        onMouseLeave={() => {
                          setShowPoster(false);
                        }}
                      >
                        <p className="Tag1">{poster?.name}</p>
                        <img
                          src={close}
                          alt=""
                          onClick={() => {
                            setPoster({ file: -1 });
                            setShowPoster(false);
                          }}
                        />
                      </div>
                    )}
                    <div style={{ position: "relative" }}>
                      {showPoster && (
                        <img id="thumb" src={poster?.thumbnail} alt="" />
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/jpg, image/jpeg, image/png"
                      ref={fileInputRef}
                      onChange={uploadProfile}
                    />
                  </div>
                </div>
              </div>
              <div className="div-recruit-question">
                <div className="recruit-label-wrapper">
                  <label className="Sub3">모집 기간</label>
                </div>
                <div
                  className="div-recruit-question-right"
                  style={
                    always ? { opacity: "0.5", pointerEvents: "none" } : null
                  }
                >
                  <div className="div-recruit-calendars-wrapper">
                    <NewCalendar
                      value={startDate || "시작일"}
                      onClick={async (e) => setStartDate(e.target.id)}
                    />
                    <p className="Body4">~</p>
                    <NewCalendar
                      value={endDate || "종료일"}
                      onClick={async (e) => setEndDate(e.target.id)}
                      start={startDate}
                    />
                  </div>
                  <p className="Body5">
                    종료일이 지나면 자동으로 공고가 마감되며 비활성화
                    처리됩니다.
                  </p>
                </div>
                <input
                  className="recruit-selection"
                  type="checkbox"
                  name="moreInfo"
                  id="always-recruit"
                  onClick={onClick}
                />
                <label
                  htmlFor="always-recruit"
                  style={{
                    position: "absolute",
                    right: "50px",
                    top: "8.5px",
                  }}
                >
                  <img src={always ? CheckBox_Checked : CheckBox} alt="" />
                  <p className="Body4">상시 모집</p>
                </label>
              </div>
              <div className="div-recruit-question">
                <div className="recruit-label-wrapper">
                  <img
                    className="img-recruit-info"
                    src={Icon_Question}
                    alt=""
                    onClick={() => setPopup2(true)}
                  />
                  {popup2 && (
                    <ToastPopup
                      className="popup-recruit-info Body5"
                      status="Gray_info"
                      close={true}
                      setModalDisplay={setPopup2}
                    >
                      선택 시 정보가 공고에 노출됩니다.
                    </ToastPopup>
                  )}
                  <label htmlFor="moreInfo" className="Sub3">
                    추가 공개 정보{" "}
                    <span style={{ color: "#CDCDCD" }}>(선택)</span>
                  </label>
                </div>
                <div className="div-recruit-selections">
                  <input
                    className="recruit-selection"
                    type="checkbox"
                    name="moreInfo"
                    id="moreInfo-region"
                    onClick={onClick}
                  />
                  <label htmlFor="moreInfo-region">
                    <img src={region ? CheckBox_Checked : CheckBox} alt="" />
                    <p className="Body4">활동 지역</p>
                  </label>
                  <input
                    className="recruit-selection"
                    type="checkbox"
                    name="moreInfo"
                    id="moreInfo-fee"
                    onClick={onClick}
                  />
                  <label htmlFor="moreInfo-fee">
                    <img src={fee ? CheckBox_Checked : CheckBox} alt="" />
                    <p className="Body4">회비</p>
                  </label>
                </div>
              </div>
              <div
                className="div-recruit-question"
                style={
                  always ? { opacity: "0.3", pointerEvents: "none" } : null
                }
              >
                <div className="recruit-label-wrapper">
                  <img
                    className="img-recruit-info"
                    src={Icon_Question}
                    alt=""
                    onClick={() => setPopup3(true)}
                  />
                  {popup3 && (
                    <ToastPopup
                      className="popup-recruit-info Body5"
                      status="Gray_info"
                      close={true}
                      setModalDisplay={setPopup3}
                    >
                      면접 선택 시, 동들에서 면접 일정 조율을 해줍니다.
                    </ToastPopup>
                  )}
                  <label htmlFor="isMeeting" className="Sub3">
                    모집 전형
                  </label>
                </div>
                <div className="div-recruit-selections">
                  <input
                    className="recruit-selection"
                    type="radio"
                    name="isMeeting"
                    id="isMeeting-true"
                    onClick={onClick}
                  />
                  <label htmlFor="isMeeting-true">
                    <img
                      src={
                        isInterview === "true"
                          ? RadioButton_Checked
                          : RadioButton
                      }
                      alt=""
                    />
                    <p className="Body4">면접</p>
                  </label>
                  <input
                    className="recruit-selection"
                    type="radio"
                    name="isMeeting"
                    id="isMeeting-false"
                    onClick={onClick}
                  />
                  <label htmlFor="isMeeting-false">
                    <img
                      src={
                        isInterview === "false"
                          ? RadioButton_Checked
                          : RadioButton
                      }
                      alt=""
                    />
                    <p className="Body4">서류만</p>
                  </label>
                </div>
              </div>
            </form>
            {guideRecruit_4 && guideRecruit_4i === 0 && (
              <div
                style={{
                  outline: "2px solid orange",
                  backgroundColor: "rgba(255, 122, 0, 0.15)",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              />
            )}
          </CoachMark>
          <div id="recruit-footer">
            <CoachMark
              isOpen={guideRecruit_4}
              guideIndex={guideRecruit_4i}
              ownIndex={2}
            >
              <div
                id="recruit-footer-left"
                onClick={() => {
                  setInfoCheck((prev) => !prev);
                }}
              >
                <img src={infoCheck ? CheckBox_Checked : CheckBox} alt="" />
                <p className="Body4">
                  공고 작성 완료 후에는 공고 제목과 공고 내용만 수정 가능합니다.
                </p>
              </div>
              {guideRecruit_4 && guideRecruit_4i === 1 && (
                <div
                  style={{
                    outline: "2px solid orange",
                    backgroundColor: "rgba(255, 122, 0, 0.15)",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                />
              )}
            </CoachMark>
            <button
              className="Sub3 main-black-button"
              disabled={
                !title ||
                !description ||
                !poster ||
                !isInterview ||
                !infoCheck ||
                poster.file === -1 ||
                !(always || !(!startDate || !endDate || strToday > startDate))
              }
              onClick={async () => {
                if (localStorage.getItem("session_key") !== "admin_demo") {
                  const res = await insertRecruit(
                    session_key,
                    clubId,
                    recruitId,
                    title,
                    description,
                    poster,
                    startDate,
                    endDate,
                    always,
                    1
                  );
                  if (res.is_success) {
                    setRecruitId(res.recruit_id);
                  }
                }

                setModalNum(2);
              }}
            >
              다음 단계
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div ref={recruitStart}>
      {modalNum === 0 && modal_0}
      {modalNum === 1 && modal_1}
      {modalNum === 2 && (
        <div className="div-modal-background" style={{ overflowX: "hidden" }}>
          <MakeForm
            setModal={setModal}
            setModalNum={setModalNum}
            club_id={clubId}
            recruit_id={recruitId}
            info={info}
            clubInfo={clubInfo}
            recruitDocs={recruitDocs}
          />
        </div>
      )}
      {loginModal && <Loginform setModalOpen={setLoginModal} />}
    </div>
  );
}
