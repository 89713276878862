function CommunityConnection(format, parameters, isAlert = true) {
  const api_host =
    // window.location.protocol === "https:"
    true ? "https://api2.dongdle.com" : "http://localhost:3001";

  const post = {
    ...parameters,
    session_key:
      parameters?.session_key || window.localStorage.getItem("session_key"),
  };
  const ret = fetch(api_host + format, {
    method: "post",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(post),
  })
    .then((res) => res.json())
    .then((json) => {
      if (json.is_success) {
        return json;
      } else {
        if (isAlert)
          window.alert(
            json.error_message +
              " " +
              (window.location.protocol === "https:" ? "" : format)
          );
        if (json.error_message === "만료된 세션입니다. 다시 로그인 해주세요.") {
          window.localStorage.clear();
          window.location.href = "/";
        }
        return json;
      }
    });

  return ret;
}

export default CommunityConnection;
