import React, { useEffect, useState } from "react";
import Icon_Share from "../asset/icon/Icon_Share.svg";
import Icon_Close from "../asset/icon/Icon_Close.svg";
import Icon_Link from "../asset/icon/Icon_Link.svg";
import Connection from "./Connection";
import Loginform from "./Loginform";
import FormQuestionCheck from "./FormQuestionCheck";

import { GuideDefaultDim } from "./guideModal";
import { useSelector } from "react-redux";
import { setRecruit1, setRecruit1i } from "../modules/guide";
import CommunityConnection from "./community/CommunityConnection";

function RecruitInfo({
  club_id,
  recruit_id,
  recruitInfo,
  recruitDetailOpen,
  setRecruitDetailOpen,
  isEditOpen,
  setIsEditOpen,
  clubInfo,
  info,
}) {
  const [loginModal, setLoginModal] = useState(false);

  const session_key = localStorage.getItem("session_key");
  const [description, setDescription] = useState();
  const [currentDescription, setCurrentDescription] = useState();
  const onChange = (e) => {
    switch (e.target.id) {
      case "description":
        setDescription(e.target.value);
        setCurrentDescription(e.target.value);
        break;
      default:
    }
  };

  useEffect(() => {
    setDescription(recruitInfo.description);
    setCurrentDescription(recruitInfo.description);
  }, [recruitInfo]);
  useEffect(() => {
    setDescription(currentDescription);
    setIsEditOpen(false);
  }, [recruitDetailOpen]);

  const [ename, setEname] = useState("");
  const getEname = async () => {
    const res = await CommunityConnection("/getEname", {
      club_id: club_id,
    });
    if (res.is_success) {
      setEname(res.ename);
    }
  };
  useEffect(() => {
    getEname();
  }, []);

  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("모집 공고 URL이 복사되었습니다!");
    } catch (e) {
      alert("복사에 실패하였습니다");
    }
  };

  const [isFormCheckOpen, setIsFormCheckOpen] = useState(false);

  const param = window.location.href.split("/");

  const [profile, setProfile] = useState({
    name: "",
    phone: "",
    gender: "",
    birthday: "",
  });
  const [formStep, setFormStep] = useState(0);
  const pnumHypen = function (str) {
    str = str.replace(/[^0-9]/g, "");
    let tmp = "";
    if (str.length < 4) {
      return str;
    } else if (str.length < 8) {
      tmp += str.substr(0, 3);
      tmp += "-";
      tmp += str.substr(3);
      return tmp;
    } else {
      tmp += str.substr(0, 3);
      tmp += "-";
      tmp += str.substr(3, 4);
      tmp += "-";
      tmp += str.substr(7);
      return tmp;
    }
  };

  const birthHyphen = function (str) {
    str = str.replace(/[^0-9]/g, "");
    let tmp = "";
    if (str.length < 5) {
      return str;
    } else if (str.length < 7) {
      tmp += str.substr(0, 4);
      tmp += ".";
      tmp += str.substr(4);
      return tmp;
    } else {
      tmp += str.substr(0, 4);
      tmp += ".";
      tmp += str.substr(4, 2);
      tmp += ".";
      tmp += str.substr(6);
      return tmp;
    }

    return str;
  };

  useEffect(() => {
    setFormStep(0);
  }, [isFormCheckOpen]);

  // redux를 활용한 가이드 인덱스 관리
  const { guideRecruit_1, guideRecruit_1i } = useSelector((state) => ({
    guideRecruit_1: state.guide.recruit_1,
    guideRecruit_1i: state.guide.recruit_1i,
  }));

  return (
    <div>
      <div className="div-manage-section-03-01">
        <div className="div-manage-section-03-01-01">
          <div className="div-manage-section-03-01-01-01 H3">📣 공고 정보</div>
          <div className="div-manage-section-03-01-01-scroll">
            {recruitInfo.poster ? (
              <div className="div-manage-poster">
                <img
                  className="img-manage-poster"
                  src={recruitInfo.poster}
                  alt=""
                  onClick={() => window.open(recruitInfo.poster, "_blank")}
                />
              </div>
            ) : (
              <div className="skeleton-manage-poster" />
            )}
            {recruitInfo.title ? (
              <pre className="pre-manage-section-03-01-01-03 Sub1">
                {recruitInfo.title}
              </pre>
            ) : (
              <div className="skeleton-manage-section-03-01-01-03" />
            )}

            {recruitInfo.is_success ? (
              <div className="div-manage-section-03-01-01-04">
                <div className="div-manage-section-03-01-01-04-row">
                  <div
                    className="Sub3"
                    style={{
                      width: "50px",
                      textAlign: "left",
                      color: "#4C4C4C",
                    }}
                  >
                    모집 기간
                  </div>
                  <div className="Sub3" style={{ fontWeight: 400 }}>
                    {recruitInfo.end_date === "2999.12.31"
                      ? "상시"
                      : `${recruitInfo.start_date} ~ ${recruitInfo.end_date}`}
                  </div>
                </div>
                <div className="div-manage-section-03-01-01-04-row Sub3">
                  <div
                    className="Sub3"
                    style={{
                      width: "50px",
                      textAlign: "left",
                      color: "#4C4C4C",
                    }}
                  >
                    모집 절차
                  </div>
                  <div className="Sub3" style={{ fontWeight: 400 }}>
                    {recruitInfo.step}
                  </div>
                </div>
                {recruitInfo.public_data.indexOf("활동지역") !== -1 && (
                  <div className="div-manage-section-03-01-01-04-row Sub3">
                    <div
                      className="Sub3"
                      style={{
                        width: "50px",
                        textAlign: "left",
                        color: "#4C4C4C",
                      }}
                    >
                      활동 지역
                    </div>
                    <div>
                      {clubInfo.location.map((loc, idx) => (
                        <div
                          className="Sub3"
                          key={idx}
                          style={{ fontWeight: 400 }}
                        >
                          {idx !== 0 && "/"}
                          {info && info.location[loc]}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {recruitInfo.public_data.indexOf("회비") !== -1 && (
                  <div className="div-manage-section-03-01-01-04-row Sub3">
                    <div
                      className="Sub3"
                      style={{
                        width: "50px",
                        textAlign: "left",
                        color: "#4C4C4C",
                      }}
                    >
                      회비
                    </div>
                    <div className="Sub3" style={{ fontWeight: 400 }}>
                      {clubInfo.cost}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="skeleton-manage-section-03-01-01-04" />
            )}
            <div style={{ display: "flex", gap: "6px" }}>
              <button
                className="outlined-black-button button-manage-section-03-01-half Sub3"
                onClick={() => {
                  setRecruitDetailOpen(true);
                }}
              >
                모집글 확인하기
              </button>
              <button
                className="outlined-black-button button-manage-section-03-01-half Sub3"
                onClick={() => {
                  setIsFormCheckOpen(true);
                }}
              >
                지원폼 확인하기
              </button>
            </div>
          </div>
          <div className="div-manage-share-section">
            <GuideDefaultDim
              guideIndex={guideRecruit_1i}
              ownIndex={2}
              CoachMark={true}
              guideName={"recruit_1"}
              isOpen={guideRecruit_1}
              style={{
                width: "228px",
                height: "33px",
                display: "flex",
                gap: "6px",
              }}
            >
              <button
                className="main-black-button button-manage-share Sub3"
                style={{ width: "50%" }}
                onClick={() =>
                  handleCopyClipBoard(
                    window.location.protocol +
                      "//" +
                      window.location.host +
                      "/form/" +
                      param[param.length - 2] +
                      "/" +
                      param[param.length - 1]
                  )
                }
              >
                <img
                  className="div-click img-manage-share"
                  src={Icon_Share}
                  alt=""
                  style={{ filter: "invert(100%)" }}
                />
                URL 공유
              </button>
              <button
                className="main-orange-button button-manage-share Sub3"
                style={{ width: "50%" }}
                onClick={() =>
                  handleCopyClipBoard(
                    window.location.protocol +
                      "//" +
                      window.location.host +
                      "/form/" +
                      ename +
                      "/" +
                      recruitInfo.start_date.replace(/\./g, "").slice(-6) +
                      param[param.length - 2].slice(-3) +
                      param[param.length - 1].slice(-3)
                  )
                }
              >
                <img
                  className="div-click img-manage-share"
                  src={Icon_Link}
                  alt=""
                  style={{ filter: "invert(100%)" }}
                />
                단축 URL
              </button>
            </GuideDefaultDim>
          </div>
          <button
            className="button-manage-delete-recruit Sub3"
            onClick={async () => {
              if (localStorage.getItem("session_key") === "admin_demo") {
                window.alert("회원가입 후 이용 가능합니다🙂");
                setLoginModal(true);
              } else if (window.confirm("정말 삭제하시겠습니까?")) {
                const ret = await CommunityConnection("/deleteRecruit", {
                  club_id: club_id,
                  recruit_id: recruit_id,
                });
                if (ret.is_success) {
                  window.alert("모집 공고 삭제가 완료되었습니다.");

                  const ci = await Connection("/getClubInfo", {
                    club_id: club_id,
                  });

                  const recruit_id =
                    (await ci.recruit.length) > 0
                      ? ci.recruit[0].id
                      : "no_recruit";

                  window.location.href = "/" + club_id + "/" + recruit_id;
                }
              }
            }}
          >
            모집 공고 삭제하기
          </button>
        </div>
      </div>
      {isFormCheckOpen && (
        <div
          className="div-modal-background"
          style={{ flexDirection: "column" }}
        >
          <div className="div-manage-recruit-form-check-section-header">
            <img
              className="icon"
              src={Icon_Close}
              alt=""
              onClick={() => {
                setIsFormCheckOpen(false);
              }}
              style={{
                position: "absolute",
                right: "16px",
                top: "12px",
              }}
            />
          </div>
          <div className="div-manage-recruit-form-check-section">
            <FormQuestionCheck
              club_id={club_id}
              recruit_id={recruit_id}
              setIsFormCheckOpen={setIsFormCheckOpen}
              profile={profile}
              setProfile={setProfile}
              step={formStep}
              setStep={setFormStep}
            />
            {formStep === 0 && (
              <button
                className="button-form-apply-check Sub3M"
                onClick={() => {
                  setProfile({
                    name: "김동들",
                    phone: pnumHypen("01012345678"),
                    gender: "male",
                    birthday: birthHyphen("200000101"),
                  });
                  setFormStep(1);
                }}
              >
                지원하러 가기
              </button>
            )}
          </div>
        </div>
      )}
      {recruitDetailOpen && (
        <div className="div-modal-background">
          <div className="div-manage-recruit-detail-section-01">
            <div className="div-manage-recruit-detail-section-01-01 Sub1">
              ✏️ 모집 공고 글
              <button>
                <img
                  className="icon"
                  src={Icon_Close}
                  alt=""
                  onClick={() => {
                    setRecruitDetailOpen(false);
                  }}
                />
              </button>
            </div>
            <textarea
              className="textarea-manage-recruit-detail-section-01-02 Body4"
              disabled={!isEditOpen}
              value={description}
              id="description"
              onChange={onChange}
            />
            <div className="div-manage-recruit-detail-section-01-03 Sub3">
              {isEditOpen ? (
                <>
                  <button
                    className="outlined-black-button button-manage-recruit-detail-section-01-03"
                    onClick={() => {
                      setIsEditOpen(false);
                      setDescription(recruitInfo.description);
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="main-black-button button-manage-recruit-detail-section-01-03-black"
                    onClick={async () => {
                      if (window.confirm("모집 공고 글을 수정하시겠습니까?")) {
                        const ret = await Connection(
                          "/updateRecruitDescription",
                          {
                            club_id: club_id,
                            recruit_id: recruit_id,
                            description: description,
                          }
                        );
                        window.alert("수정이 완료되었습니다.");
                        setIsEditOpen(false);
                        setRecruitDetailOpen(false);
                      }
                    }}
                  >
                    완료
                  </button>
                </>
              ) : (
                <button
                  className="outlined-black-button button-manage-recruit-detail-section-01-03"
                  onClick={() => {
                    if (session_key === "admin_demo") {
                      window.alert("회원가입 후 이용 가능합니다🙂");
                      setLoginModal(true);
                    } else {
                      setIsEditOpen(true);
                    }
                  }}
                >
                  수정
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {loginModal && <Loginform setModalOpen={setLoginModal} />}
    </div>
  );
}

export default RecruitInfo;
