import React, { useState, useEffect } from "react";
import Icon_Reset from "../asset/icon/Icon_Reset.svg";
import Connection from "./Connection";
import MessageFinal from "./MessageFinal";
import RecruitInfo from "./RecruitInfo";
import Table4 from "./Table4";
import ToastPopup from "./ToastPopup";
import { useMediaQuery } from "react-responsive";

function RecruitPage4({
  club_id,
  recruit_id,
  recruitDocs,
  recruitInfo,
  recruitStep1,
  setRecruitState,
  clubInfo,
  info,
  isLeftSectionOpen,
  managerList,
}) {
  const isWide = useMediaQuery({
    query: isLeftSectionOpen ? "(min-width: 1265px)" : "(min-width: 985px)",
  });

  const [recruitDetailOpen, setRecruitDetailOpen] = useState(false);
  const [countAll, setCountAll] = useState(0);
  const [countFail, setCountFail] = useState(0);
  const [countPass, setCountPass] = useState(0);

  const [applicantList, setApplicantList] = useState([]);
  const [timetable, setTimetable] = useState([]);

  async function getRecruitStep2() {
    const res = await Connection("/getRecruitStep2", {
      session_key: localStorage.getItem("session_key"),
      club_id: club_id,
      recruit_id: recruit_id,
    });

    if (res.is_success) {
      setApplicantList(
        res.applicant_r.filter((a) => a.state !== 0 && a.state !== 2)
      );
      setTimetable(res.timetable);
    }
  }

  useEffect(() => {
    if (recruitStep1.applicant_r && recruitStep1.timetable) {
      setApplicantList(
        recruitStep1.applicant_r.filter((a) => a.state !== 0 && a.state !== 2)
      );
      setTimetable(recruitStep1.timetable);
    }
  }, [recruitStep1]);

  useEffect(() => {
    setCountAll(applicantList.length);
  }, [applicantList]);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const stateHeader = [
    { k: "상태", type: 0 },
    { k: "점수", type: 0 },
    { k: "면접 날짜 (시간)", type: 6 },
  ];
  const newHeader = [].concat(stateHeader, recruitDocs.question);

  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [animation1, setAnimation1] = useState("");
  const [isToastPopupOpen, setIsToastPopupOpen] = useState(false);
  const onToggle = () => {
    setAnimation1("openAnimation-toast");
    setIsToastPopupOpen(true);
    setTimeout(() => {
      setAnimation1("closeAnimation-toast");
      setTimeout(() => {
        setIsToastPopupOpen(false);
      }, 450);
    }, 2000);
  };

  return (
    <>
      <RecruitInfo
        club_id={club_id}
        recruit_id={recruit_id}
        recruitInfo={recruitInfo}
        recruitDetailOpen={recruitDetailOpen}
        setRecruitDetailOpen={setRecruitDetailOpen}
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        clubInfo={clubInfo}
        info={info}
      />
      <div
        className={
          isLeftSectionOpen
            ? "div-manage-section-03-02"
            : "div-manage-section-03-02-wide"
        }
      >
        <div
          className={
            isLeftSectionOpen
              ? "div-manage-section-03-02-01"
              : "div-manage-section-03-02-01-wide"
          }
        >
          <div>
            <div className="div-manage-section-03-01-01-01 H3">
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <div
                  className="H3"
                  style={{
                    width: "148px",
                    flexShrink: "0",
                  }}
                >
                  🙌 서류 합격자 현황
                </div>
                {isWide && (
                  <div
                    className="div-click div-manage-section-03-01-01-01-reset Tag1"
                    onClick={async () => {
                      if (!isToastPopupOpen) {
                        await getRecruitStep2();
                        onToggle();
                      }
                    }}
                  >
                    <img src={Icon_Reset} alt="" style={{ width: "18px" }} />
                    새로고침
                  </div>
                )}
                {isToastPopupOpen && (
                  <ToastPopup
                    status={"success"}
                    children={"새로고침이 완료되었습니다."}
                    className={`${animation1} Tag1`}
                  />
                )}
              </div>
              {!isWide && (
                <ToastPopup
                  status={"warning"}
                  children={"화면을 늘려주세요!"}
                  className={`Tag1`}
                />
              )}
            </div>
            {recruitDocs.is_success && recruitStep1.is_success && (
              <Table4
                club_id={club_id}
                recruit_id={recruit_id}
                recruitDocs={recruitDocs}
                headers={newHeader}
                applicantList={applicantList}
                setCountFail={setCountFail}
                setCountPass={setCountPass}
                timetable={timetable}
                isLeftSectionOpen={isLeftSectionOpen}
                managerList={managerList}
              />
            )}
          </div>
          <div className="div-manage-section-03-02-01-02">
            <div className="div-manage-section-03-02-01-02-count-section">
              <div
                className="div-manage-section-03-02-01-02-count Body4"
                style={{ width: "56px" }}
              >
                면접 지원자
                <div className="H3">{countAll}명</div>
              </div>
              <div className="div-manage-section-03-02-01-02-count Body4">
                불합격
                <div className="H3">{countFail}명</div>
              </div>
              <div className="div-manage-section-03-02-01-02-count Body4">
                합격
                <div className="H3" style={{ color: "#FF7A00" }}>
                  {countPass}명
                </div>
              </div>
            </div>
            <button
              className="main-orange-button button-manage-section-03-02-01-02-complete H3"
              onClick={async () => {
                if (countAll === countFail + countPass) {
                  if (window.confirm("면접 합불 처리를 완료하시겠습니까?")) {
                    setIsMessageModalOpen(true);
                  }
                } else {
                  window.alert("아직 평가하지 않은 지원자가 있습니다.");
                }
              }}
            >
              최종 합불 처리
            </button>
          </div>
        </div>
      </div>
      {isMessageModalOpen && (
        <MessageFinal
          club_id={club_id}
          recruit_id={recruit_id}
          setModalOpen={setIsMessageModalOpen}
          countPass={countPass}
          countFail={countFail}
          countAll={countAll}
          setRecruitState={setRecruitState}
        />
      )}
    </>
  );
}

export default RecruitPage4;
