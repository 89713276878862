import React, { useState, useEffect, useRef } from "react";
import "../asset/css/calendar.css";
import Icon_Calendar from "../asset/icon/Icon_Calendar.svg";
import Icon_ArrowLeft from "../asset/icon/Icon_ArrowLeft.svg";
import Icon_ArrowRight from "../asset/icon/Icon_ArrowRight.svg";

const today = new Date();
export default function NewCalendar({ value, onClick, start }) {
  const calendarSection = useRef(null);
  const calendarOpener = useRef(null);
  const [modalDisplay, setModalDisplay] = useState(false);

  const [curDate, setCurDate] = useState(today);
  const [firstDate, setFirstDate] = useState(
    new Date(curDate.getFullYear(), curDate.getMonth(), 1)
  );
  const [lastDate, setLastDate] = useState(
    new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0)
  );
  const [isThisMonth, setIsThisMonth] = useState(true);

  let startDate;
  if (String(start).length === 10) {
    const numbers = String(start).split(".");
    startDate = new Date(numbers[0], numbers[1] - 1, numbers[2]);
    if (startDate < today) {
      startDate = today;
    }
  } else {
    startDate = today;
  }
  if (start > value) {
    onClick({
      target: { id: start },
    });
  }

  const modalControl = () => {
    if (modalDisplay) {
      setModalDisplay(false);
      calendarOpener.current.style.border = "1px solid #CDCDCD";
    } else {
      setModalDisplay(true);
      calendarOpener.current.style.border = "1px solid #000000";
    }
  };

  const clickArrow = (e) => {
    switch (e.target.name) {
      case "prev":
        setCurDate(
          new Date(
            curDate.getFullYear(),
            curDate.getMonth() - 1,
            curDate.getDate()
          )
        );
        break;
      case "next":
        setCurDate(
          new Date(
            curDate.getFullYear(),
            curDate.getMonth() + 1,
            curDate.getDate()
          )
        );
        break;
      default:
    }
  };

  const showCalendar = () => {
    const dateArr = [];
    for (let i = 1; i <= lastDate.getDate(); i++) {
      dateArr.push({
        date: i,
        day: (firstDate.getDay() + i - 1) % 7,
      });
    }

    let weeks = [];
    let days = [];
    let dId;
    for (let i = 0; i < dateArr[0].day; i++) {
      days.push(<div className="div-calendar-cell Tag1 cursor-default" />);
    }
    dateArr.map((d) => {
      dId = `${curDate.getFullYear()}.${String(curDate.getMonth() + 1).padStart(
        2,
        "0"
      )}.${String(d.date).padStart(2, "0")}`;
      if (d.day === 0) {
        if (days.length) {
          weeks.push(<div className="div-calendar-week">{days}</div>);
          days = [];
        }
        days.push(
          <input
            type="button"
            className={`
              div-calendar-cell Tag1 calendar-sunday
              ${value === dId ? "calendar-selected" : ""}
              ${
                isThisMonth === -1 ||
                (isThisMonth === 0 && d.date < startDate.getDate())
                  ? "calendar-past"
                  : ""
              }
            `}
            onClick={onClick}
            onDoubleClick={() => setModalDisplay(false)}
            id={dId}
            data-day={d.day}
            disabled={
              isThisMonth === -1 ||
              (isThisMonth === 0 && d.date < startDate.getDate())
            }
            value={d.date}
          />
        );
      } else {
        days.push(
          <input
            type="button"
            className={`
              div-calendar-cell Tag1
              ${value === dId ? "calendar-selected" : ""}
              ${
                isThisMonth === -1 ||
                (isThisMonth === 0 && d.date < startDate.getDate())
                  ? "calendar-past"
                  : ""
              }
            `}
            onClick={onClick}
            onDoubleClick={() => setModalDisplay(false)}
            id={dId}
            data-day={d.day}
            disabled={
              isThisMonth === -1 ||
              (isThisMonth === 0 && d.date < startDate.getDate())
            }
            value={d.date}
          />
        );
      }
    });
    if (days.length) {
      for (let i = lastDate.getDay(); i < 6; i++) {
        days.push(<div className="div-calendar-cell Tag1 cursor-default" />);
      }

      weeks.push(<div className="div-calendar-week">{days}</div>);
    }

    return weeks;
  };

  useEffect(() => {
    const clickOutside = (e) => {
      if (modalDisplay && !calendarSection.current.contains(e.target)) {
        setModalDisplay(false);
        calendarOpener.current.style.border = "1px solid #CDCDCD";
      }
    };
    if (modalDisplay) {
      window.addEventListener("mouseup", clickOutside);
    }
    return () => {
      window.removeEventListener("mouseup", clickOutside);
    };
  }, [modalDisplay]);

  useEffect(() => {
    setFirstDate(new Date(curDate.getFullYear(), curDate.getMonth(), 1));
    setLastDate(new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0));
    if (
      curDate.getFullYear() === startDate.getFullYear() &&
      curDate.getMonth() === startDate.getMonth()
    ) {
      setIsThisMonth(0);
    } else if (curDate > startDate) {
      setIsThisMonth(1); // curDate는 미래
    } else {
      setIsThisMonth(-1); // curDate는 과거
    }
  }, [curDate]);

  return (
    <div className="div-calendar-wrapper" ref={calendarSection}>
      <div
        className="div-calendar-opener"
        ref={calendarOpener}
        onClick={modalControl}
      >
        <span className="Body4">{value}</span>
        <img src={Icon_Calendar} alt="" />
      </div>
      {modalDisplay && (
        <div className="div-calendar-modal">
          <div className="div-calendar-header Sub3">
            <img
              className={`img-calendar-arrow ${
                isThisMonth !== 1 && "calendar-past"
              }`}
              src={Icon_ArrowLeft}
              alt=""
              name="prev"
              onClick={isThisMonth === 1 ? clickArrow : undefined}
            />
            <span>{`${curDate.getFullYear()}.${curDate.getMonth() + 1}`}</span>
            <img
              className="img-calendar-arrow"
              src={Icon_ArrowRight}
              alt=""
              name="next"
              onClick={clickArrow}
            />
          </div>
          <div className="div-calendar-month">
            <div className="div-calendar-week">
              <span className="div-calendar-cell Tag1 cursor-default calendar-sunday">
                일
              </span>
              <span className="div-calendar-cell Tag1 cursor-default">월</span>
              <span className="div-calendar-cell Tag1 cursor-default">화</span>
              <span className="div-calendar-cell Tag1 cursor-default">수</span>
              <span className="div-calendar-cell Tag1 cursor-default">목</span>
              <span className="div-calendar-cell Tag1 cursor-default">금</span>
              <span className="div-calendar-cell Tag1 cursor-default">토</span>
            </div>
            {showCalendar()}
          </div>
        </div>
      )}
    </div>
  );
}
