import React from "react";

function ChipStatusDocsM({ state }) {
  if (state === 0)
    return (
      <button className="button-chip button-chip-status-0M Body5M">지원</button>
    );
  else {
    switch (state) {
      case 1:
        return (
          <button className="button-chip button-chip-status-1M Body5M">
            합격
          </button>
        );
      case 2:
        return (
          <button className="button-chip button-chip-status-2M Body5M">
            불합격
          </button>
        );
      default:
    }
  }
}

export default ChipStatusDocsM;
