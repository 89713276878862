import React, { useState, useEffect } from "react";
import Loginform from "./Loginform";

import rocket from "../asset/main/rocket.png";
import section_02 from "../asset/main/section_02.png";
import section_05 from "../asset/main/section_05.png";
import section_04 from "../asset/main/section_04.mp4";
import section_06 from "../asset/mainM/section_06.mp4";
import section_07 from "../asset/mainM/section_07.mp4";

import Icon_Fold from "../asset/main/Icon_Fold.svg";
import logo_white from "../asset/main/logo_white.svg";
import background from "../asset/main/background.png";
import background_bottom_blur from "../asset/main/background_bottom_blur.svg";
import Connection from "./Connection";
import Footer from "./Footer";
import CreateClub from "./CreateClub";
import { Swiper, SwiperSlide } from "swiper/react";
import mainClubInfoCard from "../mainClubInfoCard.json";

import "swiper/css";
import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);

function Main() {
  const kakaoAuth_ok = window.localStorage.kakaoAuth_ok || 0;
  const [loginOpen, setLoginOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [info, setInfo] = useState({});

  const clickWrapper = (e) => {};
  const getUserInfo = async () => {
    const res = await Connection("/getUserInfo", {
      session_key: window.localStorage.session_key,
    });
    const res3 = await Connection("/getInfo", {});
    setInfo(res3);
    if (res.is_success) {
      window.localStorage.setItem("name", res.name);
      window.localStorage.setItem("phone", res.phone);
    } else {
      window.localStorage.removeItem("session_key");
    }
  };
  useEffect(() => {
    if (kakaoAuth_ok) {
      setLoginOpen(true);
    }
    if (window.localStorage.session_key) {
      getUserInfo();
    }
  }, []);

  return (
    <>
      {loginOpen && (
        <div
          className="div-loginform-modal-wrapper"
          style={{
            backgroundColor: loginOpen
              ? "rgba(0, 0, 0, 0.3)"
              : "rgba(0, 0, 0, 0)",
          }}
          onClick={clickWrapper}
        >
          <Loginform
            setModalOpen={setLoginOpen}
            registerNumber={kakaoAuth_ok}
          />
        </div>
      )}
      {createOpen && (
        <div
          className="div-loginform-modal-wrapper"
          style={{
            backgroundColor: createOpen
              ? "rgba(0, 0, 0, 0.3)"
              : "rgba(0, 0, 0, 0)",
          }}
        >
          <CreateClub setModalOpen={setCreateOpen} info={info} />
        </div>
      )}
      <div className="div-main-section" id="main">
        <div className="div-main-section-01">
          <img className="img-main-background" src={background} alt="" />
          <img
            className="img-main-logo"
            src={logo_white}
            alt=""
            style={{ width: "201.75px" }}
          />
          <div className="div-main-section-01-middle">
            우리들의 동아리 관리 파트너, 동들
          </div>

          {localStorage.getItem("session_key") ? (
            <button
              className="main-orange-button button-main-start H2"
              onClick={async () => {
                if (window.localStorage.session_key) {
                  const cbu = await Connection("/getClubByUser", {
                    session_key: window.localStorage.session_key,
                  });
                  if (!cbu.club.length) {
                    alert("동아리 등록 후 이용해주세요!!😀");
                    setCreateOpen(true);
                  } else {
                    const ci = await Connection("/getClubInfo", {
                      session_key: window.localStorage.session_key,
                      club_id: cbu.club[0].id,
                    });
                    const club_id = cbu.club[0].id;
                    const recruit_id =
                      ci.recruit.length > 0 ? ci.recruit[0].id : "no_recruit";

                    window.location.href = "/" + club_id + "/" + recruit_id;
                  }
                } else {
                  alert("로그인 후 이용해주세요!");
                  setLoginOpen(true);
                }
              }}
            >
              바로 시작하기
            </button>
          ) : (
            <>
              <button
                className="main-orange-button button-main-start H2"
                onClick={async () => {
                  localStorage.setItem("session_key", "admin_demo");
                  localStorage.setItem("name", "동들 관리자");
                  localStorage.setItem("phone", "01012345678");
                  const cbu = await Connection("/getClubByUser", {
                    session_key: "admin_demo",
                  });

                  const ci = await Connection("/getClubInfo", {
                    session_key: "admin_demo",
                    club_id: cbu.club[0].id,
                  });
                  const club_id = cbu.club[0].id;
                  const recruit_id =
                    ci.recruit.length > 0 ? ci.recruit[0].id : "no_recruit";

                  window.location.href = "/" + club_id + "/" + recruit_id;
                }}
              >
                동들 체험하기
              </button>
              <button
                className="button-main-start-demo-form H3"
                onClick={() =>
                  window.open(
                    "https://dongdle.com/form/986cccae-6181-45a5-a4cb-47c1e1065c7d/986cd456-4e3d-43a5-8ba8-f1f1fa297a49"
                  )
                }
              >
                동들폼 구경하기
              </button>
            </>
          )}

          <div
            className="div-click div-main-section-01-bottom bounce Sub1"
            onClick={() =>
              document.getElementById("main").scrollTo({
                top: document.getElementById("main").clientHeight,
                behavior: "smooth",
              })
            }
          >
            동들에 대해 더 알아보기
            <img src={Icon_Fold} alt="" style={{ width: "18px" }} />
          </div>
        </div>
        <div className="div-main-section-02">
          <div className="div-main-section-02-top">
            <div className="div-main-section-02-top-01">
              <div className="div-main-section-02-top-category" id="vertical">
                <Swiper
                  slidesPerView={1}
                  autoplay={{ delay: 2000, disableOnInteraction: false }}
                  loop={true}
                  direction="vertical"
                  style={{
                    height: "34px",
                  }}
                >
                  <SwiperSlide>연합 동아리</SwiperSlide>
                  <SwiperSlide>학교 동아리</SwiperSlide>
                  <SwiperSlide>대외 활동</SwiperSlide>
                  <SwiperSlide>소규모 스터디</SwiperSlide>
                </Swiper>
              </div>
              운영,
            </div>
            무엇이 고민인가요?
          </div>
          <img className="img-main-section-02-bottom" src={section_02} alt="" />
          <img
            className="img-main-section-02-bottom-blur"
            src={background_bottom_blur}
            alt=""
          />
        </div>
        <div className="div-main-section-03-01">
          <img src={rocket} alt="" style={{ width: "175.5px" }} />
          동들과 함께하면 쉽고 편한 동아리 관리
          <span style={{ fontWeight: "700" }}>
            <span
              style={{
                backgroundColor: "#FF7A00",
                color: "white",
                padding: "0 6px",
                marginRight: "3px",
              }}
            >
              모집, 관리, 홍보
            </span>
            까지 한번에!
          </span>
        </div>
        <div className="div-main-section-03-02">
          전국의 다양한 동아리들이
          <br />
          <br />
          동들과 함께 걷고있어요🤝️
          <br />
          <br />
          <div className="div-main-section-03-02-bottom">
            <Swiper
              slidesPerView={5}
              autoplay={{ delay: 0, disableOnInteraction: false }}
              loop={true}
              speed={2000}
            >
              {mainClubInfoCard.map(({ name, info, insta, image }) => (
                <SwiperSlide key={name}>
                  <div className="div-main-section-03-02-chip-section">
                    <img
                      className="img-main-section-03-02-chip"
                      src={image}
                      alt=""
                    />
                    <div className="H3" style={{ marginBottom: "4.5px" }}>
                      {name}
                    </div>
                    <div className="div-main-section-03-02-chip-content Body4">
                      {info}
                    </div>
                    <div
                      className="div-main-section-03-02-chip-content Sub2"
                      style={{ color: insta ? "#ff7a00" : "white" }}
                    >
                      @{insta}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="div-main-section-03-03">동들은 이런 서비스에요 🚀</div>
        <div className="div-main-section-04">
          <div className="div-main-section-04-left">
            우리 동아리 맞춤 질문들로
            <br />
            <br />
            편하게 지원폼을 만들 수 있어요
            <div className="div-main-section-04-left-sub Body1">
              동들에서 필요할 것 같은 질문들을 선별하여 추천해드립니다.
              <br />
              직접 작성할 필요없이 편하게 질문을 담기만 하세요!
            </div>
          </div>
          <div className="div-main-section-04-right">
            <video
              src={section_04}
              style={{ height: "75%" }}
              muted
              autoPlay
              loop
            />
          </div>
        </div>
        <div className="div-main-section-05">
          <div className="div-main-section-05-left">
            <img src={section_05} style={{ height: "60%" }} alt="" />
          </div>
          <div className="div-main-section-05-right">
            우리 동아리에 지원한
            <br />
            <br />
            지원자들의 정보를 한눈에
            <div className="div-main-section-05-right-sub Body1">
              지원자들의 정보를 한번에 보실 수 있도록 정리해드려요.
              <br />
              원하는 정보만 선별해서 확인할 수 있어 도움이 될 거에요!
            </div>
          </div>
        </div>
        <div className="div-main-section-06">
          <div className="div-main-section-04-left">
            합격자 선별부터 결과 문자까지
            <br />
            <br />
            간편하게 해볼까요?
            <div className="div-main-section-04-left-sub Body1">
              지원자들의 합/불 여부를 버튼으로 간단하게 설정하고
              <br />
              수집한 지원자들의 연락처로 결과 문자까지 한번에 보낼 수 있어요!
            </div>
          </div>
          <div className="div-main-section-04-right">
            <video
              src={section_06}
              style={{ height: "75%" }}
              muted
              autoPlay
              loop
            />
          </div>
        </div>
        {/* section07 */}
        <div className="div-main-section-04">
          <div className="div-main-section-05-left">
            <video
              src={section_07}
              style={{ height: "75%" }}
              muted
              autoPlay
              loop
            />
          </div>
          <div
            className="div-main-section-04-left"
            style={{ textAlign: "right" }}
          >
            지원자와의 약속, 면접 일정
            <br />
            <br />
            편리하게 조율할 수 있어요
            <div className="div-main-section-05-right-sub Body1">
              합격 문자 전송 시, 지원자가 면접 가능한 시간에
              <br />
              직접 면접을 신청할 수 있어 노쇼를 방지할 수 있어요!
            </div>
          </div>
        </div>
        <div className="div-main-section-last H1">
          동들과 함께 새로운 동아리 관리를
          <br />
          경험해보세요!
          <button
            className="main-orange-button button-main-section-last H2"
            onClick={async () => {
              if (window.localStorage.session_key) {
                const cbu = await Connection("/getClubByUser", {
                  session_key: window.localStorage.session_key,
                });
                if (!cbu.club.length) {
                  alert("동아리 등록 후 이용해주세요!!😀");
                  setCreateOpen(true);
                } else {
                  const ci = await Connection("/getClubInfo", {
                    session_key: window.localStorage.session_key,
                    club_id: cbu.club[0].id,
                  });
                  const club_id = cbu.club[0].id;
                  const recruit_id =
                    ci.recruit.length > 0 ? ci.recruit[0].id : "no_recruit";

                  window.location.href = "/" + club_id + "/" + recruit_id;
                }
              } else {
                alert("로그인 후 이용해주세요!");
                setLoginOpen(true);
              }
            }}
          >
            바로 시작하기
          </button>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Main;
