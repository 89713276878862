import React, { useState } from "react";
import styled from "styled-components/macro";

import Icon_ArrowLeft from "../../asset/icon/Icon_ArrowLeft.svg";
import Icon_ArrowRight from "../../asset/icon/Icon_ArrowRight.svg";

import CommunityConnection from "./CommunityConnection";
import MyInterests from "./MyInterests";

export default function MyProfile({ ...props }) {
  const [page, setPage] = useState(false);

  const getPhone = () => {
    const inputPhone = props.profile?.phone;
    let first, second, third;
    if (inputPhone.length === 10) {
      first = inputPhone.slice(0, 3);
      second = inputPhone.slice(3, 6);
      third = inputPhone.slice(6, 10);
    } else if (inputPhone.length === 11) {
      first = inputPhone.slice(0, 3);
      second = inputPhone.slice(3, 7);
      third = inputPhone.slice(7, 11);
    } else return inputPhone;
    return `${first}-${second}-${third}`;
  };
  const getBirth = () => {
    const inputDate = props.profile?.birthday;
    const year = inputDate.slice(0, 4);
    const month = inputDate.slice(4, 6);
    const day = inputDate.slice(6, 8);
    return `${year}.${month}.${day}`;
  };
  const textGender = props.profile?.gender === "male" ? "남자" : "여자";
  const textInterests = props.interests.replace(/,/g, ", ");

  const [nickname, setNickname] = useState(props.nickname || "");
  const updateNickname = async () => {
    CommunityConnection("/updateNickname", {
      session_key: window.localStorage.getItem("session_key"),
      nickname: nickname,
    }).then((res) => {
      if (res.is_success) {
        alert("닉네임 설정이 완료되었습니다.");
        props.getNickname();
      }
    });
  };

  return (
    <>
      {page || (
        <MainContainer>
          <Header className="Sub1M">
            <img
              src={Icon_ArrowLeft}
              alt=""
              onClick={() => props.setPage("mypage-main")}
            />
            내 정보
          </Header>
          <Body>
            <div>
              <MainTitle>회원 정보</MainTitle>
              <ProfileBox>
                <ProfileRow>
                  <p>이름</p>
                  <p>{props.profile?.name}</p>
                </ProfileRow>
                <ProfileRow>
                  <p>전화번호</p>
                  <p>{getPhone()}</p>
                </ProfileRow>
                <ProfileRow>
                  <p>생년월일</p>
                  <p>{getBirth()}</p>
                </ProfileRow>
                <ProfileRow>
                  <p>성별</p>
                  <p>{textGender}</p>
                </ProfileRow>
              </ProfileBox>
              <ButtonModifyProfile 
                className="Sub4M"
                onClick={() => {
                  window.open("http://pf.kakao.com/_xnQWxfxj");
                }}
              >
                정보 변경 문의하기
              </ButtonModifyProfile>
            </div>
            <div>
              <MainTitle>추가 정보</MainTitle>
              <SubTitle>닉네임</SubTitle>
              <SectionNickname>
                <InputNickName
                  className="-input-box Body4M"
                  placeholder="닉네임을 입력하세요."
                  maxLength={10}
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                />
                <ButtonUpdateNickname
                  className="Sub4M"
                  onClick={() => {
                    updateNickname();
                  }}
                  disabled={nickname === (props.nickname || "")}
                >
                  저장
                </ButtonUpdateNickname>
              </SectionNickname>
              <SubTitle>관심 분야</SubTitle>
              <DivInterests
                className="Body4M"
                isEmpty={props.interests === ""}
                onClick={() => setPage(true)}
              >
                {props.interests === ""
                  ? "선택된 관심 분야가 없어요!"
                  : textInterests}
                <img src={Icon_ArrowRight} alt="" />
              </DivInterests>
            </div>
          </Body>
          <Footer>
            <p className="Sub4M">서비스 탈퇴</p>
          </Footer>
        </MainContainer>
      )}
      {page && (
        <MyInterests
          setPage={setPage}
          interests={props.interests}
          getInterests={props.getInterests}
        />
      )}
    </>
  );
}
const MainContainer = styled.div`
  min-width: 280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  flex-shrink: 0;
  padding: 0px 12px;
  height: 56px;
  display: flex;
  gap: 8px;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
const Body = styled.div`
  padding: 12px;
  > div:first-child {
    border-bottom: 1px solid #ececec;
    padding-bottom: 16px;
    padding-top: 8px;
  }
  > div:nth-child(2) {
    padding-top: 20px;
  }
`;
const MainTitle = styled.p.attrs({
  className: "Sub3M",
})`
  margin-bottom: 14px;
`;
const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;
const ProfileRow = styled.div.attrs({
  className: "Body5M",
})`
  display: flex;
  gap: 20px;
  color: #6a6a6a;
  > p:first-child {
    width: 68px;
  }
`;
const ButtonModifyProfile = styled.button`
  padding: 9px 16px;
  width: 100%;
  height: 36px;
  text-align: center;
  border: 1px solid black;
  border-radius: 8px;
`;
const SubTitle = styled.p.attrs({
  className: "Sub4M",
})`
  margin-bottom: 12px;
`;
const SectionNickname = styled.section`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;
const InputNickName = styled.input`
  border: 1px solid #cdcdcd;
  width: 100%;
`;
const ButtonUpdateNickname = styled.button`
  flex-shrink: 0;
  color: white;
  border-radius: 8px;
  padding: 12px 16px;
  text-align: center;
  ${props => props.disabled ? "background-color: #ECECEC;" : "background-color: black;"}
`;
const DivInterests = styled.section`
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  background-color: #f8f8f8;
  color: ${(props) => (props.isEmpty ? "#cdcdcd" : "black")};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  > img {
    height: 24px;
    width: 24px;
  }
`;
const Footer = styled.div`
  flex-grow: 1;
  min-height: 78px;
  position: relative;
  > * {
    color: #808080;
    position: absolute;
    bottom: 30px;
    left: 16px;
    text-decoration-line: underline;
    font-weight: 400;
  }
`;
